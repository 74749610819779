import axios from 'axios';
import { BASE_URL } from './api';

export async function getSession(token, page) {
    return axios({
        method: 'get',
        url: `${BASE_URL}/research_sessions/get_open_session/`,
        headers: {
            'Authorization': `Token ${token}`
        },
        params: {
            page: page
        }
    }).then((response) => {
        return response;
    }).catch((error) => {
        throw error
    });
}

export async function startSession(token, data) {
    return axios({
        method: 'post',
        url: `${BASE_URL}/research_sessions/start_session/`,
        headers: {
            'Authorization': `Token ${token}`
        },
        data: data
    }).then((response) => {
        return response;
    }).catch((error) => {
        throw error
    });
}

export async function endSession(token, sessionId) {
    return axios({
        method: 'put',
        url: `${BASE_URL}/research_sessions/${sessionId}/end_session/`,
        headers: {
            'Authorization': `Token ${token}`
        }
    }).then((response) => {
        return response;
    }).catch((error) => {
        throw error
    });
}