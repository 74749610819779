<template>
    <div class="flex flex-col py-0.5 border-b border-gray-200 odd:bg-gray-50 even:white">
        <div class="px-2 pb-12 font-semibold">{{ statusGroupNumberr }}. {{ statusGroupTitle }}</div>
    </div>
</template>

<script setup>
import { ref, defineProps, computed } from 'vue';

const props = defineProps(['statusGroup', 'index'])


const statusGroupNumberr = computed(() => {
    return props.index != undefined ? props.index + 1 : 0
})

const statusGroupTitle = computed(() => {
    return props.statusGroup && props.statusGroup.title ? props.statusGroup.title : ''
})
</script>