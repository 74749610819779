<template>
  <div class= "justify-center items-center mx-1">
    <div class="flex flex-row justify-between items-center pb-0.5 border-b mx-1.5 border-b-gray-400">
      <h3 class="text-sm">Bulk Operations:</h3>
    </div>
    <div class="grid grid-cols-2 pt-1 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-7 2xl:flex 2xl:flex-row 2xl:flex-wrap justify-center 2xl:justify-start items-center">
      <base-pill-button
        class="m-1 2xl:my-1 text-xs" 
        v-if="userPermissions.isDev ||
          userPermissions.isAccountManager ||
          userPermissions.isManagement ||
          userPermissions.isSuperuser ||
          userPermissions.isAccountStaff ||
          userPermissions.isAdvancedResearch
        " 
        @click="setCompanyBulkAction('Assign To Collection', 'bulk_assign_collection_tag');loadCollectionTags()">
          Assign To Collection
      </base-pill-button>
      <base-pill-button
        class="m-1 2xl:my-1 text-xs" 
        v-if="userPermissions.isDev ||
          userPermissions.isAccountManager ||
          userPermissions.isManagement ||
          userPermissions.isSuperuser ||
          userPermissions.isAccountStaff
        " 
        @click="setCompanyBulkAction('Remove From Collection', 'bulk_remove_collection_tag');loadCollectionTags()">
          Remove From Collection
      </base-pill-button>
      <base-pill-button
        class="m-1 2xl:my-1 text-xs"
        v-if="userPermissions.isDev ||
          userPermissions.isAccountManager ||
          userPermissions.isManagement ||
          userPermissions.isSuperuser ||
          userPermissions.isAccountStaff
        "
        @click="setCollectionTagBulkAction('Assign All Results To Collection', 'This will assign all companies in search results (total companies) to the designated collection.', 'bulk_assign_query_results_to_collection_tag'); loadCollectionTags()">
          Assign All Results To Collection
      </base-pill-button>
      <!-- <base-pill-button
        class="m-1 2xl:my-1 text-xs"
        v-if="userPermissions.isDev ||
          userPermissions.isAccountManager ||
          userPermissions.isManagement ||
          userPermissions.isSuperuser ||
          userPermissions.isAccountStaff
        "
        @click="setCollectionTagBulkAction('Delete Collection', 'This will remove all companies from the designated collection and delete the collection.', 'remove_all_companies_from_collection_and_delete_collection'); loadCollectionTags()">
          Delete Collection
      </base-pill-button> -->
      <base-pill-button
        class="m-1 2xl:my-1 text-xs" 
        v-if="userPermissions.isDev ||
          userPermissions.isManagement ||
          userPermissions.isSuperuser
        " 
        @click="setCompanyBulkAction('Assign Source Owner', 'bulk_assign_source_owner');loadSourceOwners()">
          Assign Source Owner
      </base-pill-button>
      <base-pill-button
        class="m-1 2xl:my-1 text-xs"
        v-if="userPermissions.isDev ||
          userPermissions.isAccountManager ||
          userPermissions.isManagement ||
          userPermissions.isSuperuser ||
          userPermissions.isResearchLead
        "
        @click="setCompanyBulkAction('Get ZoomInfo IDs', 'bulk_get_zoom_info_ids')">
          Get ZoomInfo IDs
      </base-pill-button>
      <base-pill-button
        class="m-1 2xl:my-1 text-xs"
        v-if="userPermissions.isDev ||
          userPermissions.isAccountManager ||
          userPermissions.isManagement ||
          userPermissions.isSuperuser ||
          userPermissions.isResearchLead
        "
        @click="setCompanyBulkAction('Get ZI Basic', 'bulk_enrich_zoom_info_companies')">
          Get ZI Basic
      </base-pill-button>
      <base-pill-button
        class="m-1 2xl:my-1 text-xs"
        v-if="userPermissions.isDev ||
          userPermissions.isAccountManager ||
          userPermissions.isManagement ||
          userPermissions.isSuperuser ||
          userPermissions.isResearchLead
        "
        @click="setCompanyBulkAction('Transfer ZI Basic', 'bulk_transfer_zi_basic')">
          Transfer ZI Basic
      </base-pill-button>
      <base-pill-button
        class="m-1 2xl:my-1 text-xs"
        v-if="userPermissions.isDev ||
          userPermissions.isAccountManager ||
          userPermissions.isManagement ||
          userPermissions.isSuperuser ||
          userPermissions.isResearchLead
        "
        @click="setCompanyBulkAction('Update Category', 'bulk_update_category')">
          Update Category
      </base-pill-button>
      <base-pill-button
        class="m-1 2xl:my-1 text-xs"
        v-if="userPermissions.isDev ||
          userPermissions.isAccountManager ||
          userPermissions.isManagement ||
          userPermissions.isSuperuser ||
          userPermissions.isResearchLead
        "
        @click="setCompanyBulkAction('Update Relevant Business', 'bulk_update_relevant_business')">
          Update Relevant Business
      </base-pill-button>
      <base-pill-button
        class="m-1 2xl:my-1 text-xs"
        v-if="userPermissions.isDev ||
          userPermissions.isAccountManager ||
          userPermissions.isManagement ||
          userPermissions.isSuperuser ||
          userPermissions.isResearchLead
        "
        @click="setCompanyBulkAction('Mark Archive Reason', 'bulk_mark_archive_reason')">
          Mark Archive Reason
      </base-pill-button>
      <base-pill-button
        class="m-1 2xl:my-1 text-xs"
        v-if="userPermissions.isDev ||
          userPermissions.isAccountManager ||
          userPermissions.isManagement ||
          userPermissions.isSuperuser
        "
        @click="setCompanyBulkAction('Mark Tier 1', 'bulk_mark_tier', 1)">
          Mark Tier 1
      </base-pill-button>
      <base-pill-button
        class="m-1 2xl:my-1 text-xs"
        v-if="userPermissions.isDev ||
          userPermissions.isAccountManager ||
          userPermissions.isManagement ||
          userPermissions.isSuperuser
        "
        @click="setCompanyBulkAction('Mark Tier 2', 'bulk_mark_tier', 2)">
          Mark Tier 2
      </base-pill-button>
      <base-pill-button
        class="m-1 2xl:my-1 text-xs"
        v-if="userPermissions.isDev ||
          userPermissions.isAccountManager ||
          userPermissions.isManagement ||
          userPermissions.isSuperuser
        "
        @click="setCompanyBulkAction('Mark Tier 3', 'bulk_mark_tier', 3)">
          Mark Tier 3
      </base-pill-button>
      <base-pill-button
        class="m-1 2xl:my-1 text-xs"
        v-if="userPermissions.isDev ||
          userPermissions.isAccountManager ||
          userPermissions.isManagement ||
          userPermissions.isSuperuser
        "
        @click="setCompanyBulkAction('Mark Tier 4', 'bulk_mark_tier', 4)">
          Mark Tier 4
      </base-pill-button>
      <base-pill-button
        class="m-1 2xl:my-1 text-xs"
        v-if="userPermissions.isDev ||
          userPermissions.isAccountManager ||
          userPermissions.isManagement ||
          userPermissions.isSuperuser
        "
        @click="setCompanyBulkAction('Mark Tier 5', 'bulk_mark_tier', 5)">
          Mark Tier 5
      </base-pill-button>
      <base-pill-button
        class="m-1 2xl:my-1 text-xs"
        v-if="userPermissions.isDev ||
          userPermissions.isAccountManager ||
          userPermissions.isManagement ||
          userPermissions.isSuperuser
        "
        @click="setCompanyBulkAction('Mark Tier Removed', 'bulk_mark_tier', null)">
          Mark Tier Removed
      </base-pill-button>
      <base-pill-button
        class="m-1 2xl:my-1 text-xs"
        v-if="userPermissions.isDev ||
          userPermissions.isAccountManager ||
          userPermissions.isManagement ||
          userPermissions.isSuperuser
        "
        @click="setCompanyBulkAction('Assign To Campaign', 'bulk_assign_to_campaign'); loadCampaigns()">
          Assign To Campaign
      </base-pill-button>
      <base-pill-button
        class="m-1 2xl:my-1 text-xs"
        v-if="userPermissions.isDev ||
          userPermissions.isAccountManager ||
          userPermissions.isManagement ||
          userPermissions.isSuperuser
        "
        @click="setCompanyBulkAction('Remove From Campaign', 'bulk_remove_from_campaign')">
          Remove From Campaign
      </base-pill-button>
      <base-pill-button
        class="m-1 2xl:my-1 text-xs"
        v-if="userPermissions.isDev ||
          userPermissions.isAccountManager ||
          userPermissions.isManagement ||
          userPermissions.isSuperuser ||
          userPermissions.isAccountStaff ||
          userPermissions.isAdvancedResearch
        "
        @click="setCompanyBulkAction('Resolve Duplicates', 'bulk_resolve_duplicates')">
          Resolve Duplicates
      </base-pill-button>
      <!-- <base-pill-button
        class="m-1 2xl:my-1 text-xs"
        v-if="userPermissions.isDev ||
          userPermissions.isAccountManager ||
          userPermissions.isManagement ||
          userPermissions.isSuperuser ||
          userPermissions.isAccountStaff
        "
        @click="setCompanyBulkAction('Mark CRM Status', 'bulk_mark_crm_status')">
          Mark CRM Status
      </base-pill-button> -->
      <base-pill-button
        class="m-1 2xl:my-1 text-xs"
        v-if="userPermissions.isDev ||
          userPermissions.isAccountManager ||
          userPermissions.isManagement ||
          userPermissions.isSuperuser ||
          userPermissions.isAccountStaff
        "
        @click="setCompanyBulkAction('Mark CRM Next Action', 'bulk_mark_crm_next_action')">
          Mark CRM Next Action
      </base-pill-button>
      <base-pill-button
        class="m-1 2xl:my-1 text-xs"
        v-if="userPermissions.isDev ||
          userPermissions.isAccountManager ||
          userPermissions.isManagement ||
          userPermissions.isSuperuser ||
          userPermissions.isAccountStaff
        "
        @click="setCompanyBulkAction('Mark CRM Next Action Due Date', 'bulk_mark_crm_next_action_due_date')">
          Mark CRM Due Date
      </base-pill-button>
      <base-pill-button
        class="m-1 2xl:my-1 text-xs"
        v-if="userPermissions.isDev ||
          userPermissions.isAccountManager ||
          userPermissions.isManagement ||
          userPermissions.isSuperuser ||
          userPermissions.isAccountStaff
        "
        @click="setCompanyBulkAction('Add New CRM Detail', 'bulk_add_new_crm_action'); loadCrmUsers(); loadActionOptions()">
          Add New CRM Detail
      </base-pill-button>
      <base-pill-button
        class="m-1 2xl:my-1 text-xs"
        v-if="userPermissions.isDev ||
          userPermissions.isAccountManager ||
          userPermissions.isManagement ||
          userPermissions.isSuperuser ||
          userPermissions.isAccountStaff
        "
        @click="setCompanyBulkAction('Assign CRM Status', 'bulk_assign_new_crm_status'); loadCrmStatuses()">
          Assign CRM Status
      </base-pill-button>
      <base-pill-button
        class="m-1 2xl:my-1 text-xs"
        v-if="userPermissions.isDev ||
          userPermissions.isAccountManager ||
          userPermissions.isManagement ||
          userPermissions.isSuperuser ||
          userPermissions.isAccountStaff
        "
        @click="setCompanyBulkAction('Add to CRM Summary', 'bulk_add_to_crm_summary')">
          Append to CRM Summary
      </base-pill-button>
      <base-pill-button
        class="m-1 2xl:my-1 text-xs" 
        v-if="userPermissions.isDev ||
          userPermissions.isManagement ||
          userPermissions.isAccountManager ||
          userPermissions.isAccountStaff ||
          userPermissions.isSuperuser
        " 
        @click="setCompanyBulkAction('Mark Client Feedback', 'bulk_mark_client_feedback')">
          Mark Client Feedback
      </base-pill-button>
      <base-pill-button
        class="m-1 2xl:my-1 text-xs" 
        v-if="userPermissions.isDev ||
          userPermissions.isManagement ||
          userPermissions.isSuperuser ||
          userPermissions.isAccountManager ||
          userPermissions.isAccountStaff ||
          userPermissions.isAdvancedResearch ||
          userPermissions.isResearchLead
        " 
        @click="setCompanyBulkAction('Mark EverAxis Pillar', 'bulk_mark_everaxis_pillar')">
          Mark EverAxis Pillar
      </base-pill-button>
      <base-pill-button
        class="m-1 2xl:my-1 text-xs"
        v-if="userPermissions.isDev ||
          userPermissions.isAccountManager ||
          userPermissions.isManagement ||
          userPermissions.isSuperuser ||
          userPermissions.isAccountStaff
        "
        @click="setCompanyBulkAction('Bulk Send Email', 'bulk_send_email_template'); loadEmailTemplates()">
          Bulk Send Email
      </base-pill-button>
      <!-- <base-pill-button
        class="m-1 2xl:my-1 text-xs"
        v-if="userPermissions.isDev ||
          userPermissions.isAccountManager ||
          userPermissions.isManagement ||
          userPermissions.isSuperuser
        "
        @click="setBingApiBulkAction('Bing URL Discovery', 'bing_api_search')">
          Bing URL Discovery
      </base-pill-button> -->
      <base-pill-button
        class="m-1 2xl:my-1 text-xs"
        v-if="userPermissions.isDev
        "
        @click="setCompanyBulkAction('Export ZI Ids', 'export_zi_ids')">
          Export ZI Ids
      </base-pill-button>
    </div>
    <base-dialog-box v-if="isCompanyModalVisible" @close="closeCompanyModal">
        <template #header>
          <h2>Bulk Action: {{ bulkActionTitle }}</h2>
        </template>
        <template #body>
          <company-bulk-actions
            :title="bulkActionTitle"
            :endpoint="bulkActionEndpoint"
            :bulkActionData="bulkActionData"
            :sourceOwners="sourceOwners"
            :collectionTags="collectionTags"
            :campaigns="campaigns"
            :crmUsers="crmUsers"
            :crmStatuses="crmStatuses"
            :selectedCompanies="selectedCompanies"
            :emailTemplates="emailTemplates"
            :action-options="actionOptions"
            @reset-select-all="resetSelectAll"
            @close-modal="closeCompanyModal"
            @refetch-companies="fetchNewCompanies"
            @clear-selected-companies="clearSelectedCompanies"
          ></company-bulk-actions>
        </template>
    </base-dialog-box>
    <base-dialog-box v-if="isCollectionTagModalVisible" @close="closeCollectionTagModal">
        <template #header>
          <h2>Bulk Action: {{ bulkActionTitle }}</h2>
        </template>
        <template #body>
          <collection-tag-bulk-actions
            :title="bulkActionTitle"
            :message="bulkActionMessage"
            :filters="filters"
            :endpoint="bulkActionEndpoint"
            :bulkActionData="bulkActionData"
            :collectionTags="collectionTags"
            @close-modal="closeCollectionTagModal"
            @refetch-companies="fetchNewCompanies"
          ></collection-tag-bulk-actions>
        </template>
    </base-dialog-box>
  </div>
</template>

<script setup>
  import { ref, computed, shallowReactive } from 'vue';
  import CompanyBulkActions from './CompanyBulkActions.vue';
  import CollectionTagBulkActions from './CollectionTagBulkActions.vue';
  import { getUsersByGroup } from '@/services/User';
  import { getCollectionTags } from '@/services/CollectionTag';
  import { getEmailTemplates } from '@/services/EmailTemplates';
  import { getCampaigns } from '@/services/Campaign';
  import { getCrmStatuses } from '@/services/CrmStatus';
  import { getActions } from '@/services/CrmAction';
  import {useStore} from 'vuex';
  import { defineProps, defineEmits } from 'vue';

  const props = defineProps({
    selectedCompanies: {
      type: Array,
      required: false
    },
    userPermissions: {
      type: Object,
      required: false
    },
    filters: {
      type: String,
      required: false
    }
  })
  const emit = defineEmits(['refetch-companies', 'clear-selected-companies', 'reset-select-all'])

  const isCompanyModalVisible = ref(false);
  const isCollectionTagModalVisible = ref(false);
  const sourceOwners = ref([]);
  const collectionTags = ref([]);
  const emailTemplates = ref([]);
  const campaigns = ref([]);
  const crmUsers = ref([]);
  const actionOptions = ref([])
  const crmStatuses = ref([]);
  const bulkActionTitle = ref(null);
  const bulkActionMessage = ref(null);
  const bulkActionEndpoint = ref(null);
  const bulkActionData = ref(null);
  const store = useStore();
  const showBulk = ref(true);
  const showBulkText = ref('Hide Bulk Ops');
  const userPermissions = shallowReactive({
  isManagement: null,
  isHourly: null,
  isStudent: null,
  isResearch: null,
  isAdvancedResearch: null,
  isSuperuser: null,
  isAccountManager: null,
  isAccountStaff: null,
  isDev: null,
  isResearchLead: null,
  isAllCompanies: null,
  isGenerateTimecard: null,
});



  async function loadCollectionTags(){
    const token = store.getters['auth/showToken']
    const result = await getCollectionTags(token)
    collectionTags.value = result
  }

  async function loadCampaigns(){
    const token = store.getters['auth/showToken']
    const result = await getCampaigns(token)
    campaigns.value = result
  }

  async function loadCrmStatuses(){
    const token = store.getters['auth/showToken']
    const result = await getCrmStatuses(token)
    crmStatuses.value = result
  }

  async function loadActionOptions(){
    const token = store.getters['auth/showToken']
    const result = await getActions(token)
    actionOptions.value = result.data
  }



  async function loadEmailTemplates(){
    const token = store.getters['auth/showToken']
    const result = await getEmailTemplates(token)
    // console.log('email template results', result)
    emailTemplates.value = result.map((template) => {
        return {value: template.id, label: `${template.email_template_name} | Email to: ${template.email_to.email_address}`}
    })
  }

  async function loadSourceOwners(){
    let payload = {
      token: store.state.auth.token,
      endpoint: 'list_users',
      group: 'advanced_research_group'
    }
    const result = await getUsersByGroup(payload)
    sourceOwners.value = result.users
  }

  async function loadCrmUsers(){
    let payload = {
      token: store.state.auth.token,
      endpoint: 'list_users',
      group: 'account_manager_group'
    }
    const result = await getUsersByGroup(payload)
    crmUsers.value = result.users
  }

  function showCompanyModal() {
    isCompanyModalVisible.value = true;
  }

  function closeCompanyModal() {
    isCompanyModalVisible.value = false;
  }

  function showCollectionTagModal() {
    isCollectionTagModalVisible.value = true;
  }

  function closeCollectionTagModal() {
    isCollectionTagModalVisible.value = false;
  }
  
  function setCompanyBulkAction(title, endpoint, data=null) {
    if(props.selectedCompanies.length > 0) {
      bulkActionTitle.value = title
      bulkActionEndpoint.value = endpoint
      bulkActionData.value = data
    } else {
      bulkActionTitle.value = 'Alert'
      bulkActionEndpoint.value = null
      bulkActionData.value = null
    }
    showCompanyModal()
  }

  function setCollectionTagBulkAction(title, message, endpoint, data=null) {
    bulkActionTitle.value = title
    bulkActionMessage.value = message
    bulkActionEndpoint.value = endpoint
    bulkActionData.value = data
    showCollectionTagModal()
  }

  function setBingApiBulkAction(title, endpoint, data=null) {
    console.log('bing api')
    bulkActionTitle.value = title
    bulkActionEndpoint.value = endpoint
    bulkActionData.value = data
    showCompanyModal()
  }

  function toggleBulk() {
    showBulk.value = !showBulk.value;
    if (showBulkText.value === 'Hide Bulk Ops') {
      showBulkText.value = 'Show Bulk Ops'
    } else {
      showBulkText.value = 'Hide Bulk Ops'
    }
  }

  function fetchNewCompanies() {
    // console.log('bulk ops refetch companies ran')
    emit('refetch-companies')
  }

  function clearSelectedCompanies() {
    // console.log('bulk ops - clear selected companies ran')
    emit('clear-selected-companies')
  }

  function resetSelectAll() {
    // console.log('bulk ops reset select all ran')
    emit('reset-select-all')
  }
  const setUserPermissions = function () {
  let userPermissionsStore = store.getters['auth/showUserPermissions']
  // console.log('user permissions store', userPermissionsStore)
  userPermissions.isManagement = userPermissionsStore.isManagement
  userPermissions.isHourly = userPermissionsStore.isHourly
  userPermissions.isStudent =  userPermissionsStore.isStudent
  userPermissions.isResearch =  userPermissionsStore.isResearch
  userPermissions.isAdvancedResearch =  userPermissionsStore.isAdvancedResearch
  userPermissions.isSuperuser =  userPermissionsStore.isSuperuser
  userPermissions.isAccountManager =  userPermissionsStore.isAccountManager
  userPermissions.isAccountStaff =  userPermissionsStore.isAccountStaff
  userPermissions.isDev =  userPermissionsStore.isDev
  userPermissions.isResearchLead =  userPermissionsStore.isResearchLead
  userPermissions.isAllCompanies =  userPermissionsStore.isAllCompanies
  userPermissions.isGenerateTimecard =  userPermissionsStore.isGenerateTimecard

  // console.log('user permissions', userPermissions)
}
  const RenderBulkOperations = computed(function () {
  if(userPermissions.isAccountManager){
    return true
  }
  if(userPermissions.isAccountStaff){
    return true
  }
  if(userPermissions.isDev){
    return true
  }
  if(userPermissions.isManagement){
    return true
  }
  if(userPermissions.isResearchLead){
    return true
  }
  if(userPermissions.isSuperuser){
    return true
  }
  return false
})

  setUserPermissions()
// logUserPermissions()
</script>

<style scoped>

</style>