<template>
    <base-table-row>
        <base-table-cell :class="{'bg-blue-100' : isActiveCompany}" class="">
            <div class="">
                <input @change="setActiveCompany" name="selectedCompany" type="radio">
            </div>
        </base-table-cell>
        <base-table-cell :class="{'bg-blue-100' : isActiveCompany}">
            <div class="flex items-center">
                {{ company.id }}
            </div>
        </base-table-cell>
        <base-table-cell :class="{'bg-blue-100' : isActiveCompany}">
            <div class="flex items-center">
                {{ company.company_name }}
            </div>
        </base-table-cell>
        <base-table-cell :class="{'bg-blue-100' : isActiveCompany}">
            <div class="flex items-center">
                {{ city }}
            </div>
        </base-table-cell>
        <base-table-cell :class="{'bg-blue-100' : isActiveCompany}">
            <div class="flex items-center">
                {{ state }}
            </div>
        </base-table-cell>
        <base-table-cell :class="{'bg-blue-100' : isActiveCompany}">
            <div class="flex items-center">
                {{ country }}
            </div>
        </base-table-cell>
        <base-table-cell :class="{'bg-blue-100' : isActiveCompany}">
            <!-- <div v-if="company.website_url" class="flex items-center p-2">
                <a target="_blank" class="underline text-blue-500" :href="company.website_url">{{ company.website_url }}</a> 
            </div> -->
            <div class="flex justify-between items-center w-full p-2" >
                <input v-model="newUrl" @blur="addUrl" class="border rounded-md p-1 w-3/4" type="url">
                <div class="flex flex-col">
                    <base-button><a target="_blank" :href="googleUrlLink">Search</a></base-button>
                    <base-button @click="assignNoUrl" class="my-1">None</base-button>
                </div>
            </div>
        </base-table-cell>
        <base-table-cell :class="{'bg-blue-100' : isActiveCompany}">
            <div v-if="hasNoZoomInfoCompany" class="flex flex-col justify-center p-2">
                <div class="flex justify-between items-center">
                    No Zoom Info Company
                </div>
            </div>
            <div v-else class="flex flex-col justify-center p-2">
                <ol class="" v-if="company.zoom_info_id_candidates.length > 0">
                    <li :class="{'bg-yellow-200' : ziId == activeZiId}" class=" w-fit px-1" v-for="(ziId, idx) in company.zoom_info_id_candidates" :key="ziId">
                        {{ idx + 1 }}. {{ ziId }}
                    </li>
                </ol>
                <div class="flex justify-between items-center w-full">
                    <input @blur="addZoomInfoId" class="border rounded-md p-1 w-3/4" v-model="newZiId" type="number">
                    <div class="flex flex-col">
                        <base-button class="my-1"><a target="_blank" :href="googleZoomInfoLink">Search</a></base-button>
                        <base-button @click="assignNoZiCompany" class="my-1">None</base-button>
                    </div>
                </div>
            </div>
        </base-table-cell>

    </base-table-row>

</template>


<script setup>
import { ref, defineProps, defineEmits, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { useToast } from 'vue-toast-notification';

const props = defineProps({
    company: Object,
    activeCompany: Object,
    activeZiId: Number,
    newZiId: Number
})

const store = useStore()
const $toast = useToast()

const emit = defineEmits(['setActiveCompany', 'addCompanyUrl', 'addCompanyZoomInfoId', 'setZiIndex'])

function setActiveCompany(){
    emit('setActiveCompany', props.company)
}


const newUrl = ref(props.company.website_url)
const newZiId = ref(props.company.zoom_info_company ? props.company.zoom_info_company.zoom_info_id : null)

function addUrl(){
    emit('addCompanyUrl', props.company.id, newUrl.value)
}

function addZoomInfoId(){
    emit('addCompanyZoomInfoId', props.company.id, newZiId.value)
}

const hasNoZoomInfoCompany = computed(() => {
    return (props.company.zoom_info_company && props.company.zoom_info_company.zoom_info_id == -1) || props.company.zoom_info_id == -1
})

// watch(newUrl, () => {
//     addUrl(newUrl.value)
// })

// watch(newZiId, () => {
//     addZoomInfoId(newZiId.value)
// })

const isActiveCompany = computed(() => {
    return props.activeCompany && props.activeCompany.id == props.company.id
})

const shiftPressed = ref(false)

function registershiftPressed(e) {
    // console.log('ctrl pressed')
    if(e.key === 'Shift') {
        // e.preventDefault()
        shiftPressed.value = true
    }
}

function registerShiftReleased(e) {
    // console.log('ctrl released')
    if(e.key === 'Shift') {
        // e.preventDefault()
        shiftPressed.value = false
    }
}

function openGoogleUrlSearch(){
    window.open(googleUrlLink.value, '_blank').focus()
}

function openGoogleZoomInfoSearch(){
    window.open(googleZoomInfoLink.value, '_blank').focus()
}

function registerKeyPress(e) {
    if(shiftPressed.value && isActiveCompany.value){
        if(e.key === 'G'){
            e.preventDefault()
            openGoogleUrlSearch()
        }
        else if (e.key === 'Z') {
            e.preventDefault()
            openGoogleZoomInfoSearch()
        } else if (e.key === 'Enter') {
            newZiId.value = props.activeZiId
        }
    } else if(isActiveCompany.value && props.company.zoom_info_id_candidates.length  > 0){
        if(1 <= parseInt(e.key) <= props.company.zoom_info_id_candidates.length){
            emit('setZiIndex', parseInt(e.key))
        }
    }
}

function assignNoZiCompany(){
    emit('addCompanyZoomInfoId', props.company.id, -1)
}
function assignNoUrl(){
    emit('addCompanyUrl', props.company.id, `http://www.no-url-${crypto.randomUUID()}.com`)
}

const state = computed(() => {
    return props.company.state ? props.company.state : '--'
})

const city = computed(() => {
    return props.company.city ? props.company.city : '--'
})

const country = computed(() => {
    return props.company.country ? props.company.country : '--'
})

const googleUrlLink = computed(() => {
    return `https://www.google.com/search?q=${props.company.company_name.replace(' ', '+').replace('&', '%26')}+${props.company.city ? props.company.city.replace(' ', '+') : ''}+${props.company.state ? props.company.state.replace(' ', '+') : ''}+${props.company.country ? props.company.country.replace(' ', '+') : ''}`
})

const googleZoomInfoLink = computed(() => {
    return `https://www.google.com/search?q=${props.company.company_name.replace(' ', '+').replace('&', '%26')}+ZoomInfo`
})

watch(isActiveCompany, () => {
    if(isActiveCompany.value){
        document.addEventListener("keydown", registershiftPressed, false)
        document.addEventListener("keyup", registerShiftReleased, false)
        document.addEventListener("keydown", registerKeyPress, false)
    } else {
        document.removeEventListener("keydown", registershiftPressed, false)
        document.removeEventListener("keyup", registerShiftReleased, false)
        document.removeEventListener("keypress", registerKeyPress, false)
    }
})

watch(props, () => {
    if(props.newZiId && props.company.zoom_info_id_candidates.length > 0 && props.company.zoom_info_id_candidates.includes(props.newZiId)){
        newZiId.value = props.newZiId
        addZoomInfoId()
    }
})

watch(() => props.company.website_url, () => {
    newUrl.value = props.company.website_url
})

watch(() => props.company.zoom_info_id, (oldValue, newValue) => {
    console.log('old', oldValue)
    console.log('new', newValue)
    newZiId.value = props.company.zoom_info_id
})



</script>

<style>
.itemsTable-enter-active,
.itemsTable-leave-active {
  transition: all 0.2s ease-in;
}
.itemsTable-enter-from {
    transform: scaleY(0);
    transform-origin: top;
}
.itemsTable-leave-to {
  transform: scaleY(0);
  transform-origin: top;
}
</style>