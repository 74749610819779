<template>
  <div class="relative px-2 w-full text-xs h-full">
    <form @submit.prevent="submitForm" class="h-full">
      <base-form class="h-full">
        <template #body>
          <div class="w-full h-full">
            <div class="grid grid-cols-3 gap-3 w-full">
              <!-- <div class=""> -->
                <div class="flex flex-col pb-3">
                  <label for="companyName">Client Name*</label>
                  <input
                    class="border p-1 border-dark-primary rounded-md w-full"
                    type="text"
                    id="clientName"
                    v-model="client_name"
                    placeholder="Enter client name with no punctuation"
                    required="True"
                  />
                </div>
                <div class="flex flex-col pb-3">
                  <label for="legalBusinessName">Legal Business Name</label>
                  <input
                    class="border p-1 border-dark-primary rounded-md w-full"
                    type="text"
                    id="legalBusinessName"
                    v-model="legal_business_name"
                    placeholder="Legal Business Name"
                  />
                </div>
                <div class="flex flex-col pb-3">
                  <label for="billingName">Billing Name</label>
                  <input
                    class="border p-1 border-dark-primary rounded-md w-full"
                    type="text"
                    id="billingName"
                    v-model="billing_name"
                    placeholder="Billing Name"
                  />
                </div>
                <div class="flex flex-col pb-3">
                  <label for="owner">Owner</label>
                  <input
                    class="border p-1 border-dark-primary rounded-md w-full"
                    type="text"
                    id="owner"
                    v-model="owner"
                    placeholder="Owner"
                  />
                </div>
                <div class="flex flex-col pb-2">
                  <label for="acquisitionDate">Engagement Start</label>
                  <input
                    class="border p-1 border-dark-primary rounded-md w-full"
                    type="date"
                    id="engagementStart"
                    v-model="engagement_start"
                  />
                </div>
                <div class="flex flex-col pb-2">
                  <label for="city">Dropbox URL</label>
                  <input
                    class="border p-1 border-dark-primary rounded-md w-full"
                    type="url"
                    id="pdfLink"
                    v-model="dropbox_url"
                  />
                </div>
              <!-- </div> -->
              <!-- <div> -->
                <div class="flex flex-col pb-2">
                  <label for="researchComplete">Status</label>
                  <select
                    id="status"
                    v-model="status"
                    class="border p-1 border-dark-primary rounded-md text-gray-900 focus:ring-blue-500 focus:border-blue-500 block w-full placeholder-gray-400 focus:border-blue-500"
                  >
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                    <option value="prospect">Prospect</option>
                  </select>
                </div>
                <div class="flex flex-col pb-2">
                  <label for="acquisitionDate">Engagement End</label>
                  <input
                    class="border p-1 border-dark-primary rounded-md w-full"
                    type="date"
                    id="engagementEnd"
                    v-model="engagement_end"
                  />
                </div>
                <div class="flex flex-col pb-2">
                  <label for="address">Retainer Amount</label>
                  <input
                    class="border p-1 border-dark-primary rounded-md w-full"
                    type="number"
                    id="retainerAmount"
                    placeholder=" e.g. 10000"
                    v-model="retainer_amount"
                  />
                </div>
              <!-- </div> -->
              <!-- <div> -->
                <div class="flex flex-col pb-2">
                  <label for="researchComplete">Account Manager</label>
                  <select
                    id="accountManagerID"
                    v-model="account_manager_id"
                    class="border p-1 border-dark-primary rounded-md w-full text-gray-900 focus:ring-blue-500 focus:border-blue-500 block placeholder-gray-400 focus:border-blue-500"
                  >
                    <option
                      v-for="option in accountManagerOptions"
                      :key="option"
                      :value="option.value"
                    >
                      {{ option.label }}
                    </option>
                  </select>
                </div>
                <div class="flex flex-col pb-2">
                  <label for="state">Fee Structure</label>
                  <input
                    class="border p-1 border-dark-primary rounded-md w-full"
                    type="text"
                    id="feeStructure"
                    v-model="fee_structure"
                  />
                </div>
                <div class="flex flex-col pb-2">
                  <label for="address">Retainer Frequency</label>
                  <input
                    class="border p-1 border-dark-primary rounded-md w-full"
                    type="text"
                    id="retainerFrequency"
                    v-model="retainer_frequency"
                  />
                </div>
                <div class="flex flex-col col-span-3 pb-2">
                  <label for="address">Accounts Payable Emails</label>
                  <input
                    class="border p-1 border-dark-primary rounded-md w-full"
                    type="email"
                    multiple
                    id="accountsPayableEmails"
                    v-model="accounts_payable_emails"
                    placeholder="emails separated by commas"
                  />
                </div>
                <div class="flex flex-col col-span-3 pb-2">
                  <label for="address">Accounts Payable CCs</label>
                  <input
                    class="border p-1 border-dark-primary rounded-md w-full"
                    type="email"
                    multiple
                    id="accountsPayableCCs"
                    v-model="accounts_payable_ccs"
                    placeholder="emails separated by commas"
                  />
                </div>
                <div class="flex flex-col col-span-3 pb-2">
                  <label for="address">Client Websites</label>
                  <input
                    class="border p-1 border-dark-primary rounded-md w-full"
                    type="text"
                    multiple
                    id="clientWebsites"
                    v-model="website_urls"
                    placeholder="webistes separated by commas"
                  />
                </div>
              <!-- </div> -->
            </div>
            <div class="grid grid-cols-3 gap-3 w-full">
              <div>
                <div class="flex flex-col col-span-2 pb-2">
                  <label for="mergeConflicts">Contact Notes</label>
                  <textarea
                    class="border p-1 border-dark-primary rounded-md w-full"
                    rows="5"
                    id="contactNotes"
                    v-model="contact_notes"
                  ></textarea>
                </div>
                <div class="flex flex-col col-span-2 pb-2">
                  <label for="mergeConflicts">Scope of Buying</label>
                  <textarea
                    class="border p-1 border-dark-primary rounded-md w-full"
                    rows="5"
                    id="scopeOfBuying"
                    v-model="scope_of_buying"
                  ></textarea>
                </div>
              </div>
              <div>
                <div class="flex flex-col col-span-2 pb-2">
                  <label for="mergeConflicts">Duration of Retainer</label>
                  <textarea
                    class="border p-1 border-dark-primary rounded-md w-full"
                    rows="5"
                    id="durationOfRetainer"
                    v-model="duration_of_retainer"
                  ></textarea>
                </div>
                <div class="flex flex-col col-span-2 pb-2">
                  <label for="mergeConflicts">Engagement Notes</label>
                  <textarea
                    class="border p-1 border-dark-primary rounded-md w-full"
                    rows="5"
                    id="engagementNotes"
                    v-model="engagement_notes"
                  ></textarea>
                </div>
              </div>
              <div>
                <div class="flex flex-col col-span-2 pb-2">
                  <label for="mergeConflicts">Source History</label>
                  <textarea
                    class="border p-1 border-dark-primary rounded-md w-full"
                    rows="5"
                    id="sourceHistory"
                    v-model="source_history"
                  ></textarea>
                </div>
              </div>
            </div>
            <div v-if="client" class="grid grid-cols-3 w-full mt-2">
              <div ref="configurationInfo" class="col-span-3 w-full border-b">
                  Invoice Reminder Configuration
                  <div  @mouseover="showPopover" @mouseleave="hidePopover" class="border inline border-black h-fit bg-white bg-opacity-40 text-black px-1 rounded-lg text-xs hover:cursor-default">?</div>
              </div>
              <base-popover-hover-small
                  class="w-fit"
                  v-if="isPopoverVisible"
                  :xPosition="xPosition"
                  :yPosition="yPosition"
              >
                  <template #title>
                    <span class="text-sm">Configuration Info:</span>
                  </template>
                  <template #body>
                      <div class="py-1 border-gray-300 w-full">
                          These numbers indicate the days from an invoice's due date on which you will be prompted to send a reminder to the client. Negative values indicate days before the invoice due date; positive values, days after the invoice due date. For example: -2 is two days before the invoice is due, 2 is two days after the invoice is due.
                      </div>
                  </template>
              </base-popover-hover-small>
            </div>
            <div v-if="client" class="grid grid-cols-3 gap-3 w-full p-1">
              <div class="flex space-x-2 pb-3 col-span-3">
                  <label class="flex items-center" for="daysFromInvoiceDueDate">Days from Invoice Due Date:</label>
                  <input
                    class="border p-1 border-dark-primary rounded-md w-fit"
                    type="number"
                    id="daysFromInvoiceDueDate"
                    v-model="daysFromInvoiceDueDate"
                  />
                  <base-button-affirmative @click.prevent="addInvoiceConfig">
                    Add
                  </base-button-affirmative>
                  <div v-if="invoiceReminderConfigurations" class="flex space-x-2">
                    <base-pill-x @click="removeInvoiceReminderConfig(config.id)" class="flex items-center justify-between space-x-2 px-2 h-fit hover:cursor-pointer" v-for="config in invoiceReminderConfigurations" :key="config.id">
                      <div>{{ config.days_from_invoice_due_date }}</div>
                      <!-- <div>✗</div> -->
                    </base-pill-x>
                  </div>
              </div>
            </div>
          </div>
        </template>
        <template #footer>
          <div class="flex flex-col items-center my-2">
            <base-button-affirmative type="submit"
              >Save</base-button-affirmative
            >
          </div>
        </template>
      </base-form>
    </form>
  </div>
</template>

<script setup>
// imports

import { ref, defineEmits, defineProps, onMounted, computed } from "vue";
import { getUsersByGroup } from "../../services/User.js";
import { useStore } from "vuex";
import { addInvoiceReminderConfiguration } from "@/services/Client.js"
import { deleteClientInvoiceReminderConfiguration } from "@/services/ClientInvoiceReminderConfiguration.js"
import { useToast } from 'vue-toast-notification';

import ClientCloseButton from "./UI/ClientCloseButton.vue";

// constants/variables

const store = useStore();
const $toast = useToast()

const props = defineProps(["client", "index", "account_id", "addOrEdit"]);
const emit = defineEmits(["submit-edit-form","close-edit-form", "submit-add-form", "close-add-form", "refetch-clients"]);

const client_name = ref(props.client ? props.client.client_name: null);
const id = ref(props.client ? props.client.id: null);
const status = ref(props.client ? props.client.status: null);
const account_manager_id = ref(
  computeAccountManagerID(props.client ? props.client.account_manager: null)
);
const engagement_start = ref(props.client ? props.client.engagement_start: null);
const engagement_end = ref(props.client ? props.client.engagement_end: null);
const fee_structure = ref(props.client ? props.client.fee_structure: null);
const retainer_frequency = ref(props.client ? props.client.retainer_frequency: null);
const accounts_payable_emails = ref(props.client ? props.client.accounts_payable_emails: null);
const accounts_payable_ccs = ref(props.client ? props.client.accounts_payable_ccs: null);
const website_urls = ref(props.client ? props.client.website_urls: null);
const dropbox_url = ref(props.client ? props.client.dropbox_url: null);
const contact_notes = ref(props.client ? props.client.contact_notes: null);
const retainer_amount = ref(props.client ? props.client.retainer_amount: null);
const scope_of_buying = ref(props.client ? props.client.scope_of_buying: null);
const duration_of_retainer = ref(props.client ? props.client.duration_of_retainer: null);
const engagement_notes = ref(props.client ? props.client.engagement_notes: null);
const source_history = ref(props.client ? props.client.source_history: null);
const legal_business_name = ref(props.client ? props.client.legal_business_name: null);
const billing_name = ref(props.client ? props.client.billing_name: null);
const owner = ref(props.client ? props.client.owner: null);
const daysFromInvoiceDueDate = ref(0)

const accountManagerOptions = ref([]);
const isPopoverVisible = ref(false)
const configurationInfo = ref(null)
const xPosition = ref(0)
const yPosition = ref(0)

function showPopover() {
    isPopoverVisible.value = true;
    xPosition.value = configurationInfo.value.getBoundingClientRect().left
    yPosition.value = configurationInfo.value.getBoundingClientRect().bottom
}

function hidePopover() {
    isPopoverVisible.value = false;
    xPosition.value = 0;
    yPosition.value = 0;
}
// async functions

async function setAccountManagerOptions() {
  let payload = {
    token: store.state.auth.token,
    endpoint: "users",
    group: "account_manager_group&group=management_group",
  };
  const result = await getUsersByGroup(payload);
  accountManagerOptions.value = [
    { value: "clear", label: "------------" },
    ...result.users
      .filter((accountManager) => ![2, 10, 16, 22].includes(accountManager.id))
      .map((accountManager) => {
        return {
          value: accountManager.id,
          label: accountManager.first_name + " " + accountManager.last_name,
        };
      }),
  ];
}

const invoiceReminderConfigurations = computed(() => {
  if(props.client && props.client.invoice_reminder_configurations.length > 0){
    return props.client.invoice_reminder_configurations
  } else {
    return null
  }
})

async function addInvoiceConfig(){
  const token = store.state.auth.token
  const data = {
    days_from_invoice_due_date: daysFromInvoiceDueDate.value
  }
  const payload = {
    data: data,
    token: token,
    id: props.client.id
  }
  // console.log('payload', payload)
  const result = await addInvoiceReminderConfiguration(payload)
  if(result.status == 201){
    $toast.open({
      message: `Successfully added configuration for ${props.client.client_name}`,
      type: "success",
      duration: 3000
    })
    daysFromInvoiceDueDate.value = 0
    emit('refetch-clients')
  } else if (result.status == 400){
    $toast.open({
      message: `Configuration for ${props.client.client_name} with ${daysFromInvoiceDueDate.value} days already exists`,
      type: "error",
      duration: 3000
    })
  } else {
    console.log('result', result)
    $toast.open({
      message: `Could not create configuration for ${props.client.client_name}`,
      type: "error",
      duration: 3000
    })
  }
}

async function removeInvoiceReminderConfig(configId){
  const token = store.state.auth.token
  const result = await deleteClientInvoiceReminderConfiguration(token, configId)
  if(result.status == 204){
    $toast.open({
      message: `Removed configuration for ${props.client.client_name}`,
      type: "success",
      duration: 3000
    })
    emit('refetch-clients')
  } else {
    $toast.open({
      message: `Could not remove configuration for ${props.client.client_name}`,
      type: "error",
      duration: 3000
    })
  }
}

// functions

function submitForm() {
  if(!Array.isArray(accounts_payable_emails.value) && accounts_payable_emails.value){
      accounts_payable_emails.value = accounts_payable_emails.value.split(",")
  }
  if(!Array.isArray(accounts_payable_ccs.value) && accounts_payable_ccs.value){
      accounts_payable_ccs.value = accounts_payable_ccs.value.split(",")
  }
  if(!Array.isArray(website_urls.value) && website_urls.value){
      website_urls.value = website_urls.value.split(",")
  }
  if(!website_urls.value){
    website_urls.value = []
  }
  if(!accounts_payable_ccs.value){
    accounts_payable_ccs.value = []
  }
  if(!accounts_payable_emails.value){
    accounts_payable_emails.value = []
  }
  const formData = JSON.stringify({
    client_name: client_name.value,
    status: status.value,
    account_manager_id: account_manager_id.value,
    engagement_start: engagement_start.value,
    engagement_end: engagement_end.value,
    fee_structure: fee_structure.value,
    retainer_frequency: retainer_frequency.value,
    dropbox_url: dropbox_url.value,
    contact_notes: contact_notes.value,
    retainer_amount: retainer_amount.value,
    scope_of_buying: scope_of_buying.value,
    duration_of_retainer: duration_of_retainer.value,
    engagement_notes: engagement_notes.value,
    source_history: source_history.value,
    legal_business_name: legal_business_name.value,
    billing_name: billing_name.value,
    owner: owner.value,
    accounts_payable_emails: accounts_payable_emails.value,
    accounts_payable_ccs: accounts_payable_ccs.value,
    website_urls: website_urls.value
  });
  if (props.addOrEdit === "add") {
    emit("submit-add-form", formData);
  } else {
    emit("submit-edit-form", id.value, formData);
  }
}

function closeForm() {
    if (props.addOrEdit === "add") {
        emit("close-add-form");
    } else {
        emit("close-edit-form")
    }
}

function computeAccountManagerID(manager) {
  if (manager) {
    return manager.id;
  } else {
    return null;
  }
}

// life-cycle

onMounted(() => {
  setAccountManagerOptions();
});
</script>
