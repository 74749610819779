import axios from 'axios';
import { BASE_URL } from './api';

export async function getDataRequests(token, queryParams) {
    return axios({
        method: 'get',
        url: `${BASE_URL}/data-requests/${queryParams}`,
        headers: {
            'Authorization': `Token ${token}`
        },
    }).then((response) => {
        console.log(response)
        return response;
    }).catch((error) => {
        console.log(error)
        return error
    });
}

export async function createDataRequest(token, formData) {
    return axios({
        method: 'post',
        url: `${BASE_URL}/data-requests/`,
        headers: { 
            'Authorization': `Token ${token}`, 
            'Content-Type': 'application/json'
        },
        data: formData
    }).then((response) => {
        return response
    }).catch((error) => {
        return error
    })
}

export async function updateDataRequest(token, dataRequestId, formData) {
    return axios({
        method: 'put',
        url: `${BASE_URL}/data-requests/${dataRequestId}/`,
        headers: { 
            'Authorization': `Token ${token}`, 
            'Content-Type': 'application/json'
        },
        data: formData
    }).then((response) => {
        return response
    }).catch((error) => {
        return error
    })
}

export async function deleteDataRequest(token, dataRequestId) {
    return axios({
        method: 'delete',
        url: `${BASE_URL}/data-requests/${dataRequestId}/`,
        headers: { 
            'Authorization': `Token ${token}`, 
            'Content-Type': 'application/json'
        },
    }).then((response) => {
        return response
    }).catch((error) => {
        return error
    })
}