<template>
<svg xmlns="http://www.w3.org/2000/svg"
    fill="none" 
    viewBox="0 0 24 24" 
    :stroke-width="width" 
    :stroke="stroke" 
    class="w-4 h-4">
        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
    </svg>
</template>

<script setup>
import { defineProps, computed } from 'vue';

const props = defineProps(['strokeColor', 'strokeWidth'])

const stroke = computed(() => {
    return props.strokeColor ? props.strokeColor : 'black'
})

const width = computed(() => {
    return props.strokeWidth ? props.strokeWidth : '3'
})

</script>