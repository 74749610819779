<template>
    <div class="text-dark-primary flex flex-col">
        <p class="underline">
            Filter Options
        </p>
        <div class="flex flex-col items-start ml-1 mb-3">
            <div class="mt-1">
                <input type="checkbox" id="checkbox" class="align-middle" :checked="showFilterPane" @change="toggleShowFilterPane"/>
                <span v-if="!showBasicFilters && !showCampaignFilters && !showResearchFilters" class="ml-1 mr-2 text-gray-400">Filter Pane</span>
                <span v-else class="ml-1 mr-2">Filter Pane</span>
            </div>
            <div class="ml-4 mt-1">
                <div>
                    <input type="checkbox" id="checkbox" class="align-middle" :checked="showBasicFilters" @change="toggleShowBasicFilters"/>
                    <span v-if="!showFilterPane" class="ml-1 mr-2 text-gray-400">Basic Filters</span> 
                    <span v-else class="ml-1 mr-2">Basic Filters</span>
                    <span @click="toggleShowIndividualBasicFilters" class="hover:cursor-pointer"  v-if="showIndividualBasicFilters">▼</span>
                    <span @click="toggleShowIndividualBasicFilters" class="hover:cursor-pointer"  v-else>▶</span>
                </div>
                <div v-show="showIndividualBasicFilters">
                    <div class="ml-4 mt-1">
                        <input type="checkbox" id="checkbox" class="align-middle" :checked="visibleFilters.companyNameFilter" @change="toggleShowCompanyFilter('companyNameFilter')"/>
                        <span v-if="!showFilterPane" class="ml-1 mr-2 text-gray-400">Company Name</span> 
                        <span v-else class="ml-1 mr-2">Company Name</span>
                    </div>
                    <div class="ml-4 mt-1">
                        <input type="checkbox" id="checkbox" class="align-middle" :checked="visibleFilters.companyIdFilter" @change="toggleShowCompanyFilter('companyIdFilter')"/>
                        <span v-if="!showFilterPane" class="ml-1 mr-2 text-gray-400">Company ID</span> 
                        <span v-else class="ml-1 mr-2">Company ID</span>
                    </div>
                    <div class="ml-4 mt-1">
                        <input type="checkbox" id="checkbox" class="align-middle" :checked="visibleFilters.companyCageCodeFilter" @change="toggleShowCompanyFilter('companyCageCodeFilter')"/>
                        <span v-if="!showFilterPane" class="ml-1 mr-2 text-gray-400">CAGE Code</span> 
                        <span v-else class="ml-1 mr-2">CAGE Code</span>
                    </div>
                    <div class="ml-4 mt-1">
                        <input type="checkbox" id="checkbox" class="align-middle" :checked="visibleFilters.companyDescriptionFilter" @change="toggleShowCompanyFilter('companyDescriptionFilter')"/>
                        <span v-if="!showFilterPane" class="ml-1 mr-2 text-gray-400">Description</span> 
                        <span v-else class="ml-1 mr-2">Description</span>
                    </div>
                    <div class="ml-4 mt-1">
                        <input type="checkbox" id="checkbox" class="align-middle" :checked="visibleFilters.companyWebsiteUrlFilter" @change="toggleShowCompanyFilter('companyWebsiteUrlFilter')"/>
                        <span v-if="!showFilterPane" class="ml-1 mr-2 text-gray-400">Website Url</span> 
                        <span v-else class="ml-1 mr-2">Website Url</span>
                    </div>
                    <div class="ml-4 mt-1">
                        <input type="checkbox" id="checkbox" class="align-middle" :checked="visibleFilters.companyAddressFilter" @change="toggleShowCompanyFilter('companyAddressFilter')"/>
                        <span v-if="!showFilterPane" class="ml-1 mr-2 text-gray-400">Address</span> 
                        <span v-else class="ml-1 mr-2">Address</span>
                    </div>
                    <div class="ml-4 mt-1">
                        <input type="checkbox" id="checkbox" class="align-middle" :checked="visibleFilters.companyStateFilter" @change="toggleShowCompanyFilter('companyStateFilter')"/>
                        <span v-if="!showFilterPane" class="ml-1 mr-2 text-gray-400">State</span> 
                        <span v-else class="ml-1 mr-2">State</span>
                    </div>
                    <div class="ml-4 mt-1">
                        <input type="checkbox" id="checkbox" class="align-middle" :checked="visibleFilters.companyCountryFilter" @change="toggleShowCompanyFilter('companyCountryFilter')"/>
                        <span v-if="!showFilterPane" class="ml-1 mr-2 text-gray-400">Country</span> 
                        <span v-else class="ml-1 mr-2">Country</span>
                    </div>
                    <div class="ml-4 mt-1">
                        <input type="checkbox" id="checkbox" class="align-middle" :checked="visibleFilters.companyBusinessTypeFilter" @change="toggleShowCompanyFilter('companyBusinessTypeFilter')"/>
                        <span v-if="!showFilterPane" class="ml-1 mr-2 text-gray-400">Business Type</span> 
                        <span v-else class="ml-1 mr-2">Business Type</span>
                    </div>
                    <div class="ml-4 mt-1">
                        <input type="checkbox" id="checkbox" class="align-middle" :checked="visibleFilters.companySectorFilter" @change="toggleShowCompanyFilter('companySectorFilter')"/>
                        <span v-if="!showFilterPane" class="ml-1 mr-2 text-gray-400">Sector</span> 
                        <span v-else class="ml-1 mr-2">Sector</span>
                    </div>
                    <div class="ml-4 mt-1">
                        <input type="checkbox" id="checkbox" class="align-middle" :checked="visibleFilters.companySubSectorFilter" @change="toggleShowCompanyFilter('companySubSectorFilter')"/>
                        <span v-if="!showFilterPane" class="ml-1 mr-2 text-gray-400">Sub Sector</span> 
                        <span v-else class="ml-1 mr-2">Sub Sector</span>
                    </div>
                    <div class="ml-4 mt-1">
                        <input type="checkbox" id="checkbox" class="align-middle" :checked="visibleFilters.companyCategoryFilter" @change="toggleShowCompanyFilter('companyCategoryFilter')"/>
                        <span v-if="!showFilterPane" class="ml-1 mr-2 text-gray-400">Category</span> 
                        <span v-else class="ml-1 mr-2">Category</span>
                    </div>
                    <div class="ml-4 mt-1">
                        <input type="checkbox" id="checkbox" class="align-middle" :checked="visibleFilters.companyCollectionTagsFilter" @change="toggleShowCompanyFilter('companyCollectionTagsFilter')"/>
                        <span v-if="!showFilterPane" class="ml-1 mr-2 text-gray-400">Collection Tags</span> 
                        <span v-else class="ml-1 mr-2">Collection Tags</span>
                    </div>
                    <div class="ml-4 mt-1">
                        <input type="checkbox" id="checkbox" class="align-middle" :checked="visibleFilters.companyCollectionTagSourcesFilter" @change="toggleShowCompanyFilter('companyCollectionTagSourcesFilter')"/>
                        <span v-if="!showFilterPane" class="ml-1 mr-2 text-gray-400">Collection Tag Sources</span> 
                        <span v-else class="ml-1 mr-2">Collection Tag Sources</span>
                    </div>
                    <div class="ml-4 mt-1">
                        <input type="checkbox" id="checkbox" class="align-middle" :checked="visibleFilters.companyCollectionReviewFilter" @change="toggleShowCompanyFilter('companyCollectionReviewFilter')"/>
                        <span v-if="!showFilterPane" class="ml-1 mr-2 text-gray-400">Collection Review</span> 
                        <span v-else class="ml-1 mr-2">Collection Review</span>
                    </div>
                    <div class="ml-4 mt-1">
                        <input type="checkbox" id="checkbox" class="align-middle" :checked="visibleFilters.companyCollectionTagExcludeFilter" @change="toggleShowCompanyFilter('companyCollectionTagExcludeFilter')"/>
                        <span v-if="!showFilterPane" class="ml-1 mr-2 text-gray-400">Collection Tag Exclude</span> 
                        <span v-else class="ml-1 mr-2">Collection Tag Exclude</span>
                    </div>
                    <div class="ml-4 mt-1">
                        <input type="checkbox" id="checkbox" class="align-middle" :checked="visibleFilters.companyNAICSFilter" @change="toggleShowCompanyFilter('companyNAICSFilter')"/>
                        <span v-if="!showFilterPane" class="ml-1 mr-2 text-gray-400">NAICS Code</span> 
                        <span v-else class="ml-1 mr-2">NAICS Code</span>
                    </div>
                    <div class="ml-4 mt-1">
                        <input type="checkbox" id="checkbox" class="align-middle" :checked="visibleFilters.companyRevenueFilter" @change="toggleShowCompanyFilter('companyRevenueFilter')"/>
                        <span v-if="!showFilterPane" class="ml-1 mr-2 text-gray-400">Revenue</span> 
                        <span v-else class="ml-1 mr-2">Revenue</span>
                    </div>
                    <div class="ml-4 mt-1">
                        <input type="checkbox" id="checkbox" class="align-middle" :checked="visibleFilters.companyRevenueZiCombinedFilter" @change="toggleShowCompanyFilter('companyRevenueZiCombinedFilter')"/>
                        <span v-if="!showFilterPane" class="ml-1 mr-2 text-gray-400">Revenue - ZI Combined</span> 
                        <span v-else class="ml-1 mr-2">Revenue - ZI Combined</span>
                    </div>
                    <div class="ml-4 mt-1">
                        <input type="checkbox" id="checkbox" class="align-middle" :checked="visibleFilters.companyHeadcountFilter" @change="toggleShowCompanyFilter('companyHeadcountFilter')"/>
                        <span v-if="!showFilterPane" class="ml-1 mr-2 text-gray-400">Headcount</span> 
                        <span v-else class="ml-1 mr-2">Headcount</span>
                    </div>
                    <div class="ml-4 mt-1">
                        <input type="checkbox" id="checkbox" class="align-middle" :checked="visibleFilters.companyParentCompanyStatusFilter" @change="toggleShowCompanyFilter('companyParentCompanyStatusFilter')"/>
                        <span v-if="!showFilterPane" class="ml-1 mr-2 text-gray-400">Parent Company Status</span> 
                        <span v-else class="ml-1 mr-2">Parent Company Status</span>
                    </div>
                    <div class="ml-4 mt-1">
                        <input type="checkbox" id="checkbox" class="align-middle" :checked="visibleFilters.companyInvestorsStatusFilter" @change="toggleShowCompanyFilter('companyInvestorsStatusFilter')"/>
                        <span v-if="!showFilterPane" class="ml-1 mr-2 text-gray-400">Investors Status</span> 
                        <span v-else class="ml-1 mr-2">Investors Status</span>
                    </div>
                    <div class="ml-4 mt-1">
                        <input type="checkbox" id="checkbox" class="align-middle" :checked="visibleFilters.companyProductServiceCodeFilter" @change="toggleShowCompanyFilter('companyProductServiceCodeFilter')"/>
                        <span v-if="!showFilterPane" class="ml-1 mr-2 text-gray-400">Product Service Code</span> 
                        <span v-else class="ml-1 mr-2">Product Service Code</span>
                    </div>
                    <div class="ml-4 mt-1">
                        <input type="checkbox" id="checkbox" class="align-middle" :checked="visibleFilters.companyContractPSCKeywordFilter" @change="toggleShowCompanyFilter('companyContractPSCKeywordFilter')"/>
                        <span v-if="!showFilterPane" class="ml-1 mr-2 text-gray-400">Contract PSC Keyword</span> 
                        <span v-else class="ml-1 mr-2">Contract PSC Keyword</span>
                    </div>
                    <div class="ml-4 mt-1">
                        <input type="checkbox" id="checkbox" class="align-middle" :checked="visibleFilters.companyContractNAICSKeywordFilter" @change="toggleShowCompanyFilter('companyContractNAICSKeywordFilter')"/>
                        <span v-if="!showFilterPane" class="ml-1 mr-2 text-gray-400">Contract NAICS Keyword</span> 
                        <span v-else class="ml-1 mr-2">Contract NAICS Keyword</span>
                    </div>
                    <div class="ml-4 mt-1">
                        <input type="checkbox" id="checkbox" class="align-middle" :checked="visibleFilters.companyParentTypeFilter" @change="toggleShowCompanyFilter('companyParentTypeFilter')"/>
                        <span v-if="!showFilterPane" class="ml-1 mr-2 text-gray-400">Parent Type</span> 
                        <span v-else class="ml-1 mr-2">Parent Type</span>
                    </div>
                    <div class="ml-4 mt-1">
                        <input type="checkbox" id="checkbox" class="align-middle" :checked="visibleFilters.companyCapabilitiesFilter" @change="toggleShowCompanyFilter('companyCapabilitiesFilter')"/>
                        <span v-if="!showFilterPane" class="ml-1 mr-2 text-gray-400">Capabilities</span> 
                        <span v-else class="ml-1 mr-2">Capabilities</span>
                    </div>
                    <div class="ml-4 mt-1">
                        <input type="checkbox" id="checkbox" class="align-middle" :checked="visibleFilters.companyEnrichedAfterFilter" @change="toggleShowCompanyFilter('companyEnrichedAfterFilter')"/>
                        <span v-if="!showFilterPane" class="ml-1 mr-2 text-gray-400">Enriched After</span> 
                        <span v-else class="ml-1 mr-2">Enriched After</span>
                    </div>
                </div>
            </div>
            <div class="ml-4 mt-1">
                <div>
                    <input type="checkbox" id="checkbox" class="align-middle" :checked="showCampaignFilters" @change="toggleShowCampaignFilters"/>
                    <span v-if="!showFilterPane" class="ml-1 mr-2 text-gray-400">Campaign Filters</span> 
                    <span v-else class="ml-1 mr-2">Campaign Filters</span>
                    <span @click="toggleShowIndividualCampaignFilters" class="hover:cursor-pointer"  v-if="showIndividualCampaignFilters">▼</span>
                    <span @click="toggleShowIndividualCampaignFilters" class="hover:cursor-pointer"  v-else>▶</span>
                </div>
                <div v-show="showIndividualCampaignFilters">
                    <div class="ml-4 mt-1">
                        <input type="checkbox" id="checkbox" class="align-middle" :checked="visibleFilters.companySourcesFilter" @change="toggleShowCompanyFilter('companySourcesFilter')"/>
                        <span v-if="!showFilterPane" class="ml-1 mr-2 text-gray-400">Sources</span> 
                        <span v-else class="ml-1 mr-2">Sources</span>
                    </div>
                    <div class="ml-4 mt-1">
                        <input type="checkbox" id="checkbox" class="align-middle" :checked="visibleFilters.companyCampaignFilter" @change="toggleShowCompanyFilter('companyCampaignFilter')"/>
                        <span v-if="!showFilterPane" class="ml-1 mr-2 text-gray-400">Campaign</span> 
                        <span v-else class="ml-1 mr-2">Campaign</span>
                    </div>
                    <div class="ml-4 mt-1">
                        <input type="checkbox" id="checkbox" class="align-middle" :checked="visibleFilters.companyCampaignChangeRequestorFilter" @change="toggleShowCompanyFilter('companyCampaignChangeRequestorFilter')"/>
                        <span v-if="!showFilterPane" class="ml-1 mr-2 text-gray-400">Campaign Change Requestor</span> 
                        <span v-else class="ml-1 mr-2">Campaign Change Requestor</span>
                    </div>
                    <div class="ml-4 mt-1">
                        <input type="checkbox" id="checkbox" class="align-middle" :checked="visibleFilters.companyCampaignExcludeFilter" @change="toggleShowCompanyFilter('companyCampaignExcludeFilter')"/>
                        <span v-if="!showFilterPane" class="ml-1 mr-2 text-gray-400">Campaign Exclude</span> 
                        <span v-else class="ml-1 mr-2">Campaign Exclude</span>
                    </div>
                    <div class="ml-4 mt-1">
                        <input type="checkbox" id="checkbox" class="align-middle" :checked="visibleFilters.companyPreviousCampaignFilter" @change="toggleShowCompanyFilter('companyPreviousCampaignFilter')"/>
                        <span v-if="!showFilterPane" class="ml-1 mr-2 text-gray-400">Previous Campaign</span> 
                        <span v-else class="ml-1 mr-2">Previous Campaign</span>
                    </div>
                    <div class="ml-4 mt-1">
                        <input type="checkbox" id="checkbox" class="align-middle" :checked="visibleFilters.companyTierFilter" @change="toggleShowCompanyFilter('companyTierFilter')"/>
                        <span v-if="!showFilterPane" class="ml-1 mr-2 text-gray-400">Tier</span> 
                        <span v-else class="ml-1 mr-2">Tier</span>
                    </div>
                    <div class="ml-4 mt-1">
                        <input type="checkbox" id="checkbox" class="align-middle" :checked="visibleFilters.companyAccountManagerResearchNotesFilter" @change="toggleShowCompanyFilter('companyAccountManagerResearchNotesFilter')"/>
                        <span v-if="!showFilterPane" class="ml-1 mr-2 text-gray-400">Account Manager Research Notes</span> 
                        <span v-else class="ml-1 mr-2">Account Manager Research Notes</span>
                    </div>
                    <div class="ml-4 mt-1">
                        <input type="checkbox" id="checkbox" class="align-middle" :checked="visibleFilters.companyClientFeedbackFilter" @change="toggleShowCompanyFilter('companyClientFeedbackFilter')"/>
                        <span v-if="!showFilterPane" class="ml-1 mr-2 text-gray-400">Client Feedback</span> 
                        <span v-else class="ml-1 mr-2">Client Feedback</span>
                    </div>
                    <div class="ml-4 mt-1">
                        <input type="checkbox" id="checkbox" class="align-middle" :checked="visibleFilters.companyClientFilter" @change="toggleShowCompanyFilter('companyClientFilter')"/>
                        <span v-if="!showFilterPane" class="ml-1 mr-2 text-gray-400">Client</span> 
                        <span v-else class="ml-1 mr-2">Client</span>
                    </div>
                    <div class="ml-4 mt-1">
                        <input type="checkbox" id="checkbox" class="align-middle" :checked="visibleFilters.companyCRMStatusFilter" @change="toggleShowCompanyFilter('companyCRMStatusFilter')"/>
                        <span v-if="!showFilterPane" class="ml-1 mr-2 text-gray-400">CRM Status</span> 
                        <span v-else class="ml-1 mr-2">CRM Status</span>
                    </div>
                    <div class="ml-4 mt-1">
                        <input type="checkbox" id="checkbox" class="align-middle" :checked="visibleFilters.companyCRMNextActionFilter" @change="toggleShowCompanyFilter('companyCRMNextActionFilter')"/>
                        <span v-if="!showFilterPane" class="ml-1 mr-2 text-gray-400">CRM Next Action</span> 
                        <span v-else class="ml-1 mr-2">CRM Next Action</span>
                    </div>
                    <div class="ml-4 mt-1">
                        <input type="checkbox" id="checkbox" class="align-middle" :checked="visibleFilters.companyDueTimeRangeFilter" @change="toggleShowCompanyFilter('companyDueTimeRangeFilter')"/>
                        <span v-if="!showFilterPane" class="ml-1 mr-2 text-gray-400">Due Time Range</span> 
                        <span v-else class="ml-1 mr-2">Due Time Range</span>
                    </div>
                    <div class="ml-4 mt-1">
                        <input type="checkbox" id="checkbox" class="align-middle" :checked="visibleFilters.companyDrivingTimeFocusFilter" @change="toggleShowCompanyFilter('companyDrivingTimeFocusFilter')"/>
                        <span v-if="!showFilterPane" class="ml-1 mr-2 text-gray-400">Driving Time Focus</span> 
                        <span v-else class="ml-1 mr-2">Driving Time Focus</span>
                    </div>
                    <div class="ml-4 mt-1">
                        <input type="checkbox" id="checkbox" class="align-middle" :checked="visibleFilters.companyDrivingTimeFilter" @change="toggleShowCompanyFilter('companyDrivingTimeFilter')"/>
                        <span v-if="!showFilterPane" class="ml-1 mr-2 text-gray-400">Driving Time</span> 
                        <span v-else class="ml-1 mr-2">Driving Time</span>
                    </div>
                    <div class="ml-4 mt-1">
                        <input type="checkbox" id="checkbox" class="align-middle" :checked="visibleFilters.companyTaskUserFilter" @change="toggleShowCompanyFilter('companyTaskUserFilter')"/>
                        <span v-if="!showFilterPane" class="ml-1 mr-2 text-gray-400">Task User</span> 
                        <span v-else class="ml-1 mr-2">Task User</span>
                    </div>
                    <div class="ml-4 mt-1">
                        <input type="checkbox" id="checkbox" class="align-middle" :checked="visibleFilters.showMyTasksFilter" @change="toggleShowCompanyFilter('showMyTasksFilter')"/>
                        <span v-if="!showFilterPane" class="ml-1 mr-2 text-gray-400">Show My Tasks</span> 
                        <span v-else class="ml-1 mr-2">Show My Tasks</span>
                    </div>
                    <div class="ml-4 mt-1">
                        <input type="checkbox" id="checkbox" class="align-middle" :checked="visibleFilters.companyRelevantBusinessFilter" @change="toggleShowCompanyFilter('companyRelevantBusinessFilter')"/>
                        <span v-if="!showFilterPane" class="ml-1 mr-2 text-gray-400">Relevant Business</span> 
                        <span v-else class="ml-1 mr-2">Relevant Business</span>
                    </div>
                    <div class="ml-4 mt-1">
                        <input type="checkbox" id="checkbox" class="align-middle" :checked="visibleFilters.companyEveraxisPillarFilter" @change="toggleShowCompanyFilter('companyEveraxisPillarFilter')"/>
                        <span v-if="!showFilterPane" class="ml-1 mr-2 text-gray-400">EverAxis Pillar</span> 
                        <span v-else class="ml-1 mr-2">EverAxis Pillar</span>
                    </div>
                    <div class="ml-4 mt-1">
                        <input type="checkbox" id="checkbox" class="align-middle" :checked="visibleFilters.companyCRMActionsFilter" @change="toggleShowCompanyFilter('companyCRMActionsFilter')"/>
                        <span v-if="!showFilterPane" class="ml-1 mr-2 text-gray-400">CRM Events</span> 
                        <span v-else class="ml-1 mr-2">CRM Events</span>
                    </div>
                    <div class="ml-4 mt-1">
                        <input type="checkbox" id="checkbox" class="align-middle" :checked="visibleFilters.companyHasCRMActionsFilter" @change="toggleShowCompanyFilter('companyHasCRMActionsFilter')"/>
                        <span v-if="!showFilterPane" class="ml-1 mr-2 text-gray-400">Has CRM Events</span> 
                        <span v-else class="ml-1 mr-2">Has CRM Events</span>
                    </div>
                    <div class="ml-4 mt-1">
                        <input type="checkbox" id="checkbox" class="align-middle" :checked="visibleFilters.companyHasConflictsFilter" @change="toggleShowCompanyFilter('companyHasConflictsFilter')"/>
                        <span v-if="!showFilterPane" class="ml-1 mr-2 text-gray-400">Has Conflicts</span> 
                        <span v-else class="ml-1 mr-2">Has Conflicts</span>
                    </div>
                </div>
            </div>
            <div class="ml-4 my-1">
                <div >
                    <input type="checkbox" id="checkbox" class="align-middle" :checked="showResearchFilters" @change="toggleShowResearchFilters"/>
                    <span v-if="!showFilterPane" class="ml-1 mr-2 text-gray-400">Research Filters</span> 
                    <span v-else class="ml-1 mr-2">Research Filters</span>
                    <span @click="toggleShowIndividualResearchFilters" class="hover:cursor-pointer"  v-if="showIndividualResearchFilters">▼</span>
                    <span @click="toggleShowIndividualResearchFilters" class="hover:cursor-pointer"  v-else>▶</span>
                </div>
                <div v-show="showIndividualResearchFilters">
                    <div class="ml-4 mt-1">
                        <input type="checkbox" id="checkbox" class="align-middle" :checked="visibleFilters.companySourceOwnerFilter" @change="toggleShowCompanyFilter('companySourceOwnerFilter')"/>
                        <span v-if="!showFilterPane" class="ml-1 mr-2 text-gray-400">Source Owners</span> 
                        <span v-else class="ml-1 mr-2">Source Owners</span>
                    </div>
                    <div class="ml-4 mt-1">
                        <input type="checkbox" id="checkbox" class="align-middle" :checked="visibleFilters.companyOnboardingStatusFilter" @change="toggleShowCompanyFilter('companyOnboardingStatusFilter')"/>
                        <span v-if="!showFilterPane" class="ml-1 mr-2 text-gray-400">Onboarding Status</span> 
                        <span v-else class="ml-1 mr-2">Onboarding Status</span>
                    </div>
                    <div class="ml-4 mt-1">
                        <input type="checkbox" id="checkbox" class="align-middle" :checked="visibleFilters.companyProblemStatusFilter" @change="toggleShowCompanyFilter('companyProblemStatusFilter')"/>
                        <span v-if="!showFilterPane" class="ml-1 mr-2 text-gray-400">Problem Status</span> 
                        <span v-else class="ml-1 mr-2">Problem Status</span>
                    </div>
                    <div class="ml-4 mt-1">
                        <input type="checkbox" id="checkbox" class="align-middle" :checked="visibleFilters.companyDNRReasonFilter" @change="toggleShowCompanyFilter('companyDNRReasonFilter')"/>
                        <span v-if="!showFilterPane" class="ml-1 mr-2 text-gray-400">Do Not Research Reason</span> 
                        <span v-else class="ml-1 mr-2">Do Not Research Reason</span>
                    </div>
                    <div class="ml-4 mt-1">
                        <input type="checkbox" id="checkbox" class="align-middle" :checked="visibleFilters.companyResearchStatusFilter" @change="toggleShowCompanyFilter('companyResearchStatusFilter')"/>
                        <span v-if="!showFilterPane" class="ml-1 mr-2 text-gray-400">Research Status</span> 
                        <span v-else class="ml-1 mr-2">Research Status</span>
                    </div>
                    <div class="ml-4 mt-1">
                        <input type="checkbox" id="checkbox" class="align-middle" :checked="visibleFilters.companyResearchStatusFilterTwo" @change="toggleShowCompanyFilter('companyResearchStatusFilterTwo')"/>
                        <span v-if="!showFilterPane" class="ml-1 mr-2 text-gray-400">Research Status 2</span> 
                        <span v-else class="ml-1 mr-2">Research Status 2</span>
                    </div>
                    <div class="ml-4 mt-1">
                        <input type="checkbox" id="checkbox" class="align-middle" :checked="visibleFilters.companyShowResearchHaltedFilter" @change="toggleShowCompanyFilter('companyShowResearchHaltedFilter')"/>
                        <span v-if="!showFilterPane" class="ml-1 mr-2 text-gray-400">Show Research Halted</span> 
                        <span v-else class="ml-1 mr-2">Show Research Halted</span>
                    </div>
                    <div class="ml-4 mt-1">
                        <input type="checkbox" id="checkbox" class="align-middle" :checked="visibleFilters.companyReferralSourceFilter" @change="toggleShowCompanyFilter('companyReferralSourceFilter')"/>
                        <span v-if="!showFilterPane" class="ml-1 mr-2 text-gray-400">Referral Source</span> 
                        <span v-else class="ml-1 mr-2">Referral Source</span>
                    </div>
                    <div class="ml-4 mt-1">
                        <input type="checkbox" id="checkbox" class="align-middle" :checked="visibleFilters.companyReferralStatusFilter" @change="toggleShowCompanyFilter('companyReferralStatusFilter')"/>
                        <span v-if="!showFilterPane" class="ml-1 mr-2 text-gray-400">Referral Status</span> 
                        <span v-else class="ml-1 mr-2">Referral Status</span>
                    </div>
                </div>
            </div>
            <div class="ml-4 my-1">
                <input type="checkbox" id="checkbox" class="align-middle" :checked="showActiveFilters" @change="toggleShowActiveFilters"/>
                    <span v-if="!showFilterPane" class="ml-1 mr-2 text-gray-400">Active Filters</span> 
                    <span v-else class="ml-1 mr-2">Active Filters</span>
            </div>
        </div>
        <!-- <div v-if="activeFilters" class="ml-1 mb-3">
            <div class="underline">
                Active Filters:
            </div>
            <div class="mt-1 ml-1 max-w-[155px]">
                {{ activeFilters }}
            </div>
        </div> -->
    </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';

const store = useStore()

const showFilterPane = computed(() => store.getters['company/showFilterPane'])
const showBasicFilters = computed(() => store.getters['company/showBasicFilters'])
const showCampaignFilters = computed(() => store.getters['company/showCampaignFilters'])
const showResearchFilters = computed(() => store.getters['company/showResearchFilters'])
const showActiveFilters = computed(() => store.getters['company/showActiveFiltersVisible'])
// const activeFilters = computed(() => store.getters['company/showActiveFilters'].join(', '))
const visibleFilters = computed(() => store.getters['company/showVisibleFilters'])

const showIndividualBasicFilters = ref(false)
const showIndividualCampaignFilters = ref(false)
const showIndividualResearchFilters = ref(false)


const toggleShowFilterPane = () => {
    if(showFilterPane.value) {
        store.dispatch('company/setShowFilterPaneFalse')
    } else {
        store.dispatch('company/setShowFilterPaneTrue')
    }
}

const toggleShowBasicFilters = () => {
    if(showBasicFilters.value) {
        store.dispatch('company/setShowBasicFiltersFalse')
    } else {
        store.dispatch('company/setShowBasicFiltersTrue')
    }
}

const toggleShowCampaignFilters = () => {
    if(showCampaignFilters.value) {
        store.dispatch('company/setShowCampaignFiltersFalse')
    } else {
        store.dispatch('company/setShowCampaignFiltersTrue')
    }
}

const toggleShowActiveFilters = () => {
    if(showActiveFilters.value) {
        store.dispatch('company/setShowActiveFiltersFalse')
    } else {
        store.dispatch('company/setShowActiveFiltersTrue')
    }
    console.log('show active filters', showActiveFilters.value)
}

const toggleShowResearchFilters = () => {
    if(showResearchFilters.value) {
        store.dispatch('company/setShowResearchFiltersFalse')
    } else {
        store.dispatch('company/setShowResearchFiltersTrue')
    }
}

const toggleShowCompanyFilter = (filterName) => {
    if(visibleFilters.value[filterName]){
        store.dispatch('company/setVisibleFilters', {filterName: filterName, status: false})
    } else {
        store.dispatch('company/setVisibleFilters', {filterName: filterName, status: true})
    }
}

const toggleShowIndividualResearchFilters = () => {
    showIndividualResearchFilters.value = !showIndividualResearchFilters.value
}

const toggleShowIndividualBasicFilters = () => {
    showIndividualBasicFilters.value = !showIndividualBasicFilters.value
}

const toggleShowIndividualCampaignFilters = () => {
    showIndividualCampaignFilters.value = !showIndividualCampaignFilters.value
}

</script>