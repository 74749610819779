<template>
    <form action="" @submit.prevent="submitForm" class="w-full h-full text-xs">
        <base-form class="h-full">
            <template #body>
                <div class="p-2">
                    <section id="data-source" class="flex flex-col w-full">
                        <div class="flex w-full">
                            <div class="w-full border-b border-gray-400 leading-[0.15em] mt-5 mb-3"><span class="whitespace-nowrap pr-[10px] bg-gray-100 tracking-wider">Request Details<span  class="whitespace-nowrap pl-2 bg-gray-100 tracking-wider" v-if="existingDataRequest">{{ requestedByInfo }}</span></span></div>
                        </div>
                        <div class="flex flex-col py-2 ">
                            <div class="flex flex-col w-full space-y-1">
                                    <label for="data-source" class="pl-1">Data Source*</label>
                                    <select class="px-2 py-[5px] border border-gray-300 rounded-sm" v-model="dataRequestForm.data_source_id" name="" id="">
                                        <option v-for="dataSource in dataSourceOptions" :key="dataSource.value" :value="dataSource.value">
                                            {{ dataSource.label }}
                                        </option>
                                    </select>
                            </div>
                            <div v-if="useNewDataSource" class="pt-2 flex flex-col space-y-4 ">
                                <div class="flex flex-col space-y-1 w-full">
                                    <label for="new-data-source-name" class="pl-1">Source Name*</label>
                                    <input id="new-data-source-name" required class="border rounded-sm border-gray-300 px-2 py-[5px] w-full" placeholder="XYZ Expo" type="text" v-model="dataSourceForm.source_name"/>
                                </div>
                                <div class="flex flex-col space-y-1 w-full">
                                    <label for="new-data-source-name" class="pl-1">Source Type*</label>
                                    <select class="px-2 py-[5px] border border-gray-300 rounded-sm" v-model="dataSourceForm.source_type" name="" id="">
                                        <option v-for="dataSourceType in dataSourceTypeOptions" :key="dataSourceType.value" :value="dataSourceType.value">
                                            {{ dataSourceType.label }}
                                        </option>
                                    </select>
                                </div>
                                <div class="flex flex-col space-y-1 w-full">
                                    <label for="new-data-source-notes" class="pl-1">Source Notes</label>
                                    <textarea id="new-data-source-notes" rows="3" class="border rounded-sm border-gray-300 px-2 py-[5px] w-full" placeholder="XYZ Exhibition Americas at xyz-expo.com" type="text" v-model="dataSourceForm.notes">
                                    </textarea>
                                </div>
                                <!-- <div class="flex flex-col w-full space-y-1">
                                    <label for="request-schedule" class="pl-1">Request Schedule</label>
                                    <select class="px-2 py-[5px] border border-gray-300 rounded-sm" v-model="dataRequestForm.request_schedule" name="" id="">
                                        <option :value="null" selected>
                                            None
                                        </option>
                                        <option v-for="requestSchedule in requestScheduleOptions" :key="requestSchedule.value" :value="requestSchedule.value">
                                            {{ requestSchedule.label }}
                                        </option>
                                    </select>
                                </div> -->
                            </div>
                        </div>
                    </section>
                    <section id="request-details">
                        <div class="flex flex-col space-y-4 py-2">
                            <div v-if="showDataRequestSection" class="flex flex-col w-full space-y-1">
                                <label for="request-body" class="pl-1">Request*</label>
                                <textarea
                                    id="request-body" 
                                    rows="3" 
                                    required 
                                    class="border border-gray-300 px-2 py-[5px] w-full rounded-sm" 
                                    placeholder="Scrape MRO Americas 2025 Expo from https://mroamericas.aviationweek.com/en/home.html for targets working in Maintenance, Repair & Overhaul" 
                                    type="text" 
                                    v-model="dataRequestForm.request_body">
                                </textarea>
                            </div>
                            <div v-else class="flex flex-col w-full space-y-1">
                                <label for="request-body" class="pl-1 text-gray-600">Request*</label>
                                <textarea
                                    id="request-body" 
                                    rows="3" 
                                    disabled
                                    class="border border-gray-300 bg-gray-200 px-2 py-[5px] w-full text-gray-500 rounded-sm" 
                                    placeholder="Scrape MRO Americas 2025 Expo from https://mroamericas.aviationweek.com/en/home.html for targets working in Maintenance, Repair & Overhaul" 
                                    type="text" 
                                    v-model="dataRequestForm.request_body">
                                </textarea>
                            </div>
                            <div v-if="showDataRequestSection" class="flex flex-col w-full space-y-4">
                                <div class="flex flex-col w-full space-y-1">
                                    <label for="request-name"  class="pl-1 tracking-wide">Priority*</label>
                                    <input type="number" required placeholder="1 - High / 100 - Low" v-model="dataRequestForm.priority" class="px-2 py-[5px] border border-gray-300 rounded-sm" />
                                </div>
                                <div class="flex flex-col w-full space-y-1">
                                    <label for="request-name" class="pl-1 tracking-wide">Due Date*</label>
                                    <input type="date" required v-model="dataRequestForm.due_by" class="px-2 py-[5px] border border-gray-300 rounded-sm" />
                                </div>
                                <div class="flex flex-col w-full space-y-1">
                                    <label for="client" class="pl-1 tracking-wide">Client</label>
                                    <select class="px-2 py-[5px] border border-gray-300 rounded-sm" v-model="dataRequestForm.client_id" name="" id="">
                                        <option v-for="client in clientOptions" :key="client.value" :value="client.value">
                                            {{ client.label }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div v-else class="flex flex-col w-full space-y-4">
                                <div class="flex flex-col w-full space-y-1">
                                    <label for="request-name"  class="pl-1 tracking-wide text-gray-600">Priority*</label>
                                    <input type="number" disabled placeholder="1 - High / 100 - Low" v-model="dataRequestForm.priority" class="px-2 py-[5px] border border-gray-300 bg-gray-200 text-gray-500 rounded-sm" />
                                </div>
                                <div class="flex flex-col w-full space-y-1">
                                    <label for="request-name" class="pl-1 tracking-wide text-gray-600">Due Date*</label>
                                    <input type="date" disabled v-model="dataRequestForm.due_by" class="px-2 py-[5px] border border-gray-300 bg-gray-200 text-gray-500 rounded-sm" />
                                </div>
                                <div class="flex flex-col w-full space-y-1">
                                    <label for="client" class="pl-1 tracking-wide  text-gray-600">Client</label>
                                    <select class="px-2 py-[5px] border border-gray-300 bg-gray-200 text-gray-500 rounded-sm" disabled v-model="dataRequestForm.client_id" name="" id="">
                                        <option v-for="client in clientOptions" :key="client.value" :value="client.value">
                                            {{ client.label }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section v-if="devEditMode" id="source-details">
                        <div class="flex flex-col space-y-4 py-2">
                            <div class="flex w-full">
                                <div class="w-full border-b border-gray-400 leading-[0.15em] mt-5 mb-3"><span class="whitespace-nowrap pr-[10px] bg-gray-100  tracking-wider">Source Details</span></div>
                            </div>
                            <div class="flex flex-col w-full space-y-1">
                                <label for="request-name" class="pl-1">Request Name*</label>
                                <input type="text" required placeholder="MRO Americas 2025" v-model="dataRequestForm.request_name" class="px-2 py-[5px] border border-gray-300 rounded-sm" />
                            </div>
                            <div class="flex flex-col w-full space-y-1">
                                <label for="source-url" class="pl-1">Source URL</label>
                                <input type="text" placeholder="https://mroamericas.aviationweek.com/en/home.html" v-model="dataRequestForm.source_url" class="px-2 py-[5px] border border-gray-300 rounded-sm" />
                            </div>
                            <div class="flex flex-col w-full space-y-1">
                                <label for="date-of-event" class="pl-1">Event Date</label>
                                <input type="date" v-model="dataRequestForm.date_of_event" class="px-2 py-[5px] border border-gray-300 rounded-sm" />
                            </div>
                        </div>
                    </section>
                    <section v-if="devEditMode" id="scheduling-details">
                        <div class="flex flex-col space-y-4 py-2">
                            <div class="flex w-full">
                                <div class="w-full border-b border-gray-400 leading-[0.15em] mt-5 mb-3"><span class="whitespace-nowrap pr-[10px] bg-gray-100  tracking-wider">Scheduling Details</span></div>
                            </div>
                            <div class="flex flex-col w-full space-y-1">
                                <label for="import-schedule" class="pl-1">Import Schedule</label>
                                <select class="px-2 py-[5px] border border-gray-300 rounded-sm" v-model="dataRequestForm.import_schedule" name="" id="">
                                    <option :value="null" selected>
                                        None
                                    </option>
                                    <option v-for="importSchedule in importScheduleOptions" :key="importSchedule.value" :value="importSchedule.value">
                                        {{ importSchedule.label }}
                                    </option>
                                </select>
                            </div>
                            <div class="flex flex-col w-full space-y-1">
                                <label for="status" class="pl-1">Status</label>
                                <select class="px-2 py-[5px] border border-gray-300 rounded-sm" v-model="dataRequestForm.status" name="" id="">
                                    <option v-for="status in statusOptions" :key="status.value" :value="status.value">
                                        {{ status.label }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </section>
                    <!-- <section v-if="devEditMode" id="import-details">

                    </section> -->
                </div>
            </template>
            <template #footer>
                <div v-if="existingDataRequest" class="h-10 px-2 flex items-center justify-center space-x-2">
                    <BaseButton v-if="validForm" type="submit">Save</BaseButton>
                    <BaseButtonDisabled v-else>Save</BaseButtonDisabled>
                    <BaseButtonCancel type="button" @click="close">Cancel</BaseButtonCancel>
                </div>
                <div v-else class="h-10 px-2 flex items-center justify-center space-x-2">
                    <BaseButton v-if="validForm" type="submit">Create</BaseButton>
                    <BaseButtonDisabled v-else>Create</BaseButtonDisabled>
                    <BaseButtonCancel type="button" @click="close">Cancel</BaseButtonCancel>
                </div>
            </template>
        </base-form>
    </form>
</template>

<script setup>
import {defineProps, defineEmits, ref, computed, reactive, watch } from 'vue';
import BaseButton from '../base-components/BaseButton.vue';
import BaseButtonDisabled from '../base-components/BaseButtonDisabled.vue';
import BaseButtonCancel from '../base-components/BaseButtonCancel.vue';
import { formatDate } from '@/composables/formatDate';

const props = defineProps(['dataSourceOptions', 'clientOptions', 'userPermissions', 'existingDataRequest'])
const emit = defineEmits(['close', 'submit-form'])

const year = ref(new Date().getFullYear())

const dataSourceTypeOptions = ref([
    {label: 'Association', value: 'Association'},
    {label: 'Database', value: 'Databse'},
    {label: 'Expo', value: 'Expo'},
    {label: 'Member List', value: 'Member List'},
    {label: 'Other', value: 'Other'}
])

const importScheduleOptions = ref([
    {label: 'Once', value: 'Once'},
    {label: 'Weekly', value: 'Weekly'},
    {label: 'Monthly', value: 'Monthly'},
    {label: 'Standard Expo', value: 'Standard Expo'},
])

const requestScheduleOptions = ref([
    {label: 'Once', value: 'Once'},
    {label: 'Annual', value: 'Annual'},
    {label: 'Quarterly', value: 'Quarterly'},
    {label: 'Monthly', value: 'Monthly'},
])

const statusOptions = ref([
    {label: 'Requested', value: 'Requested'},
    {label: 'Scheduled', value: 'Scheduled'},
    // {label: 'In Progress', value: 'In Progress'},
    {label: 'Complete', value: 'Complete'},
    // {label: 'Rejected', value: 'Rejected'},
])

const dataRequestForm = reactive({
    data_source_id: props.existingDataRequest ? props.existingDataRequest.data_source.id: null,
    request_name: props.existingDataRequest ? props.existingDataRequest.request_name : null,
    request_body: props.existingDataRequest ? props.existingDataRequest.request_body : null,
    source_url: null,
    priority: props.existingDataRequest ? props.existingDataRequest.priority : null,
    date_of_event: null,
    due_by: props.existingDataRequest ? props.existingDataRequest.due_by : null,
    client_id: props.existingDataRequest ? props.existingDataRequest.client ? props.existingDataRequest.client.id : null : null,
    import_schedule: props.existingDataRequest ? props.existingDataRequest.import_schedule : null,
    status: props.existingDataRequest ? props.existingDataRequest.status : 'Requested',
})

const useNewDataSource = ref(false)

const dataSourceForm = ref(null)

const showDataRequestSection = computed(() => {
    return dataSourceForm.value && 
        dataSourceForm.value.source_name ?
        true : false
})

const devEditMode = computed(() => {
    return props.existingDataRequest && props.userPermissions.isDev ? true : false
})

const validForm = computed(() => {
    return dataSourceForm.value &&
        dataSourceForm.value.source_name &&
        dataRequestForm.data_source_id &&
        dataRequestForm.request_name &&
        dataRequestForm.request_body &&
        dataRequestForm.priority &&
        dataRequestForm.due_by
})

const requestedByInfo = computed(() => {
    if(props.existingDataRequest) {
        return `(Requested by ${props.existingDataRequest.requested_by.first_name} ${props.existingDataRequest.requested_by.last_name} on ${formatDate(props.existingDataRequest.created_at)})`
    }
    return null
})


watch(() => dataRequestForm.data_source_id, () => {
    if(dataRequestForm.data_source_id == -1) {
        useNewDataSource.value = true
    } else {
        useNewDataSource.value = false
    }
    updateDataSource(dataRequestForm.data_source_id)
})

const requestName = computed(() => {
    if(dataSourceForm.value) {
        return  `${dataSourceForm.value.source_name} ${year.value}`
    }
    return null
})

watch(() => requestName.value, () => {
    dataRequestForm.request_name = requestName.value
})

watch(() => props.existingDataRequest, () => {
    if(props.existingDataRequest) {
        refreshDataRequestForm()
        updateDataSource(props.existingDataRequest.data_source.id)
    } else {
        updateDataSource(null)
    }
})

function updateDataSource(value) {
    console.log('update Data Source', value)
    dataRequestForm.data_source_id = value
    if(value) {
        dataSourceForm.value = props.dataSourceOptions.find((source) => source.value == value).data
    } else {
        dataSourceForm.value = null
    }
}

function removeDataSource() {
    dataRequestForm.data_source_id = null
    dataSourceForm.value = null
}

function refreshDataRequestForm() {
    dataRequestForm.data_source_id = props.existingDataRequest ? props.existingDataRequest.data_source.id: null
    dataRequestForm.request_name = props.existingDataRequest ? props.existingDataRequest.request_name : null
    dataRequestForm.request_body = props.existingDataRequest ? props.existingDataRequest.request_body : null
    dataRequestForm.source_url = null
    dataRequestForm.priority = props.existingDataRequest ? props.existingDataRequest.priority : null
    dataRequestForm.date_of_event = null
    dataRequestForm.due_by = props.existingDataRequest ? props.existingDataRequest.due_by : null
    dataRequestForm.client_id = props.existingDataRequest ? props.existingDataRequest.client ? props.existingDataRequest.client.id : null : null
    dataRequestForm.import_schedule = props.existingDataRequest ? props.existingDataRequest.import_schedule : null
    dataRequestForm.status = props.existingDataRequest ? props.existingDataRequest.status : 'Requested'
}

function submitForm() {
    console.log('submit')
    let newDataSource
    if(dataSourceForm.value.id < 0) {
        newDataSource = dataSourceForm.value
    } else {
        newDataSource = null
    }
    const formData = JSON.stringify({
        data_source: newDataSource,
        data_request: dataRequestForm
    })
    emit('submit-form', formData)
}

function close() {
    console.log('DataRequestForm: close')
    emit('close')
}
</script>