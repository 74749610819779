<template>
    <div
        class="h-full cursor-pointer flex flex-row items-center align-middle text-center whitespace-nowrap accent-dark-primary"
        >
        <input
            type="checkbox"
            id="checkbox"
            class="align-middle hover:cursor-pointer"
            :checked="selectAll"
            @change="toggleSelectAll"
        />
        </div>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';

const props = defineProps(['selectAll']);

const emit = defineEmits(['toggle-select-all'])

function toggleSelectAll() {
  emit('toggle-select-all')
}

</script>