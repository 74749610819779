<template>
    <div>
        <base-table class="w-full" >
            <template #table-header>
                <base-table-header>Invoice #</base-table-header>
                <base-table-header>Status</base-table-header>
                <!-- <base-table-header>Wave Status</base-table-header> -->
                <base-table-header>Invoice Date</base-table-header>
                <base-table-header>Sent Date</base-table-header>
                <base-table-header>Due Date</base-table-header>
                <base-table-header>Effective PMT Term</base-table-header>
                <base-table-header>Date Paid</base-table-header>
                <base-table-header>Payment Timing</base-table-header>
                <base-table-header>Payment Type</base-table-header>
                <base-table-header>For</base-table-header>
                <base-table-header>Amount Due</base-table-header>
                <base-table-header>Amount Paid</base-table-header>
                <base-table-header>Balance</base-table-header>
                <base-table-header>Notes</base-table-header>
                <base-table-header>Items Count</base-table-header>
            </template>
            <template #table-body>
                <base-table-row v-for="invoice in invoices" :key="invoice.id">
                    <base-table-cell>
                        <div class=" whitespace-nowrap">
                            {{ invoice.invoice_number }} ({{ invoice.id }})
                        </div>
                    </base-table-cell>
                    <base-table-cell>
                        {{ invoice.status }}
                    </base-table-cell>
                    <!-- <base-table-cell>
                        {{ invoice.wave_status }}
                    </base-table-cell> -->
                    <base-table-cell>
                        <div class=" whitespace-nowrap flex justify-center">
                            {{ formatDateTwoDigitYear(invoice.invoice_date) }}
                        </div>
                    </base-table-cell>
                    <base-table-cell>
                        <div class=" whitespace-nowrap flex justify-center">
                            {{ formatDateTwoDigitYear(invoice.sent_date) }}
                        </div>
                    </base-table-cell>
                    <base-table-cell>
                    <div class=" whitespace-nowrap flex justify-center">
                        {{ formatDateTwoDigitYear(invoice.due_date) }}
                    </div>
                </base-table-cell>
                <base-table-cell>
                    <div class=" whitespace-nowrap flex justify-center">
                        {{ dateDelta(invoice) }}
                    </div>
                </base-table-cell>
                <base-table-cell>
                    <div class=" whitespace-nowrap flex justify-end">
                        {{ invoice.date_paid ?  formatDateTwoDigitYear(invoice.date_paid) : '--'}}
                    </div>
                </base-table-cell>
                <base-table-cell>
                    <div class=" whitespace-nowrap flex justify-center" :class="{'bg-yellow-200' : invoice.date_paid && paymentTiming(invoice) < 0}">
                        {{ paymentTiming(invoice) }}
                    </div>
                </base-table-cell>
                    <base-table-cell>
                        {{ invoice.payment_type }}
                    </base-table-cell>
                    <base-table-cell>
                        <div :class="{'line-through' : invoice.status == 'Void'}">
                            {{ invoice.invoice_for.substr(0, 120) }} <span v-if="invoice.invoice_for.length > 120">...</span>
                        </div>
                    </base-table-cell>
                    <base-table-cell>
                        <div :class="{'line-through' : invoice.status == 'Void'}" class="flex justify-end w-full">
                            {{ formatCurrency(invoice.invoice_amount) }}
                        </div>
                    </base-table-cell>
                    <base-table-cell>
                        <div :class="{'line-through' : invoice.status == 'Void'}" class="flex justify-end w-full">
                            {{ formatCurrency(invoice.amount_paid ? invoice.amount_paid : 0) }}
                        </div>
                    </base-table-cell>
                    <base-table-cell>
                        <div v-if="invoice.status == 'Void'" class="flex justify-end w-full line-through">
                            {{ balanceDue(invoice) }}
                        </div>
                        <div v-else class="flex justify-end w-full" :class="[{'bg-red-300' : balanceDue(invoice) != '$0.00' && isOverdue}, {'bg-yellow-200' : balanceDue(invoice) != '$0.00' && !isOverdue(invoice)}]" >
                            {{ balanceDue(invoice) }}
                        </div>
                    </base-table-cell>
                    <base-table-cell>
                        <div class="flex flex-col space-y-1 p-1" v-if="invoice.accounting_posts.length > 1" >
                            <div class="flex flex-col" v-for="(post, idx) in invoice.accounting_posts" :key="post.id">
                                <div class="flex " >
                                    {{ new Date(post.created_at).toLocaleDateString() }}: {{ post.post_body }}
                                </div>
                                <div class="w-full border-b border-b-black" v-if="idx < invoice.accounting_posts.length - 1"></div>
                            </div>
                            <div>
                            <button v-if="invoice.accounting_posts.length > 1" class="text-xs border border-dark-primary px-0.5 rounded-full h-fit hover:text-white hover:bg-dark-primary" @click="setAccountingPostsVisibility(false)">-</button>
                            </div>
                        </div>
                        <div v-else-if="invoice.accounting_posts.length > 0">
                            <div class="flex " >
                                    {{ new Date(invoice.accounting_posts[0].created_at).toLocaleDateString() }}: {{ invoice.accounting_posts[0].post_body }} 
                            </div>
                            <div>
                                <button class="text-xs border border-dark-primary px-0.5 rounded-full h-fit hover:text-white hover:bg-dark-primary" @click="setAccountingPostsVisibility(true)">+</button>
                            </div>
                        </div>
                    </base-table-cell>
                    <base-table-cell>
                        <div class="flex justify-center" ref="itemCount" >
                            <span >{{ invoice.items.length }}</span>
                        </div>
                    </base-table-cell>
                </base-table-row>
            </template>
        </base-table>
    </div>
</template>


<script>
export default {
    name: 'simple-invoice-table'
}
</script>

<script setup>
import { defineProps } from 'vue';
import { formatDateTwoDigitYear } from '@/composables/formatDate';

const props = defineProps({
    invoices: Array
})
function dateDelta(invoice){
    const date1 = new Date(invoice.invoice_date);
    const date2 = new Date(invoice.due_date);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
    return diffDays
}

function paymentTiming(invoice){
    if(invoice.date_paid){
        const date1 = new Date(invoice.due_date);
        const date2 = new Date(invoice.date_paid);
        const diffTime = (date1 - date2);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
        return diffDays
    } else {
        return "--"
    }
}

function formatCurrency(invoice_field){
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
    });
    return formatter.format(invoice_field);

}

function balanceDue(invoice) {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
    });
    return formatter.format(invoice.invoice_amount - (invoice.amount_paid ? invoice.amount_paid : 0));
}

function isOverdue(invoice) {
    const overdueStatuses = ['Unpaid', 'unpaid']
    const dueDate = new Date(invoice.due_date).getTime()
    const today = new Date().getTime()
    return (today > dueDate) && overdueStatuses.includes(invoice.status)
}

</script>