<template>
    <div class="flex justify-center items-center w-full h-12 tracking-wider text-sm font-medium text-gray-800 bg-gray-50 border-b border-gray-300 pl-3 pr-2">
        <div class="flex justify-between items-center w-full">
            <div class="inline-flex space-x-2 items-center">
                <button @click="closeDetails" class="hover:bg-gray-200 p-1">
                    <ChevronLeftSVG 
                        fill-color="#1F2937"
                    />
                </button>
                <div>
                    {{ title }}
                </div>
            </div>
            <div v-if="canDelete">
                <button @click="deleteRecord" class="bg-dark-secondary hover:bg-dark-primary px-3 py-1.5 rounded-sm">
                    <TrashSVG fill="white" />
                </button>
            </div>
            <!-- <div v-else>
                <button disabled class="bg-gray-300 px-3 py-1.5 rounded-sm"> 
                    <TrashSVG fill="#4B5563" />
                </button>
            </div> -->
        </div>
    </div>

</template>

<script setup>
import { defineProps, defineEmits, ref, computed} from 'vue';
import ChevronLeftSVG from '../svg-components/ChevronLeftSVG.vue';
import BaseButton from '../base-components/BaseButton.vue';
import BaseButtonDisabled from '../base-components/BaseButtonDisabled.vue';
import TrashSVG from '../svg-components/TrashSVG.vue';

const props = defineProps(['headerTitle', 'canDelete'])
const emit = defineEmits(['close-details', 'delete'])

function closeDetails() {
    emit("close-details")
}

function deleteRecord() {
    emit('delete')
}

const title = computed(() => {
    return props.headerTitle ? props.headerTitle : ''
})
</script>