import axios from 'axios';
import { BASE_URL } from './api';

export async function getDataSources(token, queryParams) {
    return axios({
        method: 'get',
        url: `${BASE_URL}/data-sources/${queryParams}`,
        headers: {
            'Authorization': `Token ${token}`
        },
    }).then((response) => {
        console.log(response)
        return response;
    }).catch((error) => {
        console.log(error)
        return error
    });
}