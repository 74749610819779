<template>
    <div class="w-full h-[calc(100vh-3.5rem)] fixed">
        <div v-show="openRow!=null" class="w-full ">
            <slot name="details-page"
                :resultRow="openRow"
                :userPermissions="userPermissions"
                :fetchFilter="fetchFilter"
                :events="{ closeDetails, refetchResults }"
            ></slot>
        </div>
        <div v-show="openRow==null" class="w-full h-full flex text-sm flex-col">
            <div class="w-full z-50">
                <CustomTableMenu
                    :moduleName="moduleName"
                    :module-name-singular="moduleNameSingular"
                    :pageFilter="pageSizeFilter"
                    :page="page"
                    :numPages="totalPages"
                    :isLoading="isLoading"
                    :filters="filters"
                    :selectAll="selectAll"
                    :selectedResults="selectedResults"
                    :menuOptions="menuOptions"
                    :userPermissions="userPermissions"
                    :bulkOperations="bulkOperations"
                    :viewingFromNbr="viewingFromNbr"
                    :viewingToNbr="viewingToNbr"
                    :default-page-size="defaultPageSize"
                    :count="count"
                    :orderBy="orderBy"
                    :create-modal-size="createModalSize"
                    @refetch-results="refetchResults(fetchFilter)"
                    @get-next="getNext"
                    @go-to-first="goToFirst"
                    @get-prev="getPrev"
                    @go-to-last="goToLast"
                    @jump-to-page="jumpToPage"
                    @set-page-size-filter="setPageSizeFilter"
                    @clear-filter="clearFilter"
                    @change-filters="changeFilters"
                    @set-filter-options="setFilterOptions"
                    @toggle-select-all="toggleSelectAll"
                    @clear-select-all="clearSelectAll"
                    @add-form-row="addFormRow"
                    @change-ordering="setOrder"
                    @do-quick-bulk="executeQuickBulkAction"
                    @select-tab="selectTab"
                >
                    <template #bulk-operations="{ events: { hideBulkActionMenu, hideBulkOperations },  bulkAction, selectedResults }">
                        <slot name="bulk-operations"
                            :selectedResults="selectedResults"
                            :bulkAction="bulkAction"
                            :fetchFilter="fetchFilter"
                            :events="{ hideBulkActionMenu, hideBulkOperations, clearSelectAllClearSelectedResults, refetchResults }"
                        ></slot>
                    </template>
                    <template #create-form="{ events: { closeCreateForm } }">
                        <slot name="create-form"
                            :fetchFilter="fetchFilter"
                            :userPermissions="userPermissions"
                            :events="{ closeCreateForm, refetchResults }"
                        ></slot>
                    </template>
                </CustomTableMenu>
            </div>
            <div v-if="results.length > 0 | formRows.length > 0" class="h-full bg-gray-200 overflow-y-auto">
                <TableTemplate>
                    <template #table-header>
                        <th
                            v-if="menuOptions.selectAllHeader"
                            class="p-0 h-6 w-[50px] text-xs sticky -top-[1px] border-t border-b border-gray-300"
                        >
                            <div class="bg-gray-100 h-full flex justify-start p-2 pl-5 border-gray-300">
                                <SelectAll
                                    :selectAll="selectAll"
                                    @toggle-select-all="toggleSelectAll"
                                />
                            </div>
                        </th>
                        <TableHeaderSticky 
                            v-for="tableHeader in tableHeaders"
                            :key="tableHeader.value"
                            :tableHeader="tableHeader"
                            :orderBy="orderBy"
                        >
                        </TableHeaderSticky>
                        <!-- <th
                            class="p-0 h-6 w-2 text-xs sticky -top-[1px] border-t border-b border-gray-300"
                        >
                            <div class="bg-gray-100 h-full flex justify-start border-gray-300">
                                    
                            </div>
                        </th> -->
                    </template>
                    <template #table-body>
                        <TransitionGroup name="results">
                            <TableRow
                                v-for="(row, index) in formRows"
                                :key="row.key"
                            >
                                <slot name="row-form"
                                    :index="index"
                                    :fetchFilter="fetchFilter"
                                    :userPermissions="userPermissions"
                                    :selectAll="selectAll"
                                    :selectedResultsOnPageMap="selectedResultsOnPageMap"
                                    :clearedSelectedResults="clearedSelectedResults"
                                    :clearedSelectedResultsOnPage="clearedSelectedResultsOnPage"
                                    :events="{ closeRowForm, refetchResults }"
                                >
                                </slot>
                            </TableRow>
                            <slot name="table-row"
                                :selectAll="selectAll"
                                :fetchFilter="fetchFilter"
                                :userPermissions="userPermissions"
                                :events="{ addSelectedResult, removeSelectedResult, refetchResults, openRowDetails }">
                            </slot>
                        </TransitionGroup>
                    </template>
                    <template #table-footer>
                        <slot name="table-footer"
                            :viewingFromNbr="viewingFromNbr"
                            :viewingToNbr="viewingToNbr"
                            :count="count"
                            :selectedResults="selectedResults"
                        >
                        </slot>
                    </template>
                </TableTemplate>
            </div>
        </div>
    </div>
</template>

<script setup>
import {ref, reactive, defineProps, defineEmits, computed, watch, shallowReactive} from 'vue';
import CustomTableMenu from './CustomTableMenu.vue';
import TableTemplate from './TableTemplate.vue';
import TableHeaderSticky from './TableHeaderSticky.vue';
import TableRow from './TableRow.vue';
import SelectAll from './SelectAll.vue';
import { useStore } from 'vuex';
import { useToast } from 'vue-toast-notification';

const store = useStore();
const $toast = useToast();

const props = defineProps([
    'response',
    'moduleName',
    'moduleNameSingular',
    'isLoading',
    'tableHeaders',
    'filters',
    'menuOptions',
    'bulkOperations',
    'defaultOrdering',
    'defaultPageSize',
    'shouldReload',
    'firstHeaderStyling',
    'createModalSize',
]);

const emit = defineEmits([
    'refetch-results',
    'clear-filter',
    'change-filters',
    'change-ordering',
    'set-filter-options',
    'do-quick-bulk',
    'select-tab'
]);

const isLoading = ref(props.isLoading);
const userPermissions = shallowReactive({
    isManagement: null,
    isHourly: null,
    isStudent: null,
    isResearch: null,
    isAdvancedResearch: null,
    isSuperuser: null,
    isAccountManager: null,
    isAccountStaff: null,
    isDev: null,
    isResearchLead: null,
    isAllCompanies: null,
    isGenerateTimecard: null,
});
const awaitingSearch = ref(false);
const shouldRefetchResults = ref(false);
const results = ref([]);
const next = ref(null);
const prev = ref(null);
const count = ref(null);
const page = ref(1);
const pageSize = ref(null);
const totalPages = ref(0);
const pageSizeFilter = reactive({
    pageSizeFilter: props.defaultPageSize ? props.defaultPageSize : 50
});
const openRow = ref(null)
const filters = reactive(props.filters);
const formRows = ref([])
const firstHeader = computed(() => {
    if(props.firstHeaderStyling){
        return props.firstHeaderStyling
    } else {
        return "w-10"
    }
})

const selectAll = ref(false);
const selectedResults = ref([]);
const clearedSelectedResults = ref(false);
const clearedSelectedResultsOnPage = ref(false);
const orderBy = ref(props.defaultOrdering ? props.defaultOrdering : []);

watch(() => props.isLoading, () => {
    isLoading.value = props.isLoading
})

watch(() => props.shouldReload, () => {
    refetchResults(fetchFilter.value)
})

watch(() => props.response, () => {
    if(props.response) {
        // console.log('response')
        // console.log(props.response)
        if(props.response.status == 200) {
            results.value = props.response.data.results
            next.value = props.response.data.next ? `&page=${props.response.data.page + 1}` : null
            prev.value = props.response.data.previous ? `&page=${props.response.data.page - 1}` : null
            count.value = props.response.data.count
            page.value = props.response.data.page
            pageSize.value = props.response.data.results.length
            totalPages.value = props.response.data.num_pages
        } else {
            $toast.open({
                message: `Error receiving ${props.moduleName} results.`,
                type: 'error',
                duration: 5000
            })
        }
    }
})

function refetchResults(filterParams) {
    console.log('refetchResults')
    console.log('filter params:')
    console.log(filterParams)
    shouldRefetchResults.value = true
    if(shouldRefetchResults.value) {
        emit('refetch-results', filterParams)
    }
}

function openRowDetails(resultRowId) {
    openRow.value = resultRowId
}

function closeDetails() {
    console.log('CustomTablePage: closeDetails')
    openRow.value = null
}

function addSelectedResult(resultRow) {
    let foundIndex = selectedResultIds.value.indexOf(resultRow.id)
    if (foundIndex === -1){
        selectedResults.value.push(resultRow);
    }
    // console.log('selected results', selectedResults.value)
}

function removeSelectedResult(resultRow) {
    let foundIndex = selectedResultIds.value.indexOf(resultRow.id);
  if (foundIndex !== -1) {
    selectedResults.value.splice(foundIndex, 1);
  }
}

function setUserPermissions () {
    console.log('setUserPermissions')
    let userPermissionsStore = store.getters["auth/showUserPermissions"];
    userPermissions.isManagement = userPermissionsStore.isManagement;
    userPermissions.isHourly = userPermissionsStore.isHourly;
    userPermissions.isStudent = userPermissionsStore.isStudent;
    userPermissions.isResearch = userPermissionsStore.isResearch;
    userPermissions.isAdvancedResearch = userPermissionsStore.isAdvancedResearch;
    userPermissions.isSuperuser = userPermissionsStore.isSuperuser;
    userPermissions.isAccountManager = userPermissionsStore.isAccountManager;
    userPermissions.isAccountStaff = userPermissionsStore.isAccountStaff;
    userPermissions.isDev = userPermissionsStore.isDev;
    userPermissions.isResearchLead = userPermissionsStore.isResearchLead;
    userPermissions.isAllCompanies = userPermissionsStore.isAllCompanies;
    userPermissions.isGenerateTimecard = userPermissionsStore.isGenerateTimecard;
}

const fetchFilter = computed(() => {
    let query = new URLSearchParams({});
    console.log('filters')
    console.log(filters)
    for(const filter in filters) {
        console.log('filter')
        console.log(filter)
        if(filters[filter].multiSelect) {
            if(filters[filter].value.length > 0) {
                filters[filter].value.forEach(filterValue => {
                    query.append(filters[filter].name, filterValue)
                })
            }
        } else {
            if(filters[filter].value) {
                query.append(filters[filter].name, filters[filter].value)
            }
        }
    }
    if (pageSizeFilter.pageSizeFilter) {
      query.append("page_size", pageSizeFilter.pageSizeFilter);
    }
    if(orderBy.value.length > 0){
      query.append("order_by", orderBy.value.join(','))
    }
    let url;
    let queriesCount = 0;
    for (let value of query.values()) {
        queriesCount = queriesCount + 1;
    }
    if (queriesCount > 0) {
        url = "?" + query.toString();
    } else {
        url = "?";
    }
    return url
})

const tableHeadersLastIndex = computed(() => {
    if(props.tableHeaders) {
        return props.tableHeaders.length - 1
    } else {
        return 0
    }
})

const selectedResultIds = computed(() => {
    if(selectedResults.value.length > 0) {
        return selectedResults.value.map(({ id }) => {
            return id;
        });
    } else {
        return [];
    }
})

const viewingFromNbr = computed(function () {
    // console.log(page.value)
    // console.log(pageSizeFilter.pageSizeFilter)
    return (page.value * pageSizeFilter.pageSizeFilter + 1) - pageSizeFilter.pageSizeFilter
  })
  
  
const viewingToNbr = computed(function () {
if (page.value === totalPages.value) {
    return count.value;
} else {
    return page.value * pageSize.value
}
})


function getNext() {
if (next.value) {
    refetchResults(fetchFilter.value + next.value)
      clearSelectAllClearSelectedResults();
} else {
    alert("No next page")
}
}
  
function getPrev() {
if (prev.value) {
    refetchResults(fetchFilter.value + prev.value)
      clearSelectAllClearSelectedResults();
} else {
    alert("No prev page")
}
}

function jumpToPage(targetPage) {
    refetchResults(fetchFilter.value + `&page=${targetPage}`)
      clearSelectAllClearSelectedResults();
}

function goToFirst(){
    refetchResults(fetchFilter.value + `&page=${1}`)
      clearSelectAllClearSelectedResults();
}
  
function goToLast(){
    refetchResults(fetchFilter.value + `&page=${totalPages.value}`)
      clearSelectAllClearSelectedResults();
}
function setPageSizeFilter(target=null){
    // console.log(target)
    if(target) {
      target == "clear" ? (pageSizeFilter.pageSizeFilter = 50) : (pageSizeFilter.pageSizeFilter = target);
      refetchResults(fetchFilter.value + `&page_size=${pageSizeFilter.pageSizeFilter}`)
    }
      clearSelectAllClearSelectedResults();
}

function toggleSelectAll() {
    if(selectAll.value) {
        clearSelectAllClearSelectedResults()
    } else {
        selectAll.value = true;
    }
}

function clearSelectAllClearSelectedResults() {
    clearSelectAll();
    clearSelectedResults();
}

function clearSelectAll() {
  if (selectAll.value) {
    selectAll.value = false;
  }
}

function clearSelectedResults() {
    selectedResults.value = []
    clearedSelectedResults.value = true;
}

function clearFilter(filterName) {
    emit('clear-filter', filterName)
}

function changeFilters(filters) {
    emit('change-filters', filters)
}

function setFilterOptions(filterName) {
    emit('set-filter-options', filterName)
}

function selectTab(tabKey) {
    emit('select-tab', tabKey)
    if(props.menuOptions.tabChangeClearsSelected) {
        clearSelectAllClearSelectedResults()
    }
}

function addFormRow(){
    formRows.value.push(
        {key: Math.random()}
    )
}

function closeRowForm(index) {
    formRows.value.splice(index, 1)
}

function noOrdering(fieldName) {
  if(!orderBy.value.includes(fieldName) && !orderBy.value.includes('-' + fieldName)){
    return true
  } else {
    return false
  }
}

function orderedDescending(fieldName){
  if(orderBy.value.includes('-' + fieldName)){
    return true
  } else {
    return false
  }  
}

function orderedAscending(fieldName){
  if(orderBy.value.includes(fieldName)){
    return true
  } else {
    return false
  }  
}

function changeOrdering(fieldName) {
  if(orderedAscending(fieldName)){
    const index = orderBy.value.indexOf(fieldName)
    orderBy.value.splice(index, 1)
  } else if(orderedDescending(fieldName)) {
    const index = orderBy.value.indexOf('-' + fieldName)
    orderBy.value[index] = fieldName
  } else {
    orderBy.value.push('-' + fieldName)
  }
}

function clearSort() {
  orderBy.value = []
}

function setOrder(fieldNames) {
  orderBy.value = fieldNames
}

function executeQuickBulkAction(bulkActionName) {
    emit('do-quick-bulk', bulkActionName, selectedResults.value)
  }

watch(() => filters, () => {
    if(!awaitingSearch.value) {
        shouldRefetchResults.value = false
        setTimeout(() => {
            refetchResults(fetchFilter.value);
            awaitingSearch.value = false
        }, 1000)
    }
    awaitingSearch.value = true
}, {deep: true})

watch(() => orderBy, () => {
    if(!awaitingSearch.value) {
        shouldRefetchResults.value = false
          setTimeout(() => {
            refetchResults(fetchFilter.value);
            awaitingSearch.value = false;
          }, 1000);
        }
        awaitingSearch.value = true
}, {deep: true})

setUserPermissions()
</script>

<style scoped>
.results-move,
.results-enter-active {
    transition: all 0.3s ease;
}
.results-leave-active {
  transition: all 0.1s ease;
}
.results-enter-from,
.results-leave-to {
  opacity: 0;
  transform: translateX(-30px);
}

.results-leave-active {
    position: absolute;
    /* position: relative; */
}
</style>