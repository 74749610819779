<template>
    <CustomTablePage
        :module-name="moduleName"
        :module-name-singular="moduleNameSingular"
        :response="response"
        :isLoading="isLoading"
        :tableHeaders="tableHeaders"
        :filters="dataRequestFilters"
        :bulkOperations="dataRequestBulkOperations"
        :menuOptions="menuOptions"
        :defaultOrdering="defaultOrdering"
        createModalSize="w-1/3 h-1/2"
        @refetch-results="loadDataRequests"
        @clear-filter="clearFilter"
        @change-filters="setFilters"
        @set-filter-options="setFilterOptions"
        @select-tab="selectRequestType"
    >
        <template #bulk-operations="{ events: { hideBulkActionMenu, hideBulkOperations, clearSelectAllClearSelectedResults, refetchResults }, bulkAction, fetchFilter, selectedResults }">
            <DataRequestBulkOperations
                :bulkAction="bulkAction"
                :selectedResults="selectedResults"
                @set-bulk-operation-options="setFilterOptions"
                @cancel-bulk-operation="hideBulkActionMenu"
                @close-bulk-operation="hideBulkOperations"
                @clear-selected-results="clearSelectAllClearSelectedResults"
                @refetch-results="refetchResults(fetchFilter)"
            />
        </template>
        <template #create-form="{ events: { closeCreateForm, refetchResults }, fetchFilter }">
            <CreateDataRequest
                :data-source-options="dataSourceOptions"
                :client-options="clientOptions"
                @refetch-results="refetchResults(fetchFilter)"
                @close="closeCreateForm"
            />
        </template>
        <template #table-row="{ events: { addSelectedResult, removeSelectedResult, refetchResults, openRowDetails }, selectAll, fetchFilter, userPermissions }">
            <DataRequestRow
                v-for="dataRequest in dataRequestsByStatus"
                :key="dataRequest.id"
                :request-type="requestStatus"
                :dataRequest="dataRequest"
                :selectAll="selectAll"
                :userPermissions="userPermissions"
                @refetch-results="refetchResults(fetchFilter)"
                @select-row="addSelectedResult"
                @deselect-row="removeSelectedResult"
                @open-row-details="openRowDetails"
            />
        </template>
        <template #details-page="{ events: { closeDetails, refetchResults }, resultRow, userPermissions, fetchFilter }">
            <DataRequestDetails
                :data-request="resultRow"
                :request-type="requestStatus"
                :user-permissions="userPermissions"
                :data-source-options="dataSourceOptions"
                :client-options="clientOptions"
                @close-details="closeDetails"
                @refetch-results="refetchResults(fetchFilter)"
            />
        </template>
        <template #table-footer="{ viewingFromNbr, viewingToNbr, count, selectedResults }">
            <DataRequestFooter
                :viewingFromNbr="viewingFromNbr"
                :viewingToNbr="viewingToNbr"
                :count="count"
                :selectedResults="selectedResults"
            />
        </template>
    </CustomTablePage>
</template>

<script setup>
import { ref, reactive, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { getDataRequests } from '@/services/DataRequest';
import { getDataSources } from '@/services/DataSource';
import { getClients } from '@/services/Client';
import { getUsers } from '@/services/User';
import CustomTablePage from '../base-components/CustomTablePage.vue';
import DataRequestBulkOperations from './DataRequestBulkOperations.vue';
import DataRequestRow from './DataRequestRow.vue';
import DataRequestFooter from './DataRequestFooter.vue';
import DataRequestDetails from './DataRequestDetails.vue';
import CreateDataRequest from './CreateDataRequest.vue';

const store = useStore()
const isLoading = ref(false)

const requestStatus = ref('requested')
const moduleName = ref('Data Requests')
const moduleNameSingular = ref('Data Request');
const response = ref(null)
const dataSourceOptions = ref([])
const filteredDataRequests = ref([])
const dataRequestsByStatus = ref([])
const clientOptions = ref([])

watch(() => requestStatus.value, () => {
    categorizeDataRequestsByStatus(filteredDataRequests.value)
})

const menuOptions = reactive({
    quickSearch: true,
    selectAll: false,
    selectAllHeader: true,
    paginationArrows: false,
    totalResults: false,
    pageSize: false,
    refreshButton: true,
    filters: true,
    bulkOperations: false,
    createForm: true,
    addFormRows: false,
    tabs: {
        requested: {
            label: 'Requested', isSelected: true
        },
        scheduled: {
            label: 'Scheduled', isSelected: false
        },
        completed: {
            label: 'Completed', isSelected: false
        }
    },
    tabChangeClearsSelected: true,
})


const defaultOrdering = ref(['priority'])

const requestedHeaders = ref([
    {
        value: 'priority',
        label: 'Priority',
        class: 'p-0 w-[70px] bg-[#F3F4F6]',
        orderFieldName: "priority"
    },
    {
        value: 'request_body',
        label: 'Request',
        class: 'p-0 w-[35%] bg-[#F3F4F6]',
        orderFieldName: "request_body"
    },
    {
        value: 'due_by',
        label: 'Due Date',
        class: 'p-0 w-[15%] bg-[#F3F4F6]',
        orderFieldName: "due_by"
    },
    {
        value: 'data_source_name',
        label: 'Source',
        class: 'p-0 w-[25%] bg-[#F3F4F6]',
        orderFieldName: "data_source__source_name"
    },
    {
        value: 'requested_by',
        label: 'Requested By',
        class: 'p-0 w-[10%] bg-[#F3F4F6]',
        orderFieldName: "requested_by__first_name"
    },
    {
        value: 'client',
        label: 'Client',
        class: 'p-0 w-[15%] bg-[#F3F4F6]',
        orderFieldName: "client__client_name"
    },
])

const scheduledHeaders = ref([
    {
        value: 'request_name',
        label: 'Request',
        class: 'p-0 w-[10%] bg-[#F3F4F6]',
        orderFieldName: "request_name"
    },
    {
        value: 'requested_by',
        label: 'Requested By',
        class: 'p-0 w-[15%] bg-[#F3F4F6]',
        orderFieldName: "requested_by__first_name"
    },
    {
        value: 'data_source_name',
        label: 'Source',
        class: 'p-0 w-[15%] bg-[#F3F4F6]',
        orderFieldName: "data_source__source_name"
    },
    {
        value: 'import_schedule',
        label: 'Import Schedule',
        class: 'p-0 w-[10%] bg-[#F3F4F6]',
        orderFieldName: "import_schedule"
    },
    {
        value: 'last_import_date',
        label: 'Last Import Date',
        class: 'p-0 w-[10%] bg-[#F3F4F6]',
        orderFieldName: "last_import_date"
    },
    {
        value: 'next_import_date',
        label: 'Next Import Date',
        class: 'p-0 w-[10%] bg-[#F3F4F6]',
        orderFieldName: "next_import_date"
    },
    {
        value: 'final_import_date',
        label: 'Final Import Date',
        class: 'p-0 w-[10%] bg-[#F3F4F6]',
        orderFieldName: "final_import_date"
    },    
    {
        value: 'last_imported_companies',
        label: '∆ Last Imported',
        class: 'p-0 w-[10%] bg-[#F3F4F6]',
    },
    {
        value: 'total_imported_companies',
        label: '∆ Total Companies',
        class: 'p-0 w-[10%] bg-[#F3F4F6]',
    },
])

const completedHeaders = ref([
    {
        value: 'request_name',
        label: 'Request',
        class: 'p-0 w-[30%] bg-[#F3F4F6]',
        orderFieldName: "request_name"
    },
    {
        value: 'requested_by',
        label: 'Requested By',
        class: 'p-0 w-[15%] bg-[#F3F4F6]',
        orderFieldName: "requested_by__first_name"
    },
    {
        value: 'data_source_name',
        label: 'Source',
        class: 'p-0 w-[25%] bg-[#F3F4F6]',
        orderFieldName: "data_source__source_name"
    },
    {
        value: 'final_import_date',
        label: 'Final Import Date',
        class: 'p-0 w-[15%] bg-[#F3F4F6]',
        orderFieldName: "final_import_date"
    },
    {
        value: 'total_imported_companies',
        label: '∆ Total Companies',
        class: 'p-0 w-[15%] bg-[#F3F4F6]',
    },
])

const tableHeaders = computed(() => {
    if(requestStatus.value == 'requested') {
        return requestedHeaders.value
    } else if(requestStatus.value == 'scheduled') {
        return scheduledHeaders.value
    } else if(requestStatus.value == 'completed') {
        return completedHeaders.value
    } else {
        return []
    }
})

const dataRequestFilters = reactive({
    requestName: {
        name: "request_name",
        label: "Request",
        value: null,
        defaultValue: null,
        type: 'text',
        widget: 'input',
        multiSelect: false
    },
    dataSource: {
        name: "data_source",
        label: "Data Source",
        value: [],
        defaultValue: [],
        options: [
            {value: "clear", label: "------------"}
        ],
        widget: 'list-box',
        multiSelect: true,
        setOptionsMethod: loadDataSourceOptions.bind(null),
        awaitingOptions: false
    },
    client: {
        name: "client",
        label: "Client",
        value: [],
        defaultValue: [],
        options: [
            {value: "clear", label: "------------"}
        ],
        widget: 'list-box',
        multiSelect: true,
        setOptionsMethod: loadClientOptions.bind(null),
        awaitingOptions: false
    },
    requestedBy: {
        name: "requested_by",
        label: "Requested By",
        value: [],
        defaultValue: [],
        options: [
            {value: "clear", label: "------------"}
        ],
        widget: 'list-box',
        multiSelect: true,
        setOptionsMethod: loadRequestedByOptions.bind(null),
        awaitingOptions: false
    },
})

function setFilters(updatedFilters) {
    dataRequestFilters.requestName = updatedFilters.requestName;
    dataRequestFilters.dataSource = updatedFilters.dataSource;
    dataRequestFilters.client = updatedFilters.client;
    dataRequestFilters.requestedBy = updatedFilters.requestedBy;
}

async function setFilterOptions(filterName, queryParams='') {
    console.log('setFilterOptions', filterName)
    if(!dataRequestFilters[filterName].awaitingOptions){
        if(dataRequestFilters[filterName].setOptionsMethod){
            dataRequestFilters[filterName].awaitingOptions = true
            dataRequestFilters[filterName].setOptionsMethod(queryParams)
            setTimeout(() => {
                dataRequestFilters[filterName].awaitingOptions = false;
            }, 30000)
        }
    }
}

function clearFilter(filterName) {
    if(Array.isArray(dataRequestFilters[filterName].value)){
        dataRequestFilters[filterName].value = []
    } else {
        dataRequestFilters[filterName].value = null
    }
}

const dataRequestBulkOperations = reactive({

})

function selectRequestType(reqType) {
    requestStatus.value = reqType
}

function checkCreatePermission(userPermissions) {
    console.log('checkCreatePermission', userPermissions)
    return false
}

function categorizeDataRequestsByStatus(resultList) {
    filteredDataRequests.value = [...resultList]
    switch(requestStatus.value) {
        case 'requested':
            dataRequestsByStatus.value = [...filteredDataRequests.value.filter((dataRequest) => dataRequest.status == 'Requested')]
            break
        case 'scheduled':
            dataRequestsByStatus.value = [...filteredDataRequests.value.filter((dataRequest) => dataRequest.status == 'Scheduled' || dataRequest.status == 'In Progress')]
            break
        case 'completed':
            dataRequestsByStatus.value = [...filteredDataRequests.value.filter((dataRequest) => dataRequest.status == 'Complete' || dataRequest.status == 'Rejected')]
            break
        default:
            dataRequestsByStatus.value = [...filteredDataRequests.value]
            break
    }
}

async function loadDataSourceOptions() {
    console.log('loadDataSourceOptions')
    const token = store.state.auth.token
    let response = await getDataSources(token, '?get_all=true')
    console.log(response)
    if(response.status == 200) {
        dataSourceOptions.value = [{label: "New Data Source", value: -1, data: {id: -1, source_name: null, notes: null}}, ...response.data.map((dataSource) => {
            return {
                label: dataSource.source_name, 
                value: dataSource.id,
                data: dataSource
            }
        })];
        dataRequestFilters.dataSource.options = [{value: "clear", label: "------------"}, ...response.data.map((dataSource) => {
            return {
                label: dataSource.source_name, 
                value: dataSource.id,
            }
        })];
    }
}

async function loadClientOptions() {
    const token = store.state.auth.token
    const result = await getClients({token: token})
    clientOptions.value = [...result.clients.map((client) => {
        return {value: client.id, label: client.client_name}
    })]
    dataRequestFilters.client.options = [{value: "clear", label: "------------"}, ...result.clients.map((client) => {
        return {
            label: client.client_name, 
            value: client.id,
        }
    })];
}

async function loadRequestedByOptions() {
    let payload = {
        token: store.state.auth.token,
        endpoint: '?is_active=true&group_id=1&group_id=6&group_id=7&group_id=8&exclude_dev=False'
    }
    const result = await getUsers(payload)
    dataRequestFilters.requestedBy.options = [{value: "clear", label: "------------"}, ...result.users.map((user) => {
        return { value: user.id, label: `${user.first_name} ${user.last_name}`}
    })]
}

async function loadDataRequests(fetchFilter='') {
    console.log('loadDataRequests')
    isLoading.value = true;
    const token = store.state.auth.token
    response.value = await getDataRequests(token, fetchFilter);
    categorizeDataRequestsByStatus(response.value.data.results)
    console.log(response.value)
    isLoading.value = false;
}

  





loadDataRequests()
loadDataSourceOptions()
loadClientOptions()
loadRequestedByOptions()
</script>