<template>
    <div class="flex flex-col text-sm fixed w-full h-full">
        <DetailsPageHeader
            :header-title="headerTitle"
            :can-delete="canDelete"
            @close-details="closeDetails"
            @delete="deleteExistingDataRequest"
        />
        <div v-if="requestType=='requested'" class="flex w-full justify-end h-[calc(100vh-6.5rem)]" :style="dataRequestStyle">
            <div class="w-full h-full border-x border-gray-300 pl-9 ">
                <EditDataRequest 
                    :data-request="dataRequest"
                    :data-source-options="dataSourceOptions"
                    :client-options="clientOptions"
                    :edit-mode="editMode"
                    :user-permissions="userPermissions"
                    @refetch-results="refetch"
                    @close="closeDetails"
                />
            </div>
        </div>
        <div v-else-if="requestType=='scheduled'" class="flex w-full h-[calc(100vh-6.5rem)] justify-end" :style="dataRequestStyle">
            <div class="w-full h-full border-x border-gray-300 pl-9">
                <ViewDataRequest 
                    :data-request="dataRequest"
                    :data-source-options="dataSourceOptions"
                    :client-options="clientOptions"
                    :edit-mode="editMode"
                    :user-permissions="userPermissions"
                    @refetch-results="refetch"
                    @close="closeDetails"
                />
            </div>
        </div>
        <div v-else-if="requestType=='completed'" class="flex w-full h-full justify-end" :style="dataRequestStyle">
            <div class="w-full border-x border-gray-300 pl-9">
                <ViewDataRequest 
                    :data-request="dataRequest"
                    :data-source-options="dataSourceOptions"
                    :client-options="clientOptions"
                    :edit-mode="editMode"
                    :user-permissions="userPermissions"
                    @refetch-results="refetch"
                    @close="closeDetails"
                />
            </div>
        </div>
    </div>
</template>

<script setup>
import { defineProps, defineEmits, ref, computed} from 'vue';
import DetailsPageHeader from '../base-components/DetailsPageHeader.vue';
import EditDataRequest from './EditDataRequest.vue';
import ViewDataRequest from './ViewDataRequest.vue';
import { deleteDataRequest } from '@/services/DataRequest';
import { useStore } from 'vuex';
import { useToast } from 'vue-toast-notification';

const store = useStore()
const $toast = useToast()

const props = defineProps(['dataRequest', 'userPermissions', 'dataSourceOptions', 'clientOptions', 'requestType'])
const emit = defineEmits(['close-details', 'refetch-results'])

const editMode = ref(false)

const canDelete = computed(() => {
    if(props.requestType == 'requested' && props.userPermissions) {
        let isRequestedBy = props.dataRequest ? props.dataRequest.requested_by.id == currentUserId.value : false
        console.log('isRequestedBy', isRequestedBy)
        if(props.userPermissions.isDev || isRequestedBy) {
            if(props.dataRequest && props.dataRequest.status == 'Requested') {
                return true
            }
        }
    }
    return false
})

const dataRequestStyle = computed(() => {
    return editMode.value ? 'background-color: #F9FAFB;' : 'background-color: #F3F4F6;'
})


const currentUserId = computed(() => {
  return store.getters['auth/showUser'] ? store.getters['auth/showUser'].id : null
})


function closeDetails() {
    console.log('DataRequestDetail: closeDetails')
    cancelEdit()
    emit('close-details')
}

function refetch() {
    emit('refetch-results')
}

function editDetails() {
    editMode.value = true
}

function cancelEdit() {
    editMode.value = false
}

const headerTitle = computed(() => {
    return props.dataRequest ? props.dataRequest.request_name : ''
})

async function deleteExistingDataRequest() {
    console.log('deleteExistingDataRequest')
    const token = store.state.auth.token
    const response = await deleteDataRequest(token, props.dataRequest.id)
    if(response.status == 204) {
        $toast.open({
            message: `Deleted Data Request.`,
            type: 'success',
            duration: 3000
        })
        refetch()
        closeDetails()
    } else {
        $toast.open({
            message: `Could Not Delete Data Request.`,
            type: 'error',
            duration: 10000
        })
    }
}

</script>