<template>
    <TableRow :isSelected="isSelected" class="h-8 hover:bg-indigo-100 cursor-pointer sticky">
        <td class="border-b border-gray-300 text-xs align-top overflow-hidden w-full h-full cursor-pointer">
            <div class="cursor-pointer pl-5 h-8 flex items-center">     
                <input type="checkbox" id="checkbox" class="accent-dark-secondary cursor-pointer" :checked="isSelected" @change="toggleSelected"/>
            </div>
        </td>
        <td @click="toggleImportCompanies" class="border-b border-gray-300 text-xs align-top overflow-hidden w-full cursor-pointer">
            <div class="cursor-pointer w-full h-8 flex items-center pl-2">{{ importDate }}</div>
        </td>
        <td @click="toggleImportCompanies" class="border-b border-gray-300 text-xs align-top overflow-hidden w-full cursor-pointer">
            <div class="cursor-pointer w-full h-8 flex items-center pl-2">{{ importName }}</div>
        </td>
        <td @click="toggleImportCompanies" class="border-b border-gray-300 text-xs align-top overflow-hidden w-full cursor-pointer">
            <div class="cursor-pointer w-full h-8 flex items-center pl-2">{{ importType }}</div>
        </td>
        <td @click="toggleImportCompanies" class="border-b border-gray-300 text-xs align-top overflow-hidden w-full cursor-pointer">
            <div class="cursor-pointer w-full h-8 flex items-center pl-2 truncate">{{ importURL }}</div>
        </td>
        <td @click="toggleImportCompanies" class="border-b border-gray-300 text-xs align-top overflow-hidden w-full cursor-pointer">
            <div class="cursor-pointer w-full h-8 flex items-center pl-2">{{ importScript }}</div>
        </td>
        <td @click="toggleImportCompanies" class="border-b border-gray-300 text-xs align-top overflow-hidden w-full cursor-pointer">
            <div class="cursor-pointer w-full h-8 flex items-center pl-2">{{ importPattern }}</div>
        </td>
        <td @click="toggleImportCompanies" class="border-b border-gray-300 text-xs align-top overflow-hidden w-full cursor-pointer">
            <div class="cursor-pointer w-full h-8 flex items-center pl-2">{{ importedRows }}</div>
        </td>
    </TableRow>
</template>

<script setup>
import { defineProps, defineEmits, ref, computed, watch  } from 'vue';
import { formatDate } from '@/composables/formatDate';
import TableRow from '../base-components/TableRow.vue';

const props = defineProps(['dataImport', 'selectAll'])

const emit = defineEmits(['open-row-details', 'select-row', 'deselect-row'])

const isSelected = ref(props.selectAll)

const dataImportCompanies = computed(() => {
    return props.dataImport && props.dataImport.companies ? props.dataImport.companies : []
})

watch(() => props.selectAll, () => {
    isSelected.value = props.selectAll
    if(props.selectAll) {
        emit('select-row',dataImportCompanies.value)
    } else if (!props.selectAll) {
        emit('deselect-row', dataImportCompanies.value)
    }
})

const importDate = computed(() => {
    return props.dataImport ? formatDate(props.dataImport.import_date) : null
})

const importName = computed(() => {
    return props.dataImport ? props.dataImport.import_name : null
})

const importType = computed(() => {
    return props.dataImport ? props.dataImport.import_type : null
})

const importURL = computed(() => {
    return props.dataImport ? props.dataImport.import_url : null
})

const importScript = computed(() => {
    return props.dataImport ? props.dataImport.import_script : null
})


const importPattern = computed(() => {
    return props.dataImport ? props.dataImport.import_pattern : null
})

const importedRows = computed(() => {
    return props.dataImport ? props.dataImport.companies.length : null
})

function openRowDetails() {
    console.log('DataImportRow: openRowDetails')
    emit('open-row-details', props.dataImport)
}

function toggleSelected() {
    if(isSelected.value) {
        isSelected.value = false
        emit('deselect-row', dataImportCompanies.value)
    } else {
        isSelected.value = true
        emit('select-row', dataImportCompanies.value)
    }
}

</script>