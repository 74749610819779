<template>
    <div>
        <button
            class="w-full border-b border-x h-[36px] border-gray-300 odd:bg-white even:bg-gray-50 hover:bg-indigo-50 rounded-sm"
            @click="showBulkActionMenu"
        >
            {{ bulkOperation.label }}
        </button>
    </div>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';


const props = defineProps([
    'bulkOperation'
])

const emit = defineEmits([
    'show-bulk-action-menu'
])


function showBulkActionMenu() {
    console.log(';showBUlActionMenu', props.bulkOperation)
    emit('show-bulk-action-menu', props.bulkOperation)
}
</script>