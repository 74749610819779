<template>
    <th
        class="p-0 h-6 text-xs sticky -top-[1px] border-t border-b border-gray-300"
        :class="tableHeader.class"
        @mouseover="showOrdering"
        @mouseout="hideOrdering"
    >
        <slot></slot>
        <div v-if="tableHeader" class="hover:bg-gray-200 cursor-pointer h-full  pl-2">
            <div 
                v-if="visibleOrdering && tableHeader.orderFieldName" 
                :class="{'justify-end' : tableHeader.contentType && tableHeader.contentType == 'number', 'justify-between' : !tableHeader.contentType || tableHeader.contentType != 'number'}"
                class="flex items-center w-full h-full text-gray-500 hover:text-gray-800"
                @click="changeOrdering(tableHeader.orderFieldName)"
            >
                <div class="font-medium  text-gray-800 tracking-wider flex h-full items-center">
                    {{ tableHeader.label }}
                </div>
                <div class="p-1 rounded-sm ">
                    <div v-if="noOrdering(tableHeader.orderFieldName)" class="flex flex-col space-y-0" >
                        <div class="text-[0.55rem] leading-none">▲</div>
                        <div class="text-[0.55rem] leading-none">▼</div>
                    </div>
                    <div v-else-if="orderedAscending(tableHeader.orderFieldName)">
                        <div class="text-[0.55rem] leading-none">▲</div>
                    </div>
                    <div v-else-if="orderedDescending(tableHeader.orderFieldName)">
                        <div class="text-[0.55rem] leading-none">▼</div>
                    </div>
                </div>
            </div>
            <div v-else class="font-medium text-gray-800 tracking-wider flex h-full items-center">
                {{ tableHeader.label }}
            </div>
        </div>
    </th>
</template>

<script setup>
import { defineProps, defineEmits, computed, ref } from 'vue'; 

const props = defineProps(
    [
        'tableHeader',
        'orderBy',
    ]
)

const emit = defineEmits(
    ['change-ordering',]
)
const visibleOrdering = ref(false)

function showOrdering() {
    visibleOrdering.value = true
}

function hideOrdering() {
    visibleOrdering.value = false
}

function noOrdering(fieldName) {
  if(!props.orderBy || (!props.orderBy.includes(fieldName) && !props.orderBy.includes('-' + fieldName))){
    return true
  } else {
    return false
  }
}

function orderedDescending(fieldName){
  if(props.orderBy.includes('-' + fieldName)){
    return true
  } else {
    return false
  }  
}

function orderedAscending(fieldName){
  if(props.orderBy.includes(fieldName)){
    return true
  } else {
    return false
  }  
}

function changeOrdering(fieldName) {
    emit('change-ordering', fieldName)
}
</script>