<template>
    <div class="w-full h-full flex overflow-x-scroll border-gray-200 border" :style="targetListStyle">
        <table class="w-full h-full border-separate border-spacing-0 bg-gray-50">
            <thead class="-top-[1px] left-0 right-0 relative">
                <th 
                    v-for="(header, index) in columnHeaders" 
                    :key="header.fieldName" 
                    class=" border-r border-b-2 whitespace-nowrap border-gray-200 border-b-gray-300 bg-white text-gray-700 tracking-widest font-semibold text-xs sticky -top-[1px]"
                    :style="getHeaderStyleByIndex(index)"
                    >
                    <div v-if="index==0" class="flex justify-center w-full h-full">
                        <div class="w-full h-full pb-[1px] hover:bg-gray-100 flex items-center justify-center cursor-pointer" @click="setExpandAll('expanded')"><ChevronDownMiniSVG /></div>
                        <div class="w-full h-full hover:bg-gray-100 flex items-center justify-center cursor-pointer" @click="setExpandAll('collapsed')"><ChevronUpMiniSVG /></div>
                    </div>
                    <div v-else class="flex justify-start w-full h-full">
                        <div class="px-2 py-1.5">
                            {{ formatHeader(header.fieldName) }}
                        </div>
                    </div>
                </th>
            </thead>
            <tbody class="overflow-y-auto -top-[1px] relative">
                <CRMTargetListRow
                    v-for="company in reportedCompanies" :key="company.id"
                    :company="company"
                    :headers="columnHeaders"
                    :expand-all="expandAll"
                    :toggleRowClick="toggleRowClick"
                    :condensed-view="condensedView"
                    :changed-reported-event="changedCompanyReportedEvent(company)"
                    @set-reported-event="setReportedEvent"
                    @set-expand-all-neutral="setExpandAll"
                    @open-crm-details="openCRMDetails"
                    @open-company-modal="openCompanyModal"
                    @add-company-to-progress-update="addCompanyToProgressUpdate"
                />  
            </tbody>
        </table>
    </div>
</template>

<script setup>
import { ref, reactive, defineProps, defineEmits, watch, computed, onMounted } from 'vue';
import ChevronUpMiniSVG from '@/components/svg-components/ChevronUpMiniSVG.vue';
import ChevronDownMiniSVG from '@/components/svg-components/ChevronDownMiniSVG.vue';
import CRMTargetListRow from './CRMTargetListRow.vue';
import { formatDate } from '@/composables/formatDate';

const props = defineProps([
    'printView',
    'condensedView',
    'clientName',
    'campaigns',
    'campaignOptions',
    'allCRMActions',
    'allCRMStatuses',
    'reportedCompanies',
    'companyIdsToUpdate'
])

const emit = defineEmits([
    'set-reported-event',
    'refetch',
    'add-company-to-progress-update',
    'open-crm-details',
    'open-company-modal'
])

const columnHeaders = ref([])
const expandAll = ref('neutral')
const toggleRowClick = ref(false)
const orderBy = ref(['computed_crm_status'])


watch(() => props.reportedCompanies, () => {
    // console.log('CRMTargetList, props.reportedCompanies changed')
}, {deep: true})

watch(() => props.condensedView, () => {
    if(props.condensedView) {
        showCoreColumns()
    } else {
        showAllColumns()
        
    }
})

const targetListStyle = computed(() => {
    if(props.condensedView) {
        return 'width: 795px; max-width: 795px; min-width: 795px;'
    } else {
        return 'width: 100%';
    }
})

onMounted(() => {
    if(props.reportedCompanies && props.reportedCompanies.length > 0) {
        showAllColumns()
        // showAllColumns()
    }
})

const printedHeaders = computed(() => {
    if(columnHeaders.value && columnHeaders.value.length > 0) {
        return columnHeaders.value.slice(1, 5)
    }
    return []
})

function showCoreColumns() {
    columnHeaders.value = [
        {fieldName: 'expand_collapse_all'},
        {fieldName: 'computed_crm_status'},
        {fieldName: 'company_name'},
        {fieldName: 'reported_crm_event'},
        {fieldName: ''}
    ]
    // const companyObject = props.reportedCompanies[0]
    // columnHeaders.value = [{fieldName: 'computed_crm_status'}, {}, {}, {}] Object.keys(companyObject).map((companyField, index) => {
    //     switch(companyField) {
    //         case 'computed_crm_status':
    //         return {fieldName: companyField, display: true, ordinal: 0}
    //         case 'company_name':
    //             return {fieldName: companyField, display: true, ordinal: index}
    //         case 'reported_crm_event':
    //             return {fieldName: companyField, display: true, ordinal: 2}
    //         // case 'campaign':
    //         //     return {fieldName: companyField, display: true, ordinal: 4}
    //         default:
    //             return {fieldName: companyField, display: true, ordinal: index}
    //     }
    // })
    // columnHeaders.value = [...columnHeaders.value.slice(0, 4), {fieldName: 'Add', display: true, ordinal: 3}].sort((a, b) => {return a.ordinal - b.ordinal})

}

function showAllColumns() {
    const companyObject = props.reportedCompanies[0]
    columnHeaders.value = Object.keys(companyObject).map((companyField, index) => {
        switch(companyField) {
            case 'computed_crm_status':
                return {fieldName: companyField, display: true, ordinal: 0}
            case 'company_name':
                return {fieldName: companyField, display: true, ordinal: index}
            case 'campaign':
                return {fieldName: companyField, display: true, ordinal: 1.75}
            case 'reported_crm_event':
                return {fieldName: companyField, display: true, ordinal: 1.5}
            default:
                return {fieldName: companyField, display: true, ordinal: index}
        }
    }).sort((a, b) => {return a.ordinal - b.ordinal})
}

function printedFields(companyFieldList) {
    let printedFieldsList = []
    companyFieldList.forEach((keyValue) => {
        if(keyValue[0] == 'computed_crm_status') {
            printedFieldsList.push(keyValue)
        } else if(keyValue[0] == 'company_name') {
            printedFieldsList.push(keyValue)
        } else if(keyValue[0] == 'campaign') {
            printedFieldsList.push(keyValue)
        } else if(keyValue[0] == 'reported_crm_event') {
             printedFieldsList.push(keyValue)
        }
    })
    return printedFieldsList
}

function setExpandAll(expandAllValue) {
    expandAll.value = expandAllValue
}

function refetch() {
    emit('refetch')
}


function openCRMDetails(company) {
    emit('open-crm-details', company)
}

function openCompanyModal(company) {
    emit('open-company-modal', company)
}

function addCompanyToProgressUpdate(companyID) {
    // console.log('CRMTargetList: addCompanyToProgressUpdate')
    emit('add-company-to-progress-update', companyID)
}

function handleFieldDisplay(fieldName, fieldValue) {
    if(fieldValue) {
        if(fieldName == 'campaign') {
            return fieldValue.campaign_name
        }
        else if(fieldName == 'tier_object') {
            return fieldValue.tier_name
        }
        else if(fieldName == 'crm_actions') {
            if(fieldValue.length == 1) {
                return `${fieldValue.length} CRM Action`
            }
            return `${fieldValue.length} CRM Actions`
        }   
        else if(fieldName == 'reported_crm_event') {
            return `${formatDate(fieldValue.resolved_date)}: ${fieldValue.action.action_name}`
        }
        else if (fieldName == 'computed_crm_status') {
            return fieldValue.label
        }
        return fieldValue
    }
    return ''
}


// 3 cols
function getHeaderStyleByIndex(index) {
    if(props.condensedView) {
        if(index == 0) {
            return `position: sticky; max-width: 46px; width: 46px; min-width: 46px; left: 0px; z-index: 20;`
        } else if(index == 1) {
            return `position: sticky; max-width: 200px; width: 200px; min-width: 200px; left: 46px; z-index: 20;` // 300
        } else if(index == 2) {
            return `position: sticky; max-width: 300px; width: 300px; min-width: 300px; left: 246px; z-index: 20;` // 150
        } else if (index == 3) {
            return `position: sticky; max-width: 200px; width: 200px; min-width: 200px; left: 546px; z-index: 20; border-right: 2px solid #D1D5DB;`
        } else if (index == 4) {
            return `position: sticky; max-width: 30px; width: 30px; min-width: 30px; left: 746px; z-index: 20;`
        } else {
            return 'z-index: 10'
        }
    } else {
        if(index == 0) {
            return `position: sticky; max-width: 46px; width: 46px; min-width: 46px; left: 0px; z-index: 20;`
        } else if(index == 1) {
            return `position: sticky; max-width: 200px; width: 200px; min-width: 200px; left: 46px; z-index: 20;` // 300
        } else if(index == 2) {
            return `position: sticky; max-width: 300px; width: 300px; min-width: 300px; left: 246px; z-index: 20;` // 150
        } else if (index == 3) {
            return `position: sticky; max-width: 200px; width: 200px; min-width: 200px; left: 546px; z-index: 20; border-right: 2px solid #D1D5DB;`
        } else {
            return 'z-index: 10'
        }
    }

}

function formatHeader(fieldName) {
    if(fieldName == 'reported_crm_event') {
        if(props.printView) {
            return 'CRM Detail'
        } else {
            return 'Reported Detail (*Auto)'
        }
    } else if(fieldName == 'computed_crm_status') {
        return 'CRM Status'
    }
    let headerLabel = fieldName.replaceAll('_', ' ').replace(/(^| )(\w)/g, function(x) {return x.toUpperCase();}).replaceAll('Url', 'URL').replaceAll('Crm', 'CRM')
    return headerLabel
}

function changedCompanyReportedEvent(company) {
    if(props.companyIdsToUpdate.includes(company.id)) {
        return true
    }
    return false
}

function setReportedEvent(company, event, autoCompute) {
    emit('set-reported-event', company, event, autoCompute)
}

</script>