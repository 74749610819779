<template>
    <div v-if="finishedAction">
        <div v-if="result.status === 200 && (endpoint==='bulk_assign_to_campaign' || endpoint==='bulk_remove_from_campaign')">
            <p>{{ this.title }}: Success!</p>
            <br>
            <p class="whitespace-pre-wrap">{{ result.data.message }}</p>
        </div>
        <div v-else-if="result.status === 200">
            <p>{{ this.title }}: Success!</p>
            <br>
            <p>This action has been performed on {{ numCompanies}} companies.</p>
        </div>
        <div v-else-if="result.status === 201 && endpoint==='bing_api_search'">
            <p>{{ this.title }}: Success!</p>
            <br>
            <p>Asynchronous job named {{ payload }} has been created. Check the Job Queue page for updates.</p>
        </div>
        <div v-else-if="result.status === 'cancelled'">
            <p>{{ this.title }}: Bulk Operation Cancelled</p>
            <br>
            <p>This action has been cancelled. Please close this modal.</p>
        </div>
        <div v-else>
            <p>{{ this.title }}: Failed: Bad Request</p>
            <br>
            <p>An error occurred that prevented the completion of this action.</p>
        </div>
        <br>
        <div class="flex justify-center">
            <base-button-negative
                type="button"
                class=""
                @click="closeModal"
            >
                Close
            </base-button-negative>
        </div>
    </div>
    <div v-else-if="openPrompt">
        <div class="space-y-2 mb-4">
            <p>{{ confirmTitle }}</p>
            <p>{{ confirmMessage }}</p>
        </div>
        <div class="max-h-[20vh] w-full overflow-y-scroll" v-if="promptDisplayedCompanies.length > 0">
            <base-table-auto class="w-full" :alignment="'left'">
                <template #table-header>
                    <base-table-header>
                        ID
                    </base-table-header>
                    <base-table-header>
                        Company Name
                    </base-table-header>
                    <base-table-header>
                        Website URL
                    </base-table-header>
                    <base-table-header>
                        Campaign
                    </base-table-header>
                </template>
                <template #table-body>
                    <base-table-row :key="company.id" v-for="company in promptDisplayedCompanies">
                        <base-table-cell>
                        {{ company.id }}
                        </base-table-cell>
                        <base-table-cell>
                        {{ company.company_name }}
                        </base-table-cell>
                        <base-table-cell>
                        {{ company.website_url }}
                        </base-table-cell>
                        <base-table-cell v-if="company.campaign">
                            {{ company.campaign.campaign_name }}
                        </base-table-cell>
                        <base-table-cell v-else>
                            --
                        </base-table-cell>
                    </base-table-row>                    
                </template>
            </base-table-auto>
        </div>
        <div class="flex justify-center my-2">
            <base-button-affirmative
                type="button"
                class="mx-1"
                @click="skipPromptAndPerformBulkOperation(promptSelectedCompanies, endpoint)"
            >
                Continue
            </base-button-affirmative>
            <base-button-negative
                type="button"
                class="mx-1"
                @click="cancelAction"
            >
                Cancel
            </base-button-negative>
        </div>
    </div>
    <div v-else>
        <div v-if="numCompanies > 0">
            <div v-if="isLoading" role="status" class="flex justify-center items-center">
                <svg aria-hidden="true" class="mr-2 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600"
                        viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="white"/>
                    <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="#2E9CCA"/>
                </svg>
                <span class="sr-only">Loading...</span>
            </div>
            <div v-else>
                <p>{{ this.title }}: Are you sure you would like to perform this action on {{ numCompanies }} companies?</p>
                <br>
                <div v-if="endpoint=='bulk_resolve_duplicates'">
                    <br>
                    <p>Please enter the correct url for all selected companies:</p>
                    <input class="p-1 border mt-1 w-full block" type="text" name="websiteUrl" id="websiteUrl" @input="setPayload"  />
                    <br>
                </div>
                <div v-else-if="endpoint=='bulk_assign_source_owner'">
                    <br>
                    <p>Please choose a researcher to assign as source owner:</p>
                    <form action="" class="max-h-10">
                        <div class="m-1 flex justify-center">
                            <select name="sourceOwner" id="sourceOwner"
                                class="bg-gray-50 p-1 border w-2/4 border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                @input="setPayload"
                                >
                                <option value="null">------------</option>
                                <option value="None">None</option>
                                <option value="Unassigned">Unassigned</option>
                                <option v-for="sourceOwner in sourceOwners" :key="sourceOwner.id" :value="`${sourceOwner.first_name} ${sourceOwner.last_name}`">{{ sourceOwner.first_name}} {{sourceOwner.last_name}}</option>
                            </select>
                        </div>
                    </form>
                    <br>
                </div>
                <div v-else-if="endpoint=='bulk_assign_to_campaign'">
                    <br>
                    <p>Please choose a campaign:</p>
                    <form action="" class="flex flex-col items-center">
                        <div class="m-1 flex justify-center">
                            <select name="campaign" id="campaign"
                                class="bg-gray-50 p-1 border w-2/4 border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                v-model="campaignId"
                                >
                                <option value="null">------------</option>
                                <option v-for="campaign in campaigns" :key="campaign.id" :value="`${campaign.id}`">{{ campaign.campaign_name}}</option>
                            </select>
                        </div>
                        <div class="m-1 flex justify-center space-x-2 items-center">
                            <label for="">Copy CRM Details?</label>
                            <input type="checkbox" v-model="copyCRMDetails">
                        </div>
                    </form>
                    <br>
                </div>
                <div v-else-if="endpoint=='bing_api_search'">
                    <br>
                    <p>
                        This action will create an asynchronous job to run the selected companies through the Bing API for URL discovery. Please note: only companies in the US are eligible for 
                        Bing API discovery, companies that have already been run through the API will not be checked again. This action is not free; <span class="font-bold"> the API costs $15 for every 1,000 companies checked</span> and no more than 500 companies can be run on a particular day. Please enter a name for the asynchronous job:
                    </p>
                    <form action="" class="max-h-10">
                        <div class="m-1 flex justify-center">
                            <input class="bg-gray-50 p-1 border w-2/4 border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                @input="setPayload" />
                        </div>
                    </form>
                    <br>
                </div>
                <div v-else-if="endpoint=='export_zi_ids'">
                    <br>
                    <p>
                        This will generate a csv with the selected companies' ZoomInfo Ids for ListMatch uploads.
                    </p>
                    <form action="" class="max-h-10">
                        <div class="m-1 flex justify-center">
                        </div>
                    </form>
                    <br>
                </div>
                <div v-else-if="endpoint=='bulk_add_to_crm_summary'">
                    <br>
                    <p>Input text to add:</p>
                    <form action="" class="max-h-10">
                        <div class="m-1 flex justify-center">
                            <input class="bg-gray-50 p-1 border w-2/4 border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                @input="setPayload" />
                        </div>
                    </form>
                    <br>
                </div>
                <div v-else-if="endpoint=='bulk_update_category'">
                    <br>
                    <p>Category:</p>
                    <form action="" class="max-h-10">
                        <div class="m-1 flex justify-center">
                            <input class="bg-gray-50 p-1 border w-2/4 border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                @input="setPayload" />
                        </div>
                    </form>
                    <br>
                </div>
                <div v-else-if="endpoint=='bulk_update_relevant_business'">
                    <br>
                    <p>Relevant Business:</p>
                    <form action="" class="max-h-10">
                        <div class="m-1 flex justify-center">
                            <input class="bg-gray-50 p-1 border w-2/4 border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                @input="setPayload" />
                        </div>
                    </form>
                    <br>
                </div>
                <div v-else-if="endpoint=='bulk_assign_collection_tag'">
                    <br>
                    <p>Please choose the collection tags to assign:</p>
                    <div class="flex flex-wrap p-1 border rounded-md">
                        Tags:
                        <base-pill class="hover:cursor-pointer m-1" @click="removeTag(value)" v-for="value in payload" :key="value">{{ value }} | X</base-pill>
                        <div class="ml-1" v-if="!payload || payload.length == 0">
                            None
                        </div>
                    </div>
                    <form action="" @submit.prevent="" class="max-h-10">
                        <div class="m-1 flex justify-center">
                            <input
                                name="collectionTag"
                                id="collectionTag"
                                type="text" list="collectionTags"
                                class="bg-gray-50 p-1 border w-2/4 border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                @change="setCollectionTagPayload"
                            />
                            <datalist id="collectionTags">
                                <option value="null">------------</option>
                                <option v-for="collectionTag in collectionTags" :key="collectionTag.id" :value="`${collectionTag.collection_tag_name}`">{{ collectionTag.collection_tag_name }}</option>
                            </datalist>
                        </div>
                    </form>
                    <br>
                </div>
                <div v-else-if="endpoint=='bulk_remove_collection_tag'">
                    <br>
                    <p>Please choose a collection tag to remove:</p>
                    <form action="" class="max-h-10">
                        <div class="m-1 flex justify-center">
                            <select name="collectionTag" id="collectionTag"
                                class="bg-gray-50 p-1 border w-2/4 border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                @input="setPayload"
                                >
                                <option value="null">------------</option>
                                <option v-for="collectionTag in collectionTags" :key="collectionTag.id" :value="`${collectionTag.collection_tag_name}`">{{ collectionTag.collection_tag_name }}</option>
                            </select>
                        </div>
                    </form>
                    <br>
                </div>
                <div v-else-if="endpoint=='bulk_mark_archive_reason'">
                    <br>
                    <p>Please choose the reason for archiving:</p>
                    <form action="" class="max-h-10">
                        <div class="m-1 flex justify-center">
                            <select name="crmStatus" id="crmStatus"
                                class="bg-gray-50 p-1 border w-2/4 border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                @input="setPayload"
                                >
                                <option value="null">------------</option>
                                <option value="Does Not Exist">Does Not Exist</option>
                                <option value="Not Aerospace">Not Aerospace</option>
                                <option value="Permanently Closed">Permanently Closed</option>
                            </select>
                        </div>
                    </form>
                    <br>
                </div>
                <div v-else-if="endpoint=='bulk_mark_crm_status'">
                    <br>
                    <p>Please choose a CRM Status to assign:</p>
                    <form action="" class="max-h-10">
                        <div class="m-1 flex justify-center">
                            <select name="crmStatus" id="crmStatus"
                                class="bg-gray-50 p-1 border w-2/4 border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                @input="setPayload"
                                >
                                <option value="null">------------</option>
                                <option v-for="status in statusOptions" :key="status.label" :value="status.value">{{ status.label }}</option>
                            </select>
                        </div>
                    </form>
                    <br>
                </div>
                <div v-else-if="endpoint=='bulk_mark_crm_next_action'">
                    <br>
                    <p>Please choose a CRM Next Action to assign:</p>
                    <form action="" class="max-h-10">
                        <div class="m-1 flex justify-center">
                            <select name="crmNextAction" id="crmNextAction"
                                class="bg-gray-50 p-1 border w-2/4 border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                @input="setPayload"
                                >
                                <option value="null">------------</option>
                                <option v-for="action in nextActionOptions" :key="action.label" :value="action.value">{{ action.label }}</option>
                            </select>
                        </div>
                    </form>
                    <br>
                </div>
                <div v-else-if="endpoint=='bulk_mark_client_feedback'">
                    <br>
                    <p>Please assign Client Feedback:</p>
                    <form action="" class="max-h-10">
                        <div class="m-1 flex justify-center">
                            <select name="crmNextAction" id="crmNextAction"
                                class="bg-gray-50 p-1 border w-2/4 border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                @input="setPayload"
                                >
                                <option value="null">------------</option>
                                <option v-for="feedback in clientFeedbackOptions" :key="feedback.label" :value="feedback.value">{{ feedback.label }}</option>
                            </select>
                        </div>
                    </form>
                    <br>
                </div>
                <div v-else-if="endpoint=='bulk_mark_everaxis_pillar'">
                    <br>
                    <p>Please Mark EverAxis Pillar:</p>
                    <form action="" class="max-h-10">
                        <div class="m-1 flex justify-center">
                            <select name="crmNextAction" id="crmNextAction"
                                class="bg-gray-50 p-1 border w-2/4 border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                @input="setPayload"
                                >
                                <option value="null">------------</option>
                                <option v-for="pillar in everaxisPillarOptions" :key="pillar.label" :value="pillar.value">{{ pillar.label }}</option>
                            </select>
                        </div>
                    </form>
                    <br>
                </div>
                <div v-else-if="endpoint=='bulk_mark_crm_next_action_due_date'">
                    <br>
                    <p>Please choose a CRM Next Action Due Date:</p>
                    <form action="" class="max-h-10">
                        <div class="m-1 flex justify-center">
                            <input name="crmNextActionDueDate" id="crmNextActionDueDate" type="date"
                                class="bg-gray-50 p-1 border w-2/4 border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                @input="setPayload"
                                >
                        </div>
                    </form>
                    <br>
                </div>
                <div v-else-if="endpoint=='bulk_add_new_crm_action'">
                    <br>
                    <p>Please fill in fields to add CRM Detail:</p>
                    <form action="" class="max-h-10 my-2">
                        <div class="m-1 flex justify-start w-full space-x-2">
                            <div class="flex flex-col items-start w-full">
                                <label for="crmNewActionAction">Detail*:</label>
                                <select id="crmNewActionAction" required
                                    class="bg-gray-50 p-1 border w-full border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    @input="setCRMAction"
                                    >
                                    <option :value="null">------------</option>
                                    <option v-for="action in actionOptions" :key="action.id" :value="action.id">{{ action.action_name }}</option>
                                </select>
                            </div>
                            <!-- <div class="flex flex-col items-start w-full">
                                <label for="crmNewActionAction">Status*:</label>
                                <select id="crmNewActionAction" required
                                    class="bg-gray-50 p-1 border w-full border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    @input="setCRMActionStatus"
                                    >
                                    <option value="Open">Open</option>
                                    <option value="Done">Done</option>
                                </select>
                            </div> -->
                            <div  class="flex flex-col items-start w-full">
                                <label for="crmNewActionDueDate">Due Date:</label>
                                <input id="crmNewActionDueDate" type="date"
                                    class="bg-gray-50 p-1 border w-full border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    @input="setCRMActionDueDate"
                                >
                            </div>
                            <div  class="flex flex-col items-start w-full">
                                <label for="crmNewActionDueDate">Res Date:</label>
                                <input id="crmNewActionDueDate" type="date"
                                    class="bg-gray-50 p-1 border w-full border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    @input="setCRMActionResolvedDate"
                                >
                            </div>
                            <div  class="flex flex-col items-start w-full">
                                <label for="crmNewActionDueDate">Note:</label>
                                <input id="crmNewActionDueDate" type="text"
                                    class="bg-gray-50 p-1 border w-full border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    @input="setCRMActionSummaryNote"
                                >
                            </div>
                            <div  class="flex flex-col items-start w-full">
                                <label for="crmNewActionUser">User:</label>
                                <select id="crmNewActionUser"
                                    class="bg-gray-50 p-1 border w-full border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    @input="setCRMActionUser"
                                    >
                                    <option :value="null">------------</option>
                                    <option value=3>Scott Shedd</option>
                                    <option v-for="crmUser in crmUsers" :key="crmUser.id" :value="`${crmUser.id}`">{{ crmUser.first_name}} {{ crmUser.last_name }}</option>
                                </select>
                            </div>
                        </div>
                    </form>
                    <br>
                </div>
                <div v-else-if="endpoint=='bulk_assign_new_crm_status'">
                    <br>
                    <p>Please choose a CRM Status:</p>
                    <form action="" class="max-h-10">
                        <div class="m-1 flex justify-center">
                            <select name="crmStatus" id="crmStatus"
                                class="bg-gray-50 p-1 border w-2/4 border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                @input="setPayload"
                                >
                                <option value="null">------------</option>
                                <option v-for="crmStatus in crmStatuses" :key="crmStatus.id" :value="`${crmStatus.id}`">{{ crmStatus.status_name}}</option>
                            </select>
                        </div>
                    </form>
                    <br>
                </div>
                <div v-else-if="endpoint=='bulk_send_email_template'">
                    <br>
                    <p>Please choose an Email Template:</p>
                    <form action="" class="max-h-10">
                        <div class="m-1 flex justify-center">
                            <select name="emailTemplate" id="emailTemplate"
                                class="bg-gray-50 p-1 border w-2/4 border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                @input="setPayload"
                                >
                                <option value="null">------------</option>
                                <option v-for="template in emailTemplates" :key="template.value" :value="template.value">{{ template.label }}</option>
                            </select>
                        </div>
                    </form>
                    <br>
                </div>
                <div class="flex justify-center">
                    <base-button-affirmative
                        type="button"
                        class="mx-1"
                        @click="performBulkOperation(selectedCompanies, endpoint)"
                    >
                        Continue
                    </base-button-affirmative>
                    <base-button-negative
                        type="button"
                        class="mx-1"
                        @click="closeModal"
                    >
                        Cancel
                    </base-button-negative>
                </div>
                <br>
                <div v-if="showSelectedCompanies">
                    <div class="flex justify-between">
                        <p class="text-left text-sm text-accent-primary hover:text-dark-primary" @click="toggleShowSelectedCompanies">Hide {{numCompanies}} Selected Companies...</p>
                        <div>
                            <input type="checkbox" id="checkbox" class="align-middle" :checked="keepSelectedCompanies" @change="toggleKeepSelectedCompanies"/><span class="pl-1 align-middle text-sm">Keep current company selection</span>
                        </div>
                    </div>
                    <div class="max-h-[20vh] w-full overflow-y-scroll">
                        <base-table-auto class="w-full" :alignment="'left'">
                        <template #table-header>
                            <base-table-header>
                                ID
                            </base-table-header>
                            <base-table-header>
                                Company Name
                            </base-table-header>
                            <base-table-header>
                                Website URL
                            </base-table-header>
                            <base-table-header>
                                Campaign
                            </base-table-header>
                        </template>
                        <template #table-body>
                            <base-table-row :key="company.id" v-for="company in selectedCompanies">
                                <base-table-cell>
                                {{ company.id }}
                                </base-table-cell>
                                <base-table-cell>
                                {{ company.company_name }}
                                </base-table-cell>
                                <base-table-cell>
                                {{ company.website_url }}
                                </base-table-cell>
                                <base-table-cell v-if="company.campaign">
                                    {{ company.campaign.campaign_name }}
                                </base-table-cell>
                                <base-table-cell v-else>
                                    --
                                </base-table-cell>
                            </base-table-row>                    
                        </template>
                        </base-table-auto>
                    </div>
                </div>
                <div v-else class="text-sm">
                    <div class="flex justify-between">
                        <p class="text-accent-primary hover:text-dark-primary" @click="toggleShowSelectedCompanies">View {{numCompanies}} Selected Companies...</p>
                        <div>
                            <input type="checkbox" id="checkbox" class="align-middle" :checked="keepSelectedCompanies.value" @change="toggleKeepSelectedCompanies"/><span class="pl-1 align-middle text-sm">Keep current company selection</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <p>No company rows have been selected. Please select one or more companies to perform a bulk action.</p>
            <br>
            <div class="flex justify-center">
                <base-button-negative
                    type="button"
                    class="mb-3"
                    @click="closeModal"
                >
                    Close
                </base-button-negative>
        </div>           
        </div>
    </div>
</template>
<script>
import { ref, reactive } from 'vue';
import { performBulkAction } from '../services/Company.js';
import {useStore} from 'vuex';
import { createAsynchronousJob } from '@/services/AsynchronousJobs';
import { createZIUploadCsv } from '@/composables/createCSV.js';


export default {
    name: "company-bulk-actions",
    props: [
        'title',
        'endpoint',
        'bulkActionData',
        'selectedCompanies',
        'sourceOwners',
        'collectionTags',
        'emailTemplates',
        'campaigns',
        'crmUsers',
        'crmStatuses',
        'actionOptions'
    ],
    setup(props, context) {
        const numCompanies = ref(props.selectedCompanies.length);
        const store = useStore();
        const payload = ref(props.bulkActionData);
        const crmNewActionPayload = reactive({
            action_name: null,
            due_date: null,
            resolved_date: null,
            user: null,
            status: 'Open'
        })
        const isLoading = ref(false);
        const showSelectedCompanies = ref(false);
        const finishedAction = ref(false);
        let companyIds = ref([]); 
        let campaignIds = ref([]);
        const confirmTitle = ref('');
        let confirmMessage = ref('');    
        let result = ref('');
        const keepSelectedCompanies = ref(false);
        const openPrompt = ref(false);
        const skipPrompt = ref(false);
        const promptSelectedCompanies = ref([]);
        const copyCRMDetails = ref(false)
        const campaignId = ref(null)
        const promptDisplayedCompanies = ref([]);

        function setCRMActionDueDateTomorrow(){
            let today = new Date()
            let tomorrow = today.getDate() + 1
            today.setDate(tomorrow)
            crmNewActionPayload.due_date = today.toJSON().substring(0,10)
        }

        const statusOptions = ref([
        {value: "None", label: 'None'},
        {value: 'Revisit / Cut', label: 'Revisit / Cut'},
        {value: 'Client Pass', label: 'Client Pass'},
        {value: 'Not Contacted', label: 'Not Contacted'},
        {value: 'No Response', label: 'No Response'},
        {value: 'Hard No', label: 'Hard No'},
        {value: 'Responded / Coverage', label: 'Responded / Coverage'},
        {value: 'Active - In Dialogue', label: 'Active - In Dialogue'},
        {value: 'IOI Submitted', label: 'IOI Submitted'},
        {value: 'Under LOI', label: 'Under LOI'},
        {value: 'Research Further', label: 'Research Further'},
        {value: 'Hold', label: 'Hold'},
        {value: 'Closed', label: 'Closed'},
        {value: 'Dead - Acquired by Other Party', label: 'Dead - Acquired by Other Party'},
        {value: 'Not Now/Keep Warm', label: 'Not Now/Keep Warm'},
        ])

        const nextActionOptions = ref([
            {value: 'None', label: 'None'},
            {value: 'Determine Next Action', label: 'Determine Next Action'},
            {value: 'Scott to Follow-Up', label: 'Scott to Follow-Up'},
            {value: 'Send Initial Email', label:'Send Initial Email' },
            {value: 'Send Another Email', label: 'Send Another Email'},
            {value: 'Send Email on Acquisition', label: 'Send Email on Acquisition'},
            {value: 'Research Correct Email', label: 'Research Correct Email'},
            {value: 'Call', label: 'Call'},
            {value: 'Schedule Meeting', label: 'Schedule Meeting'},
            {value: 'Wait on Response', label: 'Wait on Response'},
            {value: 'Wait for Client Feedback', label: 'Wait for Client Feedback'},
            {value: 'Meet', label: 'Meet'},
            {value: 'Not Now', label: 'Not Now'},
            {value: 'Leave Alone', label: 'Leave Alone'},
            {value: 'Referral Source to Reach Out', label: 'Referral Source to Reach Out'},
            {value: "Send 2nd Email", label: "Send 2nd Email"},
            {value: "Send 3rd Email", label: "Send 3rd Email"},
            {value: "Send 4th Email", label: "Send 4th Email"},
            {value: "Cold Call 1", label: "Cold Call 1"},
            {value: "Cold Call 2", label: "Cold Call 2"},
            {value: "Call again", label: "Call again"},
            {value: "Periodic Check In", label: "Periodic Check In"},
            {value: "Submit IOI", label: "Submit IOI"},
            {value: "Submit LOI", label: "Submit LOI"},
        ])

        const clientFeedbackOptions = ref([
            {value: 'noFeedback', label: 'None'},
            {value: 'approved', label: 'Approved'},
            {value: 'excluded', label: 'Excluded'},
            {value: 'hold', label: 'Hold'},
            {value: 'pass', label: 'Pass'},
            {value: 'research_further', label: 'Research Further'},
        ])

        const everaxisPillarOptions = ref([
            {value: 'noPillar', label: 'None'},
            {value: 'P1: Peers', label: 'P1: Peers'},
            {value: 'P2: EverAxis in Motion', label: 'P2: EverAxis in Motion'},
            {value: 'P3: Forever Connecting', label: 'P3: Forever Connecting'},            
        ])

        function setPayload(event) {
            payload.value = event.target.value
        }

        function setCollectionTagPayload(event) {
            if(event.target.value){
                if(Array.isArray(payload.value)){
                    if(!payload.value.includes(event.target.value)){
                        payload.value.push(event.target.value)
                    }
                } else {
                    payload.value = [event.target.value]
                }
                event.target.value = null
            }
            // console.log('payload', payload.value)
        }
        function removeTag(tagName){
            payload.value.splice(payload.value.indexOf(tagName), 1)
        }
        function setCRMActionName(event) {
            crmNewActionPayload.action_name = event.target.value;
        }
        function setCRMActionStatus(event) {
            crmNewActionPayload.status = event.target.value;
        }
        function setCRMAction(event) {
            crmNewActionPayload.action_id = event.target.value;
        }

        function setCRMActionDueDate(event) {
            crmNewActionPayload.due_date = event.target.value;
        }
        function setCRMActionResolvedDate(event) {
            crmNewActionPayload.resolved_date = event.target.value;
        }
        function setCRMActionSummaryNote(event) {
            crmNewActionPayload.action_notes = event.target.value;
        }

        function setCRMActionUser(event) {
            crmNewActionPayload.user = event.target.value;
        }

        async function performBulkOperation(companyList, endpoint) {
            companyIds = companyList.map(({id}) => id)
            console.log('campaign ids', campaignIds)
            console.log('endpoint', endpoint)
            console.log('payload value', payload.value)
            if(endpoint === 'bulk_assign_collection_tag' || endpoint === 'bulk_remove_collection_tag') {
                if(payload.value === null) {
                    return alert("Please choose a valid collection tag to proceed.")
                }
            }
            if(endpoint==='bulk_add_new_crm_action' && !crmNewActionPayload.action_id){
                return alert("Please choose an Event to proceed")
            }
            if(!keepSelectedCompanies.value){
                context.emit('clear-selected-companies')
            }
            isLoading.value = true
            const token = store.state.auth.token
            if(endpoint==='bulk_add_new_crm_action') {
                campaignIds.value = new Set(companyList.map(({campaign}) => {
                    if(campaign) {
                        return campaign.id
                    } else {
                        return 0
                    }
                }))
                console.log('campaign ids', campaignIds.value)
                if(skipPrompt.value) {
                    // if(!crmNewActionPayload.due_date) {
                    //     setCRMActionDueDateTomorrow()
                    // }

                    result.value = await performBulkAction(token, companyIds, endpoint, crmNewActionPayload)
                } else {
                    if(campaignIds.value.size > 1) {
                        confirmTitle.value = 'Alert: '
                        if(campaignIds.value.has(0)) {
                            confirmTitle.value += 'Selected companies not assigned to a campaign. '
                            promptDisplayedCompanies.value = companyList.filter((company) => !company.campaign)
                            confirmMessage.value += `${promptDisplayedCompanies.value.length} of ${companyList.length} selected companies are not assigned to a campaign (below). CRM actions won't be created for these companies.\n`
                            if(campaignIds.value.size > 2) {
                                confirmTitle.value += 'Selected companies have multiple campaigns.'
                                confirmMessage.value += 'Remaining selected companies are assigned to multiple campaigns.\n'
                            }
                        } else {
                            confirmMessage.value += 'Selected companies are assigned to multiple campaigns.\n'
                        }
                        confirmMessage.value += 'Do you wish to proceed?'
                        promptSelectedCompanies.value = companyList
                        openPrompt.value = true
                        return
                    } else {
                        // if(!crmNewActionPayload.due_date) {
                        //     setCRMActionDueDateTomorrow()
                        // }
                        result.value = await performBulkAction(token, companyIds, endpoint, crmNewActionPayload)
                    }
                }
            }else {
                if(endpoint==='bulk_assign_to_campaign') {
                    payload.value = {
                        campaign_id: campaignId.value,
                        copy_details: copyCRMDetails.value
                    }
                    companyList.forEach((company) => {
                        if(company.campaign){
                            campaignIds.value.push(company.campaign.id)
                            promptDisplayedCompanies.value.push(company)
                        }
                    })
                }
                if(skipPrompt.value){
                    result.value = await performBulkAction(token, companyIds, endpoint, payload.value)
                } else {
                    if(campaignIds.value.length >= 1) {
                        confirmTitle.value += `Alert: ${campaignIds.value.length} of ${companyList.length} selected companies already have campaigns.`
                        confirmMessage.value += 'The companies below are already assigned to campaigns.\n'
                        confirmMessage.value += 'Do you wish to proceed?'
                        promptSelectedCompanies.value = companyList
                        openPrompt.value = true
                        return
                    }
                if(endpoint==='bing_api_search') {
                    console.log('bing api search')
                    const job_data = {
                        job_title: payload.value,
                        job_type: 'bing_api_search'
                    }
                    let parameter_data = [
                        {
                            id: 8,
                            parameter_value_string: `[${companyList.map(({id}) => id)}]`
                        }
                    ]
                    const data = {
                        data: {
                            job_data: job_data,
                            parameter_data: parameter_data
                        }
                    }
                    result.value = await createAsynchronousJob(data, token)
                } else if(endpoint ==='export_zi_ids'){
                    console.log('generate csv')
                    console.log(props.selectedCompanies)
                    createZIUploadCsv(props.selectedCompanies)
                    result.value = {
                        status: 200
                    }
                }
                 else {
                        result.value = await performBulkAction(token, companyIds, endpoint, payload.value)
                    }
                }
            }
            if(!result.value) {
                result.value = {
                    'status': 'Error'
                }
            }
            isLoading.value = false
            finishedAction.value = true
            context.emit('refetch-companies')
            if(!keepSelectedCompanies.value){
                context.emit('reset-select-all')
            }
        }

        async function skipPromptAndPerformBulkOperation(companyList, endpoint) {
            // console.log('payload', payload.value)
            // console.log('selected companies', props.selectedCompanies)
            // console.log('arg: companyList', companyList)
            // console.log('arg: endpoint', endpoint)
            openPrompt.value = false
            skipPrompt.value = true
            await performBulkOperation(companyList, endpoint)
            skipPrompt.value = false
        }

        function cancelAction() {
            openPrompt.value = false
            finishedAction.value = true
            result.value = {
                'status': 'cancelled'
            }
        }

        function closeModal() {
            context.emit('close-modal')
        }

        function toggleShowSelectedCompanies() {
            showSelectedCompanies.value = !showSelectedCompanies.value
        }

        function toggleKeepSelectedCompanies() {
            keepSelectedCompanies.value = !keepSelectedCompanies.value
        }

        return {
            numCompanies: numCompanies,
            keepSelectedCompanies: keepSelectedCompanies,
            isLoading: isLoading,
            finishedAction: finishedAction,
            openPrompt: openPrompt,
            result: result,
            showSelectedCompanies: showSelectedCompanies,
            statusOptions: statusOptions,
            nextActionOptions: nextActionOptions,
            crmNewActionPayload: crmNewActionPayload,
            clientFeedbackOptions: clientFeedbackOptions,
            everaxisPillarOptions: everaxisPillarOptions,
            confirmTitle: confirmTitle,
            confirmMessage: confirmMessage,
            promptSelectedCompanies: promptSelectedCompanies,
            promptDisplayedCompanies: promptDisplayedCompanies,
            payload: payload,
            setPayload,
            performBulkOperation,
            skipPromptAndPerformBulkOperation,
            closeModal,
            cancelAction,
            toggleShowSelectedCompanies,
            toggleKeepSelectedCompanies,
            setCRMActionName,
            setCRMActionDueDate,
            setCRMActionResolvedDate,
            setCRMActionUser,
            setCRMAction,
            setCRMActionStatus,
            setCollectionTagPayload,
            removeTag,
            setCRMActionSummaryNote,
            copyCRMDetails,
            campaignId
        };
    }
}


</script>