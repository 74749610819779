<template>
    <TableRow :isSelected="isSelected" class="h-8 hover:bg-indigo-100 cursor-pointer">
        <td class="border-b border-gray-300 text-xs align-top overflow-hidden w-full h-full">
            <div class="pl-5 h-8 flex items-center">     
                <input type="checkbox" id="checkbox" class="accent-dark-secondary cursor-pointer" :checked="isSelected" @change="toggleSelected"/>
            </div>
        </td>
        <!-- <td @click="openRowDetails" class="border-b border-gray-300 text-xs align-top overflow-hidden w-full">
            <div class="w-full h-8 flex items-center pl-2">{{ priority }}</div>
        </td> -->
        <td @click="openRowDetails" class="border-b border-gray-300 text-xs align-top overflow-hidden w-full">
            <div class="w-full h-8 flex items-center pl-2">{{ requestName }} </div>
        </td>
        <td @click="openRowDetails" class="border-b border-gray-300 text-xs align-top overflow-hidden w-full">
            <div class="w-full h-8 flex items-center pl-2">{{ requestedBy }}</div>
        </td>
        <td @click="openRowDetails" class="border-b border-gray-300 text-xs align-top overflow-hidden w-full">
            <div class="w-full h-8 flex items-center justify-between pl-2"><span class="pr-2">{{ dataSourceName }}</span><DataSourceTypeTag :data-source-type="dataSourceType" /></div>
        </td>
        <td @click="openRowDetails" class="border-b border-gray-300 text-xs align-top overflow-hidden w-full">
            <div class="w-full h-8 flex items-center pl-2">{{ finalImported }}</div>
        </td>
        <td @click="openRowDetails" class="border-b border-gray-300 text-xs align-top overflow-hidden w-full">
            <div class="w-full h-8 flex items-center pl-2">{{ totalImportedCompanies }}</div>
        </td>
        <td @click="openRowDetails" class="border-b border-gray-300 text-xs align-top overflow-hidden w-full"></td>
    </TableRow>
</template>

<script setup>
import { defineProps, defineEmits, ref, computed, watch  } from 'vue';
import TableRow from '../base-components/TableRow.vue';
import TableCell from '../base-components/TableCell.vue';
import { formatDate } from '@/composables/formatDate';
import DataSourceTypeTag from '../data-sources/DataSourceTypeTag.vue';

const props = defineProps(['dataRequest', 'selectAll'])

const emit = defineEmits(['open-row-details', 'select-row', 'deselect-row'])

const isSelected = ref(props.selectAll)

const requestName = computed(() => {
    return props.dataRequest.request_name ? props.dataRequest.request_name : null
})

const dataSourceName = computed(() => {
    return props.dataRequest.data_source ? props.dataRequest.data_source.source_name : null
})

const dataSourceType = computed(() => {
    return props.dataRequest.data_source ? props.dataRequest.data_source.source_type : null
})

watch(() => props.selectAll, () => {
    isSelected.value = props.selectAll
    if(props.selectAll) {
        emit('select-row', props.dataRequest)
    } else if (!props.selectAll) {
        emit('deselect-row', props.dataRequest)
    }
})

const requestedBy = computed(() => {
    return props.dataRequest.requested_by ? `${props.dataRequest.requested_by.first_name} ${props.dataRequest.requested_by.last_name}`: null
})
const finalImported = computed(() => {
    return props.dataRequest.data_imports ? props.dataRequest.data_imports.import_date : null
})

const totalImportedCompanies = computed(() => {
    if(props.dataRequest.data_imports) {
        return props.dataRequest.data_imports.reduce((accumulator, dataImport) => {
            return accumulator + dataImport.companies.length
        }, 0)
    }
    return 0
})

function openRowDetails() {
    emit('open-row-details', props.dataRequest)
}

function toggleSelected() {
    if(isSelected.value) {
        isSelected.value = false
        emit('deselect-row', props.dataRequest)
    } else {
        isSelected.value = true
        emit('select-row', props.dataRequest)
    }
}

</script>