<template>
    <RequestedRow 
        v-if="requestType=='requested'"
        :request-type="requestType"
        :dataRequest="dataRequest"
        :selectAll="selectAll"
        :userPermissions="userPermissions"
        @refetch-results="refetchResults"
        @select-row="selectRow"
        @deselect-row="deselectRow"
        @open-row-details="openRowDetails"
    />
    <ScheduledRow 
        v-else-if="requestType=='scheduled'"
        :request-type="requestType"
        :dataRequest="dataRequest"
        :selectAll="selectAll"
        :userPermissions="userPermissions"
        @refetch-results="refetchResults"
        @select-row="selectRow"
        @deselect-row="deselectRow"
        @open-row-details="openRowDetails"
    />
    <CompletedRow
        v-else-if="requestType=='completed'"
        :request-type="requestType"
        :dataRequest="dataRequest"
        :selectAll="selectAll"
        :userPermissions="userPermissions"
        @refetch-results="refetchResults"
        @select-row="selectRow"
        @deselect-row="deselectRow"
        @open-row-details="openRowDetails"
    />
</template>

<script setup>
import { defineProps, defineEmits,  } from 'vue';
import RequestedRow from './RequestedRow.vue';
import ScheduledRow from './ScheduledRow.vue';
import CompletedRow from './CompletedRow.vue';

const props = defineProps([
    'requestType',
    'dataRequest', 
    'selectAll',
    'userPermissions',
])

const emit = defineEmits([
    'refetch-results',
    'select-row',
    'deselect-row',
    'open-row-details'
])

function refetchResults() {
    emit('refetch-results')
}

function selectRow(dataRequestRow) {
    emit('select-row', dataRequestRow)
}

function deselectRow(dataRequestRow) {
    emit('deselect-row', dataRequestRow)
}

function openRowDetails(dataRequestRow) {
    emit('open-row-details', dataRequestRow)
}

</script>