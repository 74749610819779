<template>
    <svg 
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink" 
        style="isolation:isolate" 
        viewBox="0 0 30 30" 
        class="h-3.5 w-3.5">
        <defs>
            <clipPath id="_clipPath_PRKEA0EVfQafg1PGRlxWfA6Zl2KPA9Nd">
            <rect width="30" height="30"/>
            </clipPath>
        </defs>
        <g clip-path="url(#_clipPath_PRKEA0EVfQafg1PGRlxWfA6Zl2KPA9Nd)">
            <g style="isolation:isolate">
                <g>
                    <path d=" M 28.781 8.119 Q 27.984 7.817 27.919 7.821 L 22.02 13.196 L 16.363 7.539 L 21.657 1.642 Q 21.733 1.526 21.512 0.725 C 17.929 0.441 14.187 2.644 12.995 3.836 Q 9.673 7.155 13.546 12.658 L 16.638 15.75 Q 22.344 19.863 25.684 16.524 C 26.443 15.764 29.045 11.449 28.781 8.119 Z " 
                        :fill="fillColor" 
                        vector-effect="non-scaling-stroke" 
                        stroke-width="1" 
                        :stroke="fillColor" 
                        stroke-linejoin="miter" 
                        stroke-linecap="square" 
                        stroke-miterlimit="3"
                    />
                    <path d="M 19.45 14.05 L 15.45 10.05 C 15.45 10.05 15.45 10.05 15.45 10.05 L -4 29.5 C -4 29.5 -4 29.5 -4 29.5 L 0 33.5 C 0 33.5 0 33.5 0 33.5 L 19.45 14.05 C 19.45 14.05 19.45 14.05 19.45 14.05 Z" 
                        style="stroke:none;stroke-miterlimit:10;"
                        :fill="fillColor"
                    />
                    <path d="M 21.5 32 L 21.5 26 C 21.5 26 21.5 26 21.5 26 L -0.21 26 C -0.21 26 -0.21 26 -0.21 26 L -0.21 32 C -0.21 32 -0.21 32 -0.21 32 L 21.5 32 C 21.5 32 21.5 32 21.5 32 Z" 
                        style="stroke:none;stroke-miterlimit:10;"
                        :fill="fillColor"
                    />
                </g>
                <polygon points="30,30,19.5,21,19.5,39" :fill="fillColor"/>
            </g>
        </g>
    </svg>
</template>

<script setup>
import { defineProps, computed } from 'vue';

const props = defineProps(['fill'])

const fillColor = computed(() => {
   return props.fill ? props.fill : 'black'
})
</script>