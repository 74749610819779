<template>
    <div class="fixed rounded-md z-30 w-fit text-left" :style="popoverPosition">
        <div class="bg-gray-500 border border-gray-500 text-white px-1 py-0.5 text-xs w-full block rounded-md shadow-md">
            <slot name="title"></slot>
        </div>
    </div>
</template>
<script setup>
import { defineProps, computed } from "vue"; 

const props = defineProps({
    xPosition: Number,
    yPosition: Number,
})

const popoverPosition = computed(function () {
    return `left: ${props.xPosition + 5}px; top: ${props.yPosition}px;`
})

</script>