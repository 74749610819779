<template>
    <!-- <div class="h-full w-full flex flex-col items-center ">
        <div class="bg-dark-secondary border border-b-2 border-dark-primary text-white text-base font-semibold tracking-wider w-full py-2 px-4 shadow flex justify-center items-center whitespace-nowrap rounded-t-sm">
            {{ columnTitle }}
        </div> -->
        <div class=" rounded-b-sm w-full h-full flex flex-col justify-start pb-4">
            <CRMProgressUpdatePrintRow
                v-for="(progressUpdate, index) in progressUpdates"
                :key="index"
                :progress-update="progressUpdate"
                :index="getRowNumberFromIndex(index)"
            />
            <CRMStatusGroupPrintRow
                v-for="(statusGroup, index) in statusGroups"
                :key="index"
                :status-group="statusGroup"
                :index="getRowNumberFromIndex(index)"
            />
            <!-- <div
                v-for="(progressUpdate, index) in progressUpdates"
                :key="index"
                class="flex flex-col"
            >
                <div class="text-sm font-semibold">{{ progressUpdate.company.company_name }}</div>
                <div>{{ progressUpdate.body }}</div>
            </div> -->
    
        </div>
    <!-- </div> -->
</template>

<script setup>
import { defineProps, computed } from 'vue';
import CRMProgressUpdatePrintRow from './CRMProgressUpdatePrintRow.vue';
import CRMStatusGroupPrintRow from './CRMStatusGroupPrintRow.vue';

const props = defineProps(['progressUpdateColumn'])

const columnTitle = computed(() => {
    return props.progressUpdateColumn ? props.progressUpdateColumn.title : ''
})

const progressUpdates = computed(() => {
    return props.progressUpdateColumn ? props.progressUpdateColumn.progressUpdates : []
})

const statusGroups = computed(() => {
    return props.progressUpdateColumn ? props.progressUpdateColumn.statusGroups : []
})

const startingIndex = computed(() => {
    return props.progressUpdateColumn ? props.progressUpdateColumn.startingIndex : 0
})

function getRowNumberFromIndex(index) {
    return startingIndex.value + index
}


</script>