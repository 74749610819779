<template>
    <DataRequestForm 
        :existing-data-request="dataRequest"
        :data-source-options="dataSourceOptions"
        :client-options="clientOptions"
        :user-permissions="userPermissions"
        @submit-form="updateExistingDataRequest"
        @close="close"
    />
</template>

<script setup>
import { defineProps, defineEmits, ref, computed } from 'vue';
import DataRequestForm from './DataRequestForm.vue';
import { formatDate } from '@/composables/formatDate';
import { updateDataRequest } from '@/services/DataRequest';
import { useStore } from 'vuex';
import { useToast } from 'vue-toast-notification';

const store = useStore()
const $toast = useToast()

const props = defineProps(['dataRequest', 'editMode', 'userPermissions', 'dataSourceOptions', 'clientOptions'])
const emit = defineEmits(['refetch-results', 'close'])

const isLoading = ref(false)

// function cancelEdit() {
//     console.log('cancelEdit')
//     emit('cancel-edit')
// }

async function updateExistingDataRequest(formData) {
    console.log('updateExistingDataRequest')
    console.log(formData)
    const token = store.state.auth.token
    const response = await updateDataRequest(token, props.dataRequest.id, formData)
    console.log(response)
    if(response.status == 200) {
        $toast.open({
            message: `Updated Data Request.`,
            type: 'success',
            duration: 3000
        })
        refetch()
        close()
    } else {
        $toast.open({
            message: `Could Not Update New Request.`,
            type: 'error',
            duration: 10000
        })
    }
}

function close() {
    console.log('close')
    emit('close')
}

function refetch() {
    console.log('refetch-results')
    emit('refetch-results')
}
</script>