<template>
    <div class="h-full">
        <div class="flex h-screen items-center justify-center p-2">
            <div class="grid grid-cols-3 gap-3 w-full">
                <div class="rounded-md  m-2 shadow p-2 flex justify-center w-full h-56 border-2 border-dark-primary bg-neutral-50">
                    <div class="flex flex-col items-start w-full justify-between">
                        <div class="rounded-md text-white tracking-widest bg-dark-primary w-full text-center">
                            Missing URL or ZoomInfo
                        </div>
                        <div class="flex flex-col items-start w-full my-1">
                            <div class="w-full border-b border-b-gray-500 text-gray-500 tracking-widest">
                                    Count
                            </div>
                            <div>
                                {{ formatNumbersWithCommas(missingZiOrUrlCount) }}
                            </div>
                        </div>
                        <div class="flex flex-col w-full items-start my-1">
                            <div class="w-full border-b border-b-gray-500 text-gray-500 tracking-widest">
                                    Last Session
                            </div>
                            <div>
                                {{ formatDateTwoDigitYear(missingZiOrUrlLastSession) }}
                            </div>
                        </div>
                        <div class="flex w-full justify-center">
                            <base-button @click="openMissingUrlOrZoomInfo">Launch</base-button>
                        </div>
                    </div>
                </div>
                <div class="rounded-md  m-2 shadow p-2 flex justify-center full h-56 border-2 border-dark-primary bg-neutral-50">
                    <div class="flex flex-col items-start w-full justify-between text-base">
                        <div class="rounded-md text-white tracking-widest bg-dark-primary w-full text-center">
                            Vet URLS
                        </div>
                        <div class="flex flex-col items-start w-full my-1">
                            <div class="w-full border-b border-b-gray-500 text-gray-500 tracking-widest">
                                    Count
                            </div>
                            <div>
                                {{ formatNumbersWithCommas(vetUrlCount) }}
                            </div>
                        </div>
                        <div class="flex flex-col w-full items-start my-1">
                            <div class="w-full border-b border-b-gray-500 text-gray-500 tracking-widest">
                                    Last Session
                            </div>
                            <div>
                                {{ formatDateTwoDigitYear(vetUrlLastSession) }}
                            </div>
                        </div>
                        <div class="w-full flex justify-center">
                            <base-button @click="openVetUrls">Launch</base-button>
                        </div>
                    </div>
                </div>
                <!-- <div class="rounded-md  m-2 shadow p-2 flex justify-center w-1/4 h-56 border-2 border-dark-primary bg-neutral-50">
                    <div class="flex flex-col items-center w-full justify-between">
                        <div class="rounded-md text-white bg-dark-primary w-full text-center">
                            Resolve Duplicates
                        </div>
                        <div class="flex flex-col items-start">
                            <ul class="list-disc">
                                <li>
                                    Count: 1,345
                                </li>
                                <li>
                                    Last session: 02/23/25
                                </li>
                            </ul>
                        </div>
                        <div>
                            <base-button>Launch</base-button>
                        </div>
                    </div>
                </div>
                <div class="rounded-md  m-2 shadow p-2 flex justify-center w-1/4 h-56 border-2 border-dark-primary bg-neutral-50">
                    <div class="flex flex-col items-center w-full justify-between">
                        <div class="rounded-md text-white bg-dark-primary w-full text-center">
                            Missing Contact Data
                        </div>
                        <div class="flex flex-col items-start">
                            <ul class="list-disc">
                                <li>
                                    Count: 1,345
                                </li>
                                <li>
                                    Last session: 02/23/25
                                </li>
                            </ul>
                        </div>
                        <div>
                            <base-button>Launch</base-button>
                        </div>
                    </div>
                </div>
                <div class="rounded-md  m-2 shadow p-2 flex justify-center w-1/4 h-56 border-2 border-dark-primary bg-neutral-50">
                    <div class="flex flex-col items-center w-full justify-between">
                        <div class="rounded-md text-white bg-dark-primary w-full text-center">
                            Merge Conflicts
                        </div>
                        <div class="flex flex-col items-start">
                            <ul class="list-disc">
                                <li>
                                    Count: 1,345
                                </li>
                                <li>
                                    Last session: 02/23/25
                                </li>
                            </ul>
                        </div>
                        <div>
                            <base-button>Launch</base-button>
                        </div>
                    </div>
                </div>
                <div class="rounded-md  m-2 shadow p-2 flex justify-center w-1/4 h-56 border-2 border-dark-primary bg-neutral-50">
                    <div class="flex flex-col items-center w-full justify-between">
                        <div class="rounded-md text-white bg-dark-primary w-full text-center">
                            Old/Incorrect Data
                        </div>
                        <div class="flex flex-col items-start">
                            <ul class="list-disc">
                                <li>
                                    Count: 1,345
                                </li>
                                <li>
                                    Last session: 02/23/25
                                </li>
                            </ul>
                        </div>
                        <div>
                            <base-button>Launch</base-button>
                        </div>
                    </div>
                </div>
                <div class="rounded-md  m-2 shadow p-2 flex justify-center w-1/4 h-56 border-2 border-dark-primary bg-neutral-50">
                    <div class="flex flex-col items-center w-full justify-between">
                        <div class="rounded-md text-white bg-dark-primary w-full text-center">
                            Stale Campaigns
                        </div>
                        <div class="flex flex-col items-start">
                            <ul class="list-disc">
                                <li>
                                    Count: 1,345
                                </li>
                                <li>
                                    Last session: 02/23/25
                                </li>
                            </ul>
                        </div>
                        <div>
                            <base-button>Launch</base-button>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import { getResearchDashboardData } from '@/services/Company';
import { formatNumbersWithCommas } from '@/composables/FormatNumbers';
import { formatDateTwoDigitYear } from '@/composables/formatDate';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

const store = useStore()
const router = useRouter()
const isLoading = ref(false)

const missingZiOrUrlCount = ref(0)
const vetUrlCount = ref(0)
const missingZiOrUrlLastSession = ref(null)
const vetUrlLastSession = ref(null)

async function loadCounts(){
    const token = store.state.auth.token
    const result = await getResearchDashboardData(token)
    if(result.status == 200){
        missingZiOrUrlCount.value = result.data.missing_url_or_zoom_info_data.company_count
        vetUrlCount.value = result.data.vet_url_data.company_count
        missingZiOrUrlLastSession.value = result.data.missing_url_or_zoom_info_data.most_recent_session_end
        vetUrlLastSession.value = result.data.vet_url_data.most_recent_session_end
    }
}

function openMissingUrlOrZoomInfo(){
    router.push({path:'/missing_url_or_zoom_info'})
}

function openVetUrls(){
    router.push({path:'/vet_urls'})
}

loadCounts()


</script>