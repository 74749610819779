<template>
<TableRow :isSelected="isSelected" class="h-8 hover:bg-indigo-100 cursor-pointer">
    <td class="border-b border-gray-300 text-xs align-top overflow-hidden w-full h-full cursor-pointer">
        <div class="cursor-pointer pl-5 h-8 flex items-center">     
            <input type="checkbox" id="checkbox" class="accent-dark-secondary cursor-pointer" :checked="isSelected" @change="toggleSelected"/>
        </div>
    </td>
    <td  @click="openRowDetails" class="border-b border-gray-300 text-xs align-top overflow-hidden w-full cursor-pointer">
        <div class="cursor-pointer w-full h-8 flex items-center pl-2">{{ importDate }}</div>
    </td>
    <td  @click="openRowDetails" class="border-b border-gray-300 text-xs align-top overflow-hidden w-full cursor-pointer">
        <div class="cursor-pointer w-full h-8 flex items-center pl-2">{{ companyID }}</div>
    </td>
    <td  @click="openRowDetails" class="border-b border-gray-300 text-xs align-top overflow-hidden w-full cursor-pointer">
        <div class="cursor-pointer w-full h-8 flex items-center pl-2">{{ companyName }}</div>
    </td>
    <td  @click="openRowDetails" class="border-b border-gray-300 text-xs align-top overflow-hidden w-full cursor-pointer">
        <div class="cursor-pointer w-full h-8 flex items-center pl-2">{{ companyTier }}</div>
    </td>
    <td  @click="openRowDetails" class="border-b border-gray-300 text-xs align-top overflow-hidden w-full cursor-pointer">
        <div class="cursor-pointer w-full h-8 flex items-center pl-2">{{ companyWebsiteURL }}</div>
    </td>
    <td  @click="openRowDetails" class="border-b border-gray-300 text-xs align-top overflow-hidden w-full cursor-pointer">
        <div class="cursor-pointer w-full h-8 flex items-center pl-2">{{ companyLocation }}</div>
    </td>
    <td  @click="openRowDetails" class="border-b border-gray-300 text-xs align-top overflow-hidden w-full cursor-pointer">
        <div class="cursor-pointer w-full h-8 flex items-center pl-2">{{ companyRevenue }}</div>
    </td>
</TableRow>
</template>

<script setup>
import { defineProps, defineEmits, ref, computed, watch  } from 'vue';
import { formatDate } from '@/composables/formatDate';
import TableRow from '../base-components/TableRow.vue';

const props = defineProps(['company', 'dataImport', 'selectAll'])

const emit = defineEmits(['open-row-details', 'select-row', 'deselect-row'])

const isSelected = ref(props.selectAll)

watch(() => props.selectAll, () => {
    isSelected.value = props.selectAll
    if(props.selectAll) {
        emit('select-row', props.company)
    } else if (!props.selectAll) {
        emit('deselect-row', props.company)
    }
})

const importDate = computed(() => {
    return props.dataImport ? formatDate(props.dataImport.import_date) : null
})

const companyID = computed(() => {
    return props.company ? props.company.id : null
})

const companyName = computed(() => {
    return props.company ? props.company.company_name : null
})

const companyTier = computed(() => {
    return props.company && props.company.tier_object ? props.company.tier_object.tier : null
})

const companyWebsiteURL = computed(() => {
    return props.company ? props.company.website_url : null
})

const companyLocation = computed(() => {
    return props.company ? props.company.address : null
})

const companyRevenue = computed(() => {
    return props.company ? props.company.revenue : null
})

function openRowDetails() {
    emit('open-row-details', props.company)
}

function toggleSelected() {
    if(isSelected.value) {
        isSelected.value = false
        emit('deselect-row', props.company)
    } else {
        isSelected.value = true
        emit('select-row', props.company)
    }
}
</script>