<template>
    <div>

        <transition
            enter-active-class="transition duration-400 ease-in"
            enter-from-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition duration-400 ease-out"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
            class="h-full"
        >
        <div v-if="savingChanges" class="fixed top-0 bottom-0 left-0 right-0 bg-black/[0.7] flex flex-col justify-center items-center z-50"
                    tabindex="-1" >
                <div class="flex flex-col justify-center items-center p-4 rounded-md">
                <svg aria-hidden="true" class="mx-1 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600"
                    viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="#d3d3d3"/>
                        <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="#2E9CCA"/>
                </svg>
                <div class="text-white p-4 whitespace-nowrap text-sm">
                    Please wait while we save your changes.
                </div>
                </div>
            </div>
            
        </transition>
        <!-- <base-card>
            <MissingUrlOrZoomInfoCompanyFilters @change-company-filters="setCompanyFilters" ></MissingUrlOrZoomInfoCompanyFilters>
        </base-card> -->
        
        <base-card>
        <div class="flex h-full w-full">
            <div  :class="{' w-3/5': showZiPanel, 'w-full': !showZiPanel}"  class="flex flex-col text-sm text-gray-900 max-h-[75vh]">
                <div class="flex flex-col justify-start lg:flex-row w-full mb-2">
                    <div class="px-3 h-full flex w-full lg:w-fit flex-row justify-start py-2 border-b border-light-primary lg:py-0 lg:border-none items-center align-middle text-center 0">
                    </div>
                    <div class="flex flex-row items-center justify-between py-2 border-b border-light-primary lg:py-0 lg:border-none w-full h-full px-2 ">
                        <!-- <base-pagination-header
                        class="w-fit"
                        @load-data="loadCompanies(fetchFilter)"
                        @get-next="getNext"
                        @go-to-first="goToFirst"
                        @get-prev="getPrev"
                        @go-to-last="goToLast"
                        @jump-to-page="jumpToPage"
                        @set-page-size-filter="setPageSizeFilter"
                        :page_filter="pageSizeFilter"
                        :page="page"
                        :numPages="totalPages"
                        :isLoading="isLoading"
                        >
                        <template v-slot:message>
                            <div class="pl-3 pt-1 whitespace-nowrap">
                            {{ viewingFromNbr }}-{{ viewingToNbr }} | {{ count }} Total Companies 
                            </div>
                            <div class="flex items-center ml-2 h-full pt-1">
                                <div v-if="hasActiveResearchSession" class="flex items-center whitespace-nowrap mx-1 text-sm">
                                    Session Active <span class="ml-1 h-4 w-4 animate-pulse rounded-full bg-red-500 "></span>
                                </div>
                                <div class="flex items-center" ref="helpText">
                                    <HelpSVG
                                        @mouseover="showHelpText" @mouseleave="hideHelpText" class="hover:cursor-help" stroke="#4B5563"/>
                                        <base-popover-click
                                            v-if="visibleHelpPopover"
                                            :xPosition="xPosition"
                                            :yPosition="yPosition">
                                            <div class="grid grid-cols-1 tracking-normal font-normal relative z-50 pb-4">
                                                <div class="flex border-b  tracking-wider font-semibold border-gray-200 p-1">
                                                    <div>
                                                        Hotkeys
                                                    </div>
                                                </div>
                                                <div class="grid grid-cols-2 place-content-center border-b border-gray-200 p-1">
                                                    <div class="h-full flex items-center">Shift + G</div>
                                                    <div class="">
                                                        Open Google search with Company Name + Location
                                                    </div>
                                                </div>
                                                <div class="grid grid-cols-2 place-content-center border-b border-gray-200 p-1">
                                                    <div class="h-full flex items-center">Shift + Z</div>
                                                    <div class="">
                                                        Open Google search with Company Name + ZoomInfo
                                                    </div>
                                                </div>
                                                <div class="grid grid-cols-2 place-content-center border-b border-gray-200 p-1">
                                                    <div class="h-full flex items-center">Shift + Enter</div>
                                                    <div class="">
                                                        Select the currently displayed ZoomInfo ID
                                                    </div>
                                                </div>
                                                <div class="grid grid-cols-2 place-content-center border-b border-gray-200 p-1">
                                                    <div class="h-full flex items-center">CTRL + s</div>
                                                    <div class="">
                                                        Save Changes
                                                    </div>
                                                </div>
                                                <div class="grid grid-cols-2 place-content-center border-b border-gray-200 p-1">
                                                    <div class="h-full flex items-center">1 - 9</div>
                                                    <div class="">
                                                        Open the selected ZoomInfo ID
                                                    </div>
                                                </div>
                                            </div>
                                        </base-popover-click>
                                </div>
                            </div>
                        </template>
                        </base-pagination-header> -->
                        <div class="w-5/6">
                            <TableMenu
                            :pageFilter="pageSizeFilter"
                            :page="page"
                            :numPages="totalPages"
                            :isLoading="isLoading"
                            :filters="companyFilters"
                            :menuOptions="menuOptions"
                            :bulkOperations="bulkOperations"
                            :viewingFromNbr="viewingFromNbr"
                            :viewingToNbr="viewingToNbr"
                            :default-page-size="defaultPageSize"
                            :count="count"
                            @refetch-results="loadCompanies(fetchFilter)"
                            @get-next="getNext"
                            @go-to-first="goToFirst"
                            @get-prev="getPrev"
                            @go-to-last="goToLast"
                            @jump-to-page="jumpToPage"
                            @set-page-size-filter="setPageSizeFilter"
                            @clear-filter="clearFilter"
                            @change-filters="setFilters"
                            @set-filter-options="setFilterOptions"
                        >
                        </TableMenu>
                        </div>
                        <div class="flex items-center ml-2 h-full">
                                <div v-if="hasActiveResearchSession" class="flex items-center whitespace-nowrap mx-1 text-sm">
                                    Session Active <span class="ml-1 h-4 w-4 animate-pulse rounded-full bg-red-500 "></span>
                                </div>
                                <div class="flex items-center" ref="helpText">
                                    <HelpSVG
                                        @mouseover="showHelpText" @mouseleave="hideHelpText" class="hover:cursor-help" stroke="#4B5563"/>
                                        <base-popover-click
                                            v-if="visibleHelpPopover"
                                            :xPosition="xPosition"
                                            :yPosition="yPosition">
                                            <div class="grid grid-cols-1 tracking-normal font-normal relative z-50 pb-4">
                                                <div class="flex border-b  tracking-wider font-semibold border-gray-200 p-1">
                                                    <div>
                                                        Hotkeys
                                                    </div>
                                                </div>
                                                <div class="grid grid-cols-2 place-content-center border-b border-gray-200 p-1">
                                                    <div class="h-full flex items-center">Shift + G</div>
                                                    <div class="">
                                                        Open Google search with Company Name + Location
                                                    </div>
                                                </div>
                                                <div class="grid grid-cols-2 place-content-center border-b border-gray-200 p-1">
                                                    <div class="h-full flex items-center">Shift + Z</div>
                                                    <div class="">
                                                        Open Google search with Company Name + ZoomInfo
                                                    </div>
                                                </div>
                                                <div class="grid grid-cols-2 place-content-center border-b border-gray-200 p-1">
                                                    <div class="h-full flex items-center">Shift + Enter</div>
                                                    <div class="">
                                                        Select the currently displayed ZoomInfo ID
                                                    </div>
                                                </div>
                                                <div class="grid grid-cols-2 place-content-center border-b border-gray-200 p-1">
                                                    <div class="h-full flex items-center">CTRL + s</div>
                                                    <div class="">
                                                        Save Changes
                                                    </div>
                                                </div>
                                                <div class="grid grid-cols-2 place-content-center border-b border-gray-200 p-1">
                                                    <div class="h-full flex items-center">1 - 9</div>
                                                    <div class="">
                                                        Open the selected ZoomInfo ID
                                                    </div>
                                                </div>
                                            </div>
                                        </base-popover-click>
                                </div>
                            </div>
                        <div class="flex items-center space-x-2">
                            <div class="text-xs whitespace-nowrap" v-if="autoSaveTimeRemaining > 0 && changeCount > 0">
                            Autosave in: {{ timeLeft }}
                            </div>
                            <div class="text-sm flex items-center pr-4 whitespace-nowrap">
                                Changes: &nbsp; <transition name="slide-fade" mode="out-in">
                                                    <span :key="changeCount">
                                                        {{ changeCount }}
                                                    </span>
                                                    </transition>
                            </div>
                            <base-button v-if="changeCount > 0" @click="undoChange" class="text-xl">⎌</base-button>
                            <button v-else class="rounded-md px-1 py-1 max-w-32 my-1 border-solid border bg-gray-500 text-xl border-gray-500 hover:cursor-not-allowed active:bg-gray-500 focus:bg-gray-500">⎌</button>
                            <base-button-affirmative :disabled="true" v-if="changeCount == 0">Save Changes</base-button-affirmative>
                            <base-button-affirmative v-else @click="saveChanges">Save Changes</base-button-affirmative>
                        </div>
                    </div>
                </div>
                <div class="h-full flex w-full">
                    <div v-if="companies.length > 0" class="h-full w-full flex overflow-y-auto">
                        <base-table>
                            <template #table-header>
                            <base-table-header :key="header.value" :header="header.value" :class="header.class" v-for="header in tableHeadersList">
                            <div class="flex  items-center justify-between space-x-1">
                                <div>
                                {{ header.value }}
                                </div>
                            </div>
                            </base-table-header>
                            </template>
            
                            <template #table-body>
                            <MissingUrlOrZoomInfoCompanyRow
                                :key="company.id"
                                :company="company"
                                :active-company="activeCompany"
                                :active-zi-id="activeZiId"
                                :new-zi-id="newZiId"
                                @refetch-companies="loadCompanies"
                                @set-active-company="setActiveCompany"
                                @add-company-url="addCompanyUrl"
                                @add-company-zoom-info-id="addCompanyZoomInfoId"
                                @set-zi-index="setZiIndex"
                                v-for="company in companies"
                                        />
                            </template>
                        </base-table>
                    </div>
                </div>
            </div>
            <div v-if="showZiPanel" class="w-2/5 h-full">
                <div class="flex flex-col w-full items-center justify-center p-1 h-full">
                    <div class=" w-full h-5/6 bg-white p-1 flex flex-col items-center justify-center rounded-md shadow">
                        <div class="flex w-full justify-between p-1">
                            <div @click="decrementActiveZiIdIndex" :class="{'hover:cursor-pointer' : hasPreviousZiId, 'text-gray-400' : !hasPreviousZiId}" class="justify-self-start text-lg underline">
                                &lt;
                            </div>
                            <div class="flex items-center space-x-2">
                                <span>{{ activeZiIdIndex + 1 }}. {{ activeZiId }}</span> <base-button @click="selectNewZiId(activeZiId)">✔</base-button>
                            </div>
                            <div @click="incrementActiveZiIdIndex" :class="{'hover:cursor-pointer' : hasNextZiId, 'text-gray-400' : !hasNextZiId}"  class="justify-self-end text-lg underline">
                                >
                            </div>
                        </div>
                        <iframe
                            :id="activeZiId + 'iframe'"
                            :title="activeZiId + ' Iframe'"
                            class="w-full"
                            height="600"
                            :src="zoomInfoLink(activeZiId)">
                        </iframe>
                    </div>
                </div>
            </div>
        </div>
        </base-card>
    </div>

</template>





<script setup>
import { ref, watch, computed, reactive, onMounted, onUnmounted } from 'vue';
import MissingUrlOrZoomInfoCompanyFilters from './MissingUrlOrZoomInfoCompanyFilters.vue';
import MissingUrlOrZoomInfoCompanyRow from './MissingUrlOrZoomInfoCompanyRow.vue';
import HelpSVG from '../svg-components/HelpSVG.vue';
import { getCompaniesForManualUrlAndZoomInfoResearch, saveCompaniesForManualUrlAndZoomInfoResearch } from '@/services/Company';
import { useStore } from 'vuex';
import { useToast } from 'vue-toast-notification';
import TableMenu from '../base-components/TableMenu.vue';
import { useRoute } from 'vue-router';
import { getSession } from '@/services/ResearchSession';
import { getSources } from '@/services/Source';
import { getActiveCampaigns } from '@/services/Campaign';

const store = useStore()
const isLoading = ref(false)
const $toast = useToast()

const companies = ref([])
const next = ref(null)
const prev = ref(null)
const count = ref(null)
const page = ref(1)
const pageSize = ref(null)
const newZiId = ref(null)
const companiesToUpdate = ref([])
const changeList = ref([])
const totalPages = ref(0)
const defaultPageSize = ref(50)
const savingChanges = ref(false)
const xPosition = ref(0)
const yPosition = ref(0)
const helpText = ref(null)
const visibleHelpPopover = ref(false)

const route = useRoute()

const researchPage = computed(() => {
    if(route.name === 'vet-urls'){
        return 'vet_urls'
    } else if(route.name === 'missing-url-or-zoom-info-companies'){
        return 'missing_url_or_zoom_info_companies'
    } else {
        return null
    }
})
watch(researchPage, () => {
    loadOpenSession()
})

async function loadOpenSession(){
    if(researchPage.value){
        const token = store.state.auth.token
        const result = await getSession(token, researchPage.value)
        if(result.data.session){
            store.dispatch('research/setHasActiveResearchSessionTrue');
        }
    } else {
        store.dispatch('research/setHasActiveResearchSessionFalse');
    }
}

loadOpenSession()

const hasActiveResearchSession = computed(() => {
    return store.state.research.hasActiveResearchSession
})

const changeCount = computed(() => {
    return companiesToUpdate.value.length
})

function setZiIndex(idx) {
    if(showZiPanel.value && idx <= activeCompany.value.zoom_info_id_candidates.length){
        activeZiIdIndex.value = idx - 1
    }
}

function showHelpText(){
    xPosition.value = helpText.value.getBoundingClientRect().left
    yPosition.value = helpText.value.getBoundingClientRect().bottom
    visibleHelpPopover.value = true
}
function hideHelpText(){
    visibleHelpPopover.value = false
}

async function saveChanges(){
    if(changeCount.value > 0){
        const token = store.state.auth.token
        savingChanges.value = true
        console.log(companiesToUpdate.value)
        const data = {
            companies_to_update: companiesToUpdate.value
        }
        const result = await saveCompaniesForManualUrlAndZoomInfoResearch(token, data)
        if(result.status == 200){
            $toast.open({
                message: result.data.message,
                type: 'success',
                duration: 5000
            })
            companiesToUpdate.value = []
            changeList.value = []
            loadCompanies()
        } else {
            $toast.open({
                message: 'Error, could not save companies',
                type: 'error',
                duration: 5000
            })
        }
        savingChanges.value = false
    }
}

const autoSaveWaitTime = ref(300000)
const autoSaveTimeRemaining = ref(0)

const timeLeft = computed(() => {
    if(autoSaveTimeRemaining.value > 60000){
        return Math.ceil(autoSaveTimeRemaining.value / 60000) + ' min.'
    } else {
        return autoSaveTimeRemaining.value / 1000 + ' sec.'
    }
})

const timeoutId = ref(null)
const countDownId = ref(null)
watch(autoSaveTimeRemaining, () => {
    if(autoSaveTimeRemaining.value == 0 && countDownId.value){
        clearInterval(countDownId.value)
    }
})

watch(changeCount, () => {
    if(changeCount.value && changeCount.value > 0){
        if(timeoutId.value){
            clearTimeout(timeoutId.value)
            clearInterval(countDownId.value)
        }
        window.onbeforeunload = function(e) {
          e.returnValue = "Are you sure you want to leave? You have unsaved changes."
          return "Are you sure you want to leave? You have unsaved changes."
        };
        autoSaveTimeRemaining.value = autoSaveWaitTime.value
        timeoutId.value = setTimeout(() => {
            saveChanges()
            timeoutId.value = null
        }, autoSaveWaitTime.value)
        countDownId.value = setInterval(() => {
            autoSaveTimeRemaining.value -= 1000
        }, 1000)
    } else {
        window.onbeforeunload = null
        if(changeCount.value == 0){
            // console.log('clear the interval')
            clearInterval(countDownId.value)
        }
    }
})

const activeCompany = ref(null)
function setActiveCompany(company){
    activeCompany.value = company
}

function addOrEditCompanyToUpdate(company){
    const companyIndex = companiesToUpdate.value.findIndex((companyToUpdate) => companyToUpdate.id == company.id)
    if(companyIndex >= 0){
        companiesToUpdate.value[companyIndex] = company
    } else {
        companiesToUpdate.value.push(company)
    }
}

function addCompanyUrl(companyId, url){
    const company = companies.value.find((company) => company.id === companyId)
    if(url != company.website_url){
        changeList.value.push({companyId: company.id, field: "website_url", oldValue: company.website_url, newValue: url})
        company.website_url = url
        // clients.value.changeIncrement ? clients.value.changeIncrement += 1 : clients.value.changeIncrement = 0
        addOrEditCompanyToUpdate(company)
    }
}

function addCompanyZoomInfoId(companyId, zoomInfoId){
    const company = companies.value.find((company) => company.id === companyId)
    if(zoomInfoId != company.zoom_info_id){
        changeList.value.push({companyId: company.id, field: "zoom_info_id", oldValue: company.zoom_info_id, newValue: zoomInfoId})
        company.zoom_info_id = zoomInfoId
        addOrEditCompanyToUpdate(company)
    }
}

function setOriginalValues(){
    companies.value.forEach((company) => {
        company.original_website_url = company.website_url
        company.original_zoom_info_id = company.zoom_info_id
    })
}

function undoChange(){
    const mostRecentChange = changeList.value.pop()
    const company = companies.value.find((company) => company.id === mostRecentChange.companyId)
    company[mostRecentChange.field] = mostRecentChange.oldValue
    if(company.website_url == company.original_website_url && company.zoom_info_id == company.original_zoom_info_id){
        const companyIndex = companiesToUpdate.value.findIndex((companyToUpdate) => companyToUpdate.id == company.id)
        if(companyIndex >= 0){
            companiesToUpdate.value.splice(companyIndex, 1)
        }
    }

}

function selectNewZiId(ziId){
    newZiId.value = ziId
}

watch(activeCompany, () => {
    activeZiIdIndex.value = 0
}, {deep: true})

const showZiPanel = computed(() => {
    return activeCompany.value && activeCompany.value.zoom_info_id_candidates.length > 0
})

const activeZiIdIndex = ref(0)

const hasNextZiId = computed(() => {
    if(showZiPanel.value) {
        return activeZiIdIndex.value < activeCompany.value.zoom_info_id_candidates.length - 1
    } else {
        return false
    }
})

const hasPreviousZiId = computed(() => {
    if(showZiPanel.value) {
        return activeZiIdIndex.value > 0
    } else {
        return false
    }
})

const activeZiId = computed(() => {
    if(showZiPanel.value){
        return activeCompany.value.zoom_info_id_candidates[activeZiIdIndex.value]
    } else {
        return null
    }
})

function decrementActiveZiIdIndex(){
    if(hasPreviousZiId.value){
        activeZiIdIndex.value--
    }
    if(activeZiIdIndex.value < 0){
        activeZiIdIndex.value = 0
    }
}

function incrementActiveZiIdIndex(){
    if(hasNextZiId.value){
        activeZiIdIndex.value++
    }
}

function zoomInfoLink(ziId){
    return `https://app.zoominfo.com/#/apps/profile/company/${ziId}/overview?url=%2Fapps%2FsearchV2%2Fv2%2Fresults%2Fperson%3Fquery%3DeyJmaWx0ZXJzIjp7InBhZ2UiOjEsImNvbXBhbnlQYXN0T3JQcmVzZW50IjoiMSIsImlzQ2VydGlmaWVkIjoiZXhjbHVkZSIsInNvcnRCeSI6IlJlbGV2YW5jZSIsInNvcnRPcmRlciI6ImRlc2MiLCJleGNsdWRlRGVmdW5jdENvbXBhbmllcyI6dHJ1ZSwiY29uZmlkZW5jZVNjb3JlTWluIjo4NSwiY29uZmlkZW5jZVNjb3JlTWF4Ijo5OSwib3V0cHV0Q3VycmVuY3lDb2RlIjoiVVNEIiwiaW5wdXRDdXJyZW5jeUNvZGUiOiJVU0QiLCJleGNsdWRlTm9Db21wYW55IjoidHJ1ZSIsInJldHVybk9ubHlCb2FyZE1lbWJlcnMiOmZhbHNlLCJleGNsdWRlQm9hcmRNZW1iZXJzIjp0cnVlLCJjb21wYW55SWRzIjpbeyJ2YWx1ZSI6MTMyMDkwMDAzNCwiZGlzcGxheU5hbWUiOiJVbml2ZXJzYWwgQWVyb3NwYWNlIFN5c3RlbXMifV19LCJzZWFyY2hUeXBlIjowfQ%253D%253D&titleText=Contact%20Search&profileId=1320900034&checkProfileAccess=true`
}

const tableHeadersList = ref([
    {value: '', class: "w-1/8", orderFieldName: ""},
    {value: 'ID', class: "w-1/8", orderFieldName: ""},
    {value: 'Company', class: "w-1/4", orderFieldName: ""},
    {value: 'City', class: "w-1/4", orderFieldName: ""},
    {value: 'State', class: "w-1/8", orderFieldName: ""},
    {value: 'Country', class: "w-1/8", orderFieldName: ""},
    {value: 'URL', class: "w-1/4", orderFieldName: ""},
    {value: 'Zoom Info', class: "w-1/4", orderFieldName: ""},
])

const menuOptions = reactive({
    selectAll: false,
    paginationArrows: true,
    totalResults: true,
    pageSize: false,
    refreshButton: true,
    filters: true,
    bulkOperations: false,
    addFormRows: false,
})

const bulkOperations = reactive({})

const companyFilters = reactive({
    campaign: {
        name: "campaign",
        label: "Campaign",
        value: [],
        defaultValue: [],
        options: [
            {value: "clear", label: "------------"}
        ],
        widget: 'list-box',
        multiSelect: true,
        setOptionsMethod: setCampaignOptions.bind(null),
        awaitingOptions: false
    },
    sources: {
        name: "sources",
        label: "Sources",
        value: [],
        defaultValue: [],
        options: [
            {value: "clear", label: "------------"}
        ],
        widget: 'list-box',
        multiSelect: true,
        setOptionsMethod: setSourceOptions.bind(null),
        awaitingOptions: false
    },
  });

  async function setSourceOptions() {
    const token = store.state.auth.token
    const results = await getSources(token)
    companyFilters.sources.options = [
    {value: "clear", label: "------------"}, ...results.map((source) => {
            return {value: source.id, label: source.source_name}
        })
    ]
}

async function setCampaignOptions() {
    const token = store.state.auth.token
    const result = await getActiveCampaigns(token)
    companyFilters.campaign.options = [{value: "clear", label: "------------"}, ...result.map((campaign) => {
            return {value: campaign.id, label: campaign.campaign_name}
        })]
}

// const companyFilters = reactive({
//     campaigns: null,
//     sources: null,
//   });

  const pageSizeFilter = reactive({
    pageSizeFilter: 100
  })


  const fetchFilter = computed(function () {
    let query = new URLSearchParams({
    });
    if (companyFilters.campaign && companyFilters.campaign.value) {
        companyFilters.campaign.value.forEach(campaign => {
          query.append("campaign", campaign)
        })
    }
    if (companyFilters.sources && companyFilters.sources.value) {
        companyFilters.sources.value.forEach(source => {
          query.append("source_object", source)
        })
    }
    let url;
    let queriesCount = 0;
    for (let value of query.values()) {
      queriesCount = queriesCount + 1;
    }
    if (queriesCount > 0) {
      url = "?" + query.toString();
    } else {
      url = "?";
    }
    // console.log(url);
    return url
})

async function loadCompanies(queryParams = fetchFilter.value) {
    isLoading.value = true;
    changeList.value = []
    companiesToUpdate.value =[]
    activeCompany.value = null
    const token = store.state.auth.token 
    const result = await getCompaniesForManualUrlAndZoomInfoResearch(token, queryParams);
    companies.value = result.data.results;
    next.value = result.data.next ? `&page=${result.data.page + 1}` : null
    prev.value = result.data.prev ? `&page=${result.data.page - 1}` : null
    count.value = result.data.count
    page.value = result.data.page
    pageSize.value = result.data.results.length
    totalPages.value = result.data.num_pages
    isLoading.value = false;
    setOriginalValues()
}

function setPageSizeFilter(target=null){
    if(target) {
      target == "clear" ? (pageSizeFilter.pageSizeFilter = 100) : (pageSizeFilter.pageSizeFilter = target);
      loadCompanies(fetchFilter.value)
    }
}

async function setFilterOptions(filterName, queryParams='') {
    console.log('setFilterOptions', filterName)
    if(!companyFilters[filterName].awaitingOptions){
        if(companyFilters[filterName].setOptionsMethod){
            companyFilters[filterName].awaitingOptions = true
            companyFilters[filterName].setOptionsMethod(queryParams)
            setTimeout(() => {
                companyFilters[filterName].awaitingOptions = false;
            }, 30000)
        }
    }
}

function clearFilter(filterName) {
    if(Array.isArray(companyFilters[filterName].value)){
        companyFilters[filterName].value = []
    } else {
        companyFilters[filterName].value = null
    }
}

function setFilters(updatedFilters) {
    companyFilters.campaign = updatedFilters.campaign;
    companyFilters.sources = updatedFilters.sources
}

  function getNext() {
    if (next.value) {
      loadCompanies(fetchFilter.value + next.value)
    } else {
      alert("No next page")
    }
  }
  
function getPrev() {
    if (prev.value) {
      loadCompanies(fetchFilter.value + prev.value)
    } else {
      alert("No prev page")
    }
  }

function jumpToPage(targetPage) {
    loadCompanies(fetchFilter.value + `&page=${targetPage}`)
  }

const viewingFromNbr = computed(function () {
  return (page.value * pageSizeFilter.pageSizeFilter + 1) - pageSizeFilter.pageSizeFilter
  })
  
  
const viewingToNbr = computed(function () {
    if (page.value === totalPages.value) {
      return count.value;
    } else {
      return page.value * pageSize.value
    }
  })

 function goToFirst(){
    loadCompanies(fetchFilter.value + `&page=${1}`)
  }
  
function goToLast(){
    loadCompanies(fetchFilter.value + `&page=${totalPages.value}`)
}

watch(() => companyFilters, () => {
          setTimeout(() => {
            loadCompanies(fetchFilter.value);
          }, 1000); // 2.5 sec delay
    }, {deep: true}
)

const ctrlPressed = ref(false)

function registerControlPressed(e) {
    if(e.key === 'Control') {
        e.preventDefault()
        ctrlPressed.value = true
    }
}

function registerControlReleased(e) {
    // console.log('ctrl released')
    if(e.key === 'Control') {
        e.preventDefault()
        ctrlPressed.value = false
    } else {
        return true
    }
}

function registerKeyPress(e) {
    if(ctrlPressed.value){
        if(e.key === 's'){
            e.preventDefault()
            saveChanges()
        }
    } else {
        return true
    }
}



onMounted(() => {
    document.addEventListener("keydown", registerControlPressed, false)
    document.addEventListener("keyup", registerControlReleased, false)
    document.addEventListener("keydown", registerKeyPress, false)
})

onUnmounted(() => {
    document.removeEventListener("keydown", registerControlPressed, false)
    document.removeEventListener("keyup", registerControlReleased, false)
    document.removeEventListener("keypress", registerKeyPress, false)
})

loadCompanies()


</script>
