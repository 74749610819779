<template>
    <div class="bg-dark-primary print:hidden z-50 w-[60px] flex flex-row items-center">
        <RightSideBarSVG class="h-10" @click="toggleRightSideBar"/>
    </div>

</template>

<script setup>
import { defineEmits, ref, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { getSession } from '@/services/ResearchSession';
import { useRoute } from 'vue-router';
import RightSideBarSVG from '@/components/svg-components/RightSideBarSVG.vue';

const store = useStore()
const route = useRoute()

const page = computed(() => {
    if(route.name === 'vet-urls'){
        return 'vet_urls'
    } else if(route.name === 'missing-url-or-zoom-info-companies'){
        return 'missing_url_or_zoom_info_companies'
    } else {
        return null
    }
})
watch(page, () => {
    loadOpenSession()
})

async function loadOpenSession(){
    if(page.value){
        const token = store.state.auth.token
        const result = await getSession(token, page.value)
        if(result.data.session){
            store.dispatch('research/setHasActiveResearchSessionTrue');
        }
    } else {
        store.dispatch('research/setHasActiveResearchSessionFalse');
    }
}

const toggleRightSideBar = () => {
    if(!store.getters['showRightSideBar']) {
        store.dispatch('setShowRightSideBarTrue');
    } else {
        store.dispatch('setShowRightSideBarFalse');
    }
}

loadOpenSession()

</script>