<template>
    <div class="inline-flex h-12 text-xs text-gray-800  px-2 w-full sticky border-b border-gray-300 z-50" ref="menuBoundary">
      <div
        class="flex flex-row items-center justify-start h-full w-full "
      >
        <div class="flex flex-row items-center w-full justify-between h-full ">
          <div class="flex flex-row items-center h-full w-full grow-0 min-w-0">
            <div v-if="showRefreshButton"
              class="pr-1"
              ref="refreshBoundary"
              @mouseover="showToolTipPopover('refresh', 'Refresh')"
              @mouseleave="hideToolTipPopover"
            >
              <PaginationLoader
                class="rounded-sm hover:bg-gray-200 p-[5px]"
                @refetch-results="refetchResults"
                :isLoading="isLoading"
              ></PaginationLoader>
            </div>
            <div v-if="showSelectAll"
              ref="selectAllBoundary"
              @mouseover="showToolTipPopover('selectAll', 'Select All')"
              @mouseleave="hideToolTipPopover"
              class="px-3 flex flex-row grow-0 items-center  h-6"
              >
              <SelectAll
                :selectAll="selectAll"
                @toggle-select-all="toggleSelectAll"
              />
            </div>
            <div v-if="showTabBar" class="flex h-full justify-start">
              <CustomTabBar 
                    :tab-list="tabList"
                    @set-page="setPage"
                />
            </div>
            <div v-if="showQuickSearch" class="flex flex-row h-full justify-end p-2" >        
              <input class="p-1 border border-gray-300 w-[250px] rounded-sm"
                type="text" 
                placeholder="&#9906;" 
                v-model="quickSearchFilter"
              />
              <div class="w-10 h-full flex justify-center items-center absolute">
                <button
                  v-if="quickSearchFilter"
                  class="w-6 h-6 bg-white flex justify-center items-center absolute right-[3px] top-[3px] rounded-sm hover:bg-gray-200 cursor-pointer"
                  @click="clearQuickSearchFilter"
                  @mouseover="highlightQuickSearchStroke"
                  @mouseout="unhiglightQuickSearchStroke"
                >
                  <XMarkSVG
                    :stroke="quickSearchStroke"
                    width="3"
                  />
                </button>
              </div> 
            </div>
            <div v-if="showPaginationArrows" class="flex flex-row items-end pl-3.5">
              <PaginationArrows
                @get-next="getNext"
                @go-to-first="goToFirst"
                @get-prev="getPrev"
                @go-to-last="goToLast"
                @jump-to-page="jumpToPage"
                :page="page"
                :numPages="numPages"
              ></PaginationArrows>
            </div>
            <div  v-if="showTotalResults" class="pl-3 whitespace-nowrap">
              <div v-if="viewingFromNbr==viewingToNbr" class="whitespace-nowrap">
                Showing {{ viewingFromNbr }} ({{ count }} Total)
              </div>
              <div v-else class="whitespace-nowrap">
                Showing {{ viewingFromNbr }}-{{ viewingToNbr }} ({{ count }} Total)
              </div>
            </div>
            <div v-if="showPageSize" class="w-fit h-full flex py-2.5  pl-3 pr-1.5">
              <FilterCompactHorizontal class="mx-2">
                <template v-slot:label> Pg Size </template>
                <template v-slot:widget>
                  <ListBoxHorizontal
                    class="w-12 rounded-r-md text-xs text-black"
                    placeholder="Select"
                    :options="pageSizeOptions"
                    :modelValue="pageSizeFilter"
                    :multiple="false"
                    @update:modelValue="setPageSizeFilter"
                  />
                </template>
              </FilterCompactHorizontal>
            </div>
            <div v-if="showFilters" class="h-full flex items-center min-w-0">
              <div
                :style="filterFormat"
                class="rounded-sm hover:bg-gray-200 p-[9px] hover:cursor-pointer"
                @click="toggleFilters"
                ref="filterBoundary"
                @mouseover="showToolTipPopover('filter', 'Filter')"
                @mouseleave="hideToolTipPopover"
              >
                  <FilterSVG
                    :fill="filterFill"
                  />
              </div>
              <div v-if="activeFilters.length > 0 & !visibleActiveFilters"
                @click="showActiveFilters"
                class="rounded-md hover:bg-amber-300 px-1.5 py-1 h-full hover:cursor-pointer "
                ref="activeFiltersBoundary"
                @mouseover="showToolTipPopover('activeFilters', 'Active Filters')"
                @mouseleave="hideToolTipPopover"
              >
                </div>
              <div v-show="visibleActiveFilters" class="pl-2 flex w-full grow-0 items-center  overflow-x-scroll">
                <ActiveFilters
                  :active-filters="activeFilters"
                  @clear-filter="clearFilter"
                />
              </div>
            </div>
          </div>
          <div class="flex flex-row justify-end pr-1.5 pl-2" v-if="showQuickBulkButtons">
            <button @click="executeQuickBulkAction(quickBulk.bulkAction)" v-for="quickBulk in menuOptions.quickBulkButtons" :disabled="selectedResults.length < 1" :class="{'bg-gray-300 text-gray-700' : selectedResults.length < 1, 'bg-dark-primary text-white hover:shadow-md active:bg-dark-secondary' : selectedResults.length > 0}" :key="quickBulk.label" class="rounded-sm py-1 px-2 whitespace-nowrap">
              {{ quickBulk.label }}
            </button>
          </div>
          <div v-if="showBulkOperations" class="h-full flex items-center">
            <div
              :style="bulkOperationFormat"
              class="rounded-sm hover:bg-gray-200 p-2 hover:cursor-pointer"
              @click="toggleBulkOperations"
              ref="bulkOperationsBoundary"
              @mouseover="showToolTipPopover('bulkOperations', 'Bulk Operations')"
              @mouseleave="hideToolTipPopover"
            >
              <BulkOperationSVG 
                :fill="bulkOperationsFill"
              />
            </div>
          </div>
          <div
              v-if="showCreateFormButton"
              class="flex flex-row justify-end pl-2"
              ref="createFormBoundary"
          >
            <button @click="showCreateForm" class="rounded-sm bg-dark-secondary hover:bg-dark-primary hover:text-gray-100 text-white text-lg flex items-center justify-center h-7 w-7 hover:shadow-md active:bg-dark-primary">
              +
            </button>
            <!-- <BaseButton @click="showCreateForm">
              New {{ moduleNameSingular }}
            </BaseButton> -->
          </div>
          <div v-if="showAddFormRows"
            class="flex flex-row justify-end pr-1.5 pl-2 "
            ref="addFormRowsBoundary"
              @mouseover="showToolTipPopover('addFormRows', 'Add New')"
              @mouseleave="hideToolTipPopover"
          >
            <button @click="addFormRow" class="rounded-full bg-dark-secondary text-white text-lg flex items-center justify-center h-6 w-6 hover:shadow-md active:bg-dark-primary">
              +
            </button>
            <!-- add new service record button -->
          </div>
        </div>
      </div>
      <div>
        <base-tool-tip
          v-if="visibleToolTipPopover"
          :xPosition="xPosition"
          :yPosition="yPosition"
        >
          <template #title>
            {{ toolTipText }}
          </template>
        </base-tool-tip>
        <PopoverClick
              v-show="visibleFilters"
              :xPosition="xPosition"
              :yPosition="yPosition"
              class="mr-14 shadow-md rounded-md border border-gray-300"
          >
              <FilterSet 
                :filters="filters"
                @change-filters="changeFilters"
                @change-active-filters="setActiveFilters"
                @change-ordering="setOrder"
                @set-filter-options="setFilterOptions"
                @hide-active-filters="hideActiveFilters"
                @clear-select-all="clearSelectAll"
              />
          </PopoverClick>
          <PopoverClick
              v-show="visibleBulkOperations"
              :xPosition="xPosition"
              :yPosition="yPosition"
              class="mr-14 shadow-md rounded-md border border-gray-300"
          >
            <BulkOperationButtons
              :selectedResults="selectedResults"
              :filters="filters"
              :bulkOperations="bulkOperations"
              :userPermissions="userPermissions"
              @refetch-results="refetchResults"
              @close="hideBulkOperations"
            >
            <template #bulk-operations="{ events: { hideBulkActionMenu },  bulkAction, selectedResults }">
                <slot name="bulk-operations"
                    :selectedResults="selectedResults"
                    :bulkAction="bulkAction"
                    :events="{ hideBulkActionMenu, hideBulkOperations }"
                ></slot>
              </template>
            </BulkOperationButtons>
          </PopoverClick>
      </div>
      <CustomModal
          v-if="visibleCreateForm"
          :is-form-visible="showCreateForm"
          @close="closeCreateForm"
          :modal-size="createModalSize"
        >
        <template #header>
              <div class="">
                  New {{ moduleNameSingular }}
              </div>
          </template>
          <template #body>
            <slot name="create-form"
              :events="{ closeCreateForm }"
            ></slot>
          </template>
        </CustomModal>
    </div>
  </template>
  
  <script setup>  
  import { ref, defineEmits, defineProps, watch, computed, reactive } from "vue";
  // import SelectAll from "./SelectAll.vue";
  import PaginationLoader from "./PaginationLoader.vue";
  import PaginationArrows from "./PaginationArrows.vue";
  import SelectAll from "./SelectAll.vue";
  import FilterCompactHorizontal from "./FilterCompactHorizontal.vue";
  import ListBoxHorizontal from "./ListBoxHorizontal.vue";
  import ActiveFilters from "./ActiveFilters.vue";
  import PopoverClick from './PopoverClick.vue';
  import FilterSVG from "@/components/svg-components/FilterSVG.vue";
  import BulkOperationSVG from '@/components/svg-components/BulkOperationSVG.vue';
  import FilterSet from "./FilterSet.vue";
  import BulkOperationButtons from '@/components/base-components/BulkOperationButtons.vue';
  import CustomTabBar from '../base-components/CustomTabBar.vue';
  import SearchSVG from "../svg-components/SearchSVG.vue";
  import XMarkSVG from "../svg-components/XMarkSVG.vue";
  import CustomModal from "./CustomModal.vue";
  import BaseButton from "./BaseButton.vue";
  import { useClickOutside } from "@/composables/useClickOutside";
  
  const props = defineProps([
    "isLoading",
    "page",
    "numPages",
    "pageFilter",
    "filters",
    "selectAll",
    "menuOptions",
    "selectedResults",
    "bulkOperations",
    "userPermissions",
    "count",
    "orderBy",
    "viewingFromNbr",
    "viewingToNbr",
    "defaultPageSize",
    "moduleName",
    "moduleNameSingular",
    "createModalSize"
  ]);

  const emit = defineEmits([
    "get-next",
    "refetch-results",
    "go-to-first",
    "get-prev",
    "go-to-last",
    "jump-to-page",
    "set-page-size-filter",
    "toggle-filter-set",
    "clear-filter",
    "change-filters",
    "change-ordering",
    "toggle-select-all",
    "set-filter-options",
    "add-form-row",
    "clear-select-all",
    "do-quick-bulk",
    "select-tab",
    'toggle-bulk-operations-panel',
  ]);

  function executeQuickBulkAction(bulkActionName) {
    emit('do-quick-bulk', bulkActionName)
  }
  
  const pageSizeFilter = ref(props.defaultPageSize ? props.defaultPageSize : 50);
  const pageSizeOptions = ref([
    { value: "clear", label: "--" },
    { value: 10, label: "10" },
    { value: 15, label: "15" },
    { value: 25, label: "25" },
    { value: 50, label: "50" },
    { value: 100, label: "100" },
    { value: 200, label: "200" },
  ]);
  const activeFilters = ref([]);
  const visibleBulkOperations = ref(false);
  const visibleFilters = ref(false);
  const visibleActiveFilters = ref(true);
  const filterBoundary = ref(null);
  const filterFill = ref('#374151');
  const menuBoundary = ref(null);
  const selectAllBoundary = ref(null);
  const refreshBoundary = ref(null);
  const visibleCreateForm = ref(false);
  const bulkOperationsBoundary = ref(null);
  const bulkOperationsFill = ref('#374151');
  const activeFiltersBoundary = ref(null);
  const quickSearchFilter = ref(null);
  const quickSearchStroke = ref('#3F3F46');
  const addFormRowsBoundary = ref(null);
  const xPosition = ref(0);
  const yPosition = ref(0);
  const visibleToolTipPopover = ref(false);
  const toolTipText = ref(null);
  const tabList = reactive((props.menuOptions && props.menuOptions.tabs) ? props.menuOptions.tabs : {})
  
  // watchers
  
  watch(props.pageFilter, () => {
    if (props.pageFilter.pageSizeFilter) {
      pageSizeFilter.value = props.pageFilter.pageSizeFilter;
    } else {
      pageSizeFilter.value = 50;
    }
    
  });

  watch(activeFilters.value, ()=> {
    // console.log('active filters changed')
  }, {deep: true})

  const filterFormat = computed(() => {
    if(visibleFilters.value) {
      return 'background-color: #D1D5DB;'
    } else {
      if(activeFilters.value.length > 0) {
        return 'background-color: #FCD34D;'
      } else{
        return ''
      }
    }
  })

  const bulkOperationFormat = computed(() => {
    if(visibleBulkOperations.value) {
      return 'background-color: #D1D5DB;'
    }
    return ''
  })
  

  const showSelectAll = computed(() => {
    if(props.menuOptions) {
      if('selectAll' in props.menuOptions){
        if(!props.menuOptions.selectAll) {
          return false
        }
      }
    }
    return true
  })

  const showPaginationArrows = computed(() => {
    if(props.menuOptions) {
      if('paginationArrows' in props.menuOptions){
        if(!props.menuOptions.paginationArrows) {
          return false
        }
      }
    }
    return true
  })

  const showPageSize = computed(() => {
    if(props.menuOptions) {
      if('pageSize' in props.menuOptions){
        if(!props.menuOptions.pageSize) {
          return false
        }
      }
    }
    return true
  })

  const showTotalResults = computed(() => {
    if(props.menuOptions) {
      if('totalResults' in props.menuOptions) {
        if(!props.menuOptions.totalResults) {
          return false
        }
      }
    }
    return true
  })

  const showRefreshButton = computed(() => {
    if(props.menuOptions) {
      if('refreshButton' in props.menuOptions){
        if(!props.menuOptions.refreshButton) {
          return false
        }
      }
    }
    return true
  })

  const showFilters = computed(() => {
    if(props.menuOptions) {
      if('filters' in props.menuOptions){
        if(!props.menuOptions.filters) {
          return false
        }
      }
    }
    return true
  })

  const showBulkOperations = computed(() => {
    if(props.menuOptions) {
      if('bulkOperations' in props.menuOptions){
        if(!props.menuOptions.bulkOperations) {
          return false
        }
      }
    }
    return true
  })

  const showAddFormRows = computed(() => {
    if(props.menuOptions) {
      if('addFormRows' in props.menuOptions){
        if(!props.menuOptions.addFormRows) {
          return false
        }
      }
    }
    return true
  })

  const showQuickBulkButtons = computed(() => {
    if(props.menuOptions) {
      if('quickBulkButtons' in props.menuOptions ){
        if(props.menuOptions.quickBulkButtons.length > 0) {
          return true
        }
      }
    }
    return false
  })

  const showTabBar = computed(() => {
    if(props.menuOptions) {
      if('tabs' in props.menuOptions && Object.keys(props.menuOptions.tabs).length > 0) {
        return true
      }
    }
    return false
  })

  const showCreateFormButton = computed(() => {
    if(props.menuOptions) {
      if('createForm' in props.menuOptions){
        if(props.menuOptions.createForm) {
          return true
        } else {
          return false
        }
      }
    }
    return false
  })

  const showQuickSearch = computed(() => {
    if(props.menuOptions) {
      if('quickSearch' in props.menuOptions){
        if(props.menuOptions.quickSearch) {
          return true
        } else {
          return false
        }
      }
    }
    return false
  })


  function highlightQuickSearchStroke() {
    quickSearchStroke.value = '#464866'
  }

  function unhiglightQuickSearchStroke() {
    quickSearchStroke.value = '#3F3F46'
  }

  function showToolTipPopover(elementName, text) {
    if(!visibleFilters.value & !visibleBulkOperations.value) {
      if(elementName == 'selectAll') {
        // console.log('selectAll')
        xPosition.value = selectAllBoundary.value.getBoundingClientRect().left
        yPosition.value = selectAllBoundary.value.getBoundingClientRect().bottom + 5
      } else if (elementName == 'refresh'){
        // console.log('refresh')
        xPosition.value = refreshBoundary.value.getBoundingClientRect().left
        yPosition.value = refreshBoundary.value.getBoundingClientRect().bottom + 5
      } else if (elementName == 'bulkOperations') {
        // console.log('bulkOPerations')
        xPosition.value = bulkOperationsBoundary.value.getBoundingClientRect().left
        yPosition.value = bulkOperationsBoundary.value.getBoundingClientRect().bottom + 5
      } else if (elementName == 'filter') {
        // console.log('filter')
          xPosition.value = filterBoundary.value.getBoundingClientRect().left
          yPosition.value = filterBoundary.value.getBoundingClientRect().bottom + 5
      } else if (elementName == 'activeFilters') {
        // console.log('activeFilters')
        if(!visibleActiveFilters.value) {
          xPosition.value = activeFiltersBoundary.value.getBoundingClientRect().left
          yPosition.value = activeFiltersBoundary.value.getBoundingClientRect().bottom + 5
        } else {
          return
        }
      } else if (elementName == 'addFormRows') {
        // console.log('addformrows')
        xPosition.value = addFormRowsBoundary.value.getBoundingClientRect().left
        yPosition.value = addFormRowsBoundary.value.getBoundingClientRect().bottom
      }
      visibleToolTipPopover.value = true;
      toolTipText.value = text;
    }
  }

  function hideToolTipPopover() {
    visibleToolTipPopover.value = false;
    toolTipText.value = null;
  }

  function refetchResults() {
    emit("refetch-results");
  }
  
  function getNext() {
    emit("get-next");
  }
  
  function goToFirst() {
    emit("go-to-first");
  }
  
  function getPrev() {
    emit("get-prev");
  }
  
  function goToLast() {
    emit("go-to-last");
  }
  
  function jumpToPage(targetPage) {
    emit("jump-to-page", targetPage);
  }
  
  function setPageSizeFilter(pageSize) {
    emit("set-page-size-filter", pageSize);
  }

  function toggleSelectAll() {
    emit('toggle-select-all')
  }

  function toggleFilters() {
    if(!visibleFilters.value){
      visibleBulkOperations.value = false
      visibleFilters.value = true
      xPosition.value = filterBoundary.value.getBoundingClientRect().left + 6
      yPosition.value = filterBoundary.value.getBoundingClientRect().bottom - 5
      hideToolTipPopover()
    } else {
        hideFilters()
    }
  }

  function toggleBulkOperations() {
    if(props.menuOptions.bulkOperations && props.menuOptions.bulkOperations.type == 'panel') {
      // console.log('toggle-bulk-operations-panel')
      emit('toggle-bulk-operations-panel')
    } else {
      if(!visibleBulkOperations.value) {
        visibleFilters.value = false
          visibleBulkOperations.value = true
          xPosition.value = bulkOperationsBoundary.value.getBoundingClientRect().left
          yPosition.value= bulkOperationsBoundary.value.getBoundingClientRect().bottom
        hideToolTipPopover()
      } else {
        hideBulkOperations()
      }
    }
  }

  useClickOutside(menuBoundary, () => {
    if(visibleFilters.value) {
      hideFilters()
    }
    if(visibleBulkOperations.value) {
      hideBulkOperations()
    }
  })

  function hideFilters() {
      visibleFilters.value = false
  }

  function hideBulkOperations() {
      visibleBulkOperations.value = false
  }

  function showActiveFilters() {
    visibleActiveFilters.value = true
    hideToolTipPopover()
  }

  function hideActiveFilters() {
      visibleActiveFilters.value = false
  }

  function clearFilter(filterName) {
    emit('clear-filter', filterName)
  }

  function changeFilters(filters) {
      emit('change-filters', filters)
  }

  function setActiveFilters(filters) {
      // console.log('setActiveFilters')
      // console.log(filters)
      activeFilters.value = filters
  }

  function setFilterOptions(filter) {
    emit('set-filter-options', filter)
  }

  function setOrder() {
      // console.log('setOrder')
  }

function setPage(tabKey) {
  if(showTabBar.value) {
    clearAllTabs()
    tabList[tabKey].isSelected = true;
    emit('select-tab', tabKey)
  }

}

function clearAllTabs() {
    for(const tab of Object.values(tabList)) {
        tab.isSelected = false
    }
}

  function addFormRow() {
    emit('add-form-row')
  }

function clearSelectAll() {
  emit('clear-select-all')
}

function clearQuickSearchFilter() {
    quickSearchFilter.value = null
    emit('clear-quick-search')
  }

  function showCreateForm() {
    visibleCreateForm.value = true
  }

  function closeCreateForm() {
    console.log('closeCreateForm')
    visibleCreateForm.value = false
  }

</script>

<style scoped>
  ::-webkit-scrollbar{
      height: 0px;
      width: 0px;
  }
  ::-webkit-scrollbar-thumb:horizontal{
      background: #D1D5DB;
      border-radius: 3px;
  }

  input::placeholder {
    width: fit-content;
    height: fit-content;
    position: absolute;
    top: -2px;
    left: 4px;
    font-size: 22px;
    -webkit-transform: rotate(-45deg); 
               -moz-transform: rotate(-45deg); 
                 -o-transform: rotate(-45deg);
                    transform: rotate(-45deg);
  }
</style>