<template>
    <div class="pl-4 flex flex-col w-full h-full">
        <div class="w-full h-[calc(100vh-14rem)] grid grid-cols-3 grid-rows-2 gap-4">
            <CRMStatusGroupCard
                v-for="(statusGroup, key, index) in statusGroups"
                :key="key"
                :index="index"
                :statusGroupName="key"
                :title="statusGroup.title"
                :progressUpdates="statusGroup.progressUpdates"
                :reload-defaults="reloadDefaults"
                :reset-report="resetReport"
                :bold-all="boldAll"
                :unbold-all="unboldAll"
                @update-progress-update="updateProgressUpdate"
                @remove-progress-update="removeProgressUpdate"
            />
        </div>
        <!-- <div class="w-full h-[calc(100vh-14rem)] grid grid-cols-3 grid-rows-2 gap-4"  ref="crmStatusGroupGrid">
            <CRMStatusGroupCard
                class="draggableCard"
                draggable="true"
                v-for="(statusGroup, key, index) in statusGroups"
                :key="key"
                :index="index"
                :statusGroupName="key"
                :title="statusGroup.title"
                :progressUpdates="statusGroup.progressUpdates"
                :reload-defaults="reloadDefaults"
                :reset-report="resetReport"
                :ondrop="dropHandler"
                :ondragover="dragoverHandler"
                @update-progress-update="updateProgressUpdate"
                @remove-progress-update="removeProgressUpdate"
            />
        </div> -->
        <div class="w-full h-12 flex justiyf-start items-center border-b border-gray-300 px-2 tracking-widest font-semibold text-sm text-gray-700">
            {{ numNonRespondents }} {{ nonRespondentLabel }}
        </div>
    </div>

</template>

<script setup>
import { ref, reactive, defineProps, defineEmits, watch, computed, onMounted, onUpdated } from 'vue';
import CRMStatusGroupCard from './CRMStatusGroupCard.vue';

const props = defineProps([
    'bulkProgressUpdate',
    'progressUpdates',
    'reloadDefaults',
    'resetReport',
    'selectedReport',
    'boldAll',
    'unboldAll'
])
const emit = defineEmits([
    'remove-progress-update',
    'update-progress-update',
])

const statusGroups = reactive({
    activeInDialogue: {
        title: 'Active - In Dialogue',
        progressUpdates: props.progressUpdates.filter((progressUpdate) => {
            return (progressUpdate.company.computed_crm_status.label == 'Active - In Dialogue' || 
            progressUpdate.company.computed_crm_status.label == 'Under LOI' || 
            progressUpdate.company.computed_crm_status.label == 'IOI Submitted');
        }),
        columns: []
    },
    respondedCoverage: {
        title: 'Responded / Coverage',
        progressUpdates: props.progressUpdates.filter((progressUpdate) => {
            return progressUpdate.company.computed_crm_status.label == 'Responded / Coverage';
        }),
        columns: []
    },
    notNowKeepWarm: {
        title: 'Not Now / Keep Warm',
        progressUpdates: props.progressUpdates.filter((progressUpdate) => {
            return progressUpdate.company.computed_crm_status.label == 'Not Now / Keep Warm';
        }),
        columns: []
    },
    notContactedRevisit: {
        title: 'Not Contacted / Revisit / Dead',
        progressUpdates: props.progressUpdates.filter((progressUpdate) => {
            return (progressUpdate.company.computed_crm_status.label == 'Not Contacted' || progressUpdate.company.computed_crm_status.label == 'Revist / Cut' || progressUpdate.company.computed_crm_status.label == 'Dead');
        }),
        columns: []
    },
    // deadAcquired: {
    //     title: 'Dead / Acquired',
    //     progressUpdates: props.progressUpdates.filter((progressUpdate) => {
    //         return progressUpdate.company.computed_crm_status.label == 'Dead';
    //     }),
    //     columns: []
    // },
    clientPassed: {
        title: 'Client Passed',
        progressUpdates: props.progressUpdates.filter((progressUpdate) => {
            return progressUpdate.company.computed_crm_status.label == 'Client Passed';
        }),
        columns: []
    },
    targetPassed: {
        title: 'Target Passed',
        progressUpdates: props.progressUpdates.filter((progressUpdate) => {
            return progressUpdate.company.computed_crm_status.label == 'Target Passed';
        }),
        columns: []
    },
})

const statusGroupOrder = ref(props.selectedReport ? props.selectedReport.report.crm_status_order : ['activeInDialogue', 'respondedCoverage', 'notNowKeepWarm', 'notContactedRevisit', 'deadAcquired', 'clientTargetPass'])


const crmStatusGroupGrid = ref(null)
const draggedFromIndex = ref(null)
const draggedToIndex = ref(null)

const orderedStatusGroups = computed(() => {
    if(statusGroupOrder.value) {
        let sortedStatusGroups = {}
        statusGroupOrder.value.forEach((statusGroupKey) => {
            sortedStatusGroups[statusGroupKey] = statusGroups[statusGroupKey] 
        })
        return sortedStatusGroups
    }
    return statusGroups
})



watch(() => props.progressUpdates, () => {
    // console.log('progressUpdate progressUpdates changed', props.progressUpdates)
    statusGroups.activeInDialogue.progressUpdates =  props.progressUpdates.filter((progressUpdate) => { 
        console.log(progressUpdate)
        return (progressUpdate.company.computed_crm_status.label == 'Active - In Dialogue' || 
            progressUpdate.company.computed_crm_status.label == 'Under LOI' || 
            progressUpdate.company.computed_crm_status.label == 'IOI Submitted');
    })
    statusGroups.respondedCoverage.progressUpdates = props.progressUpdates.filter((progressUpdate) => { return progressUpdate.company.computed_crm_status.label == 'Responded / Coverage'; })
    statusGroups.notNowKeepWarm.progressUpdates = props.progressUpdates.filter((progressUpdate) => { return progressUpdate.company.computed_crm_status.label == 'Not Now / Keep Warm'; })
    statusGroups.notContactedRevisit.progressUpdates = props.progressUpdates.filter((progressUpdate) => { return progressUpdate.company.computed_crm_status.label == 'Not Contacted' || progressUpdate.company.computed_crm_status.label == 'Revist / Cut' || progressUpdate.company.computed_crm_status.label == 'Dead'; })
    // statusGroups.deadAcquired.progressUpdates = props.progressUpdates.filter((progressUpdate) => { return progressUpdate.company.computed_crm_status.label == 'Dead'; })
    statusGroups.clientPassed.progressUpdates = props.progressUpdates.filter((progressUpdate) => { return progressUpdate.company.computed_crm_status.label == 'Client Passed'; })
    statusGroups.targetPassed.progressUpdates = props.progressUpdates.filter((progressUpdate) => { return progressUpdate.company.computed_crm_status.label == 'Target Passed'; })
}, {deep:true})




const numNonRespondents = computed(() => {
    return props.progressUpdates ? props.progressUpdates.filter((progressUpdate) => {return progressUpdate.company.computed_crm_status.label == 'No Response'}).length : 0
})

const nonRespondentLabel = computed(() => {
    if(numNonRespondents.value == 1) {
        return 'Non-Respondent'
    }
    return 'Non-Respondents'
})
function updateProgressUpdate(progressUpdateData) {
    emit('update-progress-update', progressUpdateData)
}

function removeProgressUpdate(eventNoteID) {
    emit('remove-progress-update', eventNoteID)
}

function swapStatusGroups(fromIndex, toIndex) {
    let fromKey = statusGroupOrder.value[fromIndex]
    let toKey = statusGroupOrder.value[toIndex]
    statusGroupOrder.value[toIndex] = fromKey
    statusGroupOrder.value[fromIndex] = toKey
}

function dragoverHandler(event) {
    event.preventDefault();
      const target = event.target.closest('.draggableCard');
      draggedToIndex.value = target.getAttribute('index')
      console.log(draggedFromIndex.value, draggedToIndex.value)
    //   if (target && target.getAttribute('index') !== draggedFromIndex.value) {

    //   } 
}

function dropHandler(event) {
    event.preventDefault();
    if(draggedFromIndex.value != draggedToIndex.value) {
        console.log('dragging', draggedFromIndex.value , '=>', draggedToIndex.value)
        swapStatusGroups(draggedFromIndex.value, draggedToIndex.value)
    }
    draggedFromIndex.value = null
    draggedToIndex.value = null
}


// onMounted(() => {
//     crmStatusGroupGrid.value.addEventListener('dragstart', (event) => {
//         draggedFromIndex.value = event.target.getAttribute('index')
//         event.dataTransfer.setData("text/plain", null);
//     })

// })


</script>