<template>
    <div class="w-full flex flex-row items-end h-full text-xs py-2  text-gray-700 overflow-x-scroll">
        <!-- <div class="h-full w-fit">
            <div v-if="firstTabSelected" class="h-full w-3   ">
                
            </div>
            <div v-else class="h-full w-3  ">

            </div>
        </div> -->
        <div v-for="(tab, key, index) in tabList" :key="key" :index="index" class="h-full flex items-end hover:cursor-pointer hover:text-dark-secondary">
            <div v-if="tab.isSelected"  @mouseenter="unHighlightTab()" @mouseout="unHighlightTab()" @click="setPage(key)" class="h-full flex flex-col ">
                <div class="bg-indigo-0  h-full px-3 flex items-center rounded-sm bg-dark-secondary active:bg-dark-primary border-r border-gray-200">
                    <div  class="whitespace-nowrap tracking-widest text-white ">
                        {{ tab.label }}
                    </div>
                </div>
                <!-- <div class="h-1 bg-dark-secondary"></div> -->
            </div>
            <div v-else @mouseover="highlightTab(index)" @mouseout="unHighlightTab()" @click="setPage(key)"  class="h-full flex flex-col ">
                <div v-if="index==highlightedTabIndex" class="h-full flex flex-col">
                    <div class="h-full px-3 flex items-center bg-gray-200 border-r border-gray-200 rounded-t-sm">
                        <div class="whitespace-nowrap tracking-widest">
                            {{ tab.label }}
                        </div>
                    </div>
                    <!-- <div class="h-1 bg-gray-300"></div> -->
                </div>
                <div v-else class="h-full flex flex-col">
                    <div class="h-full px-3 flex items-center border-r border-gray-300">
                        <div class="whitespace-nowrap tracking-widest">
                            {{ tab.label }}
                        </div>
                    </div>
                    <!-- <div class="h-1"></div> -->
                </div>
            </div>
        </div>
        <div class="w-full h-full">
            <div v-if="lastTabSelected" class="h-full w-full   ">
                
            </div>
            <div v-else class="h-full w-full  ">

            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, computed } from 'vue';

const props = defineProps(['tabList', 'firstTabSelected', 'lastTabSelected'])
const emit = defineEmits(['set-page'])

const highlightedTabIndex = ref(null)

function setPage(tabKey) {
    emit('set-page', tabKey)
}


function highlightTab(index) {
    highlightedTabIndex.value = index
}

function unHighlightTab() {
    // console.log('unHighlightTab')
    highlightedTabIndex.value = null
}

</script>

<style scoped>
::-webkit-scrollbar {
  height: 0px;
  width: 0px;      
}
</style>