<template>
    <div class="bg-white">
        <!-- <div>
            <div>
                {{ numColumns }} Estimated Columns
            </div>
            <div v-for="(statusGroup, key) in statusGroups"
                :key="key"
          
            >
                <div class="font-semibold tracking-wider">Title: {{ statusGroup.title }}</div>
                <div>ProgressUpdates: {{ statusGroup.progressUpdates.length }}</div>
                <div>Num Lines: {{ statusGroup.numLines }}</div>
                <div>Num Columns: {{ statusGroup.numColumns }}</div>
            </div>
        </div> -->
        <CRMProgressUpdatePage 
            v-for="(progressUpdatePage, index) in pages"  
            :key="index"
            :clientName="clientName"
            :numReportedCompanies="numReportedCompanies"
            :companyLabel="companyLabel"
            :numCampaigns="numCampaigns"
            :campaignLabel="campaignLabel"
            :progressUpdatePage="progressUpdatePage"
        />
    </div>

    <!-- <CRMProgressUpdatePage v-for=""
        :progressUpdates="progress"
    /> -->
</template>

<script setup>
import { defineProps, ref, reactive, watch, computed } from 'vue';
import CRMProgressUpdatePage from '@/components/crm-reporting/print-components/CRMProgressUpdatePage.vue'

const props = defineProps([
    'clientName',
    'numReportedCompanies',
    'companyLabel',
    'numCampaigns',
    'campaignLabel',
    'progressUpdates'
])

const maxPageLines = ref(57)
const maxLineLength = ref(50)


const noUpdateColumn = ref({
    title: 'Status Groups with No Updates',
    startingIndex: 0,
    statusGroups: []
})

const progressUpdateColumns = ref([])
const progressUpdatePages = ref([])

const progressUpdatesByStatus = ref([])


// watch(() => props.progressUpdates, () => {

// }, {deep:true})


// maps and sorts progress updates into single array, ordered by crm status
// getProgressUpdatesByStatus()

function getProgressUpdatesByStatus() {
    progressUpdatesByStatus.value = progressUpdatesByStatus.value.concat(
        props.progressUpdates.filter((progressUpdate) => {
            return (progressUpdate.company.computed_crm_status.label == 'Active - In Dialogue' || 
            progressUpdate.company.computed_crm_status.label == 'Under LOI' || 
            progressUpdate.company.computed_crm_status.label == 'IOI Submitted');
        }).map((progressUpdate) => {
            return {
                ...progressUpdate,
                status: 'Active - In Dialogue'
            }
        })
    ).sort((a, b) => a.company.company_name - b.company.company_name)
    progressUpdatesByStatus.value = progressUpdatesByStatus.value.concat(
        props.progressUpdates.filter((progressUpdate) => {
            return progressUpdate.company.computed_crm_status.label == 'Responded / Coverage';
        }).map((progressUpdate) => {
            return {
                ...progressUpdate,
                status: 'Responded / Coverage'
            }
        })
    ).sort((a, b) => a.company.company_name - b.company.company_name)
    progressUpdatesByStatus.value = progressUpdatesByStatus.value.concat(
        props.progressUpdates.filter((progressUpdate) => {
            return progressUpdate.company.computed_crm_status.label == 'Not Now / Keep Warm' ||
            progressUpdate.company.computed_crm_status.label == 'Dead';
        }).map((progressUpdate) => {
            return {
                ...progressUpdate,
                status: 'Not Now / Keep Warm / Dead'
            }
        })
    ).sort((a, b) => a.company.company_name - b.company.company_name)
    progressUpdatesByStatus.value = progressUpdatesByStatus.value.concat(
        props.progressUpdates.filter((progressUpdate) => {
            return progressUpdate.company.computed_crm_status.label == 'Not Contacted' || 
            progressUpdate.company.computed_crm_status.label == 'Revist / Cut' || 
            progressUpdate.company.computed_crm_status.label == 'Dead';
        }).map((progressUpdate) => {
            return {
                ...progressUpdate,
                status: 'Not Contacted / Revisit / Dead'
            }
        })
    ).sort((a, b) => a.company.company_name - b.company.company_name)
    progressUpdatesByStatus.value = progressUpdatesByStatus.value.concat(
        props.progressUpdates.filter((progressUpdate) => {
            return progressUpdate.company.computed_crm_status.label == 'Client Passed';
        }).map((progressUpdate) => {
            return {
                ...progressUpdate,
                status: 'Client Passed'
            }
        })
    ).sort((a, b) => a.company.company_name - b.company.company_name)
    progressUpdatesByStatus.value = progressUpdatesByStatus.value.concat(
        props.progressUpdates.filter((progressUpdate) => {
            return progressUpdate.company.computed_crm_status.label == 'Target Passed';
        }).map((progressUpdate) => {
            return {
                ...progressUpdate,
                status: 'Target Passed'
            }
        })
    ).sort((a, b) => a.company.company_name - b.company.company_name)
    progressUpdatesByStatus.value = progressUpdatesByStatus.value.concat(
        props.progressUpdates.filter((progressUpdate) => {
            return progressUpdate.company.computed_crm_status.label == 'No Response';
        }).map((progressUpdate) => {
            return {
                ...progressUpdate,
                status: 'No Response'
            }
        })
    ).sort((a, b) => a.company.company_name - b.company.company_name)
}

const statusGroups = reactive({
    activeInDialogue: {
        title: 'Active - In Dialogue',
        progressUpdates: props.progressUpdates.filter((progressUpdate) => {
            return (progressUpdate.company.computed_crm_status.label == 'Active - In Dialogue' || 
            progressUpdate.company.computed_crm_status.label == 'Under LOI' || 
            progressUpdate.company.computed_crm_status.label == 'IOI Submitted');
        }),
        ordinal: 0,
        columns: []
    },
    respondedCoverage: {
        title: 'Responded / Coverage',
        progressUpdates: props.progressUpdates.filter((progressUpdate) => {
            return progressUpdate.company.computed_crm_status.label == 'Responded / Coverage';
        }),
        ordinal: 1,
        columns: []
    },
    notNowKeepWarm: {
        title: 'Not Now / Keep Warm',
        progressUpdates: props.progressUpdates.filter((progressUpdate) => {
            return progressUpdate.company.computed_crm_status.label == 'Not Now / Keep Warm';
        }),
        ordinal: 2,
        columns: []
    },
    notContactedRevisit: {
        title: 'Not Contacted / Revisit / Dead',
        progressUpdates: props.progressUpdates.filter((progressUpdate) => {
            return (progressUpdate.company.computed_crm_status.label == 'Not Contacted' || progressUpdate.company.computed_crm_status.label == 'Revisit / Cut' || progressUpdate.company.computed_crm_status.label == 'Dead');
        }),
        ordinal: 3,
        columns: []
    },
    // deadAcquired: {
    //     title: 'Dead / Acquired',
    //     progressUpdates: props.progressUpdates.filter((progressUpdate) => {
    //         return progressUpdate.company.computed_crm_status.label == 'Dead';
    //     }),
    //     columns: []
    // },
    clientPassed: {
        title: 'Client Passed',
        progressUpdates: props.progressUpdates.filter((progressUpdate) => {
            return progressUpdate.company.computed_crm_status.label == 'Client Passed';
        }),
        ordinal: 4,
        columns: []
    },
    targetPassed: {
        title: 'Target Passed',
        progressUpdates: props.progressUpdates.filter((progressUpdate) => {
            return progressUpdate.company.computed_crm_status.label == 'Target Passed';
        }),
        ordinal: 5,
        columns: []
    },
    nonRespondents: {
        title: 'Non-Respondents',
        progressUpdates:  props.progressUpdates.filter((progressUpdate) => {
            return progressUpdate.company.computed_crm_status.label == 'No Response';
        }),
        ordinal: 6,
        columns: []
    }
})



function buildProgressUpdatePages() {
    buildProgressUpdateColumns()
    orderProgressUpdateColumns()
    buildProgressUpdatePagesFromColumns()
}

function buildProgressUpdateColumns() {
    Object.values(statusGroups).forEach((statusGroup) => {
        buildProgressUpdateColumnsByStatusGroup(statusGroup)
    })
}
 
function buildProgressUpdateColumnsByStatusGroup(statusGroup) {
    let title = statusGroup.title
    let startingIndex = 0
    while(statusGroup.progressUpdates.length > 0) {
        statusGroup.columns.push(buildProgressUpdateColumn(title, startingIndex, statusGroup.progressUpdates))
        startingIndex = statusGroup.columns.reduce((acc, column) => acc.concat(column.progressUpdates), []).length
    }
}

function buildProgressUpdateColumn(columnTitle, startingIndex, progressUpdates) {
    let columnLines = 0
    let columnObject = {
        title: columnTitle,
        startingIndex: startingIndex,
        progressUpdates: []
    }
    // console.log(progressUpdateToAdd.body.length, progressUpdateLines)
    // while(columnLines < maxPageLines.value) {
    while(columnLines < maxPageLines.value && progressUpdates.length > 0) {
        let progressUpdateToAdd = progressUpdates[0]
        let progressUpdateLines = 1 + Math.ceil(progressUpdateToAdd.body.length / maxLineLength.value)
        if((columnLines + progressUpdateLines) <= maxPageLines.value) {
            // console.log(progressUpdateToAdd.company.company_name)
            columnObject.progressUpdates.push(progressUpdateToAdd)
            progressUpdates.shift()
        }
        columnLines += progressUpdateLines
    }
    return columnObject
}

function addEmptyStatusGroupToNoUpdateColumn(statusGroup) {
    noUpdateColumn.value.statusGroups.push(statusGroup)
}

function orderProgressUpdateColumns() {
    console.log('orderProgressUpdateColumns')
    let sortedStatusGroups = Object.values(statusGroups).sort((a, b) => a.ordinal - b.ordinal)
    let columnIterator = 0 // make divisible by 3
    while(sortedStatusGroups.length > 0) {
        let columnsToFill = columnIterator % 3
        if(columnIterator == 0 || columnsToFill == 0) {
            progressUpdateColumns.value = progressUpdateColumns.value.concat(sortedStatusGroups[0].columns)
            console.log('adding to progressUpdateColumns', sortedStatusGroups[0].columns.length)
            console.log(progressUpdateColumns.value.length)
            columnIterator = progressUpdateColumns.value.length
            sortedStatusGroups.shift()
        } else {
            // fill page
            let lastColumnTitle = null
            for (const [index, statusGroup] of sortedStatusGroups.entries()) {
                console.log(index)
                console.log(statusGroup)
                // if(statusGroup.columns.length <= columnsToFill) {
                    console.log('concatenating', index, statusGroup)
                    if(statusGroup.columns.length > 0) {
                        progressUpdateColumns.value = progressUpdateColumns.value.concat(statusGroup.columns)
                        columnIterator = progressUpdateColumns.value.length
                        columnsToFill = columnIterator % 3
                        lastColumnTitle = statusGroup.title
                    } else {
                        addEmptyStatusGroupToNoUpdateColumn(statusGroup)
                    }
                    sortedStatusGroups.splice(index, 1)
                // } else {
                //     console.log('status group columns are greater than columns to fill', statusGroup.columns.length)
                //     //
                // }
            }
            if(columnsToFill > 0) {
                for(let i=0; i++; i < columnsToFill) {
                    progressUpdateColumns.value = progressUpdateColumns.value.concat({title: lastColumnTitle, progressUpdates: []})
                }
                columnIterator = progressUpdateColumns.value.length
                columnsToFill = 0
            }
        }
    }
    progressUpdateColumns.value = progressUpdateColumns.value.concat(noUpdateColumn.value)
    console.log(progressUpdateColumns.value)
    console.log(progressUpdateColumns.value.length)
}

function buildProgressUpdatePagesFromColumns() {
    while(progressUpdateColumns.value.length > 0) {
        progressUpdatePages.value.push({
            columns: progressUpdateColumns.value.splice(0, 3)
        })
    }

}

// buildProgressUpdatePages()


const pages = ref([])
const lastPage = ref(false)
const startingIndex = ref(0)

function buildPages() {
    getProgressUpdatesByStatus()
    // buildPage(progressUpdatesByStatus.value)
    while(progressUpdatesByStatus.value.length > 0) {
        let page = buildPage(progressUpdatesByStatus.value)
        if(lastPage.value) {
            Object.values(statusGroups).forEach((statusGroup) => {
            if(statusGroup.progressUpdates.length == 0) {
                page.sections.push({title: statusGroup.title, columns: [{startingIndex: 0, progressUpdates: []}, {startingIndex: 0, progressUpdates: []}, {startingIndex: 0, progressUpdates: []},]})
            }
        })
        }
        pages.value.push(page)
    }
    // let emptyStatusPage = buildEmptyStatusPage()
    // pages.value.push(emptyStatusPage)
}

function buildEmptyStatusPage() {
    let emptyStatusPage = {
        sections: [],
    }
    Object.values(statusGroups).forEach((statusGroup) => {
        if(statusGroup.progressUpdates.length == 0) {
            emptyStatusPage.sections.push({title: statusGroup.title, columns: [{startingIndex: 0, progressUpdates: []}, {startingIndex: 0, progressUpdates: []}, {startingIndex: 0, progressUpdates: []},]})
        }
    })
    return emptyStatusPage
}

function buildPage(remainingProgressUpdates) {
    let page = {
        sections: [],
        remainingLines: maxPageLines.value
    }
    while(page.remainingLines > 2 && remainingProgressUpdates.length > 0) {
        let crmStatus = remainingProgressUpdates[0].status
        if(!pages.value.some((page) => { 
            return page.sections.some((section) => { 
                return section.title == crmStatus 
            }
        )})) {
            startingIndex.value = 0
        }
        let numProgressUpdatesByCurrentStatus = remainingProgressUpdates.filter(progressUpdate => progressUpdate.status == crmStatus).length
        let remainingProgressUpdatesInCurrentStatus = remainingProgressUpdates.slice(0, numProgressUpdatesByCurrentStatus)
        let maxColumnLines = getMaxColumnLinesForSection(remainingProgressUpdatesInCurrentStatus, page.remainingLines)
        let newSection = buildSectionFromUpdates(crmStatus, remainingProgressUpdates, maxColumnLines)
        page.sections.push(newSection)
        page.remainingLines = page.remainingLines - maxColumnLines
    }
    if(remainingProgressUpdates.length == 0) {
        lastPage.value = true
    }
    return page
}

function buildSectionFromUpdates(crmStatus, progressUpdates, maxLines) {
    let section = {
        title: crmStatus,
        columns: [],
    }
    while(section.columns.length < 3) {
        let column = buildSectionColumn(progressUpdates, maxLines, crmStatus)
        console.log('column')
        console.log(column)
        console.log('title', crmStatus)
        section.columns.push(column)
    }
    return section
}

function buildSectionColumn(progressUpdates, maxLines, crmStatus) {
    let column = {
        startingIndex: startingIndex.value,
        progressUpdates: []
    }
    let columnLines = 0
    while(columnLines < maxLines && progressUpdates.length > 0) {
        let progressUpdateToAdd = progressUpdates[0]
        if(progressUpdateToAdd.status != crmStatus) {
            break
        }
        let progressUpdateLines = 1 + Math.ceil(progressUpdateToAdd.body.length / maxLineLength.value)
        column.progressUpdates.push(progressUpdateToAdd)
        progressUpdates.shift()
        // if((columnLines + progressUpdateLines) <= maxLines) {
        //     // console.log(progressUpdateToAdd.company.company_name)
        //     column.progressUpdates.push(progressUpdateToAdd)
        //     progressUpdates.shift()
        // }
        columnLines += progressUpdateLines
    }
    startingIndex.value += column.progressUpdates.length
    return column
}

function getMaxColumnLinesForSection(progressUpdatesByStatus, remainingLines) {
    console.log(progressUpdatesByStatus, remainingLines)
    let initialLines = 0
    let progressUpdatesMaxColumnLines = Math.ceil(progressUpdatesByStatus.reduce((acc, progressUpdate) => {
        return acc + getLinesByProgressUpdate(progressUpdate)
    }, initialLines) / 3)
    return progressUpdatesMaxColumnLines > remainingLines ? remainingLines : progressUpdatesMaxColumnLines
}

function getLinesByProgressUpdate(progressUpdate) {
    return 1 + Math.ceil(progressUpdate.body.length / maxLineLength.value)
}

buildPages()

</script>