<template>
    <span :class="sourceTypeClass">
        {{ dataSourceType }}
    </span>
</template>

<script setup>
import { defineProps, computed } from 'vue';

const props = defineProps(['dataSourceType'])

const sourceTypeClass = computed(() => {
    if(props.dataSourceType) {
        switch(props.dataSourceType) {
            case 'Association':
                return 'px-2 py-1 rounded-md  bg-emerald-100 text-emerald-700'
            case 'Database':
                return 'px-2 py-1 rounded-md bg-violet-100 text-violet-700'
            case 'Expo':
                return 'px-2 py-1 rounded-md  bg-sky-100 text-sky-700'
            case 'Member List':
                return 'px-2 py-1 rounded-md  bg-lime-100 text-lime-700'
            case 'Other':
                return 'px-2 py-1 rounded-md  bg-orange-100 text-orange-700'
            default:
                return ''
        }
    }
    return ''
})
</script>