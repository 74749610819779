export default {
    setShowFilterPaneTrue(state) {
        state.showFilterPane = true;
    },
    setShowFilterPaneFalse(state) {
        state.showFilterPane = false;
    },
    setHasActiveResearchSessionTrue(state){
        state.hasActiveResearchSession = true
    },
    setHasActiveResearchSessionFalse(state){
        state.hasActiveResearchSession = false
    }
}