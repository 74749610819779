<template>
    <div v-if="page" class="w-full h-full p-1">
        <div v-if="isLoading && !openSession">
            <div v-if="isLoading" role="status" class="pl-3">
                  <svg aria-hidden="true" class="mx-1 w-6 h-6 text-gray-200 animate-spin dark:text-gray-600"
                      viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="white"/>
                    <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="#2E9CCA"/>
                  </svg>
                  <span class="sr-only">Loading...</span>
            </div>
        </div>
        <div v-else>
            <div v-if="openSession">
                <div class="flex flex-col p-2">
                    <div class="flex flex-col text-base w-full my-1">
                        <div class="w-full border-b border-b-gray-500 text-gray-500 tracking-widest">
                            Research Page
                        </div>
                        <div>
                            {{ sessionResearchPage }}
                        </div>
                    </div>
                    <div class="flex flex-col text-base w-full my-1">
                        <div class="w-full border-b border-b-gray-500 text-gray-500 tracking-widest">
                            Session Start
                        </div>
                        <div>
                            {{ sessionStartDate }}
                        </div>
                    </div>
                    <div class="flex flex-col text-base w-full my-1">
                        <div class="w-full border-b border-b-gray-500 text-gray-500 tracking-widest">
                            Session End
                        </div>
                        <div>
                            {{ sessionEndDate }}
                        </div>
                    </div>
                </div>
                <div class="flex flex-col p-2">
                    <div class="flex flex-col text-base w-full my-1">
                        <div class="w-full border-b border-b-gray-500 text-gray-500 tracking-widest">
                            Total Items Processed
                        </div>
                        <div>
                            {{ openSession.total_processed }}
                        </div>
                    </div>
                    <div v-for="metric in openSession.metrics" :key="metric.id" class="flex flex-col text-base w-full my-1">
                        <div class="w-full border-b border-b-gray-500 text-gray-500 tracking-widest">
                            {{ metric.metric_name }}
                        </div>
                        <div>
                            {{ metric.metric_count }}
                        </div>
                    </div>
                    <div class="flex flex-col text-base w-full my-1">
                        <div class="w-full border-b border-b-gray-500 text-gray-500 tracking-widest">
                            Rate of Action 
                        </div>
                        <div>
                            {{ sessionRateOfAction }} actions per minute
                        </div>
                    </div>
                    <div class="flex justify-between items-center">
                        <button v-if="!openSession.session_end" class="my-4 rounded-md h-1/2 w-1/2
                            p-1
                            hover:shadow-md
                            bg-dark-secondary
                            text-white
                            border
                            border-dark-secondary
                            active:bg-dark-primary
                            active:text-white
                            focus:bg-dark-primary
                            focus:text-white
                            text-base
                            hover:cursor-pointer" @click="completeSession">End Session
                        </button>
                        <button v-else class="my-4 rounded-md h-1/2 w-1/2  p-1 bg-accent-primary text-white border border-accent-primary  hover:shadow-md active:text-white active:bg-accent-secondary focus:text-white focus:bg-accent-secondary text-base hover:cursor-pointer" @click="createSession">Start Session</button>
                        <div>
                            <div v-if="isLoading" role="status" class="flex items-center">
                                <svg
                                aria-hidden="true"
                                class="w-10 h-10 animate-spin"
                                viewBox="0 0 100 101"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                >
                                <path
                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                    fill="white"
                                />
                                <path
                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                    fill="#2E9CCA"
                                />
                                </svg>
                            </div>
                            <div v-else class="">
                                <svg
                                @click="loadOpenSession"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="#25274D"
                                class=" w-10 h-10 hover:cursor-pointer"
                                >
                                <path
                                    class=""
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                                />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex flex-col p-2 items-center" v-else>
                <button class="my-4 rounded-md h-1/2 w-1/2  p-1 bg-accent-primary text-white border border-accent-primary  hover:shadow-md active:text-white active:bg-accent-secondary focus:text-white focus:bg-accent-secondary text-base hover:cursor-pointer" @click="createSession">Start Session</button>
            </div>
        </div>
    </div>
    <div v-else class="ml-3 mt-2 h-full flex flex-col space-y-1 justify-start">
        <div class="flex flex-row justify-center text-xs pt-4">
            <under-construction></under-construction> 
        </div>
    </div>
</template>

<script setup>
import { defineProps, ref, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { endSession, getSession, startSession } from '@/services/ResearchSession';
import { useToast } from 'vue-toast-notification';
import { formatDateTime } from '@/composables/formatDateTime';

const $toast = useToast()
const store = useStore()
const route = useRoute()
const openSession = ref(null)
const isLoading = ref(false)

const props = defineProps([
    'userId'
])

const page = computed(() => {
    if(route.name === 'vet-urls'){
        return 'vet_urls'
    } else if(route.name === 'missing-url-or-zoom-info-companies'){
        return 'missing_url_or_zoom_info_companies'
    } else {
        return null
    }
})

const sessionStartDate = computed(() => {
    if(openSession.value){
        return formatDateTime(openSession.value.session_start)
    } else {
        return '--'
    }
})

const sessionEndDate = computed(() => {
    if(openSession.value){
        return formatDateTime(openSession.value.session_end)
    } else {
        return '--'
    }
})

const sessionRateOfAction = computed(() => {
    if(openSession.value){
        return Math.round(openSession.value.rate_of_action * 100) / 100
    } else {
        return '--'
    }
})

const sessionResearchPage = computed(() => {
    if(openSession.value){
        const researchPage = openSession.value.research_page
        return researchPage == 'vet_urls' ? 'Vet URLs' : researchPage == 'missing_url_or_zoom_info_companies' ? 'Missing URL or ZoomInfo Companies' : '--'
    } else {
        return '--'
    }
})

watch(page, () => {
    loadOpenSession()
})

async function loadOpenSession(){
    if(page.value){
        isLoading.value = true
        const token = store.state.auth.token
        const result = await getSession(token, page.value)
        if(result.data.session){
            openSession.value = result.data.session
            store.dispatch('research/setHasActiveResearchSessionTrue');
        }
        isLoading.value = false
    } else {
        openSession.value = null
        store.dispatch('research/setHasActiveResearchSessionFalse');
    }
}

async function completeSession(){
    if(openSession.value){
        isLoading.value = true
        const token = store.state.auth.token
        const result = await endSession(token, openSession.value.id)
        if(result.status === 200){
            console.log('result', result)
            openSession.value = result.data.session
            store.dispatch('research/setHasActiveResearchSessionFalse');
        } else {
            $toast.open({
            message: 'Error, could not end session',
            type: 'error',
            duration: 5000
        })
        }
        isLoading.value = false
    }
}

async function createSession(){
    isLoading.value = true
    const token = store.state.auth.token
    const data = {
        page: page.value
    }
    const result = await startSession(token, data)
    if(result.status === 201){
        openSession.value = result.data.session 
        store.dispatch('research/setHasActiveResearchSessionTrue');
    } else if(result.status === 400){
        $toast.open({
            message: result.data.status,
            type: 'error',
            duration: 5000
        })
    }
    isLoading.value = false
}

loadOpenSession()




</script>