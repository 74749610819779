<template>
    <svg 
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        style="isolation:isolate"
        viewBox="0 0 30 30"
        class="w-3 h-3"
    >
        <path 
            class="" 
            d=" M 13 15 L 7 9 L -1 1 L 0 1 L 0 0.767 C 0 0.344 0.344 0 0.767 0 L 29.233 0 C 29.656 0 30 0.344 30 0.767 L 30 1 L 31 1 L 23 9 L 17 15 L 17 29.072 C 17 29.584 16.584 30 16.072 30 L 13.928 30 C 13.416 30 13 29.584 13 29.072 L 13 15 Z " 
            fill-rule="evenodd" 
            :fill="fillColor"
        />
    </svg>
</template>

<script setup>
import { defineProps, computed } from 'vue';

const props = defineProps(['fill'])

const fillColor = computed(() => {
   return props.fill ? props.fill : 'black'
})
</script>