<template>
    <!-- <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        style="isolation:isolate"
        viewBox="0 0 60 56"
        width="60pt" height="56pt"
    >
        <defs>
            <clipPath id="_clipPath_s1wVTpGJjApB3QCWa9fuZcSuYDJsGW5X">
                <rect width="60" height="56"/>
            </clipPath>
        </defs>
        <g clip-path="url(#_clipPath_s1wVTpGJjApB3QCWa9fuZcSuYDJsGW5X)">
            <rect x="0" y="0" width="30" height="56" transform="matrix(1,0,0,1,0,0)" fill="none"/>
            <g>
                <path
                    class="hover:fill-accent-primary hover:cursor-pointer"
                    d=" M 2.562 28.562 C 2.562 13.419 14.857 1.125 30 1.125 Q 37.077 1.125 60.181 1.125 L 60.181 56 Q 35.141 56 30 56 C 14.857 56 2.562 43.706 2.562 28.562 Z  M 11.5 28.562 L 27 12.563 C 27.731 11.729 27.803 10.692 27 10 C 25.836 9.154 24.692 10.308 24 11 L 7 28.562 L 24 46.125 C 24.692 46.817 25.836 47.971 27 47.125 C 27.803 46.433 27.731 45.396 27 44.562 L 11.5 28.562 Z " fill-rule="evenodd" fill="rgb(255,255,255)"/>
            </g>
        </g>
    </svg> -->
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="isolation:isolate" viewBox="0 0 60 56" width="60pt" height="56pt">
        <defs>
            <clipPath id="_clipPath_rVTWnF9J5cdd6lkmcPyo1IBM6JAWKZs4">
                <rect width="60" height="56"/>
            </clipPath>
        </defs>
        <g clip-path="url(#_clipPath_rVTWnF9J5cdd6lkmcPyo1IBM6JAWKZs4)">
            <rect x="0" y="0" width="30" height="56" transform="matrix(1,0,0,1,0,0)" fill="none"/>
            <g>
                <path class="hover:fill-accent-primary hover:cursor-pointer" d=" M 3.094 28.562 C 3.094 11.906 20.906 13.125 30 13.125 Q 37.077 13.125 60.181 13.125 L 60.181 45 Q 35.141 45 30 45 C 21.281 45 3.094 45.218 3.094 28.562 Z  M 11.5 28.562 L 24 19.563 C 24.731 18.729 24.803 17.692 24 17 C 22.836 16.154 21.692 17.308 21 18 L 7 28.562 L 21 40.125 C 21.692 40.817 22.836 41.971 24 41.125 C 24.803 40.433 24.731 39.396 24 38.562 L 11.5 28.562 Z " fill-rule="evenodd" fill="rgb(255,255,255)"/>
            </g>
        </g>
    </svg>
</template>

<script setup>


</script>

