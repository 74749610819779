<template>
    <div :style="progressUpdateStyle" class="flex flex-col pb-1">
        <div class="px-2" >{{ progressUpdateNumber }}. {{ companyName }} {{ companyCityState }}</div>
        <div v-if="progressUpdateBody" class="pl-8 pr-2 pb-1 pretty whitespace-pre-wrap ">{{ progressUpdateBody }}</div>
    </div>
</template>

<script setup>
import { ref, defineProps, computed } from 'vue';

const props = defineProps(['progressUpdate', 'index'])


const progressUpdateNumber = computed(() => {
    return props.index != undefined ? props.index + 1 : 0
})

const progressUpdateStyle = computed(() => {
    if(props.progressUpdate && props.progressUpdate.bold_on_report) {
        return 'font-weight: 700;'
    }
    return ''
})

const companyName = computed(() => {
    if(props.progressUpdate && props.progressUpdate.company) {
        const omittedEndings = [', Inc.', ', Inc', 'Inc.', 'Inc', ', INC.', ', INC', 'INC.', 'INC', ', llc.', ', llc', 'llc.', 'llc', ', LLC.', ', LLC', 'LLC.', 'LLC', ', Corp.', ', Corp', 'Corp.', 'Corp', ', CORP.', ', CORP', 'CORP.', 'CORP', ', Ltd.', ', Ltd', 'Ltd.', 'Ltd', ', LTD.', ', LTD', 'LTD.', 'LTD']
        let companyName = props.progressUpdate.company.company_name
        for(let i = 0; i < omittedEndings.length; i++) {
            // console.log(omittedEndings[i])
            if(companyName.endsWith(omittedEndings[i])) {
                companyName = companyName.replace(omittedEndings[i], '')
                break
            }
        }
        return companyName
    }
    return ''
})

const companyCityState = computed(() => {
    if(props.progressUpdate && props.progressUpdate.company) {
        if(props.progressUpdate.company.city && props.progressUpdate.company.state) {
            let city = props.progressUpdate.company.city.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
            let state = props.progressUpdate.company.state
            if(state.length == 2) {
                state = props.progressUpdate.company.state.toUpperCase()
            } else {
                state = props.progressUpdate.company.state.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
            }
            return `(${city}, ${state})`
        } else if(props.progressUpdate.company.city) {
            let city = props.progressUpdate.company.city.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
            return `(${city})`
        } else if(props.progressUpdate.company.state) {
            let state = props.progressUpdate.company.state
            if(state.length == 2) {
                state = props.progressUpdate.company.state.toUpperCase()
            } else {
                state = props.progressUpdate.company.state.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
            }
            return `(${state})`
        }
    }
    return ''
})

const progressUpdateBody = computed(() => {
    if(props.progressUpdate && props.progressUpdate.body) {
        return props.progressUpdate.body.replaceAll('*', '\u2022')
    }
    return null
})

</script>