<template>
    <div class="w-full h-full flex flex-col justify-center items-center bg-gray-50">
        <div class="w-1/3 h-full flex flex-col justify-center items-center">
            <!-- <div class="text-dark-primary tracking-wider text-2xl font-light border-b border-gray-300 w-full flex justify-center pb-2">
                CRM Reporting Dashboard
            </div> -->
                <div class="text-sm flex items-center w-full pl-2 pb-1 pt-4 font-semibold tracking-widest text-gray-600">
                    Print Report
                </div>
                <div class="flex w-full items-center">
                    <type-ahead-box
                        placeholder="Choose Client CRM Report"
                        nullLabel="No Results"
                        :default-value="reportId"
                        :options="publishedReportOptions"
                        @update-value="updateReportId"
                    ></type-ahead-box>
                    <div @click="showPrintPreview" class="hover:bg-gray-300 bg-gray-200 rounded-r-sm h-full flex px-2 justify-center items-center cursor-pointer whitespace-nowrap font-semibold tracking-wider text-gray-600 hover:underline">
                        Preview Report
                        <!-- <PrintSVG
                            fill-color="#25274D"
                        /> -->
                    </div>
                </div>
            </div>
        <Teleport to="body">
            <div v-show="printPreviewIsVisible" class="modal h-full fixed overflow-y-scroll bg-gray-200 flex justify-center w-full">
                <div class="flex fixed print:hidden bg-gray-50 shadow-md h-14 border-b justify-center items-center border-gray-300 z-10 w-full">
                    <div @click="hidePrintPreview" class="hover:bg-gray-200 rounded-sm h-full flex px-0.5 md:px-3 justify-center items-center cursor-pointer print:hidden">
                        <ChevronLeftSVG
                            fill-color="#25274D"
                        />
                    </div>
                    <div @click="handlePrint" class="hover:bg-gray-200 rounded-sm h-full flex px-0.5 md:px-3 justify-center items-center cursor-pointer print:hidden">
                        <PrintSVG
                            fill-color="#25274D"
                        />
                    </div>
                </div>
                <div class=" bg-gray-200 relative flex justify-center w-[1632px] top-14">
                    <div ref="componentRef" class="bg-white w-full flex flex-col">
                        <div class="hidden print:flex items-center justify-center h-[2100px] break-after-page p-24 w-full bg-white">
                            <div class="flex flex-col items-center justify-center h-full w-full px-12 bg-dark-secondary">
                                <div class="text-5xl flex items-center justify-center font-extralight tracking-widest whitespace-nowrap text-white py-4 w-1/2  border-b border-gray-50">
                                    {{ clientName }}
                                </div>
                                <div class="text-2xl flex justify-center items-center w-full tracking-wider whitespace-nowrap font-medium text-gray-50 py-4">
                                    Outreach Overview {{ formatDate(today) }}
                                </div>
                                <div class="w-[300px] h-[360px] absolute bottom-20 flex justify-center">
                                    <SheddCapitalSVG 
                                        fill-color="white"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="w-full flex flex-col h-[2100px] break-after-page p-24 bg-white">
                            <div class="w-full flex h-14 px-6 items-center justify-between border-b border-gray-300 text-lg font-semibold tracking-widest whitespace-nowrap text-gray-800">
                                <div class='w-full flex space-x-2 justify-start'>
                                    <div>{{ clientName }}: CRM Overview</div>
                                    <div class="text-base 2xl:flex items-center justify-center tracking-wider whitespace-nowrap text-gray-600 pt-0.5 pb-6">
                                        {{ numReportedCompanies }} {{ companyLabel }} in {{ numCampaigns }} {{ campaignLabel }}
                                    </div>
                                </div>
                                <div class="w-[200px] flex justify-end">
                                    <SheddCapitalSVG 
                                        fill-color="#1F2937"
                                    />
                                </div>
                            </div>
                            <CRMOverview
                                class="h-[1800px]"
                                :printView="true"
                                :clientName="clientName"
                                :campaigns="campaigns"
                                :reportedCompanies="reportedCompanies"
                                :contactedCompanies="contactedCompanies"
                                :notContactedCompanies="notContactedCompanies"
                                :respondedCompanies="respondedCompanies"
                                :noResponseCompanies="noResponseCompanies"
                            />
                        </div>
                        <CRMProgressUpdatesSection 
                            :clientName="clientName"
                            :numReportedCompanies="numReportedCompanies"
                            :companyLabel="companyLabel"
                            :numCampaigns="numCampaigns"
                            :campaignLabel="campaignLabel"
                            :progressUpdates="reportProgressUpdates"
                        />
                        <div 
                            v-for="(companyBatch, index) in reportedCompaniesBatches"
                            :key="index"
                            class="w-full flex flex-col h-[2100px] break-after-page p-24 bg-white"
                        >
                            <div class="w-full flex h-14 px-2 items-center justify-between border-b border-gray-300 text-lg font-semibold tracking-widest whitespace-nowrap text-gray-800">
                                <div class='w-full flex space-x-2 justify-start'>
                                    <div>{{ clientName }}: Target List</div>
                                    <div class="text-base 2xl:flex items-center justify-center tracking-wider whitespace-nowrap text-gray-600 pt-0.5 pb-6">
                                        {{ numReportedCompanies }} {{ companyLabel }} in {{ numCampaigns }} {{ campaignLabel }}
                                    </div>
                                </div>
                                <div class="w-[200px] flex justify-end">
                                    <SheddCapitalSVG 
                                        fill-color="#1F2937"
                                    />
                                </div>
                            </div>
                            <div class="w-full overflow-clip h-[1800px]">
                                <CRMTargetList
                                    :print-view="true"
                                    :selectedCompanies="companyBatch"  
                                    :campaigns="campaigns"
                                    :startingIndex="getStartingIndexByBatchIndex(index)"
                                    :maxBatchLength="maxBatchLength"
                                />
                            </div>
                        </div>
                        <!-- <div
                            v-for="campaign in campaigns"
                            :key="campaign"
                            class="w-full"
                        >
                            <div class="w-full bg-white flex flex-col h-[2100px] break-after-page p-24">
                                <div class="w-full flex h-14 px-12 items-center justify-between border-b border-gray-300 text-lg font-semibold tracking-widest whitespace-nowrap text-gray-800">
                                    <div class='w-full flex justify-start'>
                                        Campaign: {{ getCampaignName(campaign) }}
                                    </div>
                                    <div class="w-[200px] flex justify-end">
                                        <SheddCapitalSVG 
                                            fill-color="#1F2937"
                                        />
                                    </div>
                                </div>  
                                <CRMOverview
                                    class="h-[1800px]"
                                    :printView="true"
                                    :clientName="clientName"
                                    :campaigns="campaigns"
                                    :selectedCompanies="companies.filter((company) => company.campaign.id == campaign)"
                                    :contactedCompanies="contactedCompanies.filter((company) => company.campaign.id == campaign)"
                                    :notContactedCompanies="notContactedCompanies.filter((company) => company.campaign.id == campaign)"
                                    :respondedCompanies="respondedCompanies.filter((company) => company.campaign.id == campaign)"
                                    :noResponseCompanies="noResponseCompanies.filter((company) => company.campaign.id == campaign)"
                                />
                            </div>
                            <div class="w-full bg-white flex flex-col h-[2100px] break-after-page p-24">
                                <div class="w-full flex h-14 px-12 items-center justify-between border-b border-gray-300 text-lg font-semibold tracking-widest whitespace-nowrap text-gray-800">
                                    <div class='w-full flex justify-start'>
                                        Campaign: {{ getCampaignName(campaign) }}
                                    </div>
                                    <div class="w-[200px] flex justify-end">
                                        <SheddCapitalSVG 
                                            fill-color="#1F2937"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div 
                                v-for="(companyBatch, index) in reportedCompaniesBatches"
                                :key="index"
                                class=" bg-white flex flex-col h-[2100px] break-after-page p-24 items-center w-full"
                                >
                                <div class="w-full flex h-14 px-12 items-center justify-between border-b border-gray-300 text-lg font-semibold tracking-widest whitespace-nowrap text-gray-800">
                                    <div class='w-full flex justify-start'>
                                        Campaign: {{ getCampaignName(campaign) }}
                                    </div>
                                    <div class="w-[200px] flex justify-end">
                                        <SheddCapitalSVG 
                                            fill-color="#1F2937"
                                        />
                                    </div>
                                </div >
                                <div class="w-full overflow-clip h-[1800px]">
                                    <CRMTargetList
                                    :print-view="true"
                                    :selectedCompanies="companyBatch"  
                                    :campaigns="campaigns"
                                />
                                </div>

                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </Teleport>
    </div>
</template>

<script setup>
import{ ref, defineProps, computed, watch } from 'vue';
import SheddCapitalSVG from '../svg-components/SheddCapitalSVG.vue';
import CRMOverview from './CRMOverview.vue';
import CRMTargetList from './CRMTargets.vue';
import CRMProgressUpdatesSection from '@/components/crm-reporting/print-components/CRMProgressUpdatesSection.vue'
import PrintSVG from '../svg-components/PrintSVG.vue';
import { useVueToPrint } from "vue-to-print";
import { formatDate } from '@/composables/formatDate';
import ChevronLeftSVG from '../svg-components/ChevronLeftSVG.vue';

const props = defineProps([
    "today",
    "clientName",
    "campaigns",
    "campaignOptions",
    "numReportedCompanies",
    "companyLabel",
    "numCampaigns",
    "campaignLabel",
    "reportedCompanies",
    "contactedCompanies",
    "notContactedCompanies",
    "respondedCompanies",
    "noResponseCompanies",
    "reportedCompaniesBatches",
    "reportOptions",
    "maxBatchLength",
])

const reportId = ref((props.reportOptions && props.reportOptions.filter(reportOption => reportOption.report.status == 'closed').length > 0) ? props.reportOptions.filter(reportOption => reportOption.report.status == 'closed')[0].value : null)

const reportProgressUpdates = ref([])

const printPreviewIsVisible = ref(false)
const componentRef = ref();

const publishedReportOptions = computed(() => {
    return props.reportOptions ? props.reportOptions.filter(reportOption => reportOption.report.status == 'closed') : []
})

const reportDate = computed(() => {
    const date = new Date()
    return `${date.getFullYear()} ${String(date.getMonth() + 1).padStart(2, '0')} ${String(date.getDate()).padStart(2, '0')}`
})

watch(() => reportId.value, () => {
    setReportProgressUpdatesFromSelectedReport()
})

function updateReportId(reportValue) {
    reportId.value = reportValue
}

function setReportProgressUpdatesFromSelectedReport() {
    if(reportId.value) {
        reportProgressUpdates.value = props.reportOptions.find((reportOption) => reportOption.report.id == reportId.value).report.crm_progress_updates.map(getProgressUpdateWithCompanyData).filter((progressUpdate) => progressUpdate.company != undefined)
    } else {
        reportProgressUpdates.value = []
    }
}

function showPrintPreview() {
    printPreviewIsVisible.value = true
}

function hidePrintPreview() {
    printPreviewIsVisible.value = false
}

function getCampaignName(campaignId) {
    let thisCampaign = props.campaignOptions.find((campaign) => campaign.value == campaignId)
    return thisCampaign.label
}

function getStartingIndexByBatchIndex(index) {
    return index * props.maxBatchLength
}

const { handlePrint } = useVueToPrint({
  content: componentRef,
  documentTitle: `${reportDate.value} ${props.clientName} - Outreach Report`,
  removeAfterPrint: true
});

function getProgressUpdateWithCompanyData(progressUpdate) {
    // console.log('getProgressUpdateWithCompanyData')
    return {
        // ...progressUpdate,
        id: progressUpdate.id,
        body: progressUpdate.body,
        bold_on_report: progressUpdate.bold_on_report,
        // date: progressUpdate.date,
        company: props.reportedCompanies.find((company) => company.id == progressUpdate.company),
        unsaved: false,
    }
}


setReportProgressUpdatesFromSelectedReport()
</script>