<template>
    <div class="h-full border-l border-b border-gray-300 pl-1 bg-gray-100 text-xs text-gray-800  w-72">
        <div v-if="visibleBulkActionMenu" class="h-full">
            <div class="flex items-center space-between h-8 space-x-2  border-b border-gray-300">
                <div class="w-full">
                    <button
                        @click="hideBulkActionMenu"
                        class="rounded-sm p-1 hover:bg-gray-300"
                    >
                        <BackArrowSVG />
                    </button>
                </div>
                <div class="tracking-wider font-medium flex w-full justify-center items-center whitespace-nowrap">
                    Bulk {{ bulkAction.label }}
                </div>
                <div class="w-full"></div>
            </div>
            <div class="h-[calc(100vh-28.5rem)]">
                <slot name="bulk-operations"
                    :selectedResults="selectedResults"
                    :bulkAction="bulkAction"
                    :events="{ hideBulkActionMenu }"
                >
                </slot>

            </div>
        </div>
        <div v-else class="flex flex-col w-full">
            <div class="w-full tracking-wider font-medium flex justify-center items-center h-[30px] border-b border-gray-300">
                {{ panelTitle }}
            </div>
                <div  v-if="selectedResults.length > 0">
                <BulkOperationListElement
                    v-for="(bulkOperation, key) in bulkOperations"
                    :key="key"
                    :bulk-operation="bulkOperation"
                    @show-bulk-action-menu="showBulkActionMenu"
                >
                </BulkOperationListElement>
            </div>
            <div v-else class="tracking-wide font-medium text-gray-800 flex justify-center items-center w-full p-2">
                Select row(s) view bulk operations.
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, watch, reactive, defineProps, defineEmits, computed } from 'vue';
import BackArrowSVG from '@/components/svg-components/BackArrowSVG.vue';
import BulkOperationListElement from './BulkOperationListElement.vue';

const props = defineProps([
    'selectedResults', 
    'bulkOperations',
    'title'
])

const emit = defineEmits(['close'])

const panelTitle = computed(() => {
    return props.title ? props.title : "Bulk Operations"
})


const visibleBulkActionMenu = ref(false)

const bulkAction = reactive({
    label: null,
    message: null,
    modelPath: null,
    endpoint: null,
    inputs: {}
})

function showBulkActionMenu(selectedBulkAction) {
    visibleBulkActionMenu.value = true
    bulkAction.label = selectedBulkAction.label 
    bulkAction.message = selectedBulkAction.message 
    bulkAction.modelPath = selectedBulkAction.modelPath 
    bulkAction.endpoint = selectedBulkAction.endpoint 
    bulkAction.inputs = selectedBulkAction.inputs
}

function hideBulkActionMenu() {
    console.log('hideBulkActionMenu')
    visibleBulkActionMenu.value = false
    bulkAction.value = null
}
</script>