<template>
    <tr class="h-12 w-full whitespace-nowrap bg-gray-100 absolute bottom-14 ">
        <td :colspan="columnSpan" class="bg-gray-100">Data Import Footer</td>
    </tr>
</template>

<script setup>
import { defineProps, computed } from 'vue';

const props = defineProps(['viewType'])

const columnSpan = computed(() => {
    switch(props.viewType) {
        case 'imports':
            return 9
        case 'companies':
            return 7
        default:
            return 0
    }
})
</script>