<template>
    <div class="h-full w-full flex flex-col  print:hidden bg-gray-200">
        <div class="flex w-full h-14 border-b border-x justify-start items-center border-gray-300 sticky z-20 top-0 bg-gray-50 px-4">
            <div @click="hideReport" @mouseover="boldBackButton" @mouseout="unboldBackButton" class="h-full flex justify-center items-center cursor-pointer print:hidden">
                <div class="h-full flex flex-col justify-end">
                    <div class="h-full flex items-center pt-1">
                        <ChevronLeftSVG 
                            :fill-color="backButtonFill"
                        />
                    </div>
                    <div v-if="backButtonBolded" class="h-1 bg-gray-300"></div>
                    <div v-else class="h-1 bg-gray-50"></div>
                </div>
            </div>
            <div class="flex items-center w-fit space-x-3 px-0.5 md:px-3">
                <div  class="text-base flex items-center font-semibold tracking-widest whitespace-nowrap text-gray-800">
                    {{ clientName }}
                </div>
            </div>
            <div id="tab" class="flex justify-start items-end h-full w-full overflow-x-scroll">
                <TabBar 
                    :tab-list="tabList"
                    :first-tab-selected="firstTabSelected"
                    :last-tab-selected="lastTabSelected"
                    @set-page="setPage"
                />
            </div>
            <!-- <div class="whitespace-nowrap flex items-center">
              Progress Updates: {{ progressUpdates.length }}
            </div> -->
            <!-- <div class="flex items-center w-fit text-sm tracking-wide pl-3 whitespace-nowrap pt-0.5 space-x-3 text-gray-600">
                <div @click="showPrintPreview" class="hover:underline underline-offset-4 hover:text-dark-secondary cursor-pointer">
                    Print Preview
                </div>
                <div class="hover:underline underline-offset-4 hover:text-dark-secondary cursor-pointer">
                    Download CSV
                </div>
            </div> -->
            <div class="flex items-center w-fit justify-end pl-0.5 space-x-3 print:hidden">
                <div class="text-base w-fit hidden 2xl:flex whitespace-nowrap items-center  tracking-wider text-gray-600 pt-0.5">
                    Client Feedback:
                </div>
                <div class="flex items-center w-[100px] md:w-[200px] xl:w-[300px] pt-[30px] z-50">
                    <type-ahead-multiselect
                        placeholder="Client Feedback"
                        nullLabel="None"
                        no-selection-message="Client Feedback - Nothing Selected"
                        :default-value="selectedClientFeedback"
                        :options="clientFeedbackOptions"
                        @update-value="updateClientFeedback"
                    ></type-ahead-multiselect>
                </div>
            </div>
            <div class="flex items-center w-fit justify-end pl-0.5 xl:pl-3  space-x-3 print:hidden">
                <div class="text-base w-fit hidden 2xl:flex items-center  tracking-wider text-gray-600 pt-0.5">
                    Campaigns:
                </div>
                <div class="flex items-center w-[200px] md:w-[300px]  xl:w-[400px] pt-[30px] z-50">
                    <type-ahead-multiselect
                        placeholder="Campaign(s)"
                        nullLabel="No Results"
                        :default-value="selectedCampaigns"
                        :options="campaignOptions"
                        @update-value="updateCampaigns"
                    ></type-ahead-multiselect>
                </div>
            </div>
        </div>
        <div class="w-full flex border-x border-gray-300 h-[calc(100vh-3.5rem)]">
            <CRMReportEditor
                v-if="tabList.editor.isSelected"
                :report-options="reportOptions"
                :selected-report="selectedReport"
                :companies="reportedCompanies"
                :campaigns="campaigns"
                :campaignOptions="campaignOptions"
                :allCRMActions="allCRMActions"
                :allCRMStatuses="allCRMStatuses"
                :companyIdsToUpdate="companyIdsToUpdate"
                :progressUpdates="progressUpdates"
                :numUnsavedProgressUpdates="numUnsavedProgressUpdates"
                :numDeletedProgressUpdates="numDeletedProgressUpdates"
                :reload-defaults="reloadDefaults"
                :reset-report="resetReport"
                :bold-all="boldAll"
                :unbold-all="unboldAll"
                @set-reported-event="setCompanyReportedEvent"
                @save-report="saveAll"
                @add-progress-update="addProgressUpdateByCompany"
                @remove-progress-update="removeProgressUpdate"
                @update-progress-update="updateProgressUpdate"
                @add-all-companies="addProgressUpdateForAllCompanies"
                @remove-all-companies="removeProgressUpdateForAllCompanies"
                @bold-all-progress-updates="toggleBoldAll"
                @unbold-all-progress-updates="toggleUnboldAll"
                @reset-from-report="resetFromReport"
                @select-report="setReportId"
                @refetch="refetch"
                @open-crm-details="saveAllAndOpenCRMDetails"
                @open-company-modal="saveAllAndGetCompanyAndOpenModal"
            />
            <CRMOverview 
                v-else-if="tabList.overview.isSelected"
                :clientName="clientName"
                :campaigns="campaigns"
                :reportedCompanies="reportedCompanies"
                :contactedCompanies="contactedCompanies"
                :notContactedCompanies="notContactedCompanies"
                :respondedCompanies="respondedCompanies"
                :noResponseCompanies="noResponseCompanies"
            />
            <CRMPrintView
                v-else-if="tabList.printView.isSelected"
                :today="today"
                :clientName="clientName"
                :campaignOptions="campaignOptions"
                :campaigns="campaigns"
                :numReportedCompanies="numReportedCompanies"
                :companyLabel="companyLabel"
                :numCampaigns="numCampaigns"
                :campaignLabel="campaignLabel"
                :reportedCompanies="reportedCompanies"
                :contactedCompanies="contactedCompanies"
                :notContactedCompanies="notContactedCompanies"
                :respondedCompanies="respondedCompanies"
                :noResponseCompanies="noResponseCompanies"
                :reportedCompaniesBatches="reportedCompaniesBatches"
                :max-batch-length="maxBatchLength"
                :reportOptions="reportOptions"
                :progressUpdates="progressUpdates"
            />
        </div>
        <base-modal :is-form-visible="visibleCRMDetails" @close="closeCRMDetails">
            <template #header>
            <h2 class="text-lg">
                {{ selectedCompany.company_name }} CRM Details
            </h2>
            </template>
            <template #body>
                <CRMDetailsPage
                    :company="selectedCompany" 
                    :campaigns="campaignOptions" 
                    :crm-statuses="allCRMStatuses" 
                    :crm-action-types="allCRMActions" 
                    @refetch-companies="refetch"
                    @updated="updatedEvents"
                />
            </template>
        </base-modal>
        <base-modal :is-form-visible="visibleCompanyModal" @close="closeCompanyModal">
          <template #header>
            <h2 class="text-lg">{{ selectedCompany.company_name }} {{ selectedCompany.id }}</h2>
          </template>
          <template #body>
            <EditCompany
              :company="selectedCompany"
              :campaigns="allActiveCampaigns"
              @close="closeCompanyModal"
              @refetch-companies="refetch"
            />
          </template>
        </base-modal>
    </div>
</template>

<script setup>
import { ref, reactive, defineProps, defineEmits, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { useToast } from 'vue-toast-notification';
import TabBar from '../base-components/TabBar.vue';
import CRMReportEditor from './CRMReportEditor.vue';
import CRMOverview from './CRMOverview.vue';
import CRMPrintView from './CRMPrintView.vue';
import ChevronLeftSVG from '../svg-components/ChevronLeftSVG.vue';
import CRMDetailsPage from '../CRMDetailsPage.vue';
import EditCompany from '../EditCompany.vue';
import { getComputedCRMStatusByEventId } from '@/composables/CompanyCRMTools';
import { bulkSaveCRMTargetList } from '@/services/Company';
import { createCRMReport } from '@/services/CRMReport';
import { getCompanyByID } from '@/services/Company';


const store = useStore()
const $toast = useToast()

const props = defineProps([
    'today',
    'clientId',
    'clientName',
    'campaigns',
    'allActiveCampaigns',
    'campaignOptions',
    'clientFeedback',
    'clientFeedbackOptions', 
    'companies',
    'contactedCompanies', 
    'notContactedCompanies', 
    'respondedCompanies', 
    'noResponseCompanies',
    'crmActions',
    'allCRMActions',
    'allCRMStatuses',
    'reportOptions',
    'defaultReport',
])

const emit = defineEmits([
    'hide-report',
    'refetch',
])


const selectedCampaigns = ref(props.campaigns ? props.campaigns : [])
const selectedClientFeedback = ref(props.clientFeedback ? props.clientFeedback : [])
const reportId = ref(props.defaultReport ? props.defaultReport.value: null)
const selectedReport = ref(props.defaultReport ? props.defaultReport : null)
const reportedCompanies = ref(props.companies ? props.companies : [])
const companyIdsToUpdate = ref([])

const progressUpdates = ref([])
const selectedReportProgressUpdates = ref([])
// const progressUpdates = ref(selectedReport.value ? selectedReport.value.report.crm_progress_updates ? selectedReport.value.report.crm_progress_updates.map(getProgressUpdateWithCompanyData) : [] : [])
// const selectedReportProgressUpdates = ref([...progressUpdates.value])
// const reportReset = ref(false)
const reloadDefaults = ref(false)
const resetReport = ref(false)

const boldAll = ref(false)
const unboldAll = ref(false)

const visibleCRMDetails = ref(false)
const visibleCompanyModal = ref(false)
const selectedCompany = ref(null)

const tabList = reactive({
    editor: {label: 'Edit', isSelected: true, isLeading: false, isFollowing: false},
    overview: {label: 'Overview', isSelected: false, isLeading: false, isFollowing: true},
    printView: {label: 'Print', isSelected: false, isLeading: false, isFollowing: false},
})

const backButtonBolded = ref(false)
const firstTabSelected = ref(true)
const lastTabSelected = ref(false)

const reportedCompanyIds = computed(() => {
    return reportedCompanies.value.map(company => company.id)
})

const maxBatchLength = ref(53)

const reportedCompaniesBatches = computed(() => {
    let companyBatches = []
    for (let i=0; i< reportedCompanies.value.length; i+=maxBatchLength.value) {
        companyBatches.push(reportedCompanies.value.slice(i,i+maxBatchLength.value));
    }
    return companyBatches
})

const numReportedCompanies = computed(() => {
    return reportedCompanyIds.value ? reportedCompanyIds.value.length : 0
})

const companyLabel = computed(() => {
    if(numReportedCompanies.value == 1) {
        return 'Target'
    }
    return 'Targets'
})

const numCampaigns = computed(() => {
    return props.campaigns ? props.campaigns.length : 0
})

const campaignLabel = computed(() => {
    if(numCampaigns.value == 1) {
        return 'Campaign'
    }
    return 'Campaigns'
})

const backButtonFill = computed(() => {
    return backButtonBolded.value ? '#6B7280' : '#25274D'
})

const numUnsavedProgressUpdates = computed(() => {
    return progressUpdates.value.filter((progressUpdate) => progressUpdate.unsaved).length
})

const numDeletedProgressUpdates = computed(() => {
    return selectedReportProgressUpdates.value.length > progressUpdates.value.length ? selectedReportProgressUpdates.value.length - progressUpdates.value.length : 0
})

watch(() => selectedClientFeedback.value, () => {
    reportedCompanies.value = props.companies.filter((company) => selectedCampaigns.value.includes(company.campaign.id)).filter((company) => selectedClientFeedback.value.length > 0 ? selectedClientFeedback.value.includes(company.client_feedback) : true)
}, {deep: true})

watch(() => selectedCampaigns.value, () => {
    reportedCompanies.value = props.companies.filter((company) => selectedCampaigns.value.includes(company.campaign.id)).filter((company) => selectedClientFeedback.value.length > 0 ? selectedClientFeedback.value.includes(company.client_feedback) : true)
}, {deep: true})

watch(() => props.companies.value, () => {
    reportedCompanies.value = props.companies.filter((company) => selectedCampaigns.value.includes(company.campaign.id)).filter((company) => selectedClientFeedback.value.length > 0 ? selectedClientFeedback.value.includes(company.client_feedback) : true)
}, {deep: true})

watch(() => reportedCompanies.value, () => {
    setProgressUpdatesFromSelectedReport()
}, {deep: true})

watch(() => props.defaultReport, () => {
    reportId.value = props.defaultReport ? props.defaultReport.value: null
    selectedReport.value = props.defaultReport ? props.defaultReport : null
}, {deep: true})

watch(() => reportId.value, () => {
    // console.log("reportId watcher")
    if(reportId.value) {
        // console.log('reportId.value')
        selectedReport.value = props.reportOptions.find(report => report.value == reportId.value)
        setProgressUpdatesFromSelectedReport()
        // progressUpdates.value = selectedReport.value ? selectedReport.value.report.crm_progress_updates ? selectedReport.value.report.crm_progress_updates.map(getProgressUpdateWithCompanyData) : [] : []
        // selectedReportProgressUpdates.value = [...progressUpdates.value]
    } else {
        selectedReport.value = null
        progressUpdates.value = []
    }
})

function hideReport() {
    emit('hide-report')
}

function refetch() {
    emit('refetch')
}

function setProgressUpdatesFromSelectedReport() {
    console.log('setProgressUpdatesFromSelectedReport')
    if(selectedReport.value) {
        if(selectedReport.value.report.crm_progress_updates) {
            progressUpdates.value = selectedReport.value.report.crm_progress_updates.map(getProgressUpdateWithCompanyData).filter((progressUpdate) => progressUpdate.company != undefined)
            selectedReportProgressUpdates.value = [...progressUpdates.value]
            return
        }
    }
    progressUpdates.value = []
    selectedReportProgressUpdates.value = []
}

function setReportId(reportValue) {
    // console.log('ReportingDashboard: setReportId')
    reportId.value = reportValue
}

function getProgressUpdateWithCompanyData(progressUpdate) {
    // console.log('getProgressUpdateWithCompanyData')
    // console.log(progressUpdate)
    // console.log(progressUpdate.company)
    // console.log(reportedCompanies.value)
    return {
        // ...progressUpdate,
        id: progressUpdate.id,
        body: progressUpdate.body,
        bold_on_report: progressUpdate.bold_on_report,
        // date: progressUpdate.date,
        company: reportedCompanies.value.find((company) => company.id == progressUpdate.company),
        unsaved: false,
    }
}

function addProgressUpdateByCompany(companyID) {
    // console.log('ReportingDashboard: addCompanyProgressUpdate', companyID)
    let companyToAdd = reportedCompanies.value.find((company) => company.id == companyID)
    console.log('company to add', companyToAdd)
    let existingProgressUpdateIndex = progressUpdates.value.findIndex((progressUpdate) => progressUpdate.company.id == companyID)
    if(existingProgressUpdateIndex == -1) {
        progressUpdates.value.push({id: Math.floor(Math.random() * -100000), body: '', bold_on_report: false, company: companyToAdd, unsaved: true})
    }
    console.log(progressUpdates.value.length)
}


function removeProgressUpdate(progressUpdateID) {
    // console.log('ReportingDashboard: removeProgressUpdate')
    let progressUpdateIndex = progressUpdates.value.findIndex((progressUpdate) => progressUpdate.id == progressUpdateID)
    if(progressUpdateIndex != -1) {
        progressUpdates.value.splice(progressUpdateIndex, 1)
    }
}

function resetFromReport() {
    // console.log('resetFromReport')
    progressUpdates.value = [...selectedReportProgressUpdates.value]
    resetReport.value = !resetReport.value
    // reportReset.value = true
}

function updateProgressUpdate(progressUpdateData) {
    // console.log('ReportingDashboard: updateProgressUpdate', progressUpdateData)
    let progressUpdateIndex = progressUpdates.value.findIndex((progressUpdate) => progressUpdate.id == progressUpdateData.id)
    if(progressUpdateIndex == -1) {
        progressUpdates.value.push(progressUpdateData)
    } else {
        progressUpdates.value.splice(progressUpdateIndex, 1, progressUpdateData)
        // if(progressUpdateData.body.length > 0) {
        //     progressUpdates.value.splice(progressUpdateIndex, 1, progressUpdateData)
        // } else {
        //     progressUpdates.value.splice(progressUpdateIndex, 1)
        // }
    }
}

function addProgressUpdateForAllCompanies() {
    // console.log('addProgressUpdateForAllCompanies')
    progressUpdates.value = [...selectedReportProgressUpdates.value]
    reportedCompanies.value.forEach((company) => {
        let existingProgressUpdateIndex = progressUpdates.value.findIndex((progressUpdate) => progressUpdate.company.id == company.id)
        if(existingProgressUpdateIndex == -1) {
            progressUpdates.value.push({id: Math.floor(Math.random() * -100000), body: '', bold_on_report: false, company: company, unsaved: true})
        }
    })
}

function removeProgressUpdateForAllCompanies() {
    // console.log('removeProgressUpdateForAllCompanies')
    progressUpdates.value = []
}

function toggleBoldAll() {
    console.log('toggleBoldAll')
    boldAll.value = !boldAll.value
}

function toggleUnboldAll() {
    console.log('toggleUnighlightAll')
    unboldAll.value = !unboldAll.value
}


function setPage(tabKey) {
    clearAllTabs()
    switch (tabKey) {
        case 'editor':
            firstTabSelected.value = true;
            tabList.overview.isFollowing = true;
            break
        case 'overview':
            tabList.editor.isLeading = true;
            tabList.printView.isFollowing = true;
            break
        case 'printView':
            tabList.overview.isLeading = true;
            lastTabSelected.value = true;
            break
    }
    tabList[tabKey].isSelected = true;
}

function clearAllTabs() {
    for(const tab of Object.values(tabList)) {
        tab.isSelected = false
        tab.isLeading = false
        tab.isFollowing = false
    }
}

function boldBackButton () {
    backButtonBolded.value = true
}

function unboldBackButton () {
    backButtonBolded.value = false
}

function updateCampaigns(campaignIds) {
    selectedCampaigns.value = campaignIds
}

function updateClientFeedback(clientFeedback) {
    selectedClientFeedback.value = clientFeedback
}

// ====
function updatedEvents() {
    closeCRMDetails()
}

async function saveAllAndOpenCRMDetails(company) {
    await saveAll()
    selectedCompany.value = company
    visibleCRMDetails.value = true
}

function closeCRMDetails() {
    // console.log('closeCRMDetails')
    visibleCRMDetails.value = false
    selectedCompany.value = null
}

async function saveAllAndGetCompanyAndOpenModal(company) {
    await saveAll()
    const token = store.state.auth.token
    const result = await getCompanyByID({token: token, companyId: company.id})
    selectedCompany.value = result.response.data
    visibleCompanyModal.value = true
}

function closeCompanyModal() {
    // console.log('closeCompanyModal')
    visibleCompanyModal.value = false
    selectedCompany.value = null
}
// ====


function resetDefaultLists() {
    // console.log('resetDefaultLists')
    companyIdsToUpdate.value = []
    resetFromReport()
    reloadDefaults.value = !reloadDefaults.value
}

function setCompanyReportedEvent(company, event, autoCompute) {
    // console.log('setCompanyReportedEvent', company, event, autoCompute)
    if(event) {
        company.computed_crm_status = getComputedCRMStatusByEventId(event.action.id)
    } else {
        company.computed_crm_status = getComputedCRMStatusByEventId(null)
    }

    company.reported_crm_event = event
    company.auto_compute_reported_crm_event = autoCompute

    const reported_event_not_default = 
    (!company.reported_crm_event && !company.default_crm_event) ? false : 
    (!company.reported_crm_event && company.default_crm_event) ? true : 
    (company.reported_crm_event && !company.default_crm_event) ? true : 
    (company.reported_crm_event && company.default_crm_event && company.reported_crm_event.id != company.default_crm_event.id) ? true : false

    const auto_computed_event_not_default = (company.auto_compute_reported_crm_event != company.default_auto_compute) ? true : false

    if(reported_event_not_default || auto_computed_event_not_default) {
        if(!companyIdsToUpdate.value.includes(company.id)) {
            companyIdsToUpdate.value.push(company.id)
        }
    } else {
        if(companyIdsToUpdate.value.includes(company.id)){
            let company_index = companyIdsToUpdate.value.findIndex((companyId) => companyId == company.id)
            companyIdsToUpdate.value.splice(company_index, 1)
        }
    }
}

function handleSaveResponsesAndRefreshCachedChanges(responses) {
    // console.log('handleSaveResponsesAndRefreshCachedChanges')
    // console.log(responses)
    let toastMessage = ''
    let toastType
    let toastDuration
    let flagError = false
    let showToast = false
    responses.forEach((response) => {
        // console.log(response)
        if(response.data) {
            showToast = true
            if(response.data.status != 200 && response.data.status != 201) {
                toastMessage += response.messages.error
                flagError = true
            } else {
                toastMessage += response.messages.success
            }
        }

    })
    if(showToast) {
        if(flagError) {
            toastType = 'error'
            toastDuration = 20000
        } else {
            toastType = 'success'
            toastDuration = 5000
            refetch()
            resetDefaultLists()
        }
        $toast.open({
            message: toastMessage,
            type: toastType,
            duration: toastDuration
        })
    }

}

async function saveAll(reportName=null, reportStatus='draft') {
    // console.log('saveAll')
    const responses = [
        {
            data: await saveTargetList(),
            messages: {
                error: 'Could not save target list. ',
                success: 'Saved target list. '
            }
        },
        {
            data: await saveReport(reportName, reportStatus),
            messages: {
                error: 'Could not save progress updates. ',
                success: 'Saved progress updates. '
            }
        }
    ]
    handleSaveResponsesAndRefreshCachedChanges(responses)
}


async function saveReport(reportName, reportStatus) {
    // console.log('saveReport')
    if(numUnsavedProgressUpdates.value > 0 || numDeletedProgressUpdates.value > 0) {
        const token = store.state.auth.token
        const reportData = JSON.stringify({
            report_name: reportName,
            report_date: props.today,
            report_type: "outreach",
            status: reportStatus,
            client_id: props.clientId,
            progress_updates: progressUpdates.value.map((progressUpdate) => {
                return {
                    id: progressUpdate.id,
                    company_id: progressUpdate.company.id,
                    body: progressUpdate.body,
                    bold_on_report: progressUpdate.bold_on_report,
                }
            })
        })
        console.log(reportData)
        return await createCRMReport(token, reportData)
    }
}

async function saveTargetList() {
    // console.log('Reporting Dashboard: Save Target List', companyIdsToUpdate.value.length)
    if(companyIdsToUpdate.value.length > 0) {
        let companyEventData = reportedCompanies.value.filter((company) => companyIdsToUpdate.value.includes(company.id)).map((company) => {
            return {
                company_id: company.id,
                reported_crm_event_id: company.reported_crm_event ? company.reported_crm_event.id : null,
                auto_compute_reported_crm_event: company.auto_compute_reported_crm_event
            }
        })
        let companyProgressUpdateData = []
        const token = store.state.auth.token
        return await bulkSaveCRMTargetList(token, companyEventData, companyProgressUpdateData)
    } 
}

setProgressUpdatesFromSelectedReport()
</script>

<style scoped>
/* General print styles here  */
.modal {
  position: fixed;
  z-index: 999;
  top: 0%;
  left: 0%;
  width: 100%;
  /* margin-left: -150px; */
}

::-webkit-scrollbar {
    height: 0px;
    width: 0px;      
}

#printContainer {
  table {
    width: 100%;
    border-collapse: collapse;
    break-inside: auto;
    page-break-inside: auto;
  }
  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  table td,
  table th {
    padding: 1.5mm;
    border: 2px solid #ccc;
    border: 2px solid #ccc;
    vertical-align: top;
    font-size: inherit;
  }
  table td.invoice-header {
    border: none;
  }

  table th {
    text-align: left;
    vertical-align: bottom;
    color: rgb(0, 0, 30);
    background-color: #04aa6d;
  }

  tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  tr:hover {
    background-color: #ddd;
  }

  thead {
    display: table-header-group;
  }
  tfoot {
    display: table-footer-group;
  }
  tr {
    page-break-inside: avoid;
    page-break-after: auto;
  }
  table td,
  table th,
  table tr {
    /* Prevent elements from being split across pages in paginated media (like print) */
    break-inside: avoid;
    /* Automatically insert a page break after the element, if needed */
    break-after: auto;
  }
}
/* Apply styles only when the document is being printed */
@media print {
  /* Apply styles to the printed page */
  @page {
    size: auto;
    /* Set the page margins, hide default header and footer */
    margin: 1in 1in 1in 1in !important;
  }

  .page-break {
    .page-break-after: always;
  }

  body {
    /* Ensure that colors are printed exactly as they appear on the screen */
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
  }
}
</style>