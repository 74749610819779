<template>
    <div>
        <span>
            <base-pill>ZI:</base-pill> 
            <a class="break-all text-accent-primary hover:text-dark-primary hover:underline hover:cursor-pointer" target="_blank" v-if="ziUrl"
                :href="httpCompanyUrl">{{ ziUrl }}</a>
            <span v-else>--</span>
        </span>
    </div>
</template>

<script>
    export default {
        name: "website-url-cell"
    }
</script>

<script setup>
import { computed, defineProps } from "vue";


    const props = defineProps({
        company: Object
    })

    const ziUrl = computed(() => {
        if(props.company.zoom_info_company){
            return props.company.zoom_info_company.website
        } else {
            return "--"
        }
    })


    const httpCompanyUrl = computed(() => {
        if(props.company.zoom_info_company && props.company.zoom_info_company.website){
            if(props.company.zoom_info_company.website.startsWith('http://') || props.company.zoom_info_company.website.startsWith('https://')){
                return props.company.zoom_info_company.website
            } else {
                return `http://${props.company.zoom_info_company.website}`
            }
        } else {
            return "#"
        }
            })
</script>