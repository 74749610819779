<template>
    <div class="w-full flex flex-col h-[2100px] break-after-page p-24 bg-white">
        <div class="w-full flex h-14 px-2 items-center justify-between border-b border-gray-300 text-lg font-semibold tracking-widest whitespace-nowrap text-gray-800">
            <div class='w-full flex space-x-2 justify-start'>
                <div>{{ clientName }}: Progress Updates</div>
                <div class="text-base 2xl:flex items-center justify-center tracking-wider whitespace-nowrap text-gray-600 pt-0.5 pb-6">
                    {{ numReportedCompanies }} {{ companyLabel }} in {{ numCampaigns }} {{ campaignLabel }}
                </div>
            </div>
            <div class="w-[200px] flex justify-end">
                <SheddCapitalSVG 
                    fill-color="#1F2937"
                />
            </div>
        </div>
        <div class="flex flex-col items-start justify-start h-[1800px]">
            <CRMProgressUpdatePageSection
                v-for="(section, index) in pageSections"
                :key="index"
                :section="section"
            />
        </div>

        <!-- <div class="w-full grid grid-cols-3 gap-4 pt-4 overflow-clip h-[1800px]">
           <CRMProgressUpdateColumn
                v-for="(progressUpdateColumn, index) in progressUpdateColumns"
                :key="index"
                :progress-update-column="progressUpdateColumn"
           />
        </div> -->
    </div>
</template>

<script setup>
import { defineProps, computed } from'vue';
import CRMProgressUpdatePageSection from './CRMProgressUpdatePageSection.vue';
import CRMProgressUpdateColumn from './CRMProgressUpdateColumn.vue';
import SheddCapitalSVG from '@/components/svg-components/SheddCapitalSVG.vue';

const props = defineProps([
    'clientName',
    'numReportedCompanies',
    'companyLabel',
    'numCampaigns',
    'campaignLabel',
    'progressUpdatePage'
])

const pageSections = computed(() => {
    return props.progressUpdatePage ? props.progressUpdatePage.sections : []
})

</script>