<template>
    <CustomTable
        :module-name="moduleName"
        :module-name-singular="moduleNameSingular"
        :tableHeaders="tableHeaders"
        :filters="dataImportFilters"
        :bulkOperations="dataImportBulkOperations"
        :menuOptions="menuOptions"
        :defaultOrdering="defaultOrdering"
        createModalSize="w-1/3 h-1/2"
        @select-tab="selectViewType"
    >
    <template #bulk-operations="{ events: { hideBulkActionMenu, hideBulkOperations, clearSelectAllClearSelectedResults, refetchResults }, bulkAction, fetchFilter, selectedResults }">
        <DataImportBulkOperations
            :bulkAction="bulkAction"
            :selectedResults="selectedResults"
            @cancel-bulk-operation="hideBulkActionMenu"
            @close-bulk-operation="hideBulkOperations"
            @clear-selected-results="clearSelectAllClearSelectedResults"
            @refetch-results="refetchResults(fetchFilter)"
        />
    </template>
    <template #create-form="{ events: { closeCreateForm, refetchResults }, fetchFilter }">
        <CreateDataImport
            :data-source-options="dataSourceOptions"
            :client-options="clientOptions"
            @refetch-results="refetchResults(fetchFilter)"
            @close="closeCreateForm"
        />
    </template>
    <template v-if="viewType=='imports'" #table-row="{ events: { addSelectedResults, removeSelectedResults, refetchResults, openRowDetails }, selectAll, fetchFilter, userPermissions }">
        <DataImportRow
            v-for="dataImport in dataImports"
            :key="dataImport.id"
            :view-type="viewType"
            :dataImport="dataImport"
            :selectAll="selectAll"
            :userPermissions="userPermissions"
            @refetch-results="refetchResults(fetchFilter)"
            @select-row="addSelectedResults"
            @deselect-row="removeSelectedResults"
            @open-row-details="openRowDetails"
        />
    </template>
    <template v-else-if="viewType=='companies'" #table-row="{ events: { addSelectedResult, removeSelectedResult, refetchResults, openRowDetails }, selectAll, fetchFilter, userPermissions }">
        <DataImportCompanyRow
            v-for="companyImportObject in dataImportCompanies" 
            :key="companyImportObject.company.id" 
            :company="companyImportObject.company" 
            :data-import="companyImportObject.dataImport"
            :selectAll="selectAll"
            :userPermissions="userPermissions"
            @refetch-results="refetchResults(fetchFilter)"
            @select-row="addSelectedResult"
            @deselect-row="removeSelectedResult"
            @open-row-details="openRowDetails"
        />
    </template>
    <template #table-footer="{ viewingFromNbr, viewingToNbr, count, selectedResults }">
        <DataImportFooter
            :view-type="viewType"
            :viewingFromNbr="viewingFromNbr"
            :viewingToNbr="viewingToNbr"
            :count="count"
            :selectedResults="selectedResults"
        />
    </template>
    </CustomTable>
</template>

<script setup>
import { defineProps, defineEmits, ref, computed, reactive } from 'vue';
import TableTemplate from '@/components/base-components/TableTemplate.vue';
import TableHeaderSticky from '@/components/base-components/TableHeaderSticky.vue';
import DataImportRow from './DataImportRow.vue';
import DataImportCompanyRow from './DataImportCompanyRow.vue';
import DataImportBulkOperations from './DataImportBulkOperations.vue';
import CreateDataImport from './CreateDataImport.vue';
import DataImportFooter from './DataImportFooter.vue';
import SelectAll from '../base-components/SelectAll.vue';
import ChevronUpMiniSVG from '@/components/svg-components/ChevronUpMiniSVG.vue';
import ChevronDownMiniSVG from '@/components/svg-components/ChevronDownMiniSVG.vue';
import CustomTable from '../base-components/CustomTable.vue';
import { getCollectionTags } from '@/services/CollectionTag';
import { useStore } from 'vuex';


const store = useStore()
const props = defineProps(['dataImports'])

const moduleName = ref('Data Imports')
const moduleNameSingular = ref('Data Import');
const expandAll = ref('neutral')
const viewType = ref('imports')

const defaultOrdering = ref(['-import_date'])

const dataImportCompanies = computed(() => {
    if(props.dataImports) {
        let companies = []
        props.dataImports.forEach((dataImport) => {
            if(dataImport.companies && dataImport.companies.length > 0) {
                companies = companies.concat(dataImport.companies.map((company) => {return {company: company, dataImport: dataImport}}))
            } 
        })
        return companies
    } return []
})

// const dataImports = ref(props.dataRequest ? props.dataRequest.data_imports.length > 0 ? props.dataRequest.data_imports : [] : [])

// const dataImports = computed(() => {
//     return props.dataRequest && props.dataRequest.data_imports.length > 0 ? props.dataRequest.data_imports : []
// })

const dataImportFilters = reactive({

})

const dataImportBulkOperations = reactive({
    assignToCollection: {
        label: 'Assign to Collection',
        modelPath: 'companies',
        endpoint: 'bulk_assign_collection_tag',
        message: '',
        inputs: {
            collectionTag: {
                label: "Choose Collection Tag:",
                value: [],
                defaultValue: [],
                options: [
                    {value: "clear", label: "------------"}
                ],
                widget: 'datalist',
            }
        }
    }
})

const menuOptions = reactive({
    quickSearch: true,
    selectAll: false,
    selectAllHeader: true,
    paginationArrows: false,
    totalResults: false,
    pageSize: false,
    refreshButton: false,
    filters: false,
    bulkOperations: {
        type: 'panel',
    },
    createForm: false,
    addFormRows: false,
    tabs: {
        imports: {
            label: 'Data Imports', isSelected: true
        },
        companies: {
            label: 'Imported Companies', isSelected: false
        },
    },
    tabChangeClearsSelected: true,
})

const tableHeaders = computed(() => {
    if(viewType.value == 'imports') {
        return importHeaders.value
    } else if(viewType.value == 'companies') {
        return companyHeaders.value
    } else {
        return []
    }
})

const importHeaders = ref([
    {
        value: 'import_date',
        label: 'Import Date',
        class: 'p-0 w-[15%] bg-[#F3F4F6] h-7',
        orderFieldName: "import_date"
    },
    {
        value: 'import_name',
        label: 'Import Name',
        class: 'p-0 w-[20%] bg-[#F3F4F6] h-7',
        orderFieldName: "import_name"
    },
    {
        value: 'import_type',
        label: 'Type',
        class: 'p-0 w-[15%] bg-[#F3F4F6] h-7',
    },
    {
        value: 'import_url',
        label: 'URL',
        class: 'p-0 w-[25%] bg-[#F3F4F6] h-7',
    },
    {
        value: 'import_script',
        label: 'Script',
        class: 'p-0 w-[15%] bg-[#F3F4F6] h-7',
    },
    {
        value: 'import_pattern',
        label: 'Pattern',
        class: 'p-0 w-[10%] bg-[#F3F4F6] h-7',
    },
    {
        value: 'number_imported_rows',
        label: '∆ Companies',
        class: 'p-0 w-[10%] bg-[#F3F4F6] h-7',
    },
])

const companyHeaders = ref([
    {
        value: 'import_date',
        label: 'Import Date',
        class: 'p-0 w-[15%] bg-[#F3F4F6] h-7',
        orderFieldName: "import_date"
    },
    {
        value: 'id',
        label: 'ID',
        class: 'p-0 w-[10%] bg-[#F3F4F6] h-7',
        orderFieldName: "id"
    },
    {
        value: 'company_name',
        label: 'Company Name',
        class: 'p-0 w-[20%] bg-[#F3F4F6] h-7',
        orderFieldName: "company_name"
    },
    {
        value: 'tier_object',
        label: 'Tier',
        class: 'p-0 w-[10%] bg-[#F3F4F6] h-7',
    },
    {
        value: 'website_url',
        label: 'Website URL',
        class: 'p-0 w-[20%] bg-[#F3F4F6] h-7',
    },
    {
        value: 'location',
        label: 'Location',
        class: 'p-0 w-[15%] bg-[#F3F4F6] h-7',
    },
    {
        value: 'revenue',
        label: 'Revenue',
        class: 'p-0 w-[10%] bg-[#F3F4F6] h-7',
    },
])

function setExpandAll(expandAllValue) {
    expandAll.value = expandAllValue
}

function selectViewType(viewTypeValue) {
    viewType.value = viewTypeValue
}

async function setCollectionTagOptions() {
    const token = store.getters['auth/showToken']
    const result = await getCollectionTags(token)
    dataImportBulkOperations.assignToCollection.inputs.collectionTag.options = [{value: "clear", label: "------------"}, ...result.map((collectionTag) => {
        return {value: collectionTag.collection_tag_name, label: collectionTag.collection_tag_name }
    })]
}

setCollectionTagOptions()
</script> 