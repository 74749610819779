<template>
    <div class="flex flex-col h-full w-full items-center">
      <div class="bg-dark-secondary border border-b-2 border-dark-primary text-white font-semibold tracking-wider w-full py-1.5 px-2 shadow flex justify-center items-center whitespace-nowrap rounded-t-sm">{{ title }} ({{ progressUpdates.length }})</div>
        <!-- <div class="bg-dark-secondary cursor-grab active:cursor-grabbing border border-b-2 border-dark-primary text-white font-semibold tracking-wider w-full py-1.5 px-2 shadow flex justify-center items-center whitespace-nowrap rounded-t-sm">{{ title }} ({{ progressUpdates.length }})</div> -->
      <div class="bg-gray-200 border border-gray-300 rounded-b-sm w-full h-full overflow-y-scroll">
            <CRMProgressUpdateRow 
              v-for="(progressUpdate, index) in progressUpdates"
              :key="progressUpdate.id"
              :index="index"
              :progressUpdate="progressUpdate"
              :reload-defaults="reloadDefaults"
              :reset-report="resetReport"
              :bold-all="boldAll"
              :unbold-all="unboldAll"
              @update-progress-update="updateProgressUpdate"
              @remove-progress-update="removeProgressUpdate"
            />
      </div>
    </div>
</template>

<script setup>
import { defineProps, defineEmits, computed, watch } from 'vue';
import CRMProgressUpdateRow from './CRMProgressUpdateRow.vue';

const props = defineProps(['title', 'progressUpdates', 'statusGroupName', 'reloadDefaults', 'resetReport', 'boldAll', 'unboldAll'])
const emit = defineEmits(['update-progress-update', 'remove-progress-update'])

watch(() => props.progressUpdates, () => {
  console.log('Status Group, progressUpdates changed', props.statusGroupName, props.progressUpdates.length)
}, {deep: true})

function updateProgressUpdate(progressUpdateData) {
  emit('update-progress-update', progressUpdateData)
}

function removeProgressUpdate(progressUpdateID) {
  emit('remove-progress-update', progressUpdateID)
}

</script>