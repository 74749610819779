<template>
    <transition
        enter-active-class="transition duration-100 ease-out"
        enter-from-class="transform scale-95 opacity-0"
        enter-to-class="transform scale-100 opacity-100"
        leave-active-class="transition duration-75 ease-in"
        leave-from-class="transform scale-100 opacity-100"
        leave-to-class="transform scale-95 opacity-0"
    >
        <div ref="popoverBody" class="fixed border border-solid z-50 border-gray-300 bg-white rounded-md" :style="popoverPosition">
            <div class="text-xs px-2 py-1 ">
                <slot></slot>
            </div>
        </div>
    </transition>
</template>
<script>
export default {
    name: "BasePopoverClick"
}
</script>
<script setup>
import { defineProps, defineEmits, computed, ref } from "vue";

const props = defineProps({
    xPosition: Number,
    yPosition: Number,
})

const emit = defineEmits(['close-popover'])

const popoverBody = ref(null)

// useClickOutside(popoverBody, () => {
//   emit('close-popover')
// })

// useRightClickOutside(popoverBody, () => {
//   emit('close-popover')
// })

const popoverPosition = computed(function () {
    return `left: ${props.xPosition-6}px; top: ${props.yPosition+5}px;`
})


</script>