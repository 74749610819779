export default {
    setShowFilterPaneFalse(context) {
        context.commit('setShowFilterPaneFalse')
    },
    setShowFilterPaneTrue(context) {
        context.commit('setShowFilterPaneTrue')
    },
    setHasActiveResearchSessionTrue(context){
        context.commit('setHasActiveResearchSessionTrue')
    },
    setHasActiveResearchSessionFalse(context){
        context.commit('setHasActiveResearchSessionFalse')
    }
}