<template>
    <div class="w-full flex flex-col h-full pr-3 text-xs">
        <div class="w-full grid grid-cols-2 space-x-2 py-4">
            <section id="requestSection" class="flex flex-col w-full space-y-3">
                <div class="flex flex-col space-y-1 pb-2">
                    <FormLabel>
                        Data Source:
                    </FormLabel>
                    <FormField>
                        {{ dataSourceName }} - {{ dataSourceType }}
                    </FormField>
                </div>
                <div class="flex flex-col space-y-1 pb-2 w-full">
                        <FormLabel>
                            Request:
                        </FormLabel>
                        <FormField>
                            {{ requestBody }}
                        </FormField>
                    </div>
                <div class="flex w-full space-x-2">
                    <div class="flex flex-col space-y-1 pb-2 w-full">
                        <FormLabel>
                            Requested By:
                        </FormLabel>
                        <FormField>
                            {{ requestedBy }}
                        </FormField>
                    </div>
                    <div class="flex flex-col space-y-1 pb-2 w-full">
                        <FormLabel>
                            Priority:
                        </FormLabel>
                        <FormField>
                            {{ priority }}
                        </FormField>
                    </div>
                    <div class="flex flex-col space-y-1 pb-2 w-full">
                        <FormLabel>
                            Due By:
                        </FormLabel>
                        <FormField>
                            {{ dueBy }}
                        </FormField>
                    </div>
                    <div class="flex flex-col space-y-1 pb-2 w-full">
                        <FormLabel>
                            Client:
                        </FormLabel>
                        <FormField>
                            {{ clientName }}
                        </FormField>
                    </div>
                </div>
            </section>
            <section id="devSection" class="flex flex-col w-full space-y-3">
                <div class="grid grid-cols-2 w-full">
                    <div class="flex flex-col space-y-1 pb-2 w-full pr-1">
                        <FormLabel>
                            Request Name:
                        </FormLabel>
                        <FormField>
                            {{ requestName }}
                        </FormField>
                    </div>
                    <div class="flex flex-col space-y-1 pb-2 w-full pl-1">
                        <FormLabel>
                            Event Date:
                        </FormLabel>
                        <FormField>
                            {{ eventDate }}
                        </FormField>
                    </div>
                </div>
                <div class="flex flex-col space-y-1 pb-2">
                    <FormLabel>
                        Source URL:
                    </FormLabel>
                    <FormField>
                        {{ sourceURL }}
                    </FormField>
                </div>
                <div class="flex flex-col space-y-1 pb-2">
                    <FormLabel>
                        Import Schedule:
                    </FormLabel>
                    <FormField>
                        {{ importSchedule }}
                    </FormField>
                </div>
            </section>
        </div>
        <div class="w-full h-full">
            <DataImports :data-imports="dataImports" />
        </div>
    </div>
</template>

<script setup>
import { defineProps, defineEmits, ref, computed } from 'vue';
import FormLabel from '../base-components/FormLabel.vue';
import FormField from '../base-components/FormField.vue';
import { formatDate } from '@/composables/formatDate';
import DataImports from '../data-imports/DataImports.vue';

const props = defineProps(['dataRequest'])

const dataImports = computed(() => {
    return props.dataRequest && props.dataRequest.data_imports ? props.dataRequest.data_imports : [];
})

const requestName = computed(() => {
    return props.dataRequest && props.dataRequest.request_name ? props.dataRequest.request_name : '--'
})

const requestBody = computed(() => {
    return props.dataRequest && props.dataRequest.request_body ? props.dataRequest.request_body : '--'
})

const requestedBy = computed(() => {
    return props.dataRequest && props.dataRequest.requested_by ? `${props.dataRequest.requested_by.first_name} ${props.dataRequest.requested_by.last_name}`: '--'
})

const clientName = computed(() => {
    return props.dataRequest && props.dataRequest.client ? props.dataRequest.client.client_name : '--'
})

const dueBy = computed(() => {
    return  props.dataRequest && props.dataRequest.due_by ? formatDate(props.dataRequest.due_by) : '--'   
})

const dataSourceName = computed(() => {
    return  props.dataRequest && props.dataRequest.data_source ? props.dataRequest.data_source.source_name : '--'
})

const dataSourceType = computed(() => {
    return  props.dataRequest && props.dataRequest.data_source ? props.dataRequest.data_source.source_type : '--'
})

const sourceURL = computed(() => {
    return  props.dataRequest && props.dataRequest.source_url ? props.dataRequest.source_url : '--'
})

const priority = computed(() => {
    return  props.dataRequest && props.dataRequest.priority ? props.dataRequest.priority : '--'
})

const eventDate = computed(() => {
    return  props.dataRequest && props.dataRequest.date_of_event ? props.dataRequest.date_of_event : '--'
})

const importSchedule = computed(() => {
    return  props.dataRequest && props.dataRequest.import_schedule ? props.dataRequest.import_schedule : '--'
})

const requestedByInfo = computed(() => {
    if(props.dataRequest) {
        return `(Requested by ${props.dataRequest.requested_by.first_name} ${props.dataRequest.requested_by.last_name} on ${formatDate(props.dataRequest.created_at)})`
    }
    return null
})


</script>