<template>
    <div class="w-full h-full p-1">
        <div v-if="savingChanges" class="fixed top-0 bottom-0 left-0 right-0 bg-black/[0.7] flex flex-col justify-center items-center z-50"
                    tabindex="-1" >
            <div class="flex flex-col justify-center items-center p-4 rounded-md">
            <svg aria-hidden="true" class="mx-1 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600"
                viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="#d3d3d3"/>
                    <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="#2E9CCA"/>
            </svg>
            <div class="text-white p-4 whitespace-nowrap text-sm">
                Please wait while we save your changes.
            </div>
            </div>
        </div>
        <div class="flex items-center justify-between p-1">
            <div class="w-5/6 sticky top-0">
                <TableMenu
                        :pageFilter="pageSizeFilter"
                        :page="page"
                        :numPages="totalPages"
                        :isLoading="isLoading"
                        :filters="companyFilters"
                        :menuOptions="menuOptions"
                        :bulkOperations="bulkOperations"
                        :viewingFromNbr="viewingFromNbr"
                        :viewingToNbr="viewingToNbr"
                        :default-page-size="defaultPageSize"
                        :count="calculatedCount"
                        @refetch-results="loadCompanies(fetchFilter)"
                        @get-next="getNext"
                        @go-to-first="goToFirst"
                        @get-prev="getPrev"
                        @go-to-last="goToLast"
                        @jump-to-page="jumpToPage"
                        @set-page-size-filter="setPageSizeFilter"
                        @clear-filter="clearFilter"
                        @change-filters="setFilters"
                        @set-filter-options="setFilterOptions"
                    >
                </TableMenu>
            </div>
            <div class="flex items-center">
                <div v-if="hasActiveResearchSession" class="flex items-center whitespace-nowrap mx-1 text-sm">
                    Session Active <span class="ml-1 h-4 w-4 animate-pulse rounded-full bg-red-500 "></span>
                </div>
                <div ref="helpText"> 
                    <HelpSVG @mouseover="showHelpText" @mouseleave="hideHelpText" class="hover:cursor-help m-1" stroke="#4B5563"/>
                    <base-popover-click
                        v-if="visibleHelpPopover"
                        :xPosition="xPosition"
                        :yPosition="yPosition">
                        <div class="grid grid-cols-1 tracking-normal font-normal relative z-50 pb-4">
                            <div class="flex border-b  tracking-wider font-semibold border-gray-200 p-1">
                                <div>
                                    How to Use This Page
                                </div>
                            </div>
                            <div>
                                <p>This page is designed to quickly "vet" company urls. When a url is vetted, we treat that as the definitive url for the company. Companies with vetted urls are put into the de-duplification process, and are elligible for web scraping.</p>
                            </div>
                            <div>
                                <p>The panel on the left of this page has some company information that is helpful in determining if a website is correct. The panel on the right is a browsable version of the company's website. Some websites cannot be displayed within the right panel because of certain serurity measures; this doesn't mean they're wrong, but in that case we recommend clicking on the url to open the website in a new tab.
                                    If the website seems to be correct, click the "Mark Vetted" button. If it doesn't appear to be correct, click the "Clear URL" to remove that url from the company. <br> After clicking either button the company will disappear from view, but your changes won't be saved until you press the "Save Changes" button in the top right of the screen.
                                    If you aren't sure about a specific website and want to come back to it later, you can navigate by clicking the "Prev" and "Next" buttons at the bottom of the panel on the left.
                                </p>
                            </div>
                            <!-- <div class="grid grid-cols-2 place-content-center border-b border-gray-200 p-1">
                                <div class="h-full flex items-center">Shift + G</div>
                                <div class="">
                                    Open Google search with Company Name + Location
                                </div>
                            </div> -->
                        </div>
                    </base-popover-click>
                </div>
                <div class="flex items-center">
                            <div class="text-xs mx-1 flex items-center whitespace-nowrap" v-if="autoSaveTimeRemaining > 0 && changeCount > 0">
                            Autosave in: {{ timeLeft }}
                            </div>
                            <div class="text-sm flex items-center mx-1 whitespace-nowrap">
                                Changes: &nbsp; <transition name="slide-fade" mode="out-in">
                                                    <span :key="changeCount">
                                                        {{ changeCount }}
                                                    </span>
                                                    </transition>
                            </div>
                            <base-button v-if="changeCount > 0" @click="undoChange" class="text-xl h-fit mx-1">⎌</base-button>
                            <button v-else class="rounded-md px-1 py-1 max-w-32 my-1 border-solid h-fit mx-1 border bg-gray-500 text-xl border-gray-500 hover:cursor-not-allowed active:bg-gray-500 focus:bg-gray-500">⎌</button>
                            <base-button-affirmative class="h-full" :disabled="true" v-if="changeCount == 0">Save Changes</base-button-affirmative>
                            <base-button-affirmative class="h-full" v-else @click="saveChanges">Save Changes</base-button-affirmative>
                        </div>
            </div>
        </div>
        <div class="flex flex-col w-full h-[85vh] overflow-auto">
            <div class="w-full">
                <div v-if="companyToVet" class="grid grid-cols-4 gap-2 m-2">
                    <div class="border border-dark-primary bg-neutral-50 shadow-sm p-2">
                        <div class="flex flex-col h-[80vh] w-full">
                            <div class="flex flex-col text-base w-full my-1">
                                <div class="w-full border-b border-b-gray-500 text-gray-500 tracking-widest">
                                    Company
                                </div>
                                <div>
                                    <a :href="googleUrlLink" target="_blank">{{ companyToVet.company_name }}</a>
                                </div>
                            </div>
                            <div class="flex flex-col text-base w-full my-1">
                                <div class="w-full border-b border-b-gray-500 text-gray-500 tracking-widest">
                                    URL
                                </div>
                                <div>
                                   <a :href="companyWebsite" target="_blank" class="text-blue-500 underline">{{ companyToVet.website_url }}</a> 
                                </div>
                            </div>
                            <div class="flex flex-col text-base w-full my-1">
                                <div class="w-full border-b border-b-gray-500 text-gray-500 tracking-widest">
                                    Address
                                </div>
                                <div>
                                    {{ companyToVet.address }}
                                </div>
                            </div>
                            <div class="flex flex-col text-base w-full my-1">
                                <div class="w-full border-b border-b-gray-500 text-gray-500 tracking-widest">
                                    City
                                </div>
                                <div>
                                    {{ companyToVet.city }}
                                </div>
                            </div>
                            <div class="flex flex-col text-base w-full my-1">
                                <div class="w-full border-b border-b-gray-500 text-gray-500 tracking-widest">
                                    State
                                </div>
                                <div>
                                    {{ companyToVet.state }}
                                </div>
                            </div>
                            <div class="flex flex-col text-base w-full my-1">
                                <div class="w-full border-b border-b-gray-500 text-gray-500 tracking-widest">
                                    Country
                                </div>
                                <div>
                                    {{ companyToVet.country }}
                                </div>
                            </div>
                            <div class="flex flex-col items-center w-full h-1/2 justify-center">
                                <button class="my-4 rounded-md h-1/2 w-1/2  p-1 bg-accent-primary text-white border border-accent-primary  hover:shadow-md active:text-white active:bg-accent-secondary focus:text-white focus:bg-accent-secondary text-base hover:cursor-pointer" @click="markVetted(companyToVet.id)">Mark Vetted</button>
                                <button class="my-4 rounded-md h-1/2 w-1/2
        p-1
        hover:shadow-md
        bg-dark-secondary
        text-white
        border
        border-dark-secondary
        active:bg-dark-primary
        active:text-white
        focus:bg-dark-primary
        focus:text-white
        text-base
        hover:cursor-pointer" @click="clearUrl(companyToVet.id)">Clear URL</button>
                            </div>
                        </div>
                    </div>
                    <div class="border col-span-3 border-dark-primary bg-neutral-50 shadow-sm p-2">
                        <div class="h-[80vh]">
                            <iframe
                                :id="companyToVet.id + 'iframe'"
                                :title="companyToVet.id + ' Iframe'"
                                class="w-full h-full"
                                :src="companyWebsite">
                            </iframe>
                        </div>
                    </div>
                    <div class="w-full flex p-1 justify-between">
                        <base-button @click="previousCompany">Prev</base-button>
                        <base-button @click="nextCompany">Next</base-button>
                    </div>
                </div>
                <div>
                    
                </div>
            </div>
        </div>
    </div>

</template>

<script setup>
import { ref, computed, watch, reactive } from 'vue'
import { useStore } from 'vuex';
import { getCompaniesForManualUrlVetting, saveCompaniesForManualUrlVetting } from '@/services/Company';
import HelpSVG from '../svg-components/HelpSVG.vue';
import { useToast } from 'vue-toast-notification';
import TableMenu from '../base-components/TableMenu.vue';
import { getActiveCampaigns } from '@/services/Campaign';
import { getSources } from '@/services/Source';
import { getSession } from '@/services/ResearchSession';
import { useRoute } from 'vue-router';

const store = useStore()
const isLoading = ref(false)
const $toast = useToast()
const companies = ref([])
const next = ref(null)
const prev = ref(null)
const count = ref(null)
const page = ref(1)
const pageSize = ref(null)
const defaultPageSize = ref(50)
const companiesToUpdate = ref([])
const changeList = ref([])
const totalPages = ref(0)
const savingChanges = ref(false)
const xPosition = ref(0)
const yPosition = ref(0)
const helpText = ref(null)
const visibleHelpPopover = ref(false)
const autoSaveWaitTime = ref(300000)
const autoSaveTimeRemaining = ref(0)
const displayIndex = ref(0)


const calculatedPageSize = computed(() => {
    return pageSize.value - companiesToUpdate.value.length
})

const route = useRoute()

const researchPage = computed(() => {
    if(route.name === 'vet-urls'){
        return 'vet_urls'
    } else if(route.name === 'missing-url-or-zoom-info-companies'){
        return 'missing_url_or_zoom_info_companies'
    } else {
        return null
    }
})
watch(researchPage, () => {
    loadOpenSession()
})

async function loadOpenSession(){
    if(researchPage.value){
        const token = store.state.auth.token
        const result = await getSession(token, researchPage.value)
        if(result.data.session){
            store.dispatch('research/setHasActiveResearchSessionTrue');
        }
    } else {
        store.dispatch('research/setHasActiveResearchSessionFalse');
    }
}

loadOpenSession()

const hasActiveResearchSession = computed(() => {
    return store.state.research.hasActiveResearchSession
})

const calculatedCount = computed(() => {
    return count.value - companiesToUpdate.value.length
})

async function saveChanges(){
    if(changeCount.value > 0){
        const token = store.state.auth.token
        savingChanges.value = true
        console.log(companiesToUpdate.value)
        const data = {
            companies_to_update: companiesToUpdate.value
        }
        const result = await saveCompaniesForManualUrlVetting(token, data)
        if(result.status == 200){
            $toast.open({
                message: result.data.message,
                type: 'success',
                duration: 5000
            })
            companiesToUpdate.value = []
            changeList.value = []
            loadCompanies()
        } else {
            $toast.open({
                message: 'Error, could not save companies',
                type: 'error',
                duration: 5000
            })
        }
        savingChanges.value = false
    }
}

const timeLeft = computed(() => {
    if(autoSaveTimeRemaining.value > 60000){
        return Math.ceil(autoSaveTimeRemaining.value / 60000) + ' min.'
    } else {
        return autoSaveTimeRemaining.value / 1000 + ' sec.'
    }
})

const menuOptions = reactive({
    selectAll: false,
    paginationArrows: true,
    totalResults: true,
    pageSize: false,
    refreshButton: true,
    filters: true,
    bulkOperations: false,
    addFormRows: false,
})

const bulkOperations = reactive({})

const timeoutId = ref(null)
const countDownId = ref(null)
watch(autoSaveTimeRemaining, () => {
    if(autoSaveTimeRemaining.value == 0 && countDownId.value){
        clearInterval(countDownId.value)
    }
})

const changeCount = computed(() => {
    return companiesToUpdate.value.length
})

function markVetted(companyId){
    const userId = store.state.auth.user.id
    const company = companies.value.find((company) => company.id === companyId)
        changeList.value.push({companyId: company.id, field: "url_vetted_by", oldValue: company.url_vetted_by, newValue: {id: userId}})
        company.url_vetted_by = {
            id: userId
        }
        addOrEditCompanyToUpdate(company)
}

function clearUrl(companyId){
    const userId = store.state.auth.user.id
    const company = companies.value.find((company) => company.id === companyId)
        changeList.value.push({companyId: company.id, field: "website_url", oldValue: company.website_url, newValue: null})
        company.website_url = null
        addOrEditCompanyToUpdate(company)
}

watch(changeCount, () => {
    if(changeCount.value && changeCount.value > 0){
        if(timeoutId.value){
            clearTimeout(timeoutId.value)
            clearInterval(countDownId.value)
        }
        window.onbeforeunload = function(e) {
          e.returnValue = "Are you sure you want to leave? You have unsaved changes."
          return "Are you sure you want to leave? You have unsaved changes."
        };
        autoSaveTimeRemaining.value = autoSaveWaitTime.value
        timeoutId.value = setTimeout(() => {
            saveChanges()
            timeoutId.value = null
        }, autoSaveWaitTime.value)
        countDownId.value = setInterval(() => {
            autoSaveTimeRemaining.value -= 1000
        }, 1000)
    } else {
        window.onbeforeunload = null
        if(changeCount.value == 0){
            // console.log('clear the interval')
            clearInterval(countDownId.value)
        }
    }
})

function nextCompany(){
    if(displayIndex.value + 1 < companies.value.length){
        displayIndex.value++
    }
}

function previousCompany(){
    if(displayIndex.value - 1 >= 0){
        displayIndex.value--
    }
}

const filteredCompanies = computed(() => {
    // if(companies.value.length > 0){
        return companies.value.filter((company) => {
                return company.url_vetted_by === null && company.website_url
            })
    // } else {
    //     return null
    // }
})

watch(filteredCompanies, ()=> {
    if(filteredCompanies.value){
        while(displayIndex.value >= filteredCompanies.value.length && displayIndex.value > 0){
            displayIndex.value--
        }
    }
})

const companyToVet = computed(() => {
    if(filteredCompanies.value){
        return filteredCompanies.value[displayIndex.value]
    } else {
        return null
    }
})


const companyWebsite = computed(() => {
    if(companyToVet.value){
        const website = companyToVet.value.website_url
        if(website.startsWith('http')){
            return website
        } else {
            return 'https://' + website
        }
        // console.log('company website', companyToVet.value.website_url)
        // return companyToVet.value.website_url
    } else {
        return '#'
    }
})

const companyToVetAddress = computed(() => {
    if(companyToVet.value){
        return `${companyToVet.value.address} ${companyToVet.value.city}, ${companyToVet.value.state} ${companyToVet.value.country}`
    } else {
        return null
    }
})

const googleUrlLink = computed(() => {
    if(companyToVet.value){
        return `https://www.google.com/search?q=${companyToVet.value.company_name.replace(' ', '+').replace('&', '%26')}+${companyToVet.value.city ? companyToVet.value.city.replace(' ', '+') : ''}+${companyToVet.value.state ? companyToVet.value.state.replace(' ', '+') : ''}+${companyToVet.value.country ? companyToVet.value.country.replace(' ', '+') : ''}`
    } else {
        return null
    }
})

function addOrEditCompanyToUpdate(company){
    const companyIndex = companiesToUpdate.value.findIndex((companyToUpdate) => companyToUpdate.id == company.id)
    if(companyIndex >= 0){
        companiesToUpdate.value[companyIndex] = company
    } else {
        companiesToUpdate.value.push(company)
    }
}

function showHelpText(){
    xPosition.value = helpText.value.getBoundingClientRect().left
    yPosition.value = helpText.value.getBoundingClientRect().bottom
    visibleHelpPopover.value = true
}
function hideHelpText(){
    visibleHelpPopover.value = false
}

// const companyFilters = reactive({
//     campaigns: null,
//     sources: null,
//   });

const companyFilters = reactive({
    campaign: {
        name: "campaign",
        label: "Campaign",
        value: [],
        defaultValue: [],
        options: [
            {value: "clear", label: "------------"}
        ],
        widget: 'list-box',
        multiSelect: true,
        setOptionsMethod: setCampaignOptions.bind(null),
        awaitingOptions: false
    },
    sources: {
        name: "sources",
        label: "Sources",
        value: [],
        defaultValue: [],
        options: [
            {value: "clear", label: "------------"}
        ],
        widget: 'list-box',
        multiSelect: true,
        setOptionsMethod: setSourceOptions.bind(null),
        awaitingOptions: false
    },
  });

  async function setSourceOptions() {
    const token = store.state.auth.token
    const results = await getSources(token)
    companyFilters.sources.options = [
    {value: "clear", label: "------------"}, ...results.map((source) => {
            return {value: source.id, label: source.source_name}
        })
    ]
}

async function setCampaignOptions() {
    const token = store.state.auth.token
    const result = await getActiveCampaigns(token)
    companyFilters.campaign.options = [{value: "clear", label: "------------"}, ...result.map((campaign) => {
            return {value: campaign.id, label: campaign.campaign_name}
        })]
}

function setOriginalValues(){
    companies.value.forEach((company) => {
        company.original_website_url = company.website_url
        company.original_url_vetted_by = company.url_vetted_by
    })
}

function undoChange(){
    const mostRecentChange = changeList.value.pop()
    const company = companies.value.find((company) => company.id === mostRecentChange.companyId)
    company[mostRecentChange.field] = mostRecentChange.oldValue
    if(company.website_url == company.original_website_url && company.url_vetted_by == company.original_url_vetted_by){
        const companyIndex = companiesToUpdate.value.findIndex((companyToUpdate) => companyToUpdate.id == company.id)
        if(companyIndex >= 0){
            companiesToUpdate.value.splice(companyIndex, 1)
        }
    }

}

  const pageSizeFilter = reactive({
    pageSizeFilter: 100
  })

const fetchFilter = computed(function () {
    let query = new URLSearchParams({
    });
    if (companyFilters.campaign && companyFilters.campaign.value) {
        companyFilters.campaign.value.forEach(campaign => {
          query.append("campaign", campaign)
        })
    }
    if (companyFilters.sources && companyFilters.sources.value) {
        companyFilters.sources.value.forEach(source => {
          query.append("source_object", source)
        })
    }
    let url;
    let queriesCount = 0;
    for (let value of query.values()) {
      queriesCount = queriesCount + 1;
    }
    if (queriesCount > 0) {
      url = "?" + query.toString();
    } else {
      url = "?";
    }
    // console.log(url);
    return url
})

async function loadCompanies(queryParams = fetchFilter.value) {
    saveChanges()
    isLoading.value = true;
    changeList.value = []
    companiesToUpdate.value =[]
    const token = store.state.auth.token 
    const result = await getCompaniesForManualUrlVetting(token, queryParams);
    companies.value = result.data.results;
    next.value = result.data.next ? `&page=${result.data.page + 1}` : null
    prev.value = result.data.prev ? `&page=${result.data.page - 1}` : null
    count.value = result.data.count
    page.value = result.data.page
    pageSize.value = result.data.results.length
    totalPages.value = result.data.num_pages
    isLoading.value = false;
    displayIndex.value = 0
    setOriginalValues()
}

function setPageSizeFilter(target=null){
    if(target) {
      target == "clear" ? (pageSizeFilter.pageSizeFilter = 100) : (pageSizeFilter.pageSizeFilter = target);
      loadCompanies(fetchFilter.value)
    }
}

// function setCompanyFilters(updatedFilters) {
//     companyFilters.campaigns = updatedFilters.campaigns;
//     companyFilters.sources = updatedFilters.sources;
//   }

  function getNext() {
    if (next.value) {
      loadCompanies(fetchFilter.value + next.value)
    } else {
      alert("No next page")
    }
  }
  
function getPrev() {
    if (prev.value) {
      loadCompanies(fetchFilter.value + prev.value)
    } else {
      alert("No prev page")
    }
  }

function jumpToPage(targetPage) {
    loadCompanies(fetchFilter.value + `&page=${targetPage}`)
  }

const viewingFromNbr = computed(function () {
  return (page.value * pageSizeFilter.pageSizeFilter + 1) - pageSizeFilter.pageSizeFilter
  })
  
  
const viewingToNbr = computed(function () {
    if (page.value === totalPages.value) {
      return count.value - companiesToUpdate.value.length;
    } else {
      return (page.value * pageSize.value) - companiesToUpdate.value.length
    }
  })

 function goToFirst(){
    loadCompanies(fetchFilter.value + `&page=${1}`)
  }
  
function goToLast(){
    loadCompanies(fetchFilter.value + `&page=${totalPages.value}`)
}

const filterValues = computed(() => {
    let values = []
    for(const filter in companyFilters){
        values.push(companyFilters[filter].value)
    }
    return values
})

watch(() => filterValues, () => {
          setTimeout(() => {
            loadCompanies(fetchFilter.value);
          }, 1000); // 2.5 sec delay
    }, {deep: true}
)

async function setFilterOptions(filterName, queryParams='') {
    console.log('setFilterOptions', filterName)
    if(!companyFilters[filterName].awaitingOptions){
        if(companyFilters[filterName].setOptionsMethod){
            companyFilters[filterName].awaitingOptions = true
            companyFilters[filterName].setOptionsMethod(queryParams)
            setTimeout(() => {
                companyFilters[filterName].awaitingOptions = false;
            }, 30000)
        }
    }
}

function clearFilter(filterName) {
    if(Array.isArray(companyFilters[filterName].value)){
        companyFilters[filterName].value = []
    } else {
        companyFilters[filterName].value = null
    }
}

function setFilters(updatedFilters) {
    companyFilters.campaign = updatedFilters.campaign;
    companyFilters.sources = updatedFilters.sources
}

loadCompanies()

</script>