<template>
    <div class="h-full w-full py-2">
        <div class="h-full w-full overflow-y-scroll" v-if="zoomInfoCompany">
            <section class="w-full flex flex-col space-y-3">
                <article id="zoom-info-data" class="p-2 border border-gray-400 rounded-md mt-3">
                    <div class="text-sm border-b border-gray-400 mb-1 pb-1 w-full">
                        Zoom Info Data
                    </div>
                    <div class="grid grid-cols-4 w-full px-0.5">
                        <div class="flex flex-col space-y-1 px-1">
                            <div>
                                Zoom Info ID
                            </div>
                            <div class="bg-gray-100 p-1 border border-gray-400 rounded-md">
                                {{ zoomInfoCompany.zoom_info_id }}
                            </div>
                        </div>
                        <div class="flex flex-col space-y-1 px-1">
                            <div>
                                Certified
                            </div>
                            <div class="bg-gray-100 p-1 border border-gray-400 rounded-md">
                                {{ zoomInfoCompany.certified }}
                            </div>
                        </div>
                        <div class="flex flex-col space-y-1 px-1">
                            <div>
                                Certification Date
                            </div>
                            <div class="bg-gray-100 p-1 border border-gray-400 rounded-md">
                                {{ zoomInfoCompany.certification_date }}
                            </div>
                        </div>
                        <div class="flex flex-col space-y-1 px-1">
                            <div>
                                Last Updated Date
                            </div>
                            <div class="bg-gray-100 p-1 border border-gray-400 rounded-md">
                                {{ zoomInfoCompany.last_updated_date }}
                            </div>
                        </div>
                    </div>
                </article>
                <article id="meta-data" class="p-2 border border-gray-400 rounded-md">
                    <div class="text-sm border-b border-gray-400 mb-1 pb-1 w-full">
                        Meta Data
                    </div>
                    <div class="grid grid-cols-4 w-full px-0.5">
                        <div class="flex flex-col space-y-1 px-1">
                            <div>
                                Aerocle ID
                            </div>
                            <div class="bg-gray-100 p-1 border border-gray-400 rounded-md">
                                {{ zoomInfoCompany.id }}
                            </div>
                        </div>
                        <div class="flex flex-col space-y-1 px-1">
                            <div>
                                Aerocle Created At
                            </div>
                            <div class="bg-gray-100 p-1 border border-gray-400 rounded-md">
                                {{ zoomInfoCompany.created_at }}
                            </div>
                        </div>
                        <div class="flex flex-col space-y-1 px-1">
                            <div>
                                Aerocle Updated At
                            </div>
                            <div class="bg-gray-100 p-1 border border-gray-400 rounded-md">
                                {{ zoomInfoCompany.updated_at}}
                            </div>
                        </div>
                    </div>
                </article>
                <article id="basic-info" class="p-2 border border-gray-400 rounded-md">
                    <div class="text-sm border-b border-gray-400 mb-1 pb-1 w-full">
                        Basic Company Info
                    </div>
                    <div class="grid grid-cols-4 w-full px-0.5">
                        <div class="flex flex-col space-y-1 px-1">
                            <div>
                                Name
                            </div>
                            <div class="bg-gray-100 p-1 border border-gray-400 rounded-md">
                                {{ zoomInfoCompany.company_name }}
                            </div>
                        </div>
                        <div class="flex flex-col space-y-1 px-1">
                            <div>
                                Company Status
                            </div>
                            <div class="bg-gray-100 p-1 border border-gray-400 rounded-md">
                                {{ zoomInfoCompany.company_status }}
                            </div>
                        </div>
                        <div class="flex flex-col space-y-1 px-1">
                            <div>
                                Company Status Date
                            </div>
                            <div class="bg-gray-100 p-1 border border-gray-400 rounded-md">
                                {{ zoomInfoCompany.company_status_date }}
                            </div>
                        </div>
                        <div class="flex flex-col space-y-1 px-1">
                            <div>
                                Company Type
                            </div>
                            <div class="bg-gray-100 p-1 border border-gray-400 rounded-md">
                                {{ zoomInfoCompany.company_type }}
                            </div>
                        </div>
                        <div class="flex flex-col space-y-1 px-1">
                            <div>
                                Ticker
                            </div>
                            <div class="bg-gray-100 p-1 border border-gray-400 rounded-md">
                                {{ zoomInfoCompany.ticker }}
                            </div>
                        </div>
                        <div class="flex flex-col space-y-1 px-1">
                            <div>
                                Website URL
                            </div>
                            <div class="bg-gray-100 p-1 border border-gray-400 rounded-md">
                                {{ zoomInfoCompany.website }}
                            </div>
                        </div>
                        <div class="flex flex-col space-y-1 px-1">
                            <div>
                                Address
                            </div>
                            <div class="bg-gray-100 p-1 border border-gray-400 rounded-md">
                                {{ zoomInfoCompany.street }}
                            </div>
                        </div>
                        <div class="flex flex-col space-y-1 px-1">
                            <div>
                                City
                            </div>
                            <div class="bg-gray-100 p-1 border border-gray-400 rounded-md">
                                {{ zoomInfoCompany.city }}
                            </div>
                        </div>
                        <div class="flex flex-col space-y-1 px-1">
                            <div>
                                State
                            </div>
                            <div class="bg-gray-100 p-1 border border-gray-400 rounded-md">
                                {{ zoomInfoCompany.state }}
                            </div>
                        </div>
                        <div class="flex flex-col space-y-1 px-1">
                            <div>
                                Zip
                            </div>
                            <div class="bg-gray-100 p-1 border border-gray-400 rounded-md">
                                {{ zoomInfoCompany.zip_code }}
                            </div>
                        </div>
                        <div class="flex flex-col space-y-1 px-1">
                            <div>
                                Metro Area
                            </div>
                            <div class="bg-gray-100 p-1 border border-gray-400 rounded-md">
                                {{ zoomInfoCompany.metro_area }}
                            </div>
                        </div>
                        <div class="flex flex-col space-y-1 px-1">
                            <div>
                                Country
                            </div>
                            <div class="bg-gray-100 p-1 border border-gray-400 rounded-md">
                                {{ zoomInfoCompany.country }}
                            </div>
                        </div>
                        <div class="flex flex-col space-y-1 px-1">
                            <div>
                                Continent
                            </div>
                            <div class="bg-gray-100 p-1 border border-gray-400 rounded-md">
                                {{ zoomInfoCompany.continent }}
                            </div>
                        </div>
                        <div class="flex flex-col space-y-1 px-1">
                            <div>
                                Location Count
                            </div>
                            <div class="bg-gray-100 p-1 border border-gray-400 rounded-md">
                                {{ zoomInfoCompany.location_count }}
                            </div>
                        </div>
                        <div class="flex flex-col space-y-1 px-1">
                            <div>
                                Phone
                            </div>
                            <div class="bg-gray-100 p-1 border border-gray-400 rounded-md">
                                {{ zoomInfoCompany.phone }}
                            </div>
                        </div>
                        <div class="flex flex-col space-y-1 px-1">
                            <div>
                                Revenue
                            </div>
                            <div class="bg-gray-100 p-1 border border-gray-400 rounded-md">
                                {{ zoomInfoCompany.revenue }}
                            </div>
                        </div>
                        <div class="flex flex-col space-y-1 px-1">
                            <div>
                                Revenue Range
                            </div>
                            <div class="bg-gray-100 p-1 border border-gray-400 rounded-md">
                                {{ zoomInfoCompany.revenue_range }}
                            </div>
                        </div>
                        <div class="flex flex-col space-y-1 px-1">
                            <div>
                                Employee Headcount
                            </div>
                            <div class="bg-gray-100 p-1 border border-gray-400 rounded-md">
                                {{ zoomInfoCompany.employee_count }}
                            </div>
                        </div>
                        <div class="flex flex-col space-y-1 px-1">
                            <div>
                                Employee Range
                            </div>
                            <div class="bg-gray-100 p-1 border border-gray-400 rounded-md">
                                {{ zoomInfoCompany.employee_range }}
                            </div>
                        </div>
                        <div class="flex flex-col space-y-1 px-1">
                            <div>
                                Founded Year
                            </div>
                            <div class="bg-gray-100 p-1 border border-gray-400 rounded-md">
                                {{ zoomInfoCompany.founded_year }}
                            </div>
                        </div>
                        <div class="flex flex-col space-y-1 px-1">
                            <div>
                                Is Defunct
                            </div>
                            <div class="bg-gray-100 p-1 border border-gray-400 rounded-md">
                                {{ zoomInfoCompany.is_defunct }}
                            </div>
                        </div>
                        <div class="flex flex-col space-y-1 px-1 col-span-3">
                            <div>
                                Logo Link
                            </div>
                            <div class="bg-gray-100 p-1 border border-gray-400 rounded-md">
                                {{ zoomInfoCompany.logo }}
                            </div>
                        </div>
                    </div>
                </article>
                <article id="description" class="p-2 border border-gray-400 rounded-md">
                    <div class="text-sm border-b border-gray-400 mb-1 pb-1 w-full">
                        Company Description
                    </div>
                    <div class="grid grid-cols-4 space-x-2 w-full px-0.5">
                        <div class="flex flex-col space-y-1 px-1 col-span-4">
                            <div class="bg-gray-100 p-1 border border-gray-400 rounded-md">
                                {{ zoomInfoCompany.description }}
                            </div>
                        </div>
                    </div>
                </article>
                <article id="business-models" class="p-2 border border-gray-400 rounded-md">
                    <div class="text-sm border-b border-gray-400 mb-1 pb-1 w-full">
                        Business Models
                    </div>
                    <div class="grid grid-cols-4 w-full px-0.5">
                        <div v-for="(businessModel, index) in zoomInfoCompany.business_model" :key="index" class="bg-gray-100 p-1 m-1 border border-gray-400 rounded-md">
                            {{ businessModel }}
                        </div>
                    </div>
                </article>
                <article id="additional-outreach" class="p-2 border border-gray-400 rounded-md">
                    <div class="text-sm border-b border-gray-400 mb-1 pb-1 w-full">
                        Additional Outreach
                    </div>
                    <div class="grid grid-cols-4 w-full px-0.5">
                        <div v-for="(socialMediaUrl, index) in zoomInfoCompany.social_media_urls" :key="index" class="flex flex-col col-span-4 space-y-1 px-1">
                            <div v-if="socialMediaUrl.url.length > 0">
                                {{ socialMediaUrl.type }} URL ({{ socialMediaUrl.followerCount ? socialMediaUrl.followerCount : 'Unknown' }} followers)
                            </div>
                            <div v-if="socialMediaUrl.url.length > 0" class="bg-gray-100 p-1 border border-gray-400 rounded-md">
                                {{ socialMediaUrl.url }}
                            </div>
                        </div>
                        <div class="flex flex-col space-y-1 px-1">
                            <div>
                                Fax
                            </div>
                            <div class="bg-gray-100 p-1 border border-gray-400 rounded-md">
                                {{ zoomInfoCompany.fax }}
                            </div>
                        </div>
                        <div class="flex flex-col space-y-1 px-1">
                            <div>
                                Number of Contacts In Zoom Info
                            </div>
                            <div class="bg-gray-100 p-1 border border-gray-400 rounded-md">
                                {{ zoomInfoCompany.number_of_contacts_in_zoom_info ? zoomInfoCompany.number_of_contacts_in_zoom_info : 'None' }}
                            </div>
                        </div>
                    </div>
                </article>
                <article id="contact-objects" class="p-2 border border-gray-400 rounded-md">
                    <div class="text-sm border-b border-gray-400 mb-1 pb-1 w-full">
                        Contact Objects
                    </div>
                    <div class="grid grid-cols-4 w-full px-0.5">
                        <div v-for="(contactObject, index) in zoomInfoCompany.contact_objects" :key="index" class="flex flex-col space-y-1 p-1 border border-gray-400 m-1 rounded-md">
                            <div>
                                Zoom Info ID
                            </div>
                            <div class="bg-gray-100 p-1 border border-gray-400 rounded-md">
                                {{ contactObject.zi_id }}
                            </div>
                            <div>
                                Title
                            </div>
                            <div class="bg-gray-100 p-1 border border-gray-400 rounded-md">
                                {{ contactObject.title }}
                            </div>
                            <div>
                                First Name
                            </div>
                            <div class="bg-gray-100 p-1 border border-gray-400 rounded-md">
                                {{ contactObject.first_name }}
                            </div>
                            <div>
                                Last Name
                            </div>
                            <div class="bg-gray-100 p-1 border border-gray-400 rounded-md">
                                {{ contactObject.last_name }}
                            </div>
                            <div>
                                Has Email
                            </div>
                            <div class="bg-gray-100 p-1 border border-gray-400 rounded-md">
                                {{ contactObject.has_email }}
                            </div>
                            <div>
                                Has Direct Phone
                            </div>
                            <div class="bg-gray-100 p-1 border border-gray-400 rounded-md">
                                {{ contactObject.has_direct_phone }}
                            </div>
                            <div>
                                Has Mobile Phone
                            </div>
                            <div class="bg-gray-100 p-1 border border-gray-400 rounded-md">
                                {{ contactObject.has_mobile_phone }}
                            </div>
                            <div>
                                Valid Date
                            </div>
                            <div class="bg-gray-100 p-1 border border-gray-400 rounded-md">
                                {{ contactObject.valid_date }}
                            </div>
                        </div>
                    </div>
                </article>
                <article id="parent-subsidiary-info" class="p-2 border border-gray-400 rounded-md">
                    <div class="text-sm border-b border-gray-400 mb-1 pb-1 w-full">
                        Parent Subsidiary Info
                    </div>
                    <div class="grid grid-cols-4 w-full px-0.5">
                        <div class="flex flex-col space-y-1 px-1">
                            <div>
                                Ultimate Parent Zoom Info ID
                            </div>
                            <div class="bg-gray-100 p-1 border border-gray-400 rounded-md">
                                {{ zoomInfoCompany.ultimate_parent_id }}
                            </div>
                        </div>
                        <div class="flex flex-col space-y-1 px-1">
                            <div>
                                Ultimate Parent Name
                            </div>
                            <div class="bg-gray-100 p-1 border border-gray-400 rounded-md">
                                {{ zoomInfoCompany.ultimate_parent_name ? zoomInfoCompany.ultimate_parent_name : 'None' }}
                            </div>
                        </div>
                        <div class="flex flex-col space-y-1 px-1">
                            <div>
                                Ultimate Parent Revenue
                            </div>
                            <div class="bg-gray-100 p-1 border border-gray-400 rounded-md">
                                {{ zoomInfoCompany.ultimate_parent_revenue }}
                            </div>
                        </div>
                        <div class="flex flex-col space-y-1 px-1">
                            <div>
                                Ultimate Parent Employee Headcount
                            </div>
                            <div class="bg-gray-100 p-1 border border-gray-400 rounded-md">
                                {{ zoomInfoCompany.ultimate_parent_employees }}
                            </div>
                        </div>
                        <div class="flex flex-col space-y-1 px-1">
                            <div>
                                Parent Zoom Info ID
                            </div>
                            <div class="bg-gray-100 p-1 border border-gray-400 rounded-md">
                                {{ zoomInfoCompany.parent_id }}
                            </div>
                        </div>
                        <div class="flex flex-col space-y-1 px-1">
                            <div>
                                Parent Name
                            </div>
                            <div class="bg-gray-100 p-1 border border-gray-400 rounded-md">
                                {{ zoomInfoCompany.parent_name ? zoomInfoCompany.parent_name : 'None' }}
                            </div>
                        </div>
                    </div>
                </article>
                <article id="funding" class="p-2 border border-gray-400 rounded-md">
                    <div class="text-sm border-b border-gray-400 mb-1 pb-1 w-full">
                        Funding
                    </div>
                    <div class="grid grid-cols-4 w-full px-0.5">
                        <div class="flex flex-col space-y-1 px-1">
                            <div>
                                Total Funding Amount
                            </div>
                            <div class="bg-gray-100 p-1 border border-gray-400 rounded-md">
                                {{ zoomInfoCompany.total_funding_amount }}
                            </div>
                        </div>
                        <div class="flex flex-col space-y-1 px-1">
                            <div>
                                Recent Funding Amount
                            </div>
                            <div class="bg-gray-100 p-1 border border-gray-400 rounded-md">
                                {{ zoomInfoCompany.recent_funding_amount }}
                            </div>
                        </div>
                        <div class="flex flex-col space-y-1 px-1">
                            <div>
                                Recent Funding Date
                            </div>
                            <div class="bg-gray-100 p-1 border border-gray-400 rounded-md">
                                {{ zoomInfoCompany.recent_funding_date }}
                            </div>
                        </div>
                    </div>
                    <div class="text-sm border-t py-2 mt-2 border-b border-gray-400 mb-1 pb-1 w-full">
                        Funding Rounds
                    </div>
                    <div class="grid grid-cols-4 w-full px-0.5">
                        <div v-for="(funding, index) in zoomInfoCompany.company_funding" :key="index" class="flex flex-col space-y-1 p-1 border border-gray-400 m-1 rounded-md">
                            <div>
                                Funding Type
                            </div>
                            <div class="bg-gray-100 p-1 border border-gray-400 rounded-md">
                                {{ funding.type }}
                            </div>
                            <div>
                                Funding Date
                            </div>
                            <div class="bg-gray-100 p-1 border border-gray-400 rounded-md">
                                {{ funding.date }}
                            </div>
                            <div>
                                Funding Amount
                            </div>
                            <div class="bg-gray-100 p-1 border border-gray-400 rounded-md">
                                {{ funding.amount }}
                            </div>
                            <div>
                                Investors
                            </div>
                            <div class="bg-gray-100 p-1 border border-gray-400 rounded-md">
                                {{ funding.investors }}
                            </div>
                        </div>
                    </div>
                    <div class="text-sm border-t py-2 mt-2 border-b border-gray-400 mb-1 pb-1 w-full">
                        Department Budgets
                    </div>
                    <div class="grid grid-cols-4 w-full px-0.5">
                        <div class="flex flex-col space-y-1 px-1">
                            <div>
                                Financial Budget
                            </div>
                            <div class="bg-gray-100 p-1 border border-gray-400 rounded-md">
                                {{ zoomInfoCompany.department_budgets ? zoomInfoCompany.department_budgets.financialBudget : "None" }}
                            </div>
                        </div>
                        <div class="flex flex-col space-y-1 px-1">
                            <div>
                                Marketing Budget
                            </div>
                            <div class="bg-gray-100 p-1 border border-gray-400 rounded-md">
                                {{ zoomInfoCompany.department_budgets ?  zoomInfoCompany.department_budgets.marketingBudget : "None"  }}
                            </div>
                        </div>
                        <div class="flex flex-col space-y-1 px-1">
                            <div>
                                IT Budget
                            </div>
                            <div class="bg-gray-100 p-1 border border-gray-400 rounded-md">
                                {{  zoomInfoCompany.department_budgets ? zoomInfoCompany.department_budgets.itBudget : "None"  }}
                            </div>
                        </div>
                        <div class="flex flex-col space-y-1 px-1">
                            <div>
                                HR Budget
                            </div>
                            <div class="bg-gray-100 p-1 border border-gray-400 rounded-md">
                                {{  zoomInfoCompany.department_budgets ? zoomInfoCompany.department_budgets.hrBudget : "None"  }}
                            </div>
                        </div>
                    </div>
                </article>
                <article id="industry-codes" class="p-2 border border-gray-400 rounded-md">
                    <div class="text-sm border-b border-gray-400 mb-1 pb-1 w-full">
                        Industry Codes
                    </div>
                    <div class="grid grid-cols-4 w-full px-0.5">
                        <div v-if="zoomInfoCompany" class="flex flex-col space-y-2 px-1">
                            <div>
                                NAICS Codes
                            </div>
                            <div v-for="(naicsCode, index) in zoomInfoCompany.naics_codes" :key="index" class="bg-gray-100 p-1 border border-gray-400 rounded-md space-y-1">
                                {{ naicsCode.id }} - {{ naicsCode.name }}
                            </div>
                        </div>
                        <div v-if="zoomInfoCompany" class="flex flex-col space-y-2 px-1">
                            <div>
                                SIC Codes
                            </div>
                            <div v-for="(sicCode, index) in zoomInfoCompany.sic_codes" :key="index" class="bg-gray-100 p-1 border border-gray-400 rounded-md space-y-1">
                                {{ sicCode.id }} - {{ sicCode.name }}
                            </div>
                        </div>
                        <div v-if="zoomInfoCompany" class="flex flex-col space-y-2 px-1">
                            <div>
                                Primary Industry Codes
                            </div>
                            <div v-for="(primaryIndustryCode, index) in zoomInfoCompany.primary_industry_code" :key="index" class="bg-gray-100 p-1 border border-gray-400 rounded-md space-y-1">
                                {{ primaryIndustryCode.id }} - {{ primaryIndustryCode.name }}
                            </div>
                            <div class="border-t border-b border-gray-400 py-2">
                                Primary Sub Industry Codes
                            </div>
                            <div v-for="(primarySubIndustryCode, index) in zoomInfoCompany.primary_sub_industry_code" :key="index" class="bg-gray-100 p-1 border border-gray-400 rounded-md space-y-1">
                                {{ primarySubIndustryCode.id }} - {{ primarySubIndustryCode.name }}
                            </div>
                        </div>
                        <div v-if="zoomInfoCompany" class="flex flex-col space-y-2 px-1">
                            <div>
                                Industry Codes
                            </div>
                            <div v-for="(industryCode, index) in zoomInfoCompany.industry_codes" :key="index" class="bg-gray-100 p-1 border border-gray-400 rounded-md space-y-1">
                                {{ industryCode.id }} - {{  industryCode.name }}
                            </div>
                        </div>
                        <div v-if="zoomInfoCompany" class="flex flex-col space-y-2 px-1">
                            <div  class="border-t border-b border-gray-400 py-2 mt-2">
                                Primary Industries
                            </div>
                            <div v-for="(primaryIndustry, index) in zoomInfoCompany.primary_industry" :key="index" class="bg-gray-100 p-1 border border-gray-400 rounded-md space-y-1">
                                {{ primaryIndustry }}
                            </div>
                        </div>
                        <div v-if="zoomInfoCompany" class="flex flex-col space-y-2 px-1">
                            <div  class="border-t border-b border-gray-400 py-2 mt-2">
                                Industries
                            </div>
                            <div v-for="(industry, index) in zoomInfoCompany.industries" :key="index" class="bg-gray-100 p-1 border border-gray-400 rounded-md space-y-1">
                                {{ industry }}
                            </div>
                        </div>
                        <div v-if="zoomInfoCompany" class="flex flex-col space-y-2 px-1">
                            <div  class="border-t border-b border-gray-400 py-2 mt-2">
                                Sub Unit Industries
                            </div>
                            <div v-for="(subUnitIndustry, index) in zoomInfoCompany.sub_unit_industries" :key="index" class="bg-gray-100 p-1 border border-gray-400 rounded-md space-y-1">
                                {{ subUnitIndustry }}
                            </div>
                        </div>
                        <div v-if="zoomInfoCompany" class="flex flex-col space-y-2 px-1">
                            <div  class="border-t border-b border-gray-400 py-2 mt-2">
                                Sub Unit Type
                            </div>
                            <div class="bg-gray-100 p-1 border border-gray-400 rounded-md space-y-1">
                                {{ zoomInfoCompany.sub_unit_type }}
                            </div>
                        </div>
                    </div>
                </article>
                <article id="competitors" class="p-2 border border-gray-400 rounded-md">
                    <div class="text-sm border-b border-gray-400 mb-1 pb-1 w-full">
                        Competitors
                    </div>
                    <div class="grid grid-cols-4 w-full px-0.5">
                        <div v-for="(competitor, index) in zoomInfoCompany.competitors" :key="index" class="flex flex-col space-y-1 p-1 border border-gray-400 m-1 rounded-md">
                            <div>
                                Competitor Rank
                            </div>
                            <div class="bg-gray-100 p-1 border border-gray-400 rounded-md">
                                {{ competitor.rank }}
                            </div>
                            <div>
                                Competitor Zoom Info ID
                            </div>
                            <div class="bg-gray-100 p-1 border border-gray-400 rounded-md">
                                {{ competitor.id}}
                            </div>
                            <div>
                                Competitor Name
                            </div>
                            <div class="bg-gray-100 p-1 border border-gray-400 rounded-md">
                                {{ competitor.name }}
                            </div>
                            <div>
                                Competitor Website
                            </div>
                            <div class="bg-gray-100 p-1 border border-gray-400 rounded-md">
                                {{ competitor.website }}
                            </div>
                            <div>
                                Competitor Employee Headcount
                            </div>
                            <div class="bg-gray-100 p-1 border border-gray-400 rounded-md">
                                {{ competitor.employeeCount }}
                            </div>
                        </div>
                    </div>
                </article>
                <article id="products" class="p-2 border border-gray-400 rounded-md">
                    <div class="text-sm border-b border-gray-400 mb-1 pb-1 w-full">
                        Products
                    </div>
                    <div class="grid grid-cols-4 w-full px-0.5">
                        <div v-for="(product, index) in zoomInfoCompany.products" :key="index" class="bg-gray-100 p-1 m-1 border border-gray-400 rounded-md">
                            {{ product }}
                        </div>
                    </div>
                </article>
                <article id="websites" class="p-2 border border-gray-400 rounded-md">
                    <div class="text-sm border-b border-gray-400 mb-1 pb-1 w-full">
                        Additional Websites
                    </div>
                    <div class="grid grid-cols-4 w-full px-0.5">
                        <div v-for="(domain, index) in zoomInfoCompany.domain_list" :key="index" class="flex flex-col col-span-2 space-y-1 p-1 m-1">
                            <div  class="bg-gray-100 p-1 border border-gray-400 rounded-md">
                                {{ domain }}
                            </div>
                        </div>
                    </div>
                </article>
            </section>
        </div>
        <div class="h-full" v-else>
            <div class="h-full w-full" v-if="zoomInfoCompanyCandidates.length > 0">
                <div class="flex flex-col space-y-1 px-1 py-2 border-b border-gray-400">
                    {{ zoomInfoCompanyCandidates.length }} ZoomInfo Company Candidates:
                </div>
                <!-- <div class="h-[85%] overflow-y-scroll">
                    <section class="w-full items-center align-center py-0.5 border-b border-gray-400 text-xs grid grid-cols-12" v-for="zoomInfoCompanyId, index in zoomInfoCompanyCandidates" :key="index">
                        <div class="flex col-span-2 items-center">
                            <span class="pl-2">{{ index + 1 }}:</span><span class="pl-2">ZoomInfo ID {{ zoomInfoCompanyId }}</span>
                        </div>
                        <div class="flex col-span-2">
                            <base-button class="text-xs" @click="assignZoomInfoId">Assign ZI ID To Company</base-button>
                        </div>
                    </section>
                </div> -->
                <form @submit.prevent="submitForm" action="" class="h-full w-full">
                    <base-form class="h-[85%]">
                        <template #body>
                            <div class="h-full w-full">
                                <div class="w-full flex items-center align-center py-1 border-b border-gray-400 text-xs" v-for="zoomInfoCompanyId, index in zoomInfoCompanyCandidates" :key="index">
                                    <input class="ml-2" type="radio" name="zoomInfoCandidate" :value="zoomInfoCompanyId" v-model="zoomInfoCandidateId">
                                    <label for=""><span class="pl-2">ZI ID</span><a target="_blank" :href="`https://app.zoominfo.com/#/apps/profile/company/${zoomInfoCompanyId}/overview?url=%2Fapps%2FsearchV2%2Fv2%2Fresults%2Fperson%3Fquery%3DeyJmaWx0ZXJzIjp7InBhZ2UiOjEsImNvbXBhbnlQYXN0T3JQcmVzZW50IjoiMSIsImlzQ2VydGlmaWVkIjoiZXhjbHVkZSIsInNvcnRCeSI6IlJlbGV2YW5jZSIsInNvcnRPcmRlciI6ImRlc2MiLCJleGNsdWRlRGVmdW5jdENvbXBhbmllcyI6dHJ1ZSwiY29uZmlkZW5jZVNjb3JlTWluIjo4NSwiY29uZmlkZW5jZVNjb3JlTWF4Ijo5OSwib3V0cHV0Q3VycmVuY3lDb2RlIjoiVVNEIiwiaW5wdXRDdXJyZW5jeUNvZGUiOiJVU0QiLCJleGNsdWRlTm9Db21wYW55IjoidHJ1ZSIsInJldHVybk9ubHlCb2FyZE1lbWJlcnMiOmZhbHNlLCJleGNsdWRlQm9hcmRNZW1iZXJzIjp0cnVlLCJjb21wYW55SWRzIjpbeyJ2YWx1ZSI6MTMyMDkwMDAzNCwiZGlzcGxheU5hbWUiOiJVbml2ZXJzYWwgQWVyb3NwYWNlIFN5c3RlbXMifV19LCJzZWFyY2hUeXBlIjowfQ%253D%253D&titleText=Contact%20Search&profileId=1320900034&checkProfileAccess=true`" class="pl-2 underline text-blue-600">{{ zoomInfoCompanyId }}</a></label>
                                </div>
                            </div>
                        </template>
                        <template #footer>
                            <div class="flex justify-center items-center py-2">
                                <base-button v-if="zoomInfoCandidateId" class="submit" >Assign ZI ID To Company</base-button>
                                <base-button-disabled v-else class="submit" disabled>Assign ZI ID To Company</base-button-disabled>
                            </div>
                        </template>
                    </base-form>
                </form>
            </div>
            <div v-else>
                No Zoom Info Company
            </div>
        </div>
    </div>
</template>

<script setup>
import { defineProps, defineEmits, ref } from 'vue';
import { useStore } from 'vuex';
import { getZoomInfoCompanyByCompany } from '@/services/ZoomInfoCompany';
import { assignZoomInfoID } from '@/services/Company';
import { useToast } from 'vue-toast-notification';

const store = useStore()
const $toast = useToast()
const isLoading = ref(false)

const props = defineProps(['company'])
const emit = defineEmits(['refetch-companies'])

const zoomInfoCompany = ref(null)
const zoomInfoCandidateId = ref(null)
const zoomInfoCompanyCandidates = ref(props.company.zoom_info_id_candidates.length > 0 ? props.company.zoom_info_id_candidates : [])


async function getZoomInfoCompany () {
    console.log('get zoom info company')
    const token = store.state.auth.token
    isLoading.value = true
    const response = await getZoomInfoCompanyByCompany(props.company.id, token)
    console.log(response)
    if(response.data.results.length == 1) {
        zoomInfoCompany.value = response.data.results[0]
    }
}

async function submitForm() {
    const token = store.state.auth.token
    console.log('assigning zoom info id')
    console.log(zoomInfoCandidateId.value)
    console.log('to company')
    console.log(props.company.id)
    const result = await assignZoomInfoID(props.company.id, zoomInfoCandidateId.value, token)
    console.log(result)
    if(result.status == 200) {
        $toast.open({
            message: result.data.status,
            type: 'success',
            duration: 5000
        })
    } else {
        $toast.open({
            message: 'Error Assigning Zoom Info ID.',
            type: 'error',
            duration: 5000
        })
    }
    getZoomInfoCompany()
    emit('refetch-companies')
}


getZoomInfoCompany()
</script>