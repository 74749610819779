<template>
    <div class="w-full flex flex-col items-start pb-4">
        <div class="bg-dark-secondary border border-b-2 border-dark-primary text-white text-base font-semibold tracking-wider w-full py-2 px-4 shadow flex justify-center items-center whitespace-nowrap rounded-t-sm">
            {{ sectionTitle }}
        </div>
        <div class="w-full grid grid-cols-3 border border-gray-300">
            <CRMProgressUpdateColumn
                v-for="(progressUpdateColumn, index) in progressUpdateColumns"
                :key="index"
                :progress-update-column="progressUpdateColumn"
            />
        </div>
    </div>

</template>

<script setup>
import { defineProps, computed } from'vue';
import CRMProgressUpdateColumn from './CRMProgressUpdateColumn.vue';
import SheddCapitalSVG from '@/components/svg-components/SheddCapitalSVG.vue';

const props = defineProps([
    'section'
])

const sectionTitle = computed(() => {
    return props.section ? props.section.title : '--'
})

const progressUpdateColumns = computed(() => {
    return props.section ? props.section.columns : []
})

</script>