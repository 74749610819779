export function getMostRecentCRMEvent(company) {
    if(company.crm_actions) {
        let crmActionsByDate = company.crm_actions.filter(action => {return action.campaign_id == company.campaign.id}).sort(function(a,b) {
            return new Date(b.resolved_date) - new Date(a.resolved_date)
        })
        if(crmActionsByDate.length > 0) {
            return crmActionsByDate[0]
        }
    } else {
        return null
    }
}

export function getAutoComputedCRMEvent(company) {
    // console.log('getAutoComputedCRMEvent')
    if(company.crm_actions) {
        let crmEventTypeIDs = company.crm_actions.filter(action => {return action.campaign_id == company.campaign.id}).map((action) => action.action.id)
        // console.log(crmEventTypeIDs)
        let crmEventsByOrdinal = company.crm_actions.filter(action => {return action.campaign_id == company.campaign.id}).sort(function(a,b) {
            return b.action.ordinal - a.action.ordinal
        })
        // console.log(crmEventsByOrdinal)
        if(crmEventTypeIDs.length > 0) {
            if(crmEventTypeIDs.includes(20)) {
                return crmEventsByOrdinal.find((crmEvent) => crmEvent.action.id == 20)
            } else if(crmEventTypeIDs.includes(27)) {
                return crmEventsByOrdinal.find((crmEvent) => crmEvent.action.id == 27)
            } else if (crmEventTypeIDs.includes(18)) {
                return crmEventsByOrdinal.find((crmEvent) => crmEvent.action.id == 18)
            } else if (crmEventTypeIDs.includes(17)) {
                return crmEventsByOrdinal.find((crmEvent) => crmEvent.action.id == 17)
            } else if (crmEventTypeIDs.includes(16)) {
                return crmEventsByOrdinal.find((crmEvent) => crmEvent.action.id == 16)
            } else if (crmEventTypeIDs.includes(15)) {
                return crmEventsByOrdinal.find((crmEvent) => crmEvent.action.id == 15)
            } else if (crmEventTypeIDs.includes(14)) {
                return crmEventsByOrdinal.find((crmEvent) => crmEvent.action.id == 14)
            } else if (crmEventTypeIDs.includes(13)) {
                return crmEventsByOrdinal.find((crmEvent) => crmEvent.action.id == 13)
            } else if (crmEventTypeIDs.includes(12)) {
                return crmEventsByOrdinal.find((crmEvent) => crmEvent.action.id == 12)
            } else if (crmEventTypeIDs.includes(11)) {
                return crmEventsByOrdinal.find((crmEvent) => crmEvent.action.id == 11)
            } else if (crmEventTypeIDs.includes(10)) {
                return crmEventsByOrdinal.find((crmEvent) => crmEvent.action.id == 10)
            } else if (crmEventTypeIDs.includes(9)) {
                return crmEventsByOrdinal.find((crmEvent) => crmEvent.action.id == 9)
            } else if (crmEventTypeIDs.includes(22)) {
                return crmEventsByOrdinal.find((crmEvent) => crmEvent.action.id == 22)
            } else if (crmEventTypeIDs.includes(8)) {
                return crmEventsByOrdinal.find((crmEvent) => crmEvent.action.id == 8)
            } else if (crmEventTypeIDs.includes(6)) {
                return crmEventsByOrdinal.find((crmEvent) => crmEvent.action.id == 6)
            } else if (crmEventTypeIDs.includes(7)) {
                return crmEventsByOrdinal.find((crmEvent) => crmEvent.action.id == 7)
            } else if (crmEventTypeIDs.includes(5)) {
                return crmEventsByOrdinal.find((crmEvent) => crmEvent.action.id == 5)
            } else if (crmEventTypeIDs.includes(1)) {
                return crmEventsByOrdinal.find((crmEvent) => crmEvent.action.id == 1)
            } else if (crmEventTypeIDs.includes(21)) {
                return crmEventsByOrdinal.find((crmEvent) => crmEvent.action.id == 21)
            } else if (crmEventTypeIDs.includes(4)) {
                return crmEventsByOrdinal.find((crmEvent) => crmEvent.action.id == 4)
            } else if (crmEventTypeIDs.includes(3)) {
                return crmEventsByOrdinal.find((crmEvent) => crmEvent.action.id == 3)
            } else if (crmEventTypeIDs.includes(2)) {
                return crmEventsByOrdinal.find((crmEvent) => crmEvent.action.id == 2)
            } else if (crmEventTypeIDs.includes(19)) {
                return crmEventsByOrdinal.find((crmEvent) => crmEvent.action.id == 19)
            } else {
                return null
            }
            // return crmActionsByOrdinal[0]
        } else {
            return null
        }
    } else {
        return null
    }
}

export function getDefaultReportedCRMEvent(company) {
    // console.log('getAutoComputedCRMEvent')
    if(company.crm_actions) {
        let crmEventTypeIDs = company.crm_actions.filter(action => {return action.campaign_id == company.campaign.id && action.show_on_crm_report == true}).map((action) => action.action.id)
        // console.log(crmEventTypeIDs)
        let crmEventsByOrdinal = company.crm_actions.filter(action => {return action.campaign_id == company.campaign.id && action.show_on_crm_report == true}).sort(function(a,b) {
            return b.action.ordinal - a.action.ordinal
        })
        // console.log(crmEventsByOrdinal)
        if(crmEventTypeIDs.length > 0) {
            if(crmEventTypeIDs.includes(20)) {
                return crmEventsByOrdinal.find((crmEvent) => crmEvent.action.id == 20)
            } else if(crmEventTypeIDs.includes(27)) {
                return crmEventsByOrdinal.find((crmEvent) => crmEvent.action.id == 27)
            } else if (crmEventTypeIDs.includes(18)) {
                return crmEventsByOrdinal.find((crmEvent) => crmEvent.action.id == 18)
            } else if (crmEventTypeIDs.includes(17)) {
                return crmEventsByOrdinal.find((crmEvent) => crmEvent.action.id == 17)
            } else if (crmEventTypeIDs.includes(16)) {
                return crmEventsByOrdinal.find((crmEvent) => crmEvent.action.id == 16)
            } else if (crmEventTypeIDs.includes(15)) {
                return crmEventsByOrdinal.find((crmEvent) => crmEvent.action.id == 15)
            } else if (crmEventTypeIDs.includes(14)) {
                return crmEventsByOrdinal.find((crmEvent) => crmEvent.action.id == 14)
            } else if (crmEventTypeIDs.includes(13)) {
                return crmEventsByOrdinal.find((crmEvent) => crmEvent.action.id == 13)
            } else if (crmEventTypeIDs.includes(12)) {
                return crmEventsByOrdinal.find((crmEvent) => crmEvent.action.id == 12)
            } else if (crmEventTypeIDs.includes(11)) {
                return crmEventsByOrdinal.find((crmEvent) => crmEvent.action.id == 11)
            } else if (crmEventTypeIDs.includes(10)) {
                return crmEventsByOrdinal.find((crmEvent) => crmEvent.action.id == 10)
            } else if (crmEventTypeIDs.includes(9)) {
                return crmEventsByOrdinal.find((crmEvent) => crmEvent.action.id == 9)
            } else if (crmEventTypeIDs.includes(22)) {
                return crmEventsByOrdinal.find((crmEvent) => crmEvent.action.id == 22)
            } else if (crmEventTypeIDs.includes(8)) {
                return crmEventsByOrdinal.find((crmEvent) => crmEvent.action.id == 8)
            } else if (crmEventTypeIDs.includes(6)) {
                return crmEventsByOrdinal.find((crmEvent) => crmEvent.action.id == 6)
            } else if (crmEventTypeIDs.includes(7)) {
                return crmEventsByOrdinal.find((crmEvent) => crmEvent.action.id == 7)
            } else if (crmEventTypeIDs.includes(5)) {
                return crmEventsByOrdinal.find((crmEvent) => crmEvent.action.id == 5)
            } else if (crmEventTypeIDs.includes(1)) {
                return crmEventsByOrdinal.find((crmEvent) => crmEvent.action.id == 1)
            } else if (crmEventTypeIDs.includes(21)) {
                return crmEventsByOrdinal.find((crmEvent) => crmEvent.action.id == 21)
            } else if (crmEventTypeIDs.includes(4)) {
                return crmEventsByOrdinal.find((crmEvent) => crmEvent.action.id == 4)
            } else if (crmEventTypeIDs.includes(3)) {
                return crmEventsByOrdinal.find((crmEvent) => crmEvent.action.id == 3)
            } else if (crmEventTypeIDs.includes(2)) {
                return crmEventsByOrdinal.find((crmEvent) => crmEvent.action.id == 2)
            } else if (crmEventTypeIDs.includes(19)) {
                return crmEventsByOrdinal.find((crmEvent) => crmEvent.action.id == 19)
            } else {
                return null
            }
            // return crmActionsByOrdinal[0]
        } else {
            return null
        }
    } else {
        return null
    }
}

export function getComputedCRMStatus(company) {
    let crmEventIds = [...new Set(company.crm_actions.filter((crmAction) => crmAction.campaign_id == company.campaign.id).filter(crmAction => crmAction.action.resolved_type == 'event').map((crmAction) => crmAction.action_id))]
    // console.log('crmEventIds', crmEventIds)
    if(crmEventIds.length > 0) {
        if(crmEventIds.includes(28) || crmEventIds.includes(29)) {
            return {
                label: 'Dead',
                value: 11
            }
        } else if(crmEventIds.includes(20)) {
            return {
                label: 'Client Passed',
                value: 10
            }
        } else if(crmEventIds.includes(27)) {
            return {
                label: 'Target Passed',
                value: 9
            }
        } else if (crmEventIds.includes(18)) {
            return {
                label: 'Transaction Closed',
                value: 0
            }
        } else if(crmEventIds.includes(26)) {
            return {
                label: 'Revist / Cut',
                value: 8
            }
        } else if(crmEventIds.includes(25)) {
            return {
                label: 'Not Now / Keep Warm',
                value: 7
            }
        } else if (crmEventIds.includes(16) || crmEventIds.includes(17)) {
            return {
                label: 'Under LOI',
                value: 1
            }
        } else if (crmEventIds.includes(15)) {
            return {
                label: 'IOI Submitted',
                value: 2
            }
        } else if (
            crmEventIds.includes(24) ||
            crmEventIds.includes(14) ||
            crmEventIds.includes(13) ||
            crmEventIds.includes(12) ||
            crmEventIds.includes(11) ||            
            crmEventIds.includes(10) ||
            crmEventIds.includes(9)
        ) {
            return {
                label: 'Active - In Dialogue',
                value: 3
            }
        } else if (
            crmEventIds.includes(22) ||
            crmEventIds.includes(8) ||
            crmEventIds.includes(6)
        ) {
            return {
                label: 'Responded / Coverage',
                value: 4
            }
        } else if (
            crmEventIds.includes(7) ||
            crmEventIds.includes(5) ||
            crmEventIds.includes(4) ||
            crmEventIds.includes(3) ||            
            crmEventIds.includes(2) ||
            crmEventIds.includes(1)
        ) {
            return {
                label: 'No Response',
                value: 5
            }
        } else {
            return {
                label: 'Not Contacted',
                value: 6,
            }
        }
    } else {
        return {
            label: 'Not Contacted',
            value: 6,
        }
    }
}

export function getComputedCRMStatusByEventId(crmEventId) {
    // console.log('getComputedCRMStatusByEventId', crmEventId)
    if(crmEventId) {
        if(crmEventId == 28 || crmEventId == 29) {
            return {
                label: 'Dead',
                value: 11
            }
        } else if(crmEventId == 20) {
            return {
                label: 'Client Passed',
                value: 9
            }
        } else if(crmEventId == 27) {
            return {
                label: 'Target Passed',
                value: 10
            }
        } else if (crmEventId == 18) {
            return {
                label: 'Transaction Closed',
                value: 0
            }
        } else if(crmEventId == 26) {
            return {
                label: 'Revist / Cut',
                value: 8
            }
        } else if(crmEventId == 25) {
            return {
                label: 'Not Now / Keep Warm',
                value: 7
            }
        } else if (crmEventId == 16 || crmEventId == 17) {
            return {
                label: 'Under LOI',
                value: 1
            }
        } else if (crmEventId == 15) {
            return {
                label: 'IOI Submitted',
                value: 2
            }
        } else if (
            crmEventId == 24 ||
            crmEventId == 14 ||
            crmEventId == 13 ||
            crmEventId == 12 ||
            crmEventId == 11 ||            
            crmEventId == 10 ||
            crmEventId == 9  ||
            crmEventId == 22 ||
            crmEventId == 8 ||
            crmEventId == 6
        ) {
            return {
                label: 'Active - In Dialogue',
                value: 3
            }
        } else if (
            crmEventId == 31
        ) {
            return {
                label: 'Responded / Coverage',
                value: 6
            }
        } else if (
            crmEventId == 7 ||
            crmEventId == 5 ||
            crmEventId == 4 ||
            crmEventId == 3 ||            
            crmEventId == 2 ||
            crmEventId == 1
        ) {
            return {
                label: 'No Response',
                value: 4
            }
        } else {
            return {
                label: 'Not Contacted',
                value: 5,
            }
        }
    } else {
        return {
            label: 'Not Contacted',
            value: 5,
        }
    }
}

export function getDefaultProgressUpdateByDate(company, date) {
    if(company.crm_event_notes && company.crm_event_notes.length > 0) {
        if(company.crm_event_notes.filter((event_note) => event_note.date == date).length > 0) {
            return company.crm_event_notes.filter((event_note) => event_note.date == date)[0]
        }
    }
     return null
}
