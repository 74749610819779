<template>
    <div v-if="printView" class="w-full h-full flex flex-col ">
        <!-- <div class="w-full text-gray-700 tracking-widest font-semibold text-lg p-4 space-x-2 flex justify-between items-center">
            <div class="text-base items-center justify-center tracking-wider whitespace-nowrap text-gray-600 pt-0.5">
                {{ numCompanies }} {{ companyLabel }} in {{ numCampaigns }} {{ campaignLabel }}
            </div>
        </div> -->
        <div class="w-full h-[calc(100vh-12rem)] flex -x-scroll border-l border-gray-200">
            <table class="w-full h-full ">
                <thead class="-top-[1px] left-0 right-0">
                    <th v-for="header in printedHeaders" :key="header.fieldName" class="px-2 py-1 border-b border-r whitespace-nowrap border-gray-200 bg-white text-gray-700 tracking-widest font-semibold sticky -top-[1px]">
                        <div class="flex justify-start">
                            {{ header }}
                            <!-- {{ formatHeader(header.fieldName) }} -->
                        </div>
                    </th>
                </thead>
                <tbody class="-y-auto">
                    <tr v-for="(company, index) in selectedCompanies.toSorted((a, b) => {return a.computed_crm_status.value - b.computed_crm_status.value})" :key="company.id" class="odd:bg-gray-50 even:bg-white h-8">
                        <td class="px-2 py-1 border-r border-b border-gray-200 max-w-[450px] align-top text-left">{{ getListNumberByIndex(index) }}.</td>
                        <td class="px-2 py-1 border-r border-b border-gray-200 max-w-[450px] align-top text-left">{{ company.computed_crm_status.label }}</td>
                        <td class="px-2 py-1 border-r border-b border-gray-200 max-w-[450px] align-top text-left">{{ company.company_name }}</td>
                        <td class="px-2 py-1 border-r border-b border-gray-200 max-w-[450px] align-top text-left">{{ company.campaign.campaign_name }}</td>
                        <td class="px-2 py-1 border-r border-b border-gray-200 max-w-[450px] align-top text-left">{{ company.reported_crm_event ? company.reported_crm_event.action.action_name : '--' }}</td>
                        <td class="px-2 py-1 border-r border-b border-gray-200 max-w-[450px] align-top text-left">{{ company.reported_crm_event ? formatDate(company.reported_crm_event.resolved_date) : '--' }}</td>
                        <!-- <td  v-for="[fieldName, fieldValue] in printedFields(Object.entries(company))" :key="fieldName" class="px-2 py-1 border-r border-b border-gray-200 max-w-[450px] align-top text-left">
                            <div class="truncate flex justify-start items-start">
                                {{ handleFieldDisplay(fieldName, fieldValue) }}
                            </div>
                        </td> -->
                    </tr>
                    <tr v-for="emptyPrintRows in emptyPrintRows" :key="emptyPrintRows.id" class="odd:bg-gray-50 even:bg-white h-8">
                        <td class="px-2 py-1 border-r border-b border-gray-200 max-w-[450px] align-top text-left"></td>
                        <td class="px-2 py-1 border-r border-b border-gray-200 max-w-[450px] align-top text-left"></td>
                        <td class="px-2 py-1 border-r border-b border-gray-200 max-w-[450px] align-top text-left"></td>
                        <td class="px-2 py-1 border-r border-b border-gray-200 max-w-[450px] align-top text-left"></td>
                        <td class="px-2 py-1 border-r border-b border-gray-200 max-w-[450px] align-top text-left"></td>
                        <td class="px-2 py-1 border-r border-b border-gray-200 max-w-[450px] align-top text-left"></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div v-else class="w-full h-full flex flex-col bg-white px-4 ">
        <div class="w-full text-sm text-gray-700 font-semibold tracking-widest py-3 space-x-2 flex justify-between items-center">
            <div class="flex justify-start items-center space-x-2 tracking-wider whitespace-nowrap pt-0.5">
                <div class="">
                    {{ numCompanies }} {{ companyLabel }} in {{ numCampaigns }} {{ campaignLabel }}
                </div>
                <div class="w-fit cursor-pointer hover:bg-gray-300 rounded-full p-1" @click="refetch">
                    <div  class="">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="#4B5563"
                            class=" w-6 h-6 hover:cursor-pointer"
                        >
                            <path
                                class=""
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                            />
                        </svg>
                    </div>
                </div>
            </div>
            <div v-if="numCompaniesToUpdate > 0" class="flex items-center justify-end font-normal space-x-2 text-gray-600">
                <div class="text-xs">Tracking {{ numCompaniesToUpdate}} {{ changeLabel }}</div>
                <button class="bg-dark-secondary hover:bg-dark-primary hover:text-gray-100 hover:shadow active:bg-dark-primary active:text-gray-400 text-white tracking-wider font-medium px-3 py-1 rounded-sm" @click="saveTargetList">
                    Save
                </button>
            </div>
            <div v-else class="flex items-center justify-end font-normal space-x-2 text-gray-600">
                <button disabled class="bg-gray-300 text-gray-500  tracking-wider font-medium px-3 py-1 rounded-sm" @click="saveTargetList">
                    Save
                </button>
            </div>
        </div>
        <div class="w-full h-[calc(100vh-12rem)] flex overflow-x-scroll border-l border-gray-200 border">
            <table class="w-full h-full border-separate border-spacing-0 bg-gray-50">
                <thead class="-top-[1px] left-0 right-0 relative">
                    <th 
                        v-for="(header, index) in columnHeaders" 
                        :key="header.fieldName" 
                        class=" border-r border-b-2 whitespace-nowrap border-gray-200 border-b-gray-300 bg-white text-gray-700 tracking-widest font-semibold text-xs sticky -top-[1px]"
                        :style="getHeaderStyleByIndex(index)"
                        >
                        <div v-if="index==0" class="flex justify-center w-full h-full">
                            <div class="w-full h-full pb-[1px] hover:bg-gray-100 flex items-center justify-center cursor-pointer" @click="setExpandAll('expanded')"><ChevronDownMiniSVG /></div>
                            <div class="w-full h-full hover:bg-gray-100 flex items-center justify-center cursor-pointer" @click="setExpandAll('collapsed')"><ChevronUpMiniSVG /></div>
                        </div>
                        <!-- <div v-else class="flex justify-start px-2 py-1">
                            {{ formatHeader(header.fieldName) }}
                        </div> -->
                        <div v-else class="flex justify-start w-full h-full">
                            <div class="px-2 py-1">
                                {{ formatHeader(header.fieldName) }}
                            </div>
                        </div>
                    </th>
                </thead>
                <tbody class="overflow-y-auto -top-[1px] relative">
                    <CRMTargetListRow
                        v-for="company in selectedCompanies" :key="company.id"
                        :company="company"
                        :headers="columnHeaders"
                        :expand-all="expandAll"
                        :toggleRowClick="toggleRowClick"
                        :changed-reported-event="changedCompanyReportedEvent(company)"
                        @set-reported-event="setReportedEvent"
                        @set-expand-all-neutral="setExpandAll"
                        @open-crm-details="openCRMDetails"
                    />  
                    <!-- <tr v-for="company in selectedCompanies" :key="company.id" class="odd:bg-gray-50 even:bg-white">
                        <td  v-for="[fieldName, fieldValue] in Object.entries(company)" :key="fieldName" class="px-2 py-1 border-r border-b border-gray-200 max-w-[450px] align-top text-left">
                            <div class="truncate flex justify-start items-start">
                                {{ handleFieldDisplay(fieldName, fieldValue) }}
                            </div>
                        </td>
                    </tr> -->
                </tbody>
            </table>
        </div>
        <base-modal :is-form-visible="visibleCRMDetails" @close="closeCRMDetails">
            <template #header>
            <h2 class="text-lg">
                {{ selectedCompany.company_name }} CRM Details
            </h2>
            </template>
            <template #body>
                <CRMDetailsPage
                    :company="selectedCompany" 
                    :campaigns="campaignOptions" 
                    :crm-statuses="allCRMStatuses" 
                    :crm-action-types="allCRMActions" 
                    @refetch-companies="refetch"
                    @updated="updatedEvents"
                />
            </template>
        </base-modal>
    </div>
</template>

<script setup>
import { ref, reactive, defineProps, defineEmits, watch, computed, onMounted } from 'vue';
import CRMDetailsPage from '../CRMDetailsPage.vue';
import ChevronUpMiniSVG from '@/components/svg-components/ChevronUpMiniSVG.vue';
import ChevronDownMiniSVG from '@/components/svg-components/ChevronDownMiniSVG.vue';
import CRMTargetListRow from './CRMTargetListRow.vue';
import { formatDate } from '@/composables/formatDate';

const props = defineProps([
    'printView',
    'clientName',
    'campaigns',
    'campaignOptions',
    'allCRMActions',
    'allCRMStatuses',
    'selectedCompanies',
    'companyIdsToUpdate',
    'startingIndex',
    'maxBatchLength'
])

const emit = defineEmits([
    'set-reported-event',
    'save-target-list',
    'refetch',
])

const columnHeaders = ref([])
const expandAll = ref('neutral')
const selectedCompany = ref(null)
const visibleCRMDetails = ref(false)
const toggleRowClick = ref(false)
const orderBy = ref(['computed_crm_status'])


watch(() => props.selectedCompanies, () => {
    // console.log('CRMTargetList, props.selectedCompanies changed')
}, {deep: true})

onMounted(() => {
    if(props.selectedCompanies && props.selectedCompanies.length > 0) {
        const companyObject = props.selectedCompanies[0]
        columnHeaders.value = Object.keys(companyObject).map((companyField, index) => {
            switch(companyField) {
                case 'computed_crm_status':
                return {fieldName: companyField, display: true, ordinal: 0}
                case 'company_name':
                    return {fieldName: companyField, display: true, ordinal: index}
                case 'campaign':
                    return {fieldName: companyField, display: true, ordinal: 1.25}
                case 'reported_crm_event':
                    return {fieldName: companyField, display: true, ordinal: 1.5}
                default:
                    return {fieldName: companyField, display: true, ordinal: index}
            }
        }).sort((a, b) => {return a.ordinal - b.ordinal})
    }
})

const printedHeaders = ref([
    'Row',
    'CRM Status',
    'Company Name',
    'Campaign',
    'Last CRM Event',
    'CRM Event Date'
])

// const printedHeaders = computed(() => {
//     if(columnHeaders.value && columnHeaders.value.length > 0) {
//         return [
//             columnHeaders.value[1],
//             columnHeaders.value[2],
//             columnHeaders.value[3],
//             columnHeaders.value[4],
//         ]
//     }
//     return []
// })

function  getListNumberByIndex(companyIndex) {
    return props.startingIndex + companyIndex + 1
}

function printedFields(companyFieldList) {
    let printedFieldsList = []
    companyFieldList.forEach((keyValue) => {
        if(keyValue[0] == 'computed_crm_status') {
            printedFieldsList.push(keyValue)
        } else if(keyValue[0] == 'company_name') {
            printedFieldsList.push(keyValue)
        } else if(keyValue[0] == 'campaign') {
            printedFieldsList.push(keyValue)
        } else if(keyValue[0] == 'reported_crm_event') {
             printedFieldsList.push(keyValue)
        }
    })
    return printedFieldsList
}


const numCompanies = computed(() => {
    return props.selectedCompanies ? props.selectedCompanies.length : 0
})

const companyLabel = computed(() => {
    if(numCompanies.value == 1) {
        return 'Target'
    }
    return 'Targets'
})

const numCompaniesToUpdate = computed(() => {
    return props.companyIdsToUpdate ? props.companyIdsToUpdate.length  : 0
})

const changeLabel = computed(() => {
    return numCompaniesToUpdate.value == 1 ? 'Change' : 'Changes'
})

const numCampaigns = computed(() => {
    return props.campaigns ? props.campaigns.length : 0
})

const campaignLabel = computed(() => {
    if(numCampaigns.value == 1) {
        return 'Campaign'
    }
    return 'Campaigns'
})

const emptyPrintRows = computed(() => {
    if(props.selectedCompanies.length < props.maxBatchLength) {
        const emptyRowsLength = props.maxBatchLength - props.selectedCompanies.length
        return Array(emptyRowsLength).fill({
            id: Math.floor(Math.random() * 100000)
        })
    }
    return []
})

function saveTargetList () {
    emit('save-target-list')
}

function setExpandAll(expandAllValue) {
    expandAll.value = expandAllValue
}

function refetch() {
    emit('refetch')
}

function updatedEvents() {
    closeCRMDetails()
}

function openCRMDetails(company) {
    selectedCompany.value = company
    visibleCRMDetails.value = true
}

function closeCRMDetails() {
    console.log('closeCRMDetails')
    visibleCRMDetails.value = false
    selectedCompany.value = null
    toggleRowClick.value = !toggleRowClick.value
}

function handleFieldDisplay(fieldName, fieldValue) {
    if(fieldValue) {
        if(fieldName == 'campaign') {
            return fieldValue.campaign_name
        }
        else if(fieldName == 'tier_object') {
            return fieldValue.tier_name
        }
        else if(fieldName == 'crm_actions') {
            if(fieldValue.length == 1) {
                return `${fieldValue.length} CRM Action`
            }
            return `${fieldValue.length} CRM Actions`
        }   
        else if(fieldName == 'reported_crm_event') {
            return `${formatDate(fieldValue.resolved_date)}: ${fieldValue.action.action_name}`
        }
        else if (fieldName == 'computed_crm_status') {
            return fieldValue.label
        }
        return fieldValue
    }
    return ''
}

function getHeaderStyleByIndex(index) {
    if(index == 0) {
        return `position: sticky; max-width: 46px; width: 46px; min-width: 46px; left: 0px; z-index: 20;`
    } else if(index == 1) {
        return `position: sticky; max-width: 200px; width: 200px; min-width: 200px; left: 46px; z-index: 20;` // 300
    } else if(index == 2) {
        return `position: sticky; max-width: 300px; width: 300px; min-width: 300px; left: 246px; z-index: 20;` // 150
    } else if (index == 3) {
        return `position: sticky; max-width: 150px; width: 150px; min-width: 150px; left: 546px; z-index: 20;` // 280
    } else if (index == 4) {
        return `position: sticky; max-width: 280px; width: 280px; min-width: 280px; left: 696px; z-index: 20; border-right: 2px solid #D1D5DB;`
    } else {
        return 'z-index: 10'
    }
}

function formatHeader(fieldName) {
    if(fieldName == 'reported_crm_event') {
        if(props.printView) {
            return 'CRM Detail'
        } else {
            return 'Reported Detail (*Auto Computed)'
        }
    } else if(fieldName == 'computed_crm_status') {
        return 'CRM Status'
    }
    let headerLabel = fieldName.replaceAll('_', ' ').replace(/(^| )(\w)/g, function(x) {return x.toUpperCase();}).replaceAll('Url', 'URL').replaceAll('Crm', 'CRM')
    return headerLabel
}

function changedCompanyReportedEvent(company) {
    if(props.companyIdsToUpdate.includes(company.id)) {
        return true
    }
    return false
}

function setReportedEvent(company, event, autoCompute) {
    emit('set-reported-event', company, event, autoCompute)
}


</script>