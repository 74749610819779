<template>
    <div class="static">
        <Transition appear
            enter-active-class="transition duration-200 ease-in"
            enter-from-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition duration-200 ease-out"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
            class="h-full"
            >
            <div
                v-if="isFormVisible"
                class="fixed top-0 bottom-0 left-0 right-0 bg-black/[0.3] flex justify-center items-center z-30"
                tabindex="-1" >
            </div>
        </Transition>
        <Transition appear
            enter-active-class="transition duration-200 ease-in"
            enter-from-class="scale-0"
            enter-to-class="scale-100"
            leave-active-class="transition duration-200 ease-out"
            leave-from-class="scale-100"
            leave-to-class="scale-0"
            class="h-full"
            >
            <div
                v-if="isFormVisible"
                class="fixed top-0 bottom-0 left-0 right-0 flex justify-center items-center z-40 h-full"
                tabindex="-1">
                <div 
                    :style="stackLevel"
                    class="flex flex-col bg-gray-100 shadow-lg-gray-100 rounded-sm"
                    :class="modalSize"
                >
                    <header 
                        class="flex shrink-0 h-10 justify-between items-center relative bg-dark-primary rounded-t-sm pl-3 pr-2 border-solid border-b text-white text-sm  tracking-wider border-dark-secondary"
                        ref="modalHeaderBoundary"
                    >
                        <slot name="header"></slot>
                        <div class="flex justify-between space-x-2 items-center"
                            ref="modalMenuBoundary"
                        >
                            <button
                                v-if="showModalMenuButton"
                                class="mb-4 rounded-sm text-base text-gray-800 hover:text-gray-50 hover:underline active:text-gray-50 active:underline"
                                type="button"
                                
                                @click="toggleModalMenu"
                            >
                                ...
                            </button>
                            <button
                                class="h-6 w-6 rounded-sm flex justify-center border border-dark-secondary items-center bg-dark-secondary text-white active:bg-dark-primary"
                                type="button"
                                @click="close"
                                @mouseenter="highlightCloseButton"
                                @mouseleave="unhighlightCloseButton"
                            >
                                <XMarkSVG
                                    :stroke="closeButtonStroke"
                                    width="3"
                                />
                            </button>
                        </div>
                        <PopoverClick
                            v-if="visibleModalMenu"
                            class="bg-white p-2 rounded-md shadow"
                            :xPosition="xPosition"
                            :yPosition="yPosition"
                            :relativeX="relativeX"
                            :relativeY="relativeY"
                        >
                            <div class="flex flex-col w-full h-full">
                                <div
                                    @mouseenter="toggleOrangeSVGFill"
                                    @mouseleave="toggleBlueSVGFill"
                                    @click="archiveRecord"
                                    class="flex cursor-pointer w-full py-1 px-2 justify-start items-center space-x-2 rounded-sm hover:bg-tertiary text-primary-extra-dark hover:text-tertiary-extra-dark">
                                    <div class="flex">
                                        <TrashSVG
                                            class="hover:fill-tertiary-extra-dark"
                                            :fill="trashSVGFill"
                                        />
                                    </div>
                                    <div class="text-base pt-0.5 ">
                                        {{ removalMessage }}
                                    </div>
                                </div>
                            </div>
                        </PopoverClick>
                    </header>
                    <section class="flex w-full h-[calc(100%-2.5rem)]">
                        <slot name="body"></slot>
                    </section>
                </div>
            </div>
        </Transition>
    </div>
</template>

<script setup>
import { defineEmits, defineProps, ref, computed, watch } from 'vue';
import PopoverClick from './PopoverClick.vue';
import TrashSVG from '../svg-components/TrashSVG.vue';
import { useClickOutside } from "@/composables/useClickOutside";
import XMarkSVG from '../svg-components/XMarkSVG.vue';

const props = defineProps(['isFormVisible', 'showModalMenuButton', 'stack', 'removeType', 'modalSize'])

const emit = defineEmits(['close', 'archive-record'])

const visibleModalMenu = ref(false)
const modalMenuBoundary = ref(null)
const modalHeaderBoundary = ref(null)
const xPosition = ref(null)
const yPosition = ref(null)
const relativeX = ref(null)
const relativeY = ref(null)
const trashSVGFill = ref('#154B51')
const closeButtonStroke =ref('white')

// const stackLevel = computed(() => {
//     if(props.stack){
//         console.log(`translate-x-${props.stack * 4} translate-y-${props.stack * 4}`)
//         return `translate-x-${props.stack * 4} translate-y-${props.stack * 4}`
//     }
//     return ''
// })

const stackLevel = computed(() => {
    if(props.stack){
        return `position: relative; top: ${props.stack * 20}px; left: ${props.stack * 20}px;`
    }
    return ''
})

watch((props), () => {
    console.log('visible in modal', props.isFormVisible)
})


const removalMessage = computed(() => {
    return props.removeType ? props.removeType : 'Archive Record'
})

function close () {
  emit('close')
}

useClickOutside(modalHeaderBoundary, () => {
    if(visibleModalMenu.value) {
      hideModalMenu()
    }
  })

function toggleBlueSVGFill() {
    trashSVGFill.value = "#154B51"
}

function toggleOrangeSVGFill() {
    trashSVGFill.value = "#743B06"
}

function highlightCloseButton () {
    closeButtonStroke.value = "#25274D"
}

function unhighlightCloseButton () {
    closeButtonStroke.value = "white"
}

function toggleModalMenu() {
    if(!visibleModalMenu.value) {
        xPosition.value = modalMenuBoundary.value.getBoundingClientRect().left - 110
        yPosition.value = modalMenuBoundary.value.getBoundingClientRect().bottom - 5
        relativeX.value = 'left'
        relativeY.value = 'top'
        visibleModalMenu.value = true
    } else {
        hideModalMenu()
    }
}

function hideModalMenu() {
    xPosition.value = null
    yPosition.value = null
    relativeX.value = null
    relativeY.value = null
    visibleModalMenu.value = false
}

function archiveRecord() {
    console.log('archiveRecord')
    emit('archive-record')
}

console.log('is form visible?', props.isFormVisible)

</script>